// This class...


class CountdownTimer {
    constructor(duration, updateCallback, warningCallback, startCallback, endCallback, warningTime = 30000) {
        this._timeRemaining = duration
        this._warningTime = warningTime
        this._updateCallback = updateCallback
        this._warningCallback = warningCallback
        this._startCallback = startCallback
        this._endCallback = endCallback

        this._interval = 1000 // ms
        this._endTime = this._getCurrentTime() + duration
    }


    // Public methods
    startTimer = () => {
        // Dont allow multiple starts
        if (this.countdownTimer) {
            return
        }

        // Do the starting function
        if (this._startCallback !== null) {
            this._startCallback()
        }


        this._expectedRunTime = this._getCurrentTime() + this._interval;
        this.countdownTimer = setTimeout(this._timerTick, this._interval);

        // this.countdownTimer = setInterval(this._timerTick, 1000) // old way

    }

    stopTimer = () => {
        this._clearTimerInterval()
    }


    // Private methods
    _timerTick = () => {
        const deltaDrift = this._getCurrentTime() - this._expectedRunTime; // The drift can be +/-
        if (deltaDrift > this._interval) {
            // Special handling to catch up if the delta's larger than the interval (Maybe the browser tab was inactive?)
            // Recalibrate
            this._timeRemaining = this._endTime - this._getCurrentTime()
            this._expectedRunTime = this._getCurrentTime() + this._interval;
            this.countdownTimer = setTimeout(this._timerTick, this._interval)
            return
        }



        // If class is ended clear the timer and run the end fn
        if (this._timeRemaining <= 0) {
            this._clearTimerInterval()

            if (this._endCallback !== null) {
                this._endCallback()
            }

            return
        }

        // When theres X secs remaining fire the warning callback fn
        if (this._timeRemaining <= this._warningTime) {
            if (this._warningCallback !== null) {
                this._warningCallback()
            }
        }

        // Parse out the components given a duration in ms
        //var days = Math.floor(this._timeRemaining / (1000 * 60 * 60 * 24));
        var hours = Math.floor((this._timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((this._timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((this._timeRemaining % (1000 * 60)) / 1000);

        // Show/hide the hours/minutes indication
        var hoursText = ''

        if (hours > 0) {
            hoursText = hours + 'h'
        }

        var minutesText = ''

        if (minutes > 0) {
            minutesText = minutes + 'm'
        }

        // Compile the text
        const text = hoursText + minutesText + seconds + 's'

        // Do the update function with the new time
        if (this._updateCallback !== null) {
            this._updateCallback(text)
        }


        // Update the time to wait until firing next and the time remaining
        const waitTime = this._interval - deltaDrift
        this._timeRemaining -= this._interval


        this._expectedRunTime += this._interval // This is fine (and not += waitTime) because code will wait more or less then a second to catch up
        this.countdownTimer = setTimeout(this._timerTick, waitTime)

    }

    _clearTimerInterval = () => {
        clearTimeout(this.countdownTimer)
        this.countdownTimer = null
    }

    _getCurrentTime = () => {
        return Date.now() + window.timeoffset

    }

}

export default CountdownTimer;
