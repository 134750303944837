import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API, graphqlOperation } from 'aws-amplify';

/**
 * React hook for subscribing to an AppSync subscription. Subscription events
 * are passed to a provided callback.
 *
 * @param {string} operation The graphql subscription to listen to
 * @param {function} callback A callback to pass the event data to
 */
const useGraphQLSubscription = (operation, callback) => {
  const { identity } = useSelector(state => state.auth);

  useEffect(() => {
    if (!identity) {
      return;
    }

    window.configure_amplify('arcade_endpoint');
    const op = new graphqlOperation(operation, { identity });
    const subscription = API.graphql(op).subscribe({
      error: console.error,
      next: event => {
        const subscriptionName = Object.keys(event.value.data)[0];
        return callback(event.value.data[subscriptionName]);
      }
    });

    return () => subscription.unsubscribe();
  }, [operation, identity, callback]);
};

export default useGraphQLSubscription;
