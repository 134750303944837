/*

 Signup For a Class near you
  > iFrame with Programs
 OR 
 Signup Try our Online
  > Register Online

Add Online to Programs page?
  Day/Time

If this is my game
  Show options for Public/Private
  Change title of game
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { track } from 'react-tracking';
import clsx from 'clsx';

import { bindActionCreators } from 'redux';
import { setNavbar } from 'actions/activityAction';
import { setActivity } from 'actions/activityAction';

import Leaderboard from './Leaderboard'
import Action from 'components/Action'
//import Game from './Game'
import Skills from './Skills'
import Avatar from './Avatar'
import Rate from './Rate'

// graphQL functions
import { send } from 'graphql/handler';
import { getUserPublicRecord } from 'graphql/studio';

import './styles.scss';

const avatars = [
  'avatar_frank_01.png', 'avatar_ghost_02.png', 'avatar_mummy_03.png', 'avatar_vampire_01.png', 'avatar_witch_02.png', 'avatar_wolf_03.png',
  'avatar_frank_02.png', 'avatar_ghost_03.png', 'avatar_skull_01.png', 'avatar_vampire_02.png', 'avatar_witch_03.png',
  'avatar_frank_03.png', 'avatar_mummy_01.png', 'avatar_skull_02.png', 'avatar_vampire_03.png', 'avatar_wolf_01.png',
  'avatar_ghost_01.png', 'avatar_mummy_02.png', 'avatar_skull_03.png', 'avatar_witch_01.png', 'avatar_wolf_02.png'
]
const ratings_icons = ['badge_bg_lvl_01.png', 'badge_bg_lvl_02.png', 'badge_bg_lvl_03.png']

function random(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}


class GameModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'default',
      urls: {},
      ratings: {},
      leaderboard: [],
      gameplay_id: null,
      webgl: null
    };

  }

  componentDidMount() {
    try {
      this.instructor = this.props.authentication.groups.indexOf('Instructor') > -1
      this.init_channels()
      this.init_stats()
      this.init_avatar()
    }
    catch(err) {
      // console.log("-pfg gm componentDidMount error: ", err)
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.currentArtifact) !== JSON.stringify(this.props.currentArtifact)) {
      try {
        this.init_channels()
        this.init_stats()
        this.init_avatar()
      }
      catch{
      }
      this.setState({ mode: 'default', webgl_url: null });
    }
    if (this.props.close && prevProps.close !== this.props.close) {
      this.handleClose()
    }

  }

  init_channels = () => {
    var channels = {}
    this.props.currentArtifact &&
      this.props.currentArtifact.game &&
      Object.keys(this.props.currentArtifact).map(item => {
        if (item === 'webgl') {
          channels[item] = this.props.currentArtifact[item]
          return 1
        }
        channels[item] = this.get_signed_url(this.props.currentArtifact.public.identity + '/' + this.props.currentArtifact[item])

        return 1;
      })
    channels && this.setState({ ...channels })
  }

  init_stats = () => {
    var ratings = this.state.ratings
    this.props.currentArtifact.statistics && Object.keys(this.props.currentArtifact.statistics.rating).map(key => {
      var value = Math.max(0, this.props.currentArtifact.statistics.rating[key] - 1)
      ratings[key] = 'skills/' + ratings_icons[value]
      return this.get_signed_url(ratings[key])
    })
    this.setState({ ratings: ratings })
  }

  init_avatar = () => {
    // const avatar = 'avatars/' + avatars[Math.floor(random(this.props.currentArtifact.public.username.length) * avatars.length)] // Random avatar

    // If the identity is missing dont make the call
    if (!(this.props.currentArtifact && this.props.currentArtifact.public && this.props.currentArtifact.public.identity)) {
      return
    }

    var avatar = ''
    
    window.configure_amplify('studio_endpoint')
    send(getUserPublicRecord, { identity: this.props.currentArtifact.public.identity}, (response) => {
      if (!response || !response.avatar) {
        console.log("-pfg gc error getting required user public record data: ", response)
        return
      }

      avatar = 'avatars/' + response.avatar
      this.setState({userAvatar: response.avatar})

      this.get_signed_url(avatar)

    })
  
  }

  getAvatar = () => {
    const avatar = 'avatars/' + this.state.userAvatar
    const signedAvatar = this.state.urls[avatar]
    
    return signedAvatar

  }

  get_signed_url = (data) => {
    if (!this.props.authentication || !data) {
      return
    }

    // Cache hit
    if (this.state.urls[data]) {
      return this.state.urls[data]
    }

    // Cache miss, generate a new one
    var urls = this.state.urls
    urls[data] = this.props.authentication.s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: data, Expires: 3600 })
    this.setState({ urls: urls })
  }

  register_gameplay = (response) => {
    if (response.data.createEvent.action === 'play-game') {
      this.setState({ gameplay_id: response.data.createEvent.id })
    }
  }

  onPlay = event => {
    if (['load', 'loading'].indexOf(this.state.mode) > -1) {
      return
    }
    // record data in event log so we can update how many times game was played
    this.props.tracking.trackEvent({
      input: event.type,
      action: 'play-game',
      username: this.props.authentication.username,
      parameters: {
        payload:
        {
          aggregate_id: this.props.currentArtifact.game.id,
        }
      },
      callback: this.register_gameplay
    });
    this.setState({
      mode: 'load',
    }, () => {
      setTimeout(() => {
        this.setState({ webgl_url: 'https://ume-studio-user.s3-us-west-2.amazonaws.com/' + this.props.currentArtifact.public.identity + '/' + this.state.webgl + '/index.html' })

      }, 1500)
    });
  };

  open_studio = (data) => {
    console.log('open_studio', data)
    if (data.data.createEvent.action === 'open') {
      //this.setState({ gameplay_id: data.data.createEvent.id })
      this.props.setActivity({
        action: 'studio',
        id: data.data.createEvent.username + '_' + data.data.createEvent.id,
        navbar: false
      })
    }
  }

  onOpen = event => {
    // record data in event log so we can update how many times game was played
    console.log('Open')
    if (this.state.mode === 'open') {
      return
    }
    this.props.tracking.trackEvent({
      input: event.type,
      action: 'open',
      username: this.props.authentication.username,
      parameters: {
        payload:
        {
          aggregate_id: this.props.currentArtifact.game.id,
        }

      },
      callback: this.open_studio
    });
    this.setState({ mode: 'open' });
  };

  onEdit = event => {
    // record data in event log so we can update how many times game was played
    console.log('Edit')
    if (this.state.mode === 'edit') {
      this.setState({ mode: null })
      this.slider.slickGoTo(0)
      return
    }
    this.slider.slickGoTo(1)
    this.setState({ mode: 'edit' });
  };

  cancel_load = event => {
    this.setState({ mode: 'default', rate: false, webgl_url: null });
    this.props.onClose()
  }

  quit_game = event => {
    console.log('quitGame', event)
    this.setState({ mode: 'default', rate: true, webgl_url: null })

    this.props.tracking.trackEvent({
      input: event ? event.type : 'click',
      action: 'stop-game',
      username: this.props.authentication.username,
      parameters: {
        payload:
        {
          aggregate_id: this.props.currentArtifact.game.id,
          gameplay_id: this.state.gameplay_id
        }
      },
      callback: (response) => {
        console.log('Quit Game')
        var duration = parseInt(response.data.createEvent.id) - parseInt(this.state.gameplay_id)
        if (duration > 30000) {
          console.log('Duration', duration)
          this.setState({ mode: 'rate' })
        }
      }
    });
  }

  cancel_rating = event => {
    this.setState({ mode: 'default', rate: false })
  }

  quit_rating = event => {
    this.setState({ mode: 'default', rate: false })
  }

  update_mode = value => {
    this.setState({ mode: value })
    this.props.setNavbar({ navbar: !(value === 'loaded') })

  }
  
  handleClose = (event) => {
    switch (this.state.mode) {
      case 'load':
        //this.setState({ mode: 'default', webgl_url: null })
        this.quit_game(event);
        return this.props.closeCallback(false)
      case 'rate':
        //this.setState({ mode: 'default', webgl_url: null })
        this.quit_rating(event);
        return this.props.closeCallback(false)
      default:
        this.setState({ mode: 'default', webgl_url: null })
        return this.props.closeCallback(true)
    }
  }

  onRating = (value) => {
    this.props.tracking.trackEvent({
      input: 'rating',
      action: 'rate-game',
      username: this.props.authentication.username,
      parameters: {
        payload:
        {
          aggregate_id: this.props.currentArtifact.game.id,
          gameplay_id: this.state.gameplay_id,
          rating: value,
        }
      }
    });
    this.quit_rating()
  }

  render_rate = () => {
    if (this.state.mode === 'rate') {
      return (<Rate criteria={{ graphics: 'Graphics', level: 'Level Design', sound: 'Sound Design' }} maxRating={3} onDone={this.onRating} />)
    }
    return (<div />)

  }

  render() {
    const { currentArtifact, authentication } = this.props;

    const classes = {
      game: clsx('gameModal__wrapper_pg'),
      game_play: clsx('game_play', ['load', 'loading'].indexOf(this.state.mode) > -1 && 'play')
    };
    var actions = [{ label: this.state.mode === 'load' ? 'loading' : 'play', fn: this.onPlay }]

    var ratings = {}
    Object.keys(this.state.ratings).map(key => {
      ratings[key] = this.state.urls[this.state.ratings[key]]
      return 1
    })
    return (
      <div className={classes.game}>
        <div className="content selected">
          <div className="actions" onClick={this.handleClose}>
            <span className="close" ></span>
          </div>
          <div className={classes.game_play}>

            <div className="leaderboard ">
              <Leaderboard aggregate_id={currentArtifact.game.id} />
            </div>
            <div className="skills">
              <Skills skills={ratings} />
            </div>
            <div className="action_btn">
              <Action actions={actions} />
            </div>
            <div className="preview" key={this.props.currentArtifact.public.identity + '/' + currentArtifact.video}>
              <div className="vignette" />
              <video autoPlay={true} loop={true} muted={true} playsInline={true} poster={this.state.urls[this.props.currentArtifact.public.identity + '/' + currentArtifact.thumbnail]} >
                {/* <source src={this.state.urls[this.props.currentArtifact.public.identity + '/' + currentArtifact.video]} type="video/webm" /> */}
              </video>
              <img
                className="title-overlay"
                src={this.state.urls[this.props.currentArtifact.public.identity + '/' + currentArtifact.title]}
                alt={currentArtifact.game.name}
              />
              <div className="game">
                <iframe title={this.props.currentArtifact.public.identity + '/' + this.state.webgl} src={this.state.webgl_url} scrolling="no" frameBorder="0" allowtransparency="true" width={'calc(100% - 1.5em)'} height={'100%'} />
              </div>
              {this.render_rate()}
            </div>
            <div className="avatar ">
              {this.getAvatar() && <Avatar username={(currentArtifact.public && currentArtifact.public.username) || ""} avatar={this.getAvatar()} />}
              {this.instructor ? (<div className="gameId">{currentArtifact.game.id}</div>) : ''}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.auth.authentication,

});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setActivity, setNavbar }, dispatch);

const TrackedLeaderBoards = track({
  targetToLeaderBoards: { type: 'ui-object', name: 'GameModal' }
})(GameModal);

export default connect(mapStateToProps, mapDispatchToProps)(TrackedLeaderBoards);

