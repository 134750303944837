import Badge from 'components/Badge';
import UMEButton from 'components/UMEButton';
import Background from '../assets/school-membership.png';

const OrgCard = () => (
  <Badge
    className="badge-wrapper--subscription badge-wrapper--org"
    title="School/&#8203;Organization"
    backgroundImage={Background}
    cta={(
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <UMEButton
        href="https://ume.academy/contact"
        target="_blank"
        className="badge-wrapper__button badge-wrapper__button--constrained"
      >
        Contact Us
      </UMEButton>
    )}
  >
    <ul className="membership-card__details membership-card__details--centered">
      <li className="membership-card__detail-item">
        Unlimited access to Video Game Design lessons &amp; projects,
      </li>
      <li className="membership-card__detail-item">
        Create learner cohorts and gain access to student portfolios,
      </li>
      <li className="membership-card__detail-item">
        Get access to technical &amp; training support
      </li>
    </ul>
  </Badge>
);

export default OrgCard;
