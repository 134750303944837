import React from 'react';
import { Link } from 'react-router-dom';
import UMEButton from 'components/UMEButton';
import MembershipWallButton from '../MembershipWallButton';
import { tags } from 'models/constants';
import './styles.scss';

/**
 * React component for displaying a single lesson on the learn page. Can be
 * displayed in a standalone context or included inside a CourseCard.
 */
const LessonCard = ({ activity, level = 'intro', index = null }) => (
  <article
    className="lesson-card"
  >
    <div className="lesson-card__left">
      <img
        src={activity.thumbnail}
        className="lesson-card__image"
        width="75px"
        height="46px"
        alt=""
      />
      <Link className="lesson-card__title" to={`/lessons/${activity.context}`}>
        {index !== null && `${index + 1}`.padStart(2, '0') + ' -'} {activity.title}
      </Link>
    </div>

    <div className="lesson-card__right">
      <div className="lesson-card__level">
        {level}
      </div>

      <MembershipWallButton
        to={`/lessons/${activity.context}`}
        membershipRequired={activity.tags.has(tags.MEMBERSHIP_REQUIRED)}
        short
      >
        <UMEButton to={`/lessons/${activity.context}`}>
          View
        </UMEButton>
      </MembershipWallButton>
    </div>
  </article>
);

export default LessonCard;
