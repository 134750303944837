// This class handles writing stats to the database

import { send } from 'graphql/handler';
import { TIME } from '../components/MenuBar/Rundown';
import { updateStats, stampFrontendError, stampUserInformation } from 'graphql/studio';


class DDBStamper {
    constructor() {
        // Setting up the singleton
        if (DDBStamper._instance instanceof DDBStamper) {
            return DDBStamper._instance
        }

        DDBStamper._instance = this

        this._statsEntries = {}
        this._initStatsEntryTime = null;
    }


    // Public functions
    addStatsEntry = (classId, statsEntry) => {
        const currentStatsEntries = { ...this._statsEntries, ...statsEntry };

        /**
         * Condition 1: If `currentEntriesKeys` contains both "dcvStats" and "twilioNetworkStats"
         *   - Use Sets if we start looking for more than two values
         * Condition 2: If the last time we'd added a stats entry was more than 1 minute ago
         * 
         * If either condition 1 or condition 2 is true, send a stats update to DDB.
         */
        if (
            (currentStatsEntries.dcvStats && currentStatsEntries.twilioNetworkStats) ||
            (this._getCurrentTime() - parseInt(this._initStatsEntryTime) > TIME.ONE_MINUTE)
        ) {
            window.configure_amplify('studio_endpoint');
            send(updateStats, {
                input: {
                    sort_key: classId,
                    stats: JSON.stringify({ ...currentStatsEntries, timestamp: this._getCurrentTime() })
                }
            });
            this._statsEntries = {};
            this._initStatsEntryTime = null;
        } else {
            this._statsEntries = { ...this._statsEntries, ...statsEntry };

            if (!this._initStatsEntryTime) {
                // If this is the first stats entry (ie. _initStatsEntryTime === null), set _initStatsEntryTime to current time.
                // Set _initStatsEntryTime to current time so the next time we call `addStatsEntry()`, we can compare when we last added a stats entry.
                this._initStatsEntryTime = this._getCurrentTime();
            }
        }
    }

    stampFrontendError = (classId, entry) => {
        window.configure_amplify('studio_endpoint');
        send(stampFrontendError, {
            input: {
                sort_key: classId,
                error: JSON.stringify({ ...entry, timestamp: this._getCurrentTime() })
            }
        });
    }

    stampUserInformation = (classId, entry) => {
        window.configure_amplify('studio_endpoint');
        send(stampUserInformation, {
            input: {
                sort_key: classId,
                userInfo: JSON.stringify({ ...entry, timestamp: this._getCurrentTime() })
            }
        });
    }


    // Private functions
    _getCurrentTime = () => {
        return Date.now() + window.timeoffset
    }

}


const ddbStamper = new DDBStamper();
Object.seal(ddbStamper);

export default ddbStamper;
