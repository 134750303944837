import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Layout from 'components/Layout';
import Memberships from './components/Memberships';
import Community from './components/Community';

const Subscribe = () => {
  const { id: planID } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { authenticated } = useSelector(state => state.auth);
  
  useEffect(() => {
    if (planID && authenticated === false) {
      history.replace('/login', {
        from: location.pathname,
        prompt: "You're on your way to a UME Games membership! Let's start by creating an account:"
      });
    }
  }, [planID, authenticated, history, location]);

  return (
    <Layout tab="learn" fitContent>
      <Memberships planID={planID} />
      {!planID && <Community />}
    </Layout>
  );
};

export default Subscribe;
