import React from 'react';

const DateTime = ({ timestamp, className, includeTime = true }) => {
  const date = new Date(timestamp);
  const dateString = date.toDateString();
  const timeString = date.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric'
  });

  let iso = 0;

  // Don't let an invalid date crash the whole page
  try {
    iso = date.toISOString();
  } catch (e) {
    console.error(e);
  }

  return (
    <time
      role="note"
      aria-label="time"
      dateTime={iso}
      className={className}
    >
      {dateString} {includeTime && `@ ${timeString}`}
    </time>
  );
};

export default DateTime;
