import React, { Component } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

import './styles.scss';


class AudioIndicator extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

    }

    static defaultProps = {
        student: null
    }

    parseAudioStatus = () => {
        if (!this.props.student) {
            return 'off'
        }


        // Check if muted before doing the rest (only the instructor since they dont have a <MicIndicator> component)
        if (this.props.student.instructor && this.props.student.mute) {
            return 'muted'
        }

        const level = this.props.volumeLevels[this.props.student.identity] || 0

        if (level === 0) {
            return 'off'
        } else if (level >= 1 && level < 4) {
            return 'low'
        } else if (level >= 4 && level < 7) {
            return 'mid'
        } else if (level >= 7 && level < 10) {
            return 'high'
        } else {
            return 'off'
        }

    }

    render() {
        const audioStatus = this.parseAudioStatus()

        const classes = {
            main: clsx('AudioIndicator__wrapper'),
            audio: clsx('audio', audioStatus),
        };

        return (
            <div className={classes.main}>
                <div className={classes.audio}></div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    identity: state.auth.identity,
    volumeLevels: state.studioCommsVolume.volumeLevels,

});

export default connect(mapStateToProps, null)(AudioIndicator);
