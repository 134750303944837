import React, { useCallback, useEffect, useState } from 'react';
import DateTime from './DateTime';
import { formatTime } from './utils';
import { labStatuses } from 'models/constants';

const {
  STARTS_FUTURE,
  STARTS_TODAY,
  IN_PROGRESS,
  FINISHED,
} = labStatuses;

/**
 * Time presentation for a lab card, based on the lab's status.
 *
 * The general idea goes like this:
 *   1. Current time is after the class's end time OR the class is terminated:
 *     - Time is "Ended [END_DATE_AND_TIME]"
 *     - Component checks once per minute to see if it needs to transition
 *       states.
 *   2. Current time is after the class's start time but before the class's
 *      end time:
 *     - Time is "Ends in [TIME_REMAINING]"
 *     - Component updates TIME_REMAINING once per second.
 *   3. Current time is before the class's start time but less than 12 hours
 *      before the class's start time:
 *     - Time is "Starts in [TIME_UNTIL_START]"
 *     - Component updates TIME_UNTIL_START once per second.
 *   4. Current time is more than 12 hours before the class's start time:
 *     - Time is "Starts [START_DATE_AND_TIME]"
 *     - Component does not update over time.
 *
 * @see {@link Lab.status} for how the status is set.
 */
const LabTime = ({ lab }) => {
  /**
   * Generates the string that is shown to the user.
   *
   * @method
   */
  const generateTimeString = useCallback(() => {
    if (lab.status === FINISHED) {
      return null;
    }

    const target = lab.status === IN_PROGRESS ? lab.timeRemaining : lab.timeUntilStart;
    return formatTime(target);
  }, [lab]);

  const [timeString, setTimeString] = useState(generateTimeString());

  // Set up an interval for updating the time. Interval is different depending
  // on what time state we're in.
  useEffect(() => {
    // If the lab is finished, we don't need to worry about updating the time
    // anymore.
    if (lab.status === FINISHED) {
      return;
    }

    // If the lab is starting more than 12 hours in the future, only update
    // once per minute, since nothing is changing onscreen. Otherwise, update
    // every second.
    const updateInterval = lab.status === STARTS_FUTURE ? 60 * 1000 : 1000;

    const interval = setInterval(() => {
      setTimeString(generateTimeString());
    }, updateInterval);

    return () => clearInterval(interval);
  }, [lab, generateTimeString]);

  switch (lab.status) {
    case STARTS_FUTURE:
      return (
        <span className="cl-card__date">
          Starts <DateTime timestamp={lab.start} />
        </span>
      );
    case STARTS_TODAY:
      return (
        <span className="cl-card__date">
          Starts in <time role="timer" dateTime={timeString}>
            {timeString}
          </time>
        </span>
      );
    case IN_PROGRESS:
      return (
        <span className="cl-card__date">
          Ends in <time role="timer" dateTime={timeString}>
            {timeString}
          </time>
        </span>
      );
    case FINISHED:
      return (
        <span className="cl-card__date">
          Ended <DateTime timestamp={lab.isTerminated() ? lab.terminatedAt : lab.end} />
        </span>
      );
    default:
      return null;
  }
};

export default LabTime;
