import { SET_STUDIO_SELECTED_STUDENT } from '../actions/types';


const initialState = {
    identity: null
};

function studioSelectedStudentsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_STUDIO_SELECTED_STUDENT:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default studioSelectedStudentsReducer;
