import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAudio } from 'contexts/audioContext';
import LoginLink from 'components/LoginLink';
import MembershipIcon from 'assets/images/icons/membership-icon-menu.png';
import AvatarPicker from 'components/AvatarPicker';
import AvatarIcon from 'assets/images/icons/avatar-icon-menu.png';
import FeedbackIcon from 'assets/images/icons/feedback-icon-menu.png';
import LogoutIcon from 'assets/images/icons/logout-icon-menu.png';
import LoginIcon from 'assets/images/icons/login-icon-menu.png';
import makeIcon from './assets/make';
import playIcon from './assets/play';
import learnIcon from './assets/learn';
import watchIcon from './assets/watch';
import { signOut } from 'helpers/auth';
import { useIntercom } from 'hooks';

const HamburgerMenu = ({ username, password }) => {
  const { authenticated, identity } = useSelector(state => state.auth);
  const intercom = useIntercom();
  const { play } = useAudio();

  const handleClick = () => play('click');

  const launchIntercom = () => {
    intercom.launchSession();
    intercom.showPrepopulatedHelpSessionMessage();
  };

  const handleHelpClicked = () => {
    handleClick();

    if (intercom.isIntercomActive) {
      intercom.closeSession();
    } else {
      launchIntercom();
    }
  };

  return (
    <div className="hamburger">
      <div className="hamburger__target">
        <span />
        <span />
        <span />
      </div>
      <nav className="hamburger__dropdown">
        <ul className="dropdown-list">
          <li className="dropdown-list__item">
            <Link to="/subscribe" className="hamburger__link" onClick={handleClick}>
              <img
                src={MembershipIcon}
                alt=""
                width="45px"
                height="45px"
                className="hamburger__link-icon"
              />
              Membership
            </Link>
          </li>
          <li className="dropdown-list__item dropdown-list__item--hidden-sm">
            <Link to="/creator-lab" className="hamburger__link" onClick={handleClick}>
              <img
                src={makeIcon.offState}
                width="45px"
                height="45px"
                alt=""
                className="hamburger__link-icon"
              />
              Make
            </Link>
          </li>
          <li className="dropdown-list__item dropdown-list__item--hidden-sm">
            <Link to="/arcade" className="hamburger__link" onClick={handleClick}>
              <img
                src={playIcon.offState}
                width="45px"
                height="45px"
                alt=""
                className="hamburger__link-icon"
              />
              Play
            </Link>
          </li>
          <li className="dropdown-list__item dropdown-list__item--hidden-sm">
            <Link to="/learn" className="hamburger__link" onClick={handleClick}>
              <img
                src={learnIcon.offState}
                width="45px"
                height="45px"
                alt=""
                className="hamburger__link-icon"
              />
              Learn
            </Link>
          </li>
          <li className="dropdown-list__item dropdown-list__item--hidden-sm">
            <a
              href="https://www.youtube.com/channel/UCHhM14KYlBXZIafyG9Jdrcw"
              target="_blank"
              rel="noopener noreferrer"
              className="hamburger__link"
              onClick={handleClick}
            >
              <img
                src={watchIcon.offState}
                width="45px"
                height="45px"
                alt=""
                className="hamburger__link-icon"
              />
              Watch
            </a>
          </li>
          <li className="dropdown-list__item">
            <button
              onClick={handleHelpClicked}
              className="hamburger__link"
            >
              <span
                className="hamburger__link-icon hamburger__link-icon--text"
                aria-hidden={true}
              >
                ?
              </span>
              {intercom.isIntercomActive ? 'Close Help' : 'Help'}
            </button>
          </li>
          <li className="dropdown-list__item">
            <a
              href="https://forms.gle/FpjGwKaPYQGiLffy6"
              target="_blank"
              rel="noopener noreferrer"
              className="hamburger__link"
              onClick={handleClick}
            >
              <img
                src={FeedbackIcon}
                alt=""
                width="45px"
                height="45px"
                className="hamburger__link-icon"
              />
              Feedback
            </a>
          </li>
          {authenticated && (
            <li className="dropdown-list__item">
              <AvatarPicker className="hamburger__link" onClick={handleClick}>
                <img
                  src={AvatarIcon}
                  alt=""
                  width="45px"
                  height="45px"
                  className="hamburger__link-icon"
                />
                Change avatar
              </AvatarPicker>
            </li>
          )}
          <li className="dropdown-list__item">
            {authenticated
              ? (
                <button onClick={() => { handleClick(); signOut(); }} className="hamburger__link">
                  <img
                    src={LogoutIcon}
                    alt=""
                    width="45px"
                    height="45px"
                    className="hamburger__link-icon"
                  />
                  Sign out
                </button>
              )
              : (
                <LoginLink className="hamburger__link" clip="click">
                  <img
                    src={LoginIcon}
                    alt=""
                    width="45px"
                    height="45px"
                    className="hamburger__link-icon"
                  />
                  Sign in
                </LoginLink>
              )
            }
          </li>
          {(username || password ) && (
            <li className="dropdown-list__item">
              <div className="hamburger__table">
                {username && (
                  <>
                    <label>
                      Username
                    </label>
                    <span>
                      {username}
                    </span>
                  </>
                )}

                {password && (
                  <>
                    <label>
                      Password
                    </label>
                    <span>
                      {password}
                    </span>
                  </>
                )}
              </div>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default HamburgerMenu;
