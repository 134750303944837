import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStudioSelectedStudent, setStudioStudents, setStudioScreen } from 'actions/studio';


import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import './styles.scss';

import GridViewItem from './GridViewItem'


class GridView extends Component {
  constructor(props) {

    super(props);
    this.state = {
      selected: -1,
      screensize: [window.innerWidth, window.innerHeight]
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    //console.log('Resize', window.innerWidth, window.innerHeight)
    this.setState({ screensize: [window.innerWidth, window.innerHeight] })
  }

  render() {
    const viewScreenStudent = this.props.students.find(s => s.viewScreen)

    const classes = {
      main: clsx('GridView__wrapper', viewScreenStudent && 'viewScreen'),
      slider: clsx(viewScreenStudent && 'viewScreen'),
    }

    var settings = {
      accessibility: true,
      dots: true,
      speed: 125,
      centerMode: false,
      onSelect: null,
      infinite: false,
      swipeToSlide: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
    };

    //var list = new Array(0,1,2,3,4,5,6,7,8,9,10,11,12,13)
    // Only use the length of the students currently active in the Studio
    const length = this.props.students.filter(s => !s.instructor && s.location === 'studio').length
    switch (true) {
      case (length < 3):
        settings.slidesPerRow = 2;
        settings.rows = 1;
        break;
      case (length < 7):
        settings.slidesPerRow = 3;
        settings.rows = 2;
        break;
      case (length < 13):
        settings.slidesPerRow = 4;
        settings.rows = 3;
        break;
      default:
        settings.slidesPerRow = 5;
        settings.rows = 3;
        break;
    }

    return (
      <div className={classes.main}>

        < Slider className={classes.slider} ref={slider => (this.slider = slider)} {...settings}>
          {this.props.students.filter(s => !s.instructor && s.location === 'studio').sort((a, b) => { return a.order - b.order }).map((s) => {

            return (
              <div key={s.identity}>
                <GridViewItem
                  // key={s.identity}
                  viewScreen={viewScreenStudent && viewScreenStudent.identity === s.identity}
                  data={{ ...s }}
                  screen_id={this.props.screen_id}
                  screensize={this.state.screensize}
                  create_url={s.create_url || 'https://www.ume.games'}
                  gridAllowed={viewScreenStudent === undefined}
                />
              </div>
            )

          })}

          {
            /* DEBUG extra students
            list.map((item, i) => {
              console.log('STUB', i)
              const s = this.props.students.find(s => !s.instructor)
              if (!s) return (<div />)
              return (
                <div key={s.identity + i}> <GridViewItem viewScreen={viewScreenStudent && viewScreenStudent.identity === s.identity} data={{ ...s }} screen_id={this.props.screen_id} screensize={this.state.screensize} create_url={s.create_url || 'https://ume.academy'} gridAllowed={viewScreenStudent === undefined} /></div>
              )
            })
            */
          }
        </Slider>

      </div>
    )

  }
}

const mapStateToProps = state => ({
  //_student: state.studioSelectedStudent.identity,
  identity: state.auth.identity,
  screen_id: state.studioScreen.screen_id,
  lastScreen_id: state.studioScreen.lastScreen_id,
  students: state.studioStudents.students,
  studio_cmd: state.studioCommand.command,

});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setStudioSelectedStudent, setStudioStudents, setStudioScreen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridView);
