const ActionableType = {
    TOGGLE_SELECTED: 'toggle_selected_state',
    TURN_SELECTED_ON: 'turn_selected_on',
    TURN_SELECTED_OFF: 'turn_selected_off',
    TURN_SELECTED_ON_REST_OFF: 'turn_selected_on_rest_off',
    TURN_SELECTED_OFF_REST_ON: 'turn_selected_off_rest_on',
    TOGGLE_SELECTED_REST_ON: 'toggle_selected_rest_on',
    TOGGLE_SELECTED_REST_OFF: 'toggle_selected_rest_off',
    TURN_REST_ON: 'turn_rest_on',
    TURN_REST_OFF: 'turn_rest_off',
    TURN_ALL_ON: 'all_on',
    TURN_ALL_OFF: 'all_off'
}

export default ActionableType;