import { SET_ARTIFACTS, SET_CURRENT_ARTIFACT } from './types';

// set artifacts
export const setArtifacts = artifacts => {
  return {
    type: SET_ARTIFACTS,
    payload: artifacts
  };
};

// set current artifact
export const setCurrentArtifact = data => {
  return {
    type: SET_CURRENT_ARTIFACT,
    payload: data
  };
};


