/**
 * Wrapper around helpers/intercom for better integration with react, especially
 * re: the isIntercomActive() property.
 */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUserData } from 'contexts/userDataContext';
import intercom from 'helpers/intercom';

const useIntercom = () => {
  const { identity } = useSelector(state => state.auth);
  const { username } = useUserData();
  const [isIntercomActive, setIsIntercomActive] = useState(intercom.isIntercomActive());

  const launchSession = () => {
    if (identity) {
      intercom.launchSignedInSession(identity, username);
    } else {
      intercom.launchAnonymousSession();
    }

    setIsIntercomActive(intercom.isIntercomActive());
  };

  const closeSession = () => {
    intercom.closeSession();
    setIsIntercomActive(intercom.isIntercomActive());
  };

  const showPrepopulatedHelpSessionMessage = (extraInfo = '') =>
    intercom.showPrepopulatedHelpSessionMessage(username, extraInfo);

  return {
    launchSession,
    closeSession,
    isIntercomActive,
    showPrepopulatedHelpSessionMessage
  };
};

// HOC for class components. Use sparingly.
export const withIntercomHOC = (Component) => (props) => {
  const intercom = useIntercom();

  return <Component intercom={intercom} {...props} />;
};

export default useIntercom;
