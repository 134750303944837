import React from 'react';
import './styles.scss';

const BaseCard = ({ className, id, children }) => (
  <article data-id={id} className={`cl-card ${className}`}>
    {children}
  </article>
);

export default BaseCard;
