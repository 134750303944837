import React, { useState, useEffect } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import clsx from 'clsx';

import './styles.scss';

const Room = ({ roomName, token, studentMuted, instructor, showVideo, handleCommsError,
    handleStudentsNetQualityChanged, handleMyNetQualityChanged, handleDominantSpeaker,
    handleVolumeLevel, handleRemParticipantJoined, handleRemParticipantLeft, studentMe,
    refreshAudioTrack, actionCallback }) => {

    const [room, setRoom] = useState(null);
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if (!token || !roomName) {
            return
        }
        const participantConnected = participant => {
            setParticipants(prevParticipants => [...prevParticipants, participant]);

            handleRemParticipantJoined(participant, studentMe)

            // Setup monitoring for remote particpants network quality level
            participant.on('networkQualityLevelChanged', (networkQualityLevel) => {
                handleStudentsNetQualityChanged(participant.identity, networkQualityLevel)
            })
        };

        const participantDisconnected = participant => {
            setParticipants(prevParticipants =>
                prevParticipants.filter(p => p !== participant)
            );

            handleRemParticipantLeft(participant, studentMe)
        };

        // https://www.twilio.com/docs/video/using-network-quality-api
        // LocalParticipant's Network Quality verbosity [1 - 3]
        // RemoteParticipants' Network Quality verbosity [0 - 3]
        Video.connect(token, {
            name: roomName,
            audio: true,
            video: instructor,
            dominantSpeaker: true,
            networkQuality: {
                local: 3,
                remote: 1 //instructor ? 1 : 0
            }
        }).then(room => {
            //console.log('Video.connect', room)

            setRoom(room);
            window.twilio_room = room

            room.on('participantConnected', participantConnected); // Remote participant that is
            room.on('participantDisconnected', participantDisconnected);
            room.on('dominantSpeakerChanged', participant => {
                handleDominantSpeaker(participant)
            });
            room.participants.forEach(participantConnected);
            room.localParticipant.on('networkQualityLevelChanged', (networkQualityLevel, networkQualityStats) => {
                handleMyNetQualityChanged(networkQualityLevel, networkQualityStats)
            })
        }).catch(function (error) {
            // console.log(error);
            handleCommsError("setupFail", "Failed to setup Twilio comms, error thown in Video.connect setup", error)
        });


        return () => {
            setRoom(currentRoom => {
                if (currentRoom && currentRoom.localParticipant.state === 'connected') {
                    currentRoom.disconnect();
                    return null;
                } else {
                    return currentRoom;
                }
            });
        };
    }, [roomName, token, instructor, handleCommsError, handleStudentsNetQualityChanged, handleMyNetQualityChanged, handleDominantSpeaker, handleRemParticipantJoined, handleRemParticipantLeft]);

    const remoteParticipants = participants.map(participant => (
        <Participant
            key={participant.sid}
            participant={participant}
            studentMuted={false}
            refreshAudioTrack={0}
            handleVolumeLevel={handleVolumeLevel}
        />
    ));
    /*
    const handleMouseEnterEvent = (event) => {
        event.preventDefault()

        // Only call action callback if its the student in control hovering onto video
        if (studentMe.controlWatch) {
            actionCallback("mouse-on-instructor-video", studentMe)
        }
    }

    const handleMouseExitEvent = (event) => {
        event.preventDefault()

        // Only call action callback if its the student in control hovering out of the video
        if (studentMe.controlWatch) {
            actionCallback("mouse-off-instructor-video", studentMe)
        }
    }
    */


    const classes = {
        room: clsx('RoomVideo__wrapper', showVideo ? 'show' : 'hide', studentMe.dimInstructorVideo && 'dim'),
    }

    return (
        <div className={classes.room}
        //onMouseEnter={handleMouseEnterEvent}
        //onMouseLeave={handleMouseExitEvent}
        >
            <div className="local-participant">
                {room ? (
                    <Participant
                        key={room.localParticipant.sid}
                        participant={room.localParticipant}
                        studentMuted={studentMuted}
                        refreshAudioTrack={refreshAudioTrack}
                        handleVolumeLevel={handleVolumeLevel}
                    />
                ) : (
                        ''
                    )}
            </div>
            <div className="remote-participants">{remoteParticipants}</div>
        </div>
    );
};

export default Room;
