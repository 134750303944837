import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setContextMenu } from 'actions/contextMenuAction';

import clsx from 'clsx';

import './styles.scss';


class Game extends Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState) {

    }

    render() {

        const classes = {
            wrapper: clsx('Game__wrapper', this.props.show && 'show'),

        };


        return (
            <div className={classes.wrapper}>
                {/* TODO: Put something here */}
            </div>
        )
    }

}
const mapStateToProps = state => ({
    callback: state.contextMenu.callback,
    students: state.studioStudents.students,
    identity: state.auth.identity,
    screen_id: state.studioScreen.screen_id,
    subScreen_id: state.studioScreen.subScreen_id,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setContextMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Game);
