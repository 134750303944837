export const offsetTopElement = el => {
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop;
};
export const offsetBottomElement = el => {
  const rect = el.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return rect.top + scrollTop;
};

export const scrollToY = (position, duration) => {
  window.requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();

  function apply() {
    t += 1 / 60;
    var duration = t / c;
    var l = i(duration);
    if (duration < 1) {
      window.requestAnimFrame(apply);
      window.scrollTo(0, r + (position - r) * l);
    } else {
      window.scrollTo(0, position);
    }
  }

  var r = window.scrollY,
    pos = position || 0,
    period = duration || 2e3,
    t = 0,
    c = Math.max(0.1, Math.min(Math.abs(r - pos) / period, 0.8)),
    i = function (pos) {
      return -0.5 * (Math.cos(Math.PI * pos) - 1);
    };

  apply();
};
