import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { useAudio } from 'contexts/audioContext';
import MembershipIcon from 'pages/Subscribe/assets/membership-icon-large.png';
import makeIcon from 'assets/images/icons/anvil-md';
import './styles.scss';

/**
 * Helper component for the MembershipWallButton. Displays a large button if
 * the user does not have permission to view a resource.
 */
const Button = ({ to, children, icon, className = '', short = false }) => {
  const { play } = useAudio();

  return (
    <Link
      to={to}
      onClick={() => play('click')}
      className={clsx('membership-wall', className, short && 'membership-wall--short')}
    >
      <img
        src={icon.defaultState || icon}
        alt=""
        className="membership-wall__icon"
        width="52px"
        height="52px"
      />
      <img
        src={icon.hoverState || icon}
        alt=""
        className="membership-wall__icon membership-wall__icon--hover"
        width="52px"
        height="52px"
      />
      <span className="membership-wall__label">
        {children}
      </span>
    </Link>
  );
};

/**
 * React component that only displays its children if the user has permission
 * to view a resource. If the user does not have permission, a link to the
 * subscribe page is displayed instead.
 *
 * Permission is assumed to be "the user is signed in" unless membershipRequired
 * is true. If that is the case, permission means "the user has a subscription."
 *
 * NOTE: This assumes that there is only one kind of subscription.
 */
const MembershipWallButton = ({ children, to, membershipRequired = false, className='', short = false }) => {
  const { identity, subscriptions } = useSelector(state => state.auth);
  const location = useLocation();

  if (membershipRequired && (!subscriptions || subscriptions.size === 0)) {
    return (
      <Button
        to="/subscribe"
        icon={MembershipIcon}
        className={clsx('membership-wall--membership', className)}
        short={short}
      >
        {short ? 'CREATOR' : 'with CREATOR MEMBERSHIP'}
      </Button>
    );
  }

  if (!identity) {
    const target = {
      pathname: '/login',
      state: {
        from: (to ? to : location.pathname) + location.search,
        prompt: 'Sign in or sign up to access this content.'
      }
    };

    return (
      <Button
        to={target}
        icon={makeIcon}
        className={clsx('membership-wall--account', className)}
        short={short}
      >
        {short ? 'START' : 'START PROJECT'}
      </Button>
    );
  }

  return <>{children}</>;
};

export default MembershipWallButton;
