/**
 * @namespace models.lab
 */
import React from 'react';
import clsx from 'clsx';
import { labStatuses } from './constants';
import LabCard from 'components/CardGrid/LabCard';

/**
 * Model for a lab class in the User-Beta table of DDB.
 */
class Lab {
  /**
   * @param {Object} data Data retrieved from DDB
   */
  constructor(data) {
    this.id = data.id;
    this.identity = data.identity;
    this.context = data.sort_key;
    this.terminatedAt = data.terminated_at ? parseInt(data.terminated_at) : null;
    this.start = parseInt(data.start || 0);
    this.end = parseInt(data.end || Number.MAX_SAFE_INTEGER);
    this.isLab = data.lab;
  }

  get status() {
    if (this.hasEnded()) {
      return labStatuses.FINISHED;
    }

    if (this.isActive()) {
      return labStatuses.IN_PROGRESS;
    }

    // "today" = starts in less than 12 hours
    if (this.start - Date.now() < 12 * 60 * 60 * 1000) {
      return labStatuses.STARTS_TODAY;
    }

    return labStatuses.STARTS_FUTURE;
  }

  /**
   * Has this lab been terminated by the user?
   *
   * @returns {boolean}
   */
  isTerminated() {
    return this.terminatedAt !== null;
  }

  hasStarted() {
    return this.start < Date.now();
  }

  hasEnded() {
    return this.isTerminated() || this.end < Date.now();
  }

  isJoinable() {
    return this.start - Date.now() < 5 * 60 * 1000 && !this.hasEnded();
  }

  /**
   * Is this lab still ongoing?
   *
   * @returns {boolean}
   */
  isActive() {
    return this.hasStarted() && !this.hasEnded();
  }

  /**
   * The time remaining before the end of the lab
   *
   * @type {Number} 
   */
  get timeRemaining() {
    return this.end - Date.now();
  }

  get timeUntilStart() {
    return this.start - Date.now();
  }

  /**
   * The direct URI to join this lab.
   *
   * @type {string} 
   */
  get studioURI() {
    return `/studio/${this.context}`;
  }

  /**
   * Generates a timestamp to represent this lab.
   *
   * If the lab is active, this will be the time the lab started. If the lab is
   * not active, it will be the time the lab ended (either via termination or
   * timeout).
   *
   * @type {number} 
   */
  get timestamp() {
    if (!this.hasStarted() || this.isActive()) {
      return this.start;
    }

    if (this.isTerminated()) {
      return this.terminatedAt;
    }

    return this.end;
  }

  /**
   * Converts the lab to a presentable card.
   *
   * @param {Object} params - Parameters to pass to the React element.
   * @param {number} params.delay - How long to wait before displaying the card.
   * @returns {ReactElement} A card representing the lab.
   */
  toCard(options = {}) {
    const styles = clsx({
      [`fade-in-${options.delay}`]: options.hasOwnProperty('delay')
    });

    return <LabCard
      key={this.id}
      lab={this}
      className={styles}
    />;
  }
}

export const factory = (data = {}) => new Lab({
  id: 'factory-lab',
  sort_key: 'class-factory-001',
  start: 0,
  ...data
});

export default Lab;
