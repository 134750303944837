
import { SET_STUDIO_COMMAND, SET_STUDIO_SCREEN, SET_STUDIO_STUDENTS, SET_STUDIO_SELECTED_STUDENT, SET_STUDIO_COMMS_VOLUME } from './types';

// set profile
export const setStudioCommand = data => {
    return {
        type: SET_STUDIO_COMMAND,
        payload: data
    };
};
// set signin requirement
export const setStudioScreen = data => {
    return {
        type: SET_STUDIO_SCREEN,
        payload: data
    };
};
// set signin requirement
export const setStudioStudents = data => {
    return {
        type: SET_STUDIO_STUDENTS,
        payload: data
    };
};
// set signin requirement
export const setStudioSelectedStudent = data => {
    return {
        type: SET_STUDIO_SELECTED_STUDENT,
        payload: data
    };
};

export const setStudioCommsVolume = data => {
    return {
        type: SET_STUDIO_COMMS_VOLUME,
        payload: data
    };
};
