/**
 * React context for preloading audio on the page so that it can be triggered
 * programmatically by React components
 */
import { createContext, useContext, useRef } from 'react';
import Click from 'assets/audio/click1.ogg';

/**
 * The available audio clips when clips are not provided explicitly.
 */
const DEFAULT_CLIPS = {
  'click': Click
};

const AudioContext = createContext(null);

/**
 * Audio provider: creates and preloads <audio> elements, and exposes a play()
 * function to trigger those elements.
 */
export const AudioProvider = ({ children, clips = DEFAULT_CLIPS }) => {
  const ref = useRef({});

  const play = clipName => {
    if (!clipName) {
      return;
    }

    const clip = ref.current[clipName];

    if (clip) {
      clip.play();
    } else {
      console.warn(`No clip named ${clipName}`);
    }
  };

  return (
    <AudioContext.Provider value={{ play }}>
      <div style={{ display: 'none' }}>
        {Object.entries(clips).map(([key, src]) => (
          <audio 
            key={key}
            ref={el => { ref.current[key] = el; }}
            src={src}
            preload="auto"
          />
        ))}
      </div>

      {children}
    </AudioContext.Provider>
  );
};

/**
 * Use this inside a component to access play()
 */
export const useAudio = () => useContext(AudioContext);
