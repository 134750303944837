import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { endMyStudioSession } from 'api';
import { useLaunchLab } from 'contexts/launchLabContext';
import UMEButton from 'components/UMEButton';
import Loader from 'components/Loader';
import './styles.scss';

const LAB_ALREADY_OPEN = 'LAB_ALREADY_OPEN';
const userHandledErrors = [LAB_ALREADY_OPEN];

const LoadingView = () => (
  <>
    <div className="start-lab__message">
      <p>Your lab will be ready in just a moment!</p>
    </div>
    <Loader />
  </>
);

const ErrorView = () => (
  <p>Whoops! Looks like we couldn't start your lab.</p>
);

const LabAreadyOpenView = ({ url, closeModal }) => {
  const closeOldLab = () => {
    const sortKey = url.replace('/studio/', '');

    endMyStudioSession(sortKey);
    closeModal();
  };

  return (
    <>
      <div className="start-lab__message">
        <p>You already have a lab open. What would you like to do?</p>
      </div>

      <div className="start-lab__buttons">
        <UMEButton
          to={url}
          target="_blank"
          className="cl-button--fullwidth"
          onClick={closeModal}
        >
          Resume old lab
        </UMEButton>

        <UMEButton
          type="button"
          className="cl-button--fullwidth"
          onClick={closeOldLab}
        >
          Close old lab
        </UMEButton>

        <UMEButton
          type="button"
          className="cl-button--fullwidth"
          onClick={closeModal}
        >
          Go back
        </UMEButton>
      </div>
    </>
  );
};

const SuccessView = ({ url, closeModal }) => (
  <>
    <div className="start-lab__message">
      <p>Your lab is ready! Have fun!</p>
    </div>
    <div className="start-lab__buttons">
      <div />
      <UMEButton
        to={url}
        target="_blank"
        onClick={closeModal}
      >
        Start creating
      </UMEButton>

      <div />
    </div>
  </>
);

const StartLabModal = () => {
  let { url, loading, error, reset } = useLaunchLab();
  const location = useLocation();

  // Leave the modal open for an extra second when there's an error so that the
  // user can read it.
  useEffect(() => {
    if (error && !userHandledErrors.includes(error)) {
      const timeout = setTimeout(reset, 1000);
      return () => clearTimeout(timeout);
    }
  }, [error, reset]);

  const show = loading || url || error;
  const style = clsx(
    'modal-overlay',
    'start-lab',
    show && 'start-lab--visible'
  );

  let view = <LoadingView />;

  if (error) {
    if (error === LAB_ALREADY_OPEN) {
      view = <LabAreadyOpenView url={url + location.search} closeModal={reset} />;
    } else {
      view = <ErrorView />;
    }
  } else if (url) {
    view = <SuccessView url={url + location.search} closeModal={reset} />;
  }

  return (
    <div className={style} aria-hidden={!show}>
      <div className="start-lab__modal">
        {view}
      </div>
    </div>
  );
};

export default StartLabModal;
