import React from 'react';
import clsx from 'clsx';
import { UserDataProvider } from 'contexts/userDataContext';
import Auth from 'components/Auth';
import Header from './Header';
import Footer from './Footer';
import Watermark from 'assets/images/UME-Logo-Controller-bg.png';
import './styles.scss';

const Layout = ({
  className,
  children,
  fitContent,
  tab = '',
  breadcrumbs = [],
  showHeader = true,
  showWatermark = true
}) => (
  <UserDataProvider>
    <div className={clsx('layout', className, breadcrumbs.length > 0 && 'layout--with-subnav')}>
      {showWatermark && <img src={Watermark} alt="" className="layout__bg-image" />}

      {showHeader && <Header tab={tab} breadcrumbs={breadcrumbs} />}

      <div className="gradient" />

      <div className={clsx('content', fitContent && 'content--fit', showHeader && 'content--height-adjusted')}>
        {children}
      </div>

      <Footer />

      <Auth hideAuth={false} />
    </div>
  </UserDataProvider>
);

export default Layout;
