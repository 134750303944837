
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch

} from 'react-router-dom';
import { Provider } from 'react-redux';
import track from 'react-tracking';
import store from './store';


import Arcade from 'pages/Arcade';
import Studio from 'pages/Studio';
import Share from 'pages/Share';
import CreatorLab from 'pages/CreatorLab';
import Home from 'pages/Home';
import Login from 'pages/Login';
import FAQ from 'pages/FAQ';
import Terms from 'pages/Terms';
import Learn from 'pages/Learn';
import Subscribe from 'pages/Subscribe';
import Lesson from 'pages/Lesson';
import EditProject from 'pages/EditProject';
import { AudioProvider } from 'contexts/audioContext';
import ScrollToTop from 'components/ScrollToTop';
import UnmatchedRouteHandler from 'components/UnmatchedRouteHandler';
import SubscriptionCodeHandler from 'components/SubscriptionCodeHandler';
import QueryStringValidator from 'components/QueryStringValidator';

import './index.scss';
//          <Redirect exact from="/" to="/create2" />
/*
  ROUTING
  ume.games > ume.games/arcade
  ume.games/play?id=a34adff > game modal with game loaded
  ume.games/create?id=a34adff > open studio with class id (requests login before entering, queries class table to initialize data)
  ume.games/parent > parent login/register
  ume.games/profile?id=username > open page with info about user (if the user is logged in, show private info, otherwise just public info)
*/
const App = (props) => {

  return (
    <Provider store={store}>
      <AudioProvider>
        <Router>
          <QueryStringValidator>
            <SubscriptionCodeHandler />
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/studio/:id" component={Studio} />
              <Route path="/play/:id" component={Share} />
              <Route path="/creator-lab/:id" component={EditProject} />
              <Route path="/creator-lab" component={CreatorLab} />
              <Route path="/arcade" component={Arcade} />
              <Route path="/login" component={Login} />
              <Route path="/faq" component={FAQ} />
              <Route path="/terms" component={Terms} />
              <Route path="/learn" component={Learn} />
              <Route path="/lessons/:id" component={Lesson} />
              <Route path="/subscribe/:id" component={Subscribe} />
              <Route path="/subscribe" component={Subscribe} />
              <Route path="*" component={UnmatchedRouteHandler} />
            </Switch>
          </QueryStringValidator>
        </Router>
      </AudioProvider>
    </Provider>
  );
}
const TrackedApp = track(
  { app: 'arcade-app' },
  {
    dispatch: async data => {
      try {
        data.callback && data.callback(data)
      }
      catch (err) {

      }
    }
  }
)(App);


export default TrackedApp;
