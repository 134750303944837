export const createUser = `mutation CreateUser($input: UserInput!){
  createUser(input: $input) {
    login
    avatar
    status 
    parent_id    
    message   
  }
}
`
export const studioCommand = `mutation StudioCommand($input:StudioCmdInput!){
  studioCommand(input:$input){
    id
    timestamp
    command
    arguments
  }
}
`;
export const userCommand = `mutation UserCommand($input:UserCmdInput!) {
  userCommand(input:$input) {
      identity
      sort_key
      arguments
      timestamp
  }
}
`;
export const requestSeat = `mutation RequestSeat($id:String!,$index:Int!){
  requestSeat(id:$id,index:$index){
    id
    seats
  }
}
`;

export const updateSeat = `mutation UpdateSeat($input: SeatInput!){
  updateSeat(input:$input){
    identity
    sort_key
    login
    order
    create_url
    create_status 
    aggregate
    appsync_id
    comms_status
    duration
    end
    start
    username
    terminated_at
  }
}
`;

export const updateStats = `mutation UpdateStats($input: SeatInput!){
  updateStats(input:$input){
    identity
    sort_key   
  }
}
`;


export const updateHelpHistory = `mutation UpdateHelpHistory($input: HelpHistoryInput!){
  updateHelpHistory(input:$input){
    identity
    time
    is_active
    end_time
    final_outcome
    answers
  }
}
`;

export const stampUserInformation = `mutation UpdateUserInfo($input: SeatInput!) {
  updateUserInfo(input: $input) {
    identity
    sort_key
  }
}`;

export const stampFrontendError = `mutation StampFrontendError($input: SeatInput!) {
  stampFrontendError(input: $input) {
    identity
    sort_key
  }
}`;
