import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUserData } from 'contexts/userDataContext';
import HamburgerMenu from './HamburgerMenu';
import NavLink from './NavLink';
import UMEGamesLogo from 'assets/images/UME_Games_logo.png';
import LoginLink from 'components/LoginLink';
import Breadcrumbs from './Breadcrumbs';
import makeIcon from './assets/make';
import playIcon from './assets/play';
import learnIcon from './assets/learn';
import watchIcon from './assets/watch';
import MembershipIcon from 'assets/images/icons/membership-icon-menu.png';

const PasswordReminder = () => (
  <div className="password-reminder">
    <strong>IMPORTANT:</strong> Write down this password!!!!!
    <div className="password-reminder__icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
    </div>
  </div>
);

const Header = ({ tab, breadcrumbs }) => {
  const { authenticated, authentication, subscriptions } = useSelector(state => state.auth);
  const { username, password } = useUserData();

  return (
    <header className="header">
      <div className="header__secondary">
        <aside className="header__top-bar">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <span className="hidden-sm">Welcome to UME Games.</span> <a className="header__link" href="https://ume.academy" target="_blank">Are you an educator?</a>
        </aside>

        <div className="header__ctas">
          {authenticated && subscriptions && subscriptions.size === 0 && (
            <Link to="/subscribe" className="flag header__membership-cta">
              <img
                src={MembershipIcon}
                alt=""
                className="cta-image"
                width="16px"
                height="16px"
              />
              Try for free!
            </Link>
          )}
          {password && <PasswordReminder />}
        </div>
      </div>
      <div className="header__primary">
        <div className="header__left">
          <Link to="/" className="header__logo">
            <img src={UMEGamesLogo} alt="UME Games" />
          </Link>
        </div>
        <div className="header__center">
          <NavLink
            to="/creator-lab"
            icon={makeIcon}
            label="Make"
            selected={tab === 'creator-lab'}
          />
          <NavLink
            to="/arcade"
            icon={playIcon}
            label="Play"
            selected={tab === 'arcade'}
          />
          <NavLink
            to="/learn"
            icon={learnIcon}
            label="Learn"
            selected={tab === 'learn'}
          />
          <NavLink
            to="https://www.youtube.com/channel/UCHhM14KYlBXZIafyG9Jdrcw"
            icon={watchIcon}
            label="Watch"
            className="nav-link--watch"
            target="_blank"
            rel="noopener noreferrer"
            selected={tab === 'watch'}
          />
        </div>
        <div className="header__right">
          {authenticated
            ? (
              <img className="header__avatar" src={authentication.public.avatar} alt="avatar" />
            ) : (
              <LoginLink className="header__login">
                Sign Up / In
              </LoginLink>
            )}
          <HamburgerMenu username={username} password={password} />
        </div>
      </div>

      {breadcrumbs.length > 0 && (
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          className="header__subnavigation"
        />
      )}
    </header>
  );
};

export default Header;
