import React, { Component } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

import './styles.scss';


class ClassMicsToggle extends Component {
    constructor(props) {
        super(props)

        this.state = {
            micsToggleable: true
        }

    }

    static defaultProps = {
    }

    toggleMicState = () => {
        this.state.micsToggleable ? this.props.classActionCallback('mic-disable-all') : this.props.classActionCallback('mic-enable-all')
        this.setState({ micsToggleable: !this.state.micsToggleable })

    }

    render() {
        const classes = {
            main: clsx('ClassMicsToggle__wrapper'),
            switchButton: clsx('switch-button', this.state.micsToggleable ? 'enabled' : 'disabled'),
            actionText: clsx('action-text')
        };

        return (
            <div className={classes.main}>
                <div className={classes.switchButton} onClick={this.toggleMicState} />
                <div className={classes.actionText}>{this.state.micsToggleable ? 'Disable Mics' : 'Enable Mics'}</div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    identity: state.auth.identity,

});

export default connect(mapStateToProps, null)(ClassMicsToggle);
