import React from 'react';
import Watermark from 'assets/images/UME-Logo-Controller-bg.png';

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <img
        src={Watermark}
        alt=""
        className="layout__bg-image layout__bg-image--high"
      />
      <div className="gradient" />
      {children}
    </div>
  );
};

export default Layout;
