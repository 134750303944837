// @ts-check
import { API_ENDPOINT } from 'constants/urls';

/**
 * @typedef {typeof STAGES[number]} Stage
 */

const STAGES = /** @type {const} */(['dev', 'smoke', 'prod', 'beta']);

/**
 * Determines if a string maps to a known stage
 *
 * @param {string} val - The value to check
 * @returns {val is Stage}
 */
const isStage = (val) => {
  return STAGES.includes(/** @type {Stage} */(val));
};

/**
 * Resolves the current application version
 *
 * @returns {Stage} The application version
 */
export const getVersion = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlVersion = urlParams.get('version');

  // If a version is specified in the query string, allow it to override
  // anything else.
  if (urlVersion && isStage(urlVersion)) {
    return urlVersion;
  }

  if (process.env.REACT_APP_UME_ENVIRONMENT === 'dev') {
    return 'dev';
  }

  return 'prod';
};

/**
 * Resolves the current API endpoint, optionally adding on a path to the end
 *
 * @param {string} path - A path to add at the end of the URL
 * @returns {string} The resolved URL
 */
export const getApiEndpoint = (path = '') => {
  const version = getVersion();

  // There aren't any dedicated routes set up for beta or smoke right now so
  // reroute them to prod.
  const versionToken = version === 'dev' ? 'dev' : 'prod';
  const safePath = path.startsWith('/') ? path : `/${path}`;

  return `${API_ENDPOINT}/${versionToken}${safePath}`;
};
