import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import LessonCard from '../LessonCard';
import MembershipIcon from 'assets/images/icons/membership-icon-menu.png';
import './styles.scss';

/**
 * React component that displays information about a course on the learn page.
 * Includes information about the course and all lessons associated with it.
 */
const CourseCard = ({ course }) => {
  const membershipRequired = useMemo(
    () => course.lessons.some(lesson => lesson.tags.has('membership-required')),
    [course]
  );

  return (
    <article className="course-card">
      <header>
        <h4 className="course-card__title">
          {course.isComingSoon() && <span className="course-card__coming-soon">
            Coming soon!
          </span>}
          <div>{course.title} {course.level && `- ${course.level}`}</div>
          {membershipRequired && (
            <Link to="/subscribe" className="course-card__icon-wrapper">
              <img
                src={MembershipIcon}
                alt=""
                width="45px"
                height="45px"
                className="course-card__icon"
              />
            </Link>
          )}
        </h4>
        <p className="course-card__description">
          {course.description}
        </p>
      </header>

      <ul className="learn__lesson-list">
        {course.lessons.map((lesson, idx) => (
          <li key={lesson.id}>
            <LessonCard activity={lesson} level={course.level} index={idx} />
          </li>
        ))}
      </ul>
    </article>
  );
};

export default CourseCard;
