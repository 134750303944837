import store from 'store';
import cognitoService from 'services/cognito';
import { setProfile } from 'actions/authAction';

export const signOut = () => {
  cognitoService.signOut(() => {
    window.configure_amplify('arcade_endpoint');
    cognitoService.get_user((...args) => {
      store.dispatch(setProfile(...args));
    });
  });
};
