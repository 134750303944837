import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAudio } from 'contexts/audioContext';

const LoginLink = ({ children, prompt, disabled, clip = 'click', className = '' }) => {
  const location = useLocation();
  const { play } = useAudio();
  const to = {
    pathname: '/login',
    state: {
      from: location.pathname + location.search,
      prompt
    }
  };

  return (
    <Link to={to} className={className} disabled={disabled} onClick={() => play(clip)}>
      {children}
    </Link>
  );
};

export default LoginLink;
