import { SET_POPUP } from '../actions/types';

const initialState = {
    action: 'popup',
    show: false,
    contents: null,
    timer: null,
};

function popupReducer(state = initialState, action) {
    switch (action.type) {
        case SET_POPUP:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default popupReducer;
