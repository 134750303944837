import React, { useEffect, useState } from 'react';
import UMEButton from 'components/UMEButton';

/**
 * Step 1 in unlocking subscriptions: collect the purchaser's email and ask if
 * they want to subscribe to the mailing list.
 */
const Step1 = ({ plan, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [confirmedMailingList, setConfirmedMailingList] = useState(false);

  useEffect(() => {
    // If the plan is free, we don't need to collect an email; complete this
    // step immediately.
    if (plan && plan.cost === 0) {
      onSubmit({
        email: null,
        confirmedMailingList: false
      });
    }
  }, [plan, onSubmit]);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({
      email,
      confirmedMailingList
    });
  };

  return (
    <form className="unlock-form__content" onSubmit={handleSubmit}>
      <p className="unlock-form__subtitle">
        Parent/Guardian Contact
      </p>
      <div className="unlock-form__fields">
        <div className="unlock-form__field">
          <label htmlFor="unlock-email" className="unlock-form__label">
            Email (for password recovery and technical support)
          </label>
          <input
            id="unlock-email"
            name="email"
            type="email"
            onChange={e => setEmail(e.target.value)}
            value={email}
            className="unlock-form__input"
            required
          />
        </div>
        <div className="unlock-form__field">
          <label className="unlock-form__label unlock-form__label--checkbox">
            <input
              type="checkbox"
              onChange={e => setConfirmedMailingList(e.target.checked)}
              checked={confirmedMailingList}
              className="unlock-form__checkbox"
            />
            Check if you are interested in hearing about new courses, promotions, or community events
          </label>
        </div>
      </div>
      <UMEButton type="submit" className="unlock-form__button">
        Continue
      </UMEButton>
    </form>
  );
};

export default Step1;
