import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const sign = (authentication, url) => {
    const payload = {
        Bucket: 'ume-studio-user',
        Key: url,
        Expires: 3600
    };

    return authentication.s3?.getSignedUrl('getObject', payload);
};

const useSignedUrls = keys => {
    const [signedUrls, setSignedUrls] = useState([]);
    const { authentication } = useSelector(state => state.auth);

    useEffect(() => {
        if (!authentication || !keys) {
            setSignedUrls([]);
            return;
        }

        if (Array.isArray(keys)) {
            setSignedUrls(keys.map(x => sign(authentication, x)));
        } else {
            setSignedUrls([sign(authentication, keys)]);
        }
    }, [keys, authentication]);

    return signedUrls;
};

export default useSignedUrls;
