
import React, { Component } from 'react';
import './styles.scss';
import clsx from 'clsx';


class Action extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        };
    }

    render() {
        const classes = {
            enabled: clsx('button neonBorder glow_purple',
                this.state.idx && 'selected textGlow_purpleBold',
            ),
            disabled: clsx('button neonBorder glow_purple disabled'),
        };
        return (
            <div className="Action__wrapper">
                {
                    this.props.actions && this.props.actions.map((item, idx) => {
                        return (
                            <button key={idx}
                                className={item.disabled ? classes.disabled : classes.enabled}
                                onClick={(event) => {
                                    this.setState({ idx: 'selected textGlow_purpleBold' });
                                    item.fn && item.fn(event)
                                }
                                }>
                                {item.label}
                            </button>
                        )
                    })
                }
            </div>
        )
    }
}

export default Action;
