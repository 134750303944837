import { REFRESH_TOKEN, SET_PROFILE, SET_SIGNIN } from './types';

export const refreshToken = data => {
  return {
    type: REFRESH_TOKEN,
    payload: data
  };
};

// set profile
export const setProfile = data => {
  return {
    type: SET_PROFILE,
    payload: data
  };
};
// set signin requirement
export const setSignIn = data => {
  return {
    type: SET_SIGNIN,
    payload: data
  };
};
