import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSignIn } from 'actions/authAction';
import { setActivity } from 'actions/activityAction';
import Layout from './Layout';
import Auth from 'components/Auth';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { authenticated } = useSelector(state => state.auth);

  const from = location.state ? location.state.from : null;
  const prompt = location.state ? location.state.prompt : null;

  useEffect(() => {
    if (authenticated) {
      history.push(from ? from : '/');
    } else {
      // Make sure we're using the sign in/sign up version of signing in
      dispatch(setActivity({ action: 'simple-sign-in' }));
    }
  }, [dispatch, history, from, authenticated]);

  // Sign in is required so long as you are on this page.
  useEffect(() => {
    dispatch(setSignIn(true));
    return () => dispatch(setSignIn(false));
  }, [dispatch]);

  return (
    <Layout>
      <Auth hideAuth={false} prompt={prompt} />
    </Layout>
  );
};

export default Login;
