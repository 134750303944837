import { useMemo } from 'react';
import { fetchMyActivities } from 'api';
import useMyResource from './useMyResource';

/**
 * React hook for retrieving the current user's activities.
 *
 * @method
 * @returns {ResourceState}
 */
const useMyActivities = options => {
  const { data, loaded, error } = useMyResource(fetchMyActivities, options, []);

  const uniqueSessions = useMemo(() => {
    return data.filter((item, i, arr) => i === arr.findIndex(x => x.projectChainId === item.projectChainId));
  }, [data]);

  return { data: uniqueSessions, loaded, error };
};

export default useMyActivities;
