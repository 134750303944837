import React from 'react';
import Layout from 'components/Layout';

const FAQ = () => {
  return (
    <Layout>
      {/* Inline styling because this page's style has not been finalized. */}
      <div style={{
        position: 'relative',
        margin: '1rem auto 5rem',
        padding: '3rem',
        maxWidth: '75ch',
        background: '#fffa'
      }}>
        <h1>
          FAQ
        </h1>

        <h2>
          Using UME Games
        </h2>

        <h3>
          How much does UME Games cost?
        </h3>
        <p>
          UME Games is completely free to join and free to use. In the future, there will be opportunities to purchase additional lessons and content packs to further inspire your child’s creativity.
        </p>

        <h3>
          What requirements are there to use UME Games?
        </h3>
        <p>
          You will need a computer with a keyboard and a stable internet connection. We recommend using Google Chrome to access ume.games.
        </p>
        <h3>
          How will my child learn to use the UME Creator Lab?
        </h3>
        <p>
We include pre recorded videos crafted by our expert game design teachers for your child to follow along with. Lessons are broken up into chapters, so your child is free to follow along at their own pace.
        </p>

        <p>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          We are also available for live instruction and facilitation! Consider signing up for one of our public live classes on <a href="https://outschool.com/teachers/UME-Academy#abkw3r2tgo" target="_blank" rel="noopener noreferrer">Outschool</a> or <a href="https://ume.academy/contact" target="_blank">contact us</a> to inquire about private lessons.
        </p>

        <h3>
          I’m an educator and I’d like to use UME Games with my students. What resources exist for me?
        </h3>
        <p>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          We provide a number of (free and paid) lesson plans for classroom teachers to use Unity as a tool to learn other core curricula. A full list of lesson plans is available at <a href="https://ume.academy" target="_blank">ume.academy</a>.
        </p>

        <h2>
          Philosophy
        </h2>

        <h3>
          Why is UME Games educational?
        </h3>
        <p>
          We agree with how Roblox answers this question: “There is a well-respected theory named “Constructionism,” first posed by Seymour Papert of MIT Media Lab. It holds that kids learn best when they are in the active roles of designer and builder and that this learning is accelerated when they assume these roles in a public forum. We support this theory.”
        </p>

        <p>
          UME Games teaches kids to use some of the same software used by professional game designers, including Unity 3D. Once kids are comfortable using Unity, they are free to use it as a rich learning environment to explore other subjects like math, physics, computer science, and storytelling.
        </p>

        <h2>
          Safety &amp; Privacy
        </h2>

        <h3>
          What personal data does UME Games collect?
        </h3>
        <p>
          UME Games does not collect any personal data from students. Students can create their own username and are provided a password which gives them ongoing access to their projects on our platform.
        </p>

        <p>
          Please note that if your child joins one of our Outschool classes, the class will be recorded by Outschool for safety and security.
        </p>

        <h3>
          Are there any other official UME websites?
        </h3>
        <p>
          Please take note of the following official UME websites:
        </p>
        <ul>
          <li>Sites beginning with https://www.ume.games/</li>
          <li>Sites beginning with https://www.ume.academy/</li>
          <li>Sites ending with .ume.games/</li>
          <li>Sites ending with .ume.studio/</li>
        </ul>

        <h3>
          How can I keep my child safe on UME Games?
        </h3>
        <p>
          UME Games encourages you to create with your child and invite them to share their experiences with you.
        </p>

        <p>
          At this time, students may only interact with each other when they are part of an instructor-led class. UME instructors undergo an extensive training program and are required to have criminal record and child abuse registry checks.
        </p>
      </div>
    </Layout>
  );
};

export default FAQ;
