import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import clsx from 'clsx';
import { toggleFullScreen } from 'helpers/utils'
import { setActivity } from 'actions/activityAction';

import { AWSPingAvg } from 'helpers/awsPing';
import axios from 'axios';
import Auth from 'components/Auth';
import { default as LoginLayout } from 'pages/Login/Layout';

import { LOBBY_SCREEN, WATCH_SCREEN, CREATE_SCREEN, PLAY_SCREEN, GRIDVIEW_SCREEN, PORTFOLIO_SCREEN } from './types';

// graphQL functions
import { send, query, studio_cmd } from 'graphql/handler';
import {
    getComms, getSeat, getClass, querySeats,
    studioCommand, userCommand, updateStats, requestSeat, updateSeat,
    onStudioCommand, onUserCommand
} from 'graphql/studio';
import { getClassInfo } from 'graphql/arcade'

import { graphqlOperation } from 'aws-amplify';

import { setStudioScreen, setStudioStudents } from 'actions/studio';
import { setContextMenu } from 'actions/contextMenuAction';
import { setSignIn } from 'actions/authAction';

import { setPopUp } from 'actions/popupAction';
import PopUp from 'components/PopUp';
import Button from 'components/Button';
import FacilitatorCodeWatcher from 'components/FacilitatorCodeWatcher';
import { UserDataProvider } from 'contexts/userDataContext';

import ContextMenu from './components/ContextMenu';

import MenuBar from './components/MenuBar'
import Classrow from './components/Classrow'
import Screen from './components/Screen'
import Wait from './components/Wait'
import JoinedTooEarly from './components/JoinedTooEarly'

import ActionHandler from './helpers/ActionHandler'
import ddbStamper from './helpers/DDBStamper'
import { AWSPing } from 'helpers/awsPing'
import { TIME, getCurrentTime } from 'helpers/datetime'
import { NUM_CLASS_SEATS, AUTO_ASSIGN_SEAT_RETRY_ATTEMPTS } from 'helpers/defaults'
import { fetchLatestPublishByContext } from 'api';

import Subscription from "services/Subscription"

import { find_games, find_lessons } from 'helpers/algolia';
import { withIntercomHOC } from 'hooks/useIntercom';

import './styles.scss'


class Studio extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        // Storage for subscriptions so we can cleanup on exit
        this.subscriptions = {}
        this.dcvAlert = null
        this.state = {
            class_id: props.match.params.id,
            screen_id: LOBBY_SCREEN,
            lowWifi: false,
            scale: 1,
            host_active: false,
            newHelpContent: false,
            mlHelpPayload: {},
            games: [],
            userJoinedTooEarly: false,
        }
        //props.setStudioCommand({ command: this.studio_cmd })
        props.setContextMenu({ callback: this.action_callback })

        this.actionHandler = new ActionHandler(this.state.class_id)
        this.numInitClassAttempts = 0;

    }

    componentDidMount() {
        this.props.setActivity({ action: 'studio' })

        window.configure_amplify('studio_endpoint')

        this.verifyUserSystemTime()

        // Popup the signin window if user is not signed in
        if (this.props.authenticated) {
            this.auth_change()
        } else {
            this.props.setSignIn(true)

            // This check is happening too fast; the user is never signed in at
            // this point if they visit the studio directly (e.g. through
            // opening a new tab, as in a lab). This is resulting in an
            // anonymous intercom session being launched, which is very
            // unhelpful for us (they have next to no information). Let's wait
            // a few seconds to verify that the user really isn't signed in
            // before opening an intercom panel.
            //
            // TODO: Put in a more reliable auth check. This will likely
            // involve moving this out of componentDidMount, since it's
            // obviously too early in the component's lifecycle.
            setTimeout(() => {
                if (!this.props.authenticated) {
                    this.props.intercom.launchSession();
                }
            }, 3000);
        }

        window.addEventListener('resize', this.resize)
        this.resize();

        window.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    // called when this component is removed, cleanup subscriptions
    componentWillUnmount() {
        window.configure_amplify('studio_endpoint') // remove any subscription attached to studio 
        Object.keys(this.subscriptions).forEach(key => {
            this.subscriptions[key] && this.subscriptions[key].unsubscribe()
        })

        window.removeEventListener('resize', this.resize)
        window.removeEventListener('visibilitychange', this.handleVisibilityChange);

        // Remove the timer
        this.dcvUpdateTimer && clearInterval(this.dcvUpdateTimer)

        this.props.intercom.closeSession()

    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("-st componentDidUpdate: ", prevProps, this.props)
        prevProps.authenticated !== this.props.authenticated && this.auth_change();

        // Check if the host is in the class
        if (!prevState.host_active && this.state.host_active) {
            // When the host becomes active close the Intercom bubble in the lower right corner
            this.props.intercom.closeSession()

            // Look for the user on the db, if they are there and dont have an order then auto assign one to them
            query(querySeats, { id: this.state.class_id }, (response) => {
                var me = response.find(s => s.identity === this.props.identity)
                if (!me) {
                    return // TODO: I don't have a User-Beta record for this class, i shouldn't be here
                }
                // if user screen is smaller than required, go full screen 
                this.goFullScreen()

                // if we don't have a seat order, assign one randomly 
                isNaN(parseInt(me.order)) && this.autoAssignSeat()

            })
        }

        this.guardAgainstClosedLab();
        /*
        if (prevState.comms_token !== this.state.comms_token) {
            let channel = null
            Chat.create(this.state.comms_token).then(chatClient => {
                console.log('CHAT CLIENT', chatClient)

                chatClient.createChannel({
                    uniqueName: 'general',
                    friendlyName: 'General Chat Channel',
                })
                    .then(function (channel) {
                        console.log('Created general channel:');
                        console.log(channel);

                    })
                    .catch(function (err) {
                    });
                chatClient.getPublicChannelDescriptors().then(function (paginator) {
                    for (let i = 0; i < paginator.items.length; i++) {
                        const channel = paginator.items[i];
                        channel.getChannel().then(function (myChannel) {

                            // Join a previously created channel
                            chatClient.on('channelJoined', function (channel) {
                                console.log('Joined channel ' + channel.friendlyName);

                                console.log('Got mychannel:');
                                console.log(channel);

                                channel.on('messageAdded', function (message) {
                                    console.log(message.author, message.body);
                                });
                                channel.sendMessage('yoyo')

                            });

                            myChannel.join().catch(function (err) {
                                console.error(
                                    "Couldn't join channel " + channel.friendlyName + ' because ' + err
                                );

                            });
                            var coords = []
                            var mouse_monitor = function (e) {
                                var x = e.pageX;
                                var y = e.pageY;
                                console.log(x, y);
                                coords.push({ x: x, y: y })


                            }
                            var send = function () {
                                if (coords.length) {
                                    myChannel.sendMessage(JSON.stringify(coords))
                                    coords = []
                                }
                            }
                            setInterval(send, 500)

                            document.addEventListener('mousemove', mouse_monitor);

                            myChannel.sendMessage('yoyo')
                            myChannel.on('messageAdded', function (message) {
                                console.log(message.author, message.body);
                            });

                        });


                        // Join a previously created channel



                    }
                });
            })

        }
        */
    }

    guardAgainstClosedLab = () => {
        const {
            identity,
            students,
            setStudioScreen,
            class_screen_id
        } = this.props;

        if (identity && students) {
            const student = students.find(s => s.identity === identity);

            if (student && student.lab && student.terminated_at && class_screen_id !== LOBBY_SCREEN) {
                setStudioScreen({
                    screen_id: LOBBY_SCREEN,
                    class_screen_id: LOBBY_SCREEN,
                    subScreen_id: 0
                })
            }
        }
    }

    resize = () => {
        var targetAspectRatio = 1366.0 / 768.0
        var actualAspectRatio = window.innerWidth / window.innerHeight
        // if the window is very wide max out the height and leave padding on the sides, otherwise max out the width
        var scale = actualAspectRatio > targetAspectRatio ? window.innerHeight / 768 : window.innerWidth / 1366
        scale = scale < 1 ? 1 : scale;
        if (scale !== this.state.scale) {
            this.setState({ scale: scale })
        }
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            // If I don't have a create_url yet, check to make sure that the
            // reason for that isn't that I missed the message.
            const { identity, students } = this.props;
            const thisStudent = students.find(s => s.identity === identity);
            if (thisStudent && !thisStudent.create_url) {
                this.updateMissingCreateUrls();
            }
        }
    }

    goFullScreen = () => {
        !window.toggleFullScreen_mode && (window.innerWidth < 1366 || window.innerHeight < 768) && this.props.setPopUp({
            alert: true,
            show: true,
            contents: () => {
                return (
                    <div onClick={() => { this.props.setPopUp({ show: false }); toggleFullScreen() }}>
                        The class requires your full screen. Are you ready?
                        <div className="buttonRow">
                            <Button label="Start" />
                        </div>
                    </div>)
            },
            onClose: null
        })
    }

    auth_change = () => {
        // console.log('-st auth_change: ', this.props.authenticated)
        if (!this.props.authenticated || !this.props.authentication) {
            this.instructor = null;

            this.props.intercom.closeSession()
            this.props.intercom.launchSession()

            return this.setState({ aggregate: null })
        }

        // force the browser scroller to top of page
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        // Get info on the DCV stream status and report it to the backend every X seconds
        this.dcvUpdateTimer = setInterval(this.updateDcvStreamStatus, 15000)

        // Clear the redux cache of past classes
        this.props.setStudioStudents({ students: [] }) // remove all students from redux cache
        this.props.setStudioScreen({ screen_id: LOBBY_SCREEN, class_screen_id: LOBBY_SCREEN, subScreen_id: 0 })

        this.instructor = this.props.authentication ? this.props.authentication.groups.indexOf('Instructor') > -1 : false

        window.navigator.serviceWorker && window.navigator.serviceWorker.getRegistrations().then((registrations) => {
            const serviceWorkers = registrations.map((reg) => ({
                "serviceWorker": {
                    active: { state: reg.active && reg.active.state },
                    scope: reg.scope,
                    waiting: reg.waiting
                }
            }));

            const userInfo = {
                branch_version: window.branch_version,
                userAgent: window.navigator.userAgent,
                serviceWorkers
            };

            ddbStamper.stampUserInformation(this.state.class_id, { ...userInfo });
        });

        this.init_class()
    }

    handleLocalActionables = (action, student) => {
        if (student.identity !== this.props.identity) return;

        // Intercept and handle client-side actions we can do locally as opposed to executing on them by waiting and hearing back on the subscription
        if (action === "mute") {
            // console.log("-st Local audio mute state: ", student.mute);

            this.upsertStudent(
                { 
                    identity: this.props.identity, 
                    mute: !student.mute 
                },
                "handleLocalActionables case: mute"
            );
        } else if (action === "participate" || action === "participate-off") {
            // console.log("-st Local .participate state: ", student.participate);

            let newParticipateState = student.participate
            
            if (action === "participate") {
                newParticipateState = !student.participate
            } else if (action === "participate-off") {
                newParticipateState = false
            }
            this.upsertStudent(
                { 
                    identity: this.props.identity, 
                    participate: newParticipateState,
                },
                "handleLocalActionables case: participate"
            );
        }
    };

    action_callback = (action, student) => {
        console.log("Sending action: ", action, student);

        this.props.setContextMenu({ show: false });

        this.handleLocalActionables(action, student);

        this.actionHandler.send(action, student, this.props.students);
    };

    classAction_callback = (action) => {
        console.log("Sending Class Action: ", action)

        // Automatically inject the user calling this action (typically the instructor) as the student
        const student = this.props.students.find(s => s.identity === this.props.identity)

        this.actionHandler.send(action, student, this.props.students)

    }

    upsertStudent = (data, calledBy) => {
        // merge an existing student's data with a filtering of non-null data
        Object.keys(data).forEach(key => data[key] === null && delete data[key])
        if (!Object.keys(data).length) {
            return true
        }

        // NOTE: Order has been commentted out/removed from merged_data below so that the value set here of -1 for the user is not used to stomp
        // over the order value that has come in from the database in get_students() when the user data is merged and returned at the end of the function.
        var merged_data = {
            // order: -1,
            ...this.props.students.find(s => s.identity === data.identity),
            ...data
        };

        // console.log("upsertStudent", "(calledBy " + calledBy + "):", merged_data);

        // combine the merged data with the rest of the students
        var newData = [merged_data, ...this.props.students.filter(s => s.identity !== data.identity)]
        this.props.setStudioStudents({ students: newData })
        return true
    }

    get_students = (callback) => {
        window.configure_amplify('studio_endpoint')

        query(querySeats, { id: this.state.class_id }, (response) => {
            // filter out students without an order, unless they are the instructor
            // Order set as -1 in upsertStudent(...)
            let students = response.filter(s => !isNaN(parseInt(s.order)) || (this.instructor && s.identity === this.props.identity))

            // update my record with my full public id so i can send my avater to people
            const myState = {
                ...this.props.authentication.public,
                instructor: this.instructor,
                broadcast: this.instructor,
                controlWatch: this.instructor,
                micToggleable: true,
                mute: false,
                controlCreate: true,
                location: 'studio',
                createScreenNavigable: true,
                watchScreenNavigable: true,
                lobbyScreenNavigable: true,
                playScreenNavigable: true,
                portfolioScreenNavigable: true
            }
            this.setState(myState, () => {
                const studentMeCurrent = this.props.students.find(s => s.identity === this.props.identity)

                students = students.map(s => s.identity === this.props.identity ? { ...myState, ...s, ...studentMeCurrent } : s) // update myState with my class record
                this.props.setStudioStudents({ students: students })
                callback && callback(students)
            })
        })
    }

    updateMissingCreateUrls = () => {
        query(querySeats,
            {
                id: this.state.class_id,
            },
            (response) => {
                console.log("-st QUERY SEATS RESP: ", response)
                if (!response || !response.length) {
                    return
                }

                var students = this.props.students.map(student => {
                    const match = response.find(s => s.identity === student.identity)
                    return {
                        ...student,
                        create_url: match ? match.create_url : null
                    };
                })
                this.props.setStudioStudents({ students: students })
            }
        )

    }

    autoAssignSeat = (retryAttempts = 0) => {
        console.log("-st Auto assigning a seat")

        const classTableId = this.state.class_id.replace('class-', '')

        window.configure_amplify('arcade_endpoint')
        send(getClassInfo, { id: classTableId }, (classInfo) => {
            // Check for network errors related to the call
            if (!classInfo || classInfo.errors) {
                this.props.intercom.launchSession()
                this.props.intercom.showPrepopulatedHelpSessionMessage(this.props.authentication.username, "I can't get a seat\nError with getting info about seats from Class table\nAppSync problem?")
                return
            }

            const studentOccupiedSeats = classInfo.seats
                ? classInfo.seats.filter(seat => seat >= 0)
                : [];

            // Start with 12 seats and tack on an extra 3 if we're full
            const numSeats = studentOccupiedSeats.length >= NUM_CLASS_SEATS
                ? NUM_CLASS_SEATS + 3
                : NUM_CLASS_SEATS;

            // For our available seats, find the open ones and pick one randomly
            const allSeats = [...Array(numSeats).keys()]
            const openSeats = allSeats.filter(seat => !studentOccupiedSeats.includes(seat))
            const randomSeat = openSeats.random()

            console.log("-st Seats info: ", openSeats, randomSeat)

            // Check if an error was encountered picking a free seat
            if (isNaN(parseInt(randomSeat))) {
                this.props.intercom.launchSession()
                this.props.intercom.showPrepopulatedHelpSessionMessage(this.props.authentication.username, "I can't get a seat\nOpen seats in Class: " + openSeats + "\nChosen seat: " + randomSeat)
                return
            }

            const order = this.instructor ? -1 : randomSeat

            window.configure_amplify('studio_endpoint')
            send(requestSeat,
                {
                    id: classTableId,
                    index: order
                }, (response) => {
                    if (response.errors) {
                        // console.log("-st requestSeat send error: ", response)
                        ddbStamper.stampFrontendError(this.state.class_id, { "error": response || "no err response available", "type": "requestSeat", "req": { "id": classTableId, "index": order } })

                        // Try requesting a new seat which is usually in the case when the seat gets taken from the user by another user behind their back
                        const conditionalCheckFailed = response.errors.some(err => err.errorType === "DynamoDB:ConditionalCheckFailedException")
                        const retryAttemptsExceeded = retryAttempts >= AUTO_ASSIGN_SEAT_RETRY_ATTEMPTS

                        if (conditionalCheckFailed && !retryAttemptsExceeded) {
                            this.autoAssignSeat(retryAttempts + 1)
                        } else {
                            this.props.intercom.launchSession()
                            this.props.intercom.showPrepopulatedHelpSessionMessage(this.props.authentication.username, "I can't get a seat\nRequest seat failing\nconditionalCheckFailed: " + conditionalCheckFailed + "\nretryAttemptsExceeded: " + retryAttemptsExceeded + "\nCheck stats for more info.")
                        }
                        return
                    }

                    if (response.seats && response.seats.indexOf(order) > -1) { // If the requested order is now in the seats list, we can use it
                        let environment_type = "prod"
                        const ec2_tag = window.ume_config.ec2_tag || ""
                        if (ec2_tag.toLowerCase().includes("dev")) environment_type = "dev"
                        else if (ec2_tag.toLowerCase().includes("smoke")) environment_type = "smoke"

                        window.configure_amplify('studio_endpoint')
                        send(updateSeat, // update the User-Beta class- record with the selected seat
                            {
                                input: {
                                    sort_key: this.state.class_id,
                                    order: order,
                                    appsync_id: window.ume_config.studio_api,
                                    environment_type
                                }
                            }, (response) => {
                                if (response.errors) {
                                    console.log("-st updateSeat send error: ", response)
                                    ddbStamper.stampFrontendError(this.state.class_id, { "error": response || "no err response available", "type": "updateSeat", "req": { "input": { "sort_key": this.state.class_id, "order": order, "appsync_id": window.ume_config.studio_api } } });
                                    return
                                }
                                this.get_students((students) => { // get_students will now see our order and include us
                                    console.log("-st get_students -- End of auto assigning seat, sending rollcall now")
                                    var message = {}
                                    message.from = { ...students.find(s => s.identity === this.props.identity) || {} }
                                    message.from.location = 'studio'
                                    studio_cmd(this.state.class_id, "rollcall", message)
                                })
                            })
                    }
                })
        })
    }

    initializeSubscriptions = (class_id) => {
        // Unfortionately have to wrap the subscriptions setup in get_students() so Studio can initialize properly
        // At some point this should be cleaned up so things are awaited and not within callbacks of callbacks
        this.get_students((_students) => {
            console.log("-st get_students -- initializeSubscriptions")

            // Initialize the OnStudioCommand (OSC) which is the classroom level communication between all the users
            const onStudioCommandSubscription = new graphqlOperation(onStudioCommand, { id: class_id });
            const studioCommandMutation = new graphqlOperation(studioCommand, {
                input: {
                    id: class_id,
                    command: "", // Leave empty
                    arguments: "", // Injected later
                }
            });

            const OSCSubscription = new Subscription(class_id, onStudioCommandSubscription, studioCommandMutation, "osc")
            OSCSubscription.start(this.handleIncomingOSCEvent, () => {
                this.get_students((students) => {
                    console.log("-st get_students -- OSC rollcall")
                    let message = {}
                    message.from = { ...students.find(s => s.identity === this.props.identity) || {} }
                    message.from.location = 'studio'
                    studio_cmd(class_id, "rollcall", message)
                })
            })


            // Initialize the OnUserCommand (OUC) which listens to changes from the User table
            const onUserCommandSubscription = new graphqlOperation(onUserCommand, { identity: this.props.identity, sort_key: class_id });
            const userCommandMutation = new graphqlOperation(userCommand, {
                input: {
                    identity: this.props.identity,
                    sort_key: class_id,
                    arguments: "", // Injected later
                },
            });

            const OUCSubscription = new Subscription(class_id, onUserCommandSubscription, userCommandMutation, "ouc")
            OUCSubscription.start(this.handleIncomingOUCEvent)


            // Initialize comms
            const testCommsLocally = true
            if ((document.location.hostname === "ume.games" || testCommsLocally) && !this.state.lab) {
                this.init_comms()
            }
        })
    }

    handleIncomingOUCEvent = (event) => {
        const args = JSON.parse(event.value.data.onUserCommand.arguments)

        // INFO: We must remove `avatar` from data if it is coming from `subscribe_to_userCommand` (STUC) because STUC should not
        // send an `avatar` prop. If there is an `avatar` prop, it would come in as "file_name.png", which would overwrite
        // the original `avatar` structure, which is a URL (eg. "https://link-to-aws-s3.com/filename.png")
        if ("avatar" in args) delete args.avatar

        // console.log("ouc: ", args)

        // Important* Cache the previous data for the user before upserting (updating) them with the new data
        const prevUserData = this.props.students.find(s => s.identity === this.props.identity)

        this.upsertStudent(args, "subscribe_to_userCommand");

        // If the create_url has changed then broadcast it out
        if (prevUserData && args.create_url && prevUserData.create_url !== args.create_url) {
            this.classAction_callback('update-create-url')
        }

        // Handle the incoming ML Help
        if (args.ml_help) {
            if (this.state.metadata?.ml) {
                this.handleMLHelp(prevUserData.ml_help, args.ml_help);
            } else {
                console.log('ML_HELP: Received ML help, but this lesson is not configured to show help', args.ml_help);
            }
        }
    } 

    handleIncomingOSCEvent = (event) => {
        const command = event.value.data.onStudioCommand.command
        const args = JSON.parse(event.value.data.onStudioCommand.arguments)

        // Prepare any messages we need to broadcast to the classroom
        var message = {}
        message.from = { ...this.props.students.find(s => s.identity === this.props.identity) || {} }
        message.from.location = 'studio'

        switch (command) {
            case 'rollcall': // i have been asked to share my state with the requester
                console.log('osc: rollcall:', args)
                message.to = { ...args.from }
                message.state = this.instructor ? this.get_studioState() : null // only instructor can share the state
                args.from.location === 'studio' &&
                    (!isNaN(parseInt(args.from.order)) || args.from.instructor) && this.upsertStudent(args.from, "subscribe_to_studioCommand; case rollcall")

                args.from.location === 'studio' && args.from.instructor && this.setState((prevState, props) => ({ host_active: true }))

                args.from &&
                    args.from.identity !== this.props.identity &&
                    message.from.identity && studio_cmd(this.state.class_id, "hello", message)
                break;

            case 'hello':
                console.log("osc: hello: ", args, args.from.order, !isNaN(parseInt(args.from.order)))
                args.from.location === 'studio' && args.from.instructor && this.setState((prevState, props) => ({ host_active: true }))

                // update my record of this student as it may have changed from when i got it from the database                        
                args.from && // there is sender information
                    args.from.identity && args.from.identity !== this.props.identity && // they have an identity
                    args.from.location === 'studio' && // the sender is in the studio                            
                    (!isNaN(parseInt(args.from.order)) || args.from.instructor) && // they have selected a seat                            
                    this.upsertStudent(args.from, "subscribe_to_studioCommand; case hello") && // upsert them
                    args.state && args.to.identity === this.props.identity && this.set_studioState(args.state) // if instructor sent state to me, initialize self with it
                break;
            case 'watch':
                console.log("osc: watch: ", args)

                if (this.props.class_screen_id === WATCH_SCREEN && args.newPinState === "off") {
                    // Class is pinned in this tab already so calling this endpoint again will unpin them (unless the instructor had lost the prev state)
                    this.props.setStudioScreen({ class_screen_id: -1 })

                    // Exit: don't need to set the watch url again if unpinning the tab
                    return
                } else if (args.newPinState === "on") {
                    // This is the only time you'd want to move everyone over to the tab
                    this.props.setStudioScreen({ screen_id: WATCH_SCREEN, class_screen_id: WATCH_SCREEN })
                } else if (this.instructor ^ args.broadcasterIdentity === this.props.identity) {
                    // If a user gets set to broadcast bring them and the instructor over to the Watch tab
                    // When the student leaves the Watch tab the watch_url is set to the instructors but dont bring the instructor over on this un-set event
                    this.props.setStudioScreen({ screen_id: WATCH_SCREEN })
                } else {
                    // Do nothing with moving screens around, silently set the watch_url behind the scenes
                }

                this.setState({ watch_url: args.watch_url })
                break;
            case 'create':
                //console.log("osc: create: ", args)

                if (this.props.class_screen_id === CREATE_SCREEN && args.newPinState === "off") {
                    // Class is pinned in this tab already so calling this endpoint again will unpin them
                    this.props.setStudioScreen({ class_screen_id: -1 })
                } else if (args.newPinState === "on") {
                    this.props.setStudioScreen({ screen_id: CREATE_SCREEN, class_screen_id: CREATE_SCREEN })
                }

                break;
            case 'lobby':
                //console.log("osc: lobby: ", args)

                if (this.props.class_screen_id === LOBBY_SCREEN && args.newPinState === "off") {
                    // Class is pinned in this tab already so calling this endpoint again will unpin them
                    this.props.setStudioScreen({ class_screen_id: -1 })
                } else if (args.newPinState === "on") {
                    this.props.setStudioScreen({ screen_id: LOBBY_SCREEN, class_screen_id: LOBBY_SCREEN })
                }

                break;
            case 'play':
                //console.log("osc: play: ", args)

                if (this.props.class_screen_id === PLAY_SCREEN && args.newPinState === "off") {
                    // Class is pinned in this tab already so calling this endpoint again will unpin them
                    this.props.setStudioScreen({ class_screen_id: -1 })
                } else if (args.newPinState === "on") {
                    this.props.setStudioScreen({ screen_id: PLAY_SCREEN, class_screen_id: PLAY_SCREEN })
                }

                break;
            case 'portfolio':
                //console.log("osc: portfolio: ", args)

                if (this.props.class_screen_id === PORTFOLIO_SCREEN && args.newPinState === "off") {
                    // Class is pinned in this tab already so calling this endpoint again will unpin them
                    this.props.setStudioScreen({ class_screen_id: -1 })
                } else if (args.newPinState === "on") {
                    this.props.setStudioScreen({ screen_id: PORTFOLIO_SCREEN, class_screen_id: PORTFOLIO_SCREEN })
                }

                break;
            case 'reload-student-browser':
                //console.log("osc: ", command, args)
                args.forEach(s => {
                    if (s.identity === this.props.identity) {
                        window.location.reload()
                    }
                })
                break;
            case 'flag-student-help':
                //console.log("osc: ", command, args)
                args.forEach(s => {
                    if (s.identity === this.props.identity) {
                        this.props.intercom.launchSession()
                        this.props.intercom.showPrepopulatedHelpSessionMessage(this.props.authentication.username, "I've been assigned help from the instructor.")
                    }
                })
                break;
            case 'viewScreen':
                //console.log("osc: ", command, args)
                args.forEach(s => this.upsertStudent(s, "subscribe_to_studioCommand; viewScreen"))

                // Check if any students are missing create_url (if one exists in the database) and then update students
                this.props.students.find(s => !s.create_url) && this.updateMissingCreateUrls()

                // Switch to the Gridview screen if the user isn't on it since the user can shortcut it by clicking "View Screen" anywhere (since on the DCV is just enlarged on the Gridview)
                this.instructor && args.some(s => s.viewScreen) && this.props.screen_id !== GRIDVIEW_SCREEN && this.props.setStudioScreen({ screen_id: GRIDVIEW_SCREEN });
                break;
            case 'broadcast':
            case 'participate':
            case 'participate-off':
            case 'control-create':
            case 'control-watch':
            case 'reset-broadcast':
            case 'reset-control-create':
            case 'mute':
            case 'mic-toggle':
            case 'mic-disable':
            case 'mic-enable':
            case 'mic-disable-all':
            case 'mic-enable-all':
            case 'mouse-on-instructor-video':
            case 'mouse-off-instructor-video':
            case 'dcv-stream-warning':
            case 'lock-create-screen':
            case 'lock-watch-screen':
            case 'lock-lobby-screen':
            case 'lock-play-screen':
            case 'lock-portfolio-screen':
            case 'unlock-create-screen':
            case 'unlock-watch-screen':
            case 'unlock-lobby-screen':
            case 'unlock-play-screen':
            case 'unlock-portfolio-screen':
            case 'update-create-url':
                console.log("osc: ", command, args);
                args.forEach((s) => {
                    // Ignore updating myself when i hear back in some cases since those are already handled locally
                    if (s.identity === this.props.identity && ("mute" in s || "participate" in s || "participate-off" in s)) {
                        return;
                    }

                    this.upsertStudent(s, "subscribe_to_studioCommand; all other cases");
                });
            default:
                break;
        }
    }

    set_studioState = (state) => {
        console.log("-st state incoming: ", state)
        this.setState({ watch_url: state.watch_url }, () => {
            // Set which screen the student should be on
            this.props.setStudioScreen({
                // subScreen_id: !isNaN(parseInt(state.subScreen_id)) ? state.subScreen_id : 0, // Commented out because timer sets lobby sub screen, otherwise get competing screen flashing/switching
                screen_id: !isNaN(parseInt(state.screen_id)) && state.screen_id >= 0 ? state.class_screen_id : 0,
                class_screen_id: !isNaN(parseInt(state.class_screen_id)) && state.class_screen_id >= 0 ? state.class_screen_id : -1
            })


            // Based on what screens are unlocked by the instructor update what the student has access to
            const incomingData = {
                identity: this.props.identity,
                createScreenNavigable: state.createScreenNavigable,
                watchScreenNavigable: state.watchScreenNavigable,
                lobbyScreenNavigable: state.lobbyScreenNavigable,
                playScreenNavigable: state.playScreenNavigable,
                portfolioScreenNavigable: state.portfolioScreenNavigable,
                micToggleable: state.micToggleable,
            }

            this.upsertStudent(incomingData, "set_studioState; updating with incoming Studio state")

        })
    }

    get_studioState = () => {
        console.log("-st state outgoing ")

        const isBroadcasting = this.props.students.find(s => s.broadcast)
        const instructorData = this.props.students.find((s) => s.instructor)

        return {
            subScreen_id: this.props.subScreen_id,
            class_screen_id: this.props.class_screen_id,
            screen_id: this.props.class_screen_id,
            watch_url: this.props.screen_id === WATCH_SCREEN && isBroadcasting ? isBroadcasting.create_url : instructorData ? instructorData.create_url : null,
            createScreenNavigable: instructorData.createScreenNavigable,
            watchScreenNavigable: instructorData.watchScreenNavigable,
            lobbyScreenNavigable: instructorData.lobbyScreenNavigable,
            playScreenNavigable: instructorData.playScreenNavigable,
            portfolioScreenNavigable: instructorData.portfolioScreenNavigable,
            micToggleable: instructorData.micToggleable,
        }
    }

    handleMLHelp = (prevMLHelp, mlHelp) => {
        if ((!prevMLHelp && mlHelp) || (JSON.parse(prevMLHelp)["time"] !== JSON.parse(mlHelp)["time"])) {
            console.log("-st ML help incoming: ", prevMLHelp, mlHelp)
            this.setState((prevState, prevProps) => {
                return { newHelpContent: true, mlHelpPayload: JSON.parse(mlHelp) }
            })
        }
    }

    helpReceived = () => {
        this.setState((prevState, prevProps) => {
            console.log("-st Resetting flag new help content")
            return { newHelpContent: false}
        })
    }


    init_play = () => {
        this.state.aggregate && find_lessons(this.state.aggregate.id, 1, lessons => {
            console.log("-st init_play: ", lessons)

            const lessonGameIds = (lessons && lessons[0] && lessons[0].games) || []

            lessonGameIds.forEach(lessonGameId => {
                // Find all the game records from Algolia given the game id in the lesson, this returns an array
                find_games(lessonGameId, 1, game => {

                    this.setState((prevState, props) => {
                        // Append games found from Algolia to the games state variable array
                        return { games: [...prevState.games, ...game] }
                    })

                })
            })
        })
    }

    /**
     * Initialize comms
     * Passes the activity_id aka Class.id
     * Resolver injects the user's identity via their cognito credentials
     * Update the state with the new comms data
     */
    init_comms = () => {
        send(getComms,
            {
                id: this.state.class_id,
            },
            (response) => {
                //console.log("-c GET_COMMS RESP: ", response)
                response && this.setState(response)
            }
        )
    }

    /**
    * Initialize DCV seat
    * Passes the activity_id aka Class.id
    * Resolver injects the user's identity via their cognito credentials
    * Resolver updates the user's User-Beta table with identity,sort_key being the class
    * Studio listens for that update to get the create_url and any other DCV info
    */

    init_seat = () => {
        AWSPing(result => {
            window.ume_config.pings = result
            const now = Date.now()
            const data = {}
            data[now] = { 'awsPing': result }
            send(updateStats,
                {
                    input: {
                        sort_key: this.state.class_id, stats: [JSON.stringify(data)]
                    }
                },
                () => {
                    send(getSeat,
                        {
                            id: this.state.class_id,
                            role: this.instructor ? 'instructor' : 'student',
                            config: window.ume_config.id
                        },
                        (response) => {
                            (response && !response.errors) ? console.log("-c GET SEAT RESP: ", response) : console.log("-c GET SEAT ERROR: ", response)

                            if (response && response.errors) {
                                ddbStamper.stampFrontendError(this.state.class_id, { "error": response || "no err response available", "type": "getSeat", "req": { "class_id": this.state.class_id, "role": this.instructor ? 'instructor' : 'student', "config": window.ume_config.id } })
                                return
                            }

                            // @Daniel
                            // These don't do anything right - the OUC handles setting create_url? Response always comes back null
                            // Lambda should probably return a response object
                            // this.setState(response)
                            // this.upsertStudent(response, "init_seat")
                        }
                    )
                }
            )

        })

    }

    loadLessonMetadata = async () => {
        try {
            const lessonId = this.state.class_id.split('-')[2];
            const lesson = await fetchLatestPublishByContext(`lessons:${lessonId}`);

            this.setState({
                metadata: lesson.metadata,
            });
        } catch (err) {
            console.error('Failed to parse metadata', err);
        }
    }

    /**
    * Initialize the class data from the user's sort_key
    * Updated:
    *   If they are not registered for this class
    *       register them and then call init_class again
    *   If they are registered but have no seat
    *       find an empty seat and assign it, then call init_class again
    */
    init_class = () => {
        this.numInitClassAttempts++;
        if (this.numInitClassAttempts > 12) {
            // Because this is a recursive function, this code is to defensively prevent infinite recursion.
            // The number 12 is arbitrary, max attempts should be 2.
            console.error("Too many attempts to initialize class. Exiting...")
            return;
        }

        window.configure_amplify('studio_endpoint')
        //console.log('init_class')

        // get the User table class record for this user, configure initial information
        send(getClass,
            {
                id: this.state.class_id,
            },
            (response) => {
                console.log("-c GET CLASS RESP: ", response)

                this.loadLessonMetadata();

                if (response.lab) {
                    response.aggregate = JSON.parse(response.lab_data);
                    this.setState({ ...response, userJoinedTooEarly: false, lab: true }, () => {
                        this.props.setStudioScreen({ screen_id: CREATE_SCREEN, class_screen_id: CREATE_SCREEN });
                    });
                    this.initializeSubscriptions(this.state.class_id);
                    return;
                }

                if (response && response.aggregate) {
                    var aggregate_id;
                    try {
                        aggregate_id = JSON.parse(response.aggregate).id // support the legacy cache of aggregate on old records
                    } catch {
                        aggregate_id = response.aggregate
                    }

                    // get the latest lesson data from Algolia index
                    find_lessons(aggregate_id, 1, (results) => {
                        response.aggregate = results[0]

                        const startThreshhold = (parseInt(response.start) || 0) - (TIME.TWENTY_MINUTES);
                        if ((getCurrentTime() - startThreshhold) < 0) {
                            ddbStamper.stampFrontendError(this.state.class_id, {
                                error: {
                                    reject: "Entered too early"
                                }
                            });
                            this.props.intercom.launchSession();
                            return this.setState({ ...response, userJoinedTooEarly: true });
                        }

                        // initialize the class state with info from my user record
                        this.setState({ ...response, userJoinedTooEarly: false }, this.init_play);
                        this.initializeSubscriptions(this.state.class_id)
                    })
                    return
                }
                console.log(this.state.class_id)
                AWSPingAvg((pingResults) => {

                    axios.post('https://uejsw70lt0.execute-api.us-west-2.amazonaws.com/release/registration', {
                        class_id: this.state.class_id.replace('class-', ''),
                        region: pingResults[0].name,
                        credentials: this.props.authentication.credentials
                    })
                        .then((response) => {
                            if (response.data.status === 'success') {
                                this.init_class();
                            }
                        })
                        .catch((err) => {
                            console.error("ume-registration responded with an error!", err)
                        })
                })
            },
            (error) => {
                console.log("-c GET CLASS ERROR: ", error)
            }
        )
    }


    screen_callback = (screen) => {
        this.setState({ screen_id: screen })
    }

    lowWifi_callback = (qualityLevel) => {
        // Set popup with quality level
        if (qualityLevel < 2) {
            this.setState({ lowWifi: true })
        } else {
            this.setState({ lowWifi: false })
        }
    }

    updateDcvStreamStatus = () => {
        //console.log("-c UPDCV - UPDATE FIRED")

        // this.handleDcvStreamStatus(this.state.screen === 1 ? JSON.stringify({ "status": "ok", "fps": 20, "latency": 13.4 }) : JSON.stringify({ "status": "warn", "fps": 11, "latency": 40.67 }))

        // Catch errors related to non-initialized iframes and CORS when running localhost
        var stats = {}

        try {
            this.dcvConnection = this.dcvConnection ? this.dcvConnection : document.getElementById('create_iframe').contentWindow.dcvConnection
            stats = this.dcvConnection.getStats();
            const dcvStats = {
                fps: stats.fps,
                latency: parseInt(stats.latency),
                traffic: parseInt(stats.traffic) / 1024
            }

            ddbStamper.addStatsEntry(this.state.class_id, { dcvStats })

            //console.log("-c UPDCV - no error, stats are: ", stats)
        } catch (error) {
            // Could catch different errors related to DCV and otherwise and update status in BE ie: failure status
            //console.log("-c UPDCV - stats error: ", this.dcvConnection, error)
            return 1
        }



        // @jas do we need to update here or can we use logic below?
        //this.props.setStudioStudents({ students: localized_students })

        const studentMe = this.props.students.find(s => s.identity === this.props.identity)
        // Calculate a response based on the stats
        if (stats.latency > 43) {
            // Cache previous status, if different then:
            if (!this.dcvAlert) {
                // Send warning
                this.action_callback("dcv-stream-warning", studentMe)
                this.dcvAlert = true
            }
        } else {
            if (this.dcvAlert) {
                // Send ok now
                this.action_callback("dcv-stream-warning", studentMe)
                this.dcvAlert = false
            }
        }


    }

    verifyUserSystemTime = () => {
        if (Math.abs(window.timeoffset) > TIME.FIVE_MINUTES) {
            const now = Date.now()
            const username = (this.props.authentication && this.props.authentication.username) || "ERROR UNKNOWN USERNAME"
            this.props.intercom.launchSession();
            this.props.intercom.showPrepopulatedHelpSessionMessage(username, "My time is out of sync.\nWindow.timeoffset: " + window.timeoffset + "\nMy date and time in epoch and regular: " + now + "\n" + new Date(now))
        }
    }


    render() {
        const viewScreen = this.props.students.find(s => s.viewScreen)

        const classes = {
            main: clsx('Studio__wrapper'),
            screen: clsx('Screen',
                this.props.screen_id === LOBBY_SCREEN && 'lobby',
                this.props.screen_id === WATCH_SCREEN && 'watch',
                this.props.screen_id === CREATE_SCREEN && 'create',
                this.props.screen_id === PLAY_SCREEN && 'play',
                this.props.screen_id === GRIDVIEW_SCREEN && 'gridView',
                this.props.screen_id === PORTFOLIO_SCREEN && 'portfolio',
                viewScreen && 'viewScreen'
            ),
            classrow: clsx('Classrow',
                this.props.screen_id === LOBBY_SCREEN && 'lobby',
                this.props.screen_id === WATCH_SCREEN && 'watch',
                this.props.screen_id === CREATE_SCREEN && 'create',
                this.props.screen_id === PLAY_SCREEN && 'play',
                this.props.screen_id === GRIDVIEW_SCREEN && 'gridView',
                this.props.screen_id === PORTFOLIO_SCREEN && 'portfolio',
                viewScreen && 'viewScreen'
            ),
        }
        // these are the settings used for the slider, see https://react-slick.neostack.com/docs/api

        if (!this.props.authenticated) {
            return (
                <LoginLayout>
                    <Auth hideAuth={false} />
                </LoginLayout>
            );
        }
        if (this.state.userJoinedTooEarly) {
            return <JoinedTooEarly
                start={this.state.start}
                aggregate={this.state.aggregate}
            />
        }
        if (!this.instructor && !this.state.host_active && !this.state.lab) {
            return (
                <Wait
                    username={this.props.authentication.public.username}
                    avatar={this.props.authentication.public.avatar}
                    waiting={!this.state.host_active}
                />
            )
        }

        return (
            <div className={classes.main} style={{ transform: 'translate(-50%,-50%) scale(' + this.state.scale + ')' }} onMouseMove={this.handleMouseMove}>
                <PopUp key="StudioPopup" />
                <ContextMenu scale={this.state.scale} />
                <Auth hideAuth={false} />
                <FacilitatorCodeWatcher />

                {/* Top */}
                <MenuBar
                    aggregate={this.state.aggregate}
                    start={this.state.start}
                    screen_callback={this.screen_callback}
                    lowWifi={this.state.lowWifi}
                    instructor={this.instructor}
                    classActionCallback={this.classAction_callback}
                    action_callback={this.action_callback}
                    studio_cmd={(action, payload) => { studio_cmd(this.state.class_id, action, payload) }}
                    lab={this.state.lab}
                />

                {/* Mid */}
                <div className={classes.screen} id="StudioScreens" >
                    <Screen
                        authentication={this.props.authentication}
                        games={this.state.games}
                        watch_url={this.state.watch_url}
                        class_id={this.state.class_id}
                        instructor={this.instructor}
                        action_callback={this.action_callback}
                        aggregate={this.state.aggregate}
                        scale={this.state.scale}
                        lab={this.state.lab}
                    />
                </div>
                {/* Bottom */}
                <div className={classes.classrow}>
                    <Classrow
                        screen_callback={this.screen_callback}
                        setPopUp={this.props.setPopUp}
                        class_id={this.state.class_id}
                        authenticated={this.props.authenticated}
                        authentication={this.props.authentication}
                        twilioRoomName={this.state.comms_id}
                        twilioCommsToken={this.state.comms_token}
                        instructor={this.instructor}
                        instructorIdentity={this.instructor ? this.props.identity : null}
                        lowWifi_callback={this.lowWifi_callback}
                        action_callback={this.action_callback}
                        classActionCallback={this.classAction_callback}
                        newHelpContent={this.state.newHelpContent}
                        helpReceivedCallback={this.helpReceived}
                        mlHelpPayload={this.state.mlHelpPayload}
                        lab={this.state.lab}
                    />
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    authenticated: state.auth.authenticated,
    authentication: state.auth.authentication,
    identity: state.auth.identity,
    signIn: state.auth.signIn,
    selected_student: state.studioSelectedStudent.identity,
    screen_id: state.studioScreen.screen_id,
    subScreen_id: state.studioScreen.subScreen_id,
    class_screen_id: state.studioScreen.class_screen_id,
    lastScreen_id: state.studioScreen.lastScreen_id,
    students: state.studioStudents.students,

});
const mapDispatchToProps = dispatch =>
    bindActionCreators({
        setPopUp, setSignIn, setActivity,
        setContextMenu, setStudioStudents, setStudioScreen
    }, dispatch);

// Studio component before being wrapped in providers
const PreStudio = connect(mapStateToProps, mapDispatchToProps)(withIntercomHOC(Studio));

const FullStudio = (props) => (
    <UserDataProvider>
        <PreStudio {...props} />
    </UserDataProvider>
);

export default FullStudio;
