import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import clsx from 'clsx';

import { setStudioScreen, setStudioStudents, setStudioCommsVolume } from 'actions/studio';
import { setContextMenu } from 'actions/contextMenuAction';

import { query, send } from 'graphql/handler';
import { querySeats, updateHelpHistory } from 'graphql/studio';

import { LOBBY_SCREEN, CREATE_SCREEN, WATCH_SCREEN, GRIDVIEW_SCREEN } from 'pages/Studio/types';
import LobbyType from 'pages/Studio/enums/LobbyType'

import './styles.scss';

import ddbStamper from '../../helpers/DDBStamper'

import Room from '../../components/Comms/Room';

import Button from 'components/Button';

import StudentIcon from './StudentIcon'
import ClassroomTools from './ClassroomTools'
import InstructorTools from './InstructorTools'
import AudioIndicator from './AudioIndicator';
import ClassMicsToggle from './ClassMicsToggle';
import HelpAgentIcon from './HelpAgentIcon';
import { getCurrentTime } from 'helpers';


class Classrow extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the child, they should be treated as const values

        this.state = {
            selected: [],
            inScreen: false,
            hideBubble: true,
            receivedHelp:false,
            surveyDone:false,
        }
    }

    static defaultProps = {
        authenticated: false,
        twilioRoomName: null,
        twilioCommsToken: null,
        instructor: false,
        lowWifi_callback: null, // fn
        screen_id: null,
        students: []
    }

    componentDidMount() {
        this.setState({
            startTime: getCurrentTime(),
        });
    }

    componentWillUnmount() {
        const createListenerFn = document.getElementById('create_iframe')['removeEventListener']
        createListenerFn('mouseover', this.handleIFrame)
        createListenerFn('mouseout', this.handleIFrame)

        const listenerFn = document.getElementById('watch_iframe')['removeEventListener']
        listenerFn('mouseover', (e) => { this.handleIFrame(e) })
        listenerFn('mouseout', (e) => { this.handleIFrame(e) })

    }
    componentDidUpdate(prevProps) {
        if (prevProps.screen_id !== this.props.screen_id) {
            const createListenerFn = document.getElementById('create_iframe')[this.props.screen_id === CREATE_SCREEN ? 'addEventListener' : 'removeEventListener']
            createListenerFn('mouseover', this.handleIFrame)
            createListenerFn('mouseout', this.handleIFrame)

            const listenerFn = document.getElementById('watch_iframe')[this.props.screen_id === WATCH_SCREEN ? 'addEventListener' : 'removeEventListener']
            listenerFn('mouseover', (e) => { this.handleIFrame(e) })
            listenerFn('mouseout', (e) => { this.handleIFrame(e) })

        }

        if (!prevProps.newHelpContent && this.props.newHelpContent) {
            this.handleNewHelp()
        }

        const spentAtLeastOneMinute = getCurrentTime() > this.state.startTime + 60 * 1000;

        if (!this.state.surveyDone && this.state.hideBubble && this.props.screen_id === LOBBY_SCREEN && spentAtLeastOneMinute && (this.props.lab || this.props.subScreen_id === LobbyType.END)) {
            this.setState({ needsHelp: true })
            this.render_helpSurvey()
            this.openPopup()
        }

    }

    handleNewHelp = () => {
        //console.log("-cr handling new help: ", this.props.mlHelpPayload)
        try {
            if (Array.isArray(this.props.mlHelpPayload["payload"]["help_content"][1]["text"])){
                if (Object.keys(this.props.mlHelpPayload).length && this.props.newHelpContent) {
                    this.render_helpAgent(this.props.mlHelpPayload, "1", {"receivedTime":this.getCurrentTime()});
                    this.setState({ needsHelp: true , receivedHelp:true})
                } else {
                    this.setState({ needsHelp: false })
                }

                // Resets help needed boolean after help has been recieved
                this.openPopup()
                this.props.helpReceivedCallback()
            }
            else {throw Error("Help payload schema does not match the ML_Help version!")}
        }
        catch (e) {
            console.warn(e); 
            this.props.helpReceivedCallback()
        }
    }

    closePopup = () => {
        this.setState({ hideBubble: true })
        this.props.helpReceivedCallback()
    }

    openPopup = () => {
        this.setState({ hideBubble: false })
    }

    resetHelpAgent = () => {
        this.setState({
            needsHelp: false,
            hideBubble: true,
            helpContent: null
        })
    }

    getCurrentTime = () => {
        return Date.now() + window.timeoffset;
    }

    prepareTargetObject = (text, targetObject) => {
        return text.replace('<targetObject>', targetObject);
    }

    prepareHelpText = (current, targetObject) =>{
        let content = [];
        if (Array.isArray(current["text"])){
            current.text.map((textItem, i) => {
                content.push(<p className="talktext">{this.prepareTargetObject(textItem, targetObject)}</p>)
                content.push(this.prepareHelpImage(current, i))
            })
            return content
        } else {
            throw Error("Help payload schema does not match the ML_Help version!")
        }
    }
	
	prepareHelpImage = (current, i) => {
        if ('image' in current) {
            if (current['image'][i]){
                return (<div className="helpImage"><img width="90%" src={current['image'][i]} /> </div>)
            }
        }
    }

    render_helpAgent = (helpPayload, helpNo, answers = {}) => {
        let current = helpPayload["payload"]["help_content"][helpNo]
        let choices = current["choices"]
        answers[helpNo] = {}
        
        
        let listButtons = current["choices"].map((item, index) => (
            <Button
                callback={() => {
                    this.closePopup();
                    answers[helpNo]["choice"]= choices[index]["text"];
                    answers[helpNo]["answerTime"] = this.getCurrentTime();
                    if (choices[index]["next"]) { 
                        this.openPopup(); 
                        this.render_helpAgent(helpPayload, choices[index]["next"], answers) 
                    } else {
                        this.sendAnswers(this.prepareAnswers(answers, "Done"), helpPayload["time"])
                    }
                }}
                label={choices[index]["text"]}
            />
        ));
		
        try {      
            let content = <div className="ml-help-content-div">
                <div className="actions" onClick={() => {
                        this.sendAnswers(this.prepareAnswers(answers, "Exit"), helpPayload["time"])
                        this.closePopup();
                    }}>
                    <span className="close" ></span>
                </div>
                
                {this.prepareHelpText(current, helpPayload["target_object"])}

                <div className="buttonRow"> {listButtons}</div>
            </div>

            this.setState({ helpContent: content })
            answers[helpNo]["renderTime"] = this.getCurrentTime()

        } catch (e) {
            console.warn(e);
            this.sendAnswers(this.prepareAnswers(answers, "Error"), helpPayload["time"])
            this.closePopup();
        }
    }

    prepareAnswers = (answers, result) => {
        answers["result"] = result
        answers["resultTime"] = this.getCurrentTime()
        return answers
    }   

    sendAnswers = (answers, time) => {
        this.resetHelpAgent()

        send(
            updateHelpHistory,
            {
                input: {
                    identity: this.props.authentication.identityId,
                    time: time,
                    is_active: false,
                    final_outcome: answers['result'],
                    answers: answers,
                    end_time: this.getCurrentTime()
                }
            },
            (response) => {
                console.log("-c ML_HELP RESP: ", response);
            }
        );

    }

    render_helpSurvey = () => {
        const surveyBaseURL = this.state.receivedHelp
            ? "https://ubc.ca1.qualtrics.com/jfe/form/SV_6gx014MlHwBrPEi"
            : "https://ubc.ca1.qualtrics.com/jfe/form/SV_40cCrx6ml9jiFro";

        const queryString = new URLSearchParams({
            id: this.props.identity,
            class: this.props.class_id
        });

        const surveyLink = `${surveyBaseURL}?${queryString.toString()}`;
        
        const content = <div className="ml-help-content-div">
            <div className="actions" onClick={() => { this.closePopup(); this.resetHelpAgent(); }}>
                <span className="close" ></span>
            </div>
            <iframe
                id="AgentSurvey"
                title="Agent Survey"
                frameBorder="0"
                height={"670em"}
                src={surveyLink}
            />
        </div>

        this.setState({ helpContent: content, surveyDone: true })
    }


    handleIFrame = (e) => {
        e.stopPropagation();
        e.preventDefault();
        e.target.tagName === 'IFRAME' && this.setState({ inScreen: e.type === 'mouseover' ? true : false })
    }

    /**
     * Gets the comms room
     * Uses the Twilio data (room name and token) initialized after the user is authenticated and returns
     * the Room component 
     */
    getCommsRoom = () => {
        // If any data is missing, dont set up the Room
        if (!(this.props.authenticated && this.props.twilioRoomName && this.props.twilioCommsToken)) {
            return
        }

        // Find the logged in student
        var foundStudent = this.props.students.find((student) => student.identity === this.props.authentication.identityId)

        if (foundStudent === undefined) {
            // Usually means student array didnt fully initialize yet
            return (<div />)
        }

        return (<Room
            showVideo={true} //{this.state.screen === 1 ? false : true}
            roomName={this.props.twilioRoomName}
            instructor={this.props.instructor}
            token={this.props.twilioCommsToken}
            studentMuted={foundStudent.mute || (!this.props.instructor && !foundStudent.micToggleable)}
            refreshAudioTrack={foundStudent.refreshAudioTrack}
            handleCommsError={this.handleCommsError}
            handleStudentsNetQualityChanged={this.handleStudentsNetQualityChanged}
            handleMyNetQualityChanged={this.handleMyNetQualityChanged}
            handleDominantSpeaker={this.handleDominantSpeaker}
            handleVolumeLevel={this.handleVolumeLevel}
            handleRemParticipantJoined={this.handleRemParticipantJoined}
            handleRemParticipantLeft={this.handleRemParticipantLeft}
            studentMe={foundStudent}
            actionCallback={this.props.action_callback} />)
    }

    handleCommsError = (errorType, errorMsg, rawError) => {
        console.log("Twilio comms error: ", errorType, errorMsg, rawError)

        // Send event
        // ...

    }

    handleRemParticipantJoined = (participant, studentMe) => {
        // Function gets called when a new participant joins the class initially feeding in all the existing participants
        //  as well as when a new person joins at any other time
        const member = this.props.students.find((student) => student.identity === participant.identity)

        // If the instructor is a participant then unmute yourself
        if (member && member.instructor) {
            member.micToggleable ? this.props.action_callback("mic-enable", studentMe) : this.props.action_callback("mic-disable", studentMe)

        }
    }

    handleRemParticipantLeft = (participant, studentMe) => {
        const member = this.props.students.find((student) => student.identity === participant.identity)

        // If the instructor left then mute yourself (by default any new person joining is muted)
        if (member && member.instructor) {
            this.props.action_callback("mic-disable", studentMe)
            this.props.setPopUp({ alert: true, show: true, contents: () => 'Everyone is muted until the teacher returns.', timer: 2500 })

        }
    }

    handleDominantSpeaker = (participant) => {
        /*
      // For the instructor inject the Twilio dominant speaker bool into the student to be used down the chain
      console.log("-c New dom speaker: ", participant)
     
      var localized_students = []
     
      this.state.students.map((student) => {
     
        if (participant && student.identity === participant.identity) {
          const twilioDominantSpeaker = true
          localized_students.push({ ...student, twilioDominantSpeaker }) // Ensures a copy of student not ref
        } else {
          const twilioDominantSpeaker = false
          localized_students.push({ ...student, twilioDominantSpeaker })
        }
     
      })
     
      this.setState({ students: localized_students })
      */
    }

    handleVolumeLevel = (participantIdentity, level) => {

        this.props.setStudioCommsVolume({ ...this.props.volumeLevels, [participantIdentity]: level })

    }

    handleMyNetQualityChanged = (qualityLevel, twilioNetworkStats) => {
        // Find the logged in student and set their Twilio network quality
        var localized_students = []

        this.props.students.map((student) => {

            if (student.identity === this.props.authentication.identityId) {
                const twilioNetQuality = qualityLevel
                return localized_students.push({ ...student, twilioNetQuality }) // Ensures a copy of student not ref
            } else {
                return localized_students.push({ ...student })
            }

        })

        this.props.setStudioStudents({ students: localized_students })


        // Alert the calling UI
        this.props.lowWifi_callback(qualityLevel)

        // Add it to the stats
        ddbStamper.addStatsEntry(this.props.class_id, { twilioNetworkStats })

    }

    handleStudentsNetQualityChanged = (participantIdentity, qualityLevel) => {
        // For the instructor inject the Twilio quality level into the student to be used down the chain
        var localized_students = []

        this.props.students.map((student) => {

            if (student.identity === participantIdentity) {
                const twilioNetQuality = qualityLevel
                return localized_students.push({ ...student, twilioNetQuality }) // Ensures a copy of student not ref
            } else {
                return localized_students.push({ ...student })
            }

        })

        this.props.setStudioStudents({ students: localized_students })

    }

    init_seats = () => {
        query(querySeats,
            {
                id: this.props.class_id,
            },
            (response) => {
                console.log("-c QUERY SEATS RESP: ", response)
                if (!response || !response.length) {
                    return
                }

                var students = this.props.students.map(student => {
                    const match = response.find(s => s.identity === student.identity)
                    student.create_url = match ? match.create_url : null
                    return student
                })
                this.props.setStudioStudents({ students: students })
            }
        )

    }

    showGridView = () => {
        // If you are not on the gridview screen, go to it
        if (this.props.screen_id !== GRIDVIEW_SCREEN) {
            // check if any students are missing create_url and check if there is one, then update students
            this.props.students.find(s => !s.create_url) && this.init_seats()

            return this.props.setStudioScreen({ screen_id: GRIDVIEW_SCREEN });

        }

        // If you are on the gridview screen and zoomed in (viewing a students screen) then zoom out (basically reset viewScreen and the controlCreate of the student)
        var viewScreenStudent = this.props.students.find((s) => s.viewScreen)

        if (viewScreenStudent !== undefined && viewScreenStudent.viewScreen) {
            this.props.action_callback("viewScreen", viewScreenStudent)
            this.props.action_callback("reset-control-create", viewScreenStudent)
            return;
        }

        // Go back to the last screens
        return this.props.setStudioScreen({ screen_id: this.props.lastScreen_id })

    }

    render_contextMenu(event, data) {
        if (!this.props.instructor) {
            return (<div />)
        }
        var params = {
            students: this.props.students,
            screen_id: this.props.screen_id,
        }

        return this.props.setContextMenu(
            {
                show: true,
                clientX: event.clientX,
                clientY: event.clientY,
                contents: () => {
                    // if clicking on the instructor icon vs a student icon
                    if (!data.student && data.instructor && data.instructor.identity === this.props.identity) {
                        return (<InstructorTools {...{ ...params, data: { ...data.instructor } }} />)
                    }
                    return (<ClassroomTools {...{ ...params, data: { ...data.student }, instructorData: { ...data.instructor } }} />)
                }

            })
    }

    render_gridViewButton() {
        if (!this.props.instructor) {
            return (<div />)
        }
        const viewScreen = this.props.students.find(s => s.viewScreen)
        const classes = {
            main: clsx('gridViewBtn ', (this.props.screen_id === GRIDVIEW_SCREEN && !viewScreen) && 'return'),
        }
        return (
            <div className={classes.main} onClick={this.showGridView} />
        )
    }

    render() {
        const instructorData = this.props.students.find((s) => s.instructor)
        const viewScreen = this.props.students.find(s => s.viewScreen)
        const viewingStudent = this.props.students.find(s => s.viewScreen === true)

        const self = this.props.students.find(s => s.identity === this.props.identity)

        const isWatchScreen = this.props.screen_id === WATCH_SCREEN
        const isCreateScreen = this.props.screen_id === CREATE_SCREEN
        const isLobbyScreen = this.props.screen_id === LOBBY_SCREEN

        // If someone has an order higher than 11, expand the number of seats
        // to accomodate them.
        const maxSeat = Math.max(...this.props.students.map(s => s.order));
        const numSeats = maxSeat < 12 ? 12 : 15;

        const isInstructorControl = instructorData &&
            (
                (isWatchScreen && !instructorData.broadcast && instructorData.controlWatch) ||
                (isCreateScreen && !self.controlCreate) ||
                (viewingStudent && !viewingStudent.controlCreate)
            )
        const classes = {
            main: clsx('Classrow__wrapper '),
            left: clsx('left',
                isLobbyScreen && this.props.subScreen_id === LobbyType.SUMMARY && !this.props.lab && 'zoom',
                isLobbyScreen && this.props.subScreen_id === LobbyType.END && !this.props.lab && ' zoom center'),
            studentIcon: clsx('StudentIcon'),
            cursor: clsx(isInstructorControl && 'cursor'),
            instructorIcon: clsx('InstructorIcon', (isWatchScreen && instructorData && instructorData.broadcast) && 'spotlight'),
            username: clsx('username', (isWatchScreen && instructorData && instructorData.broadcast) && 'spotlight'),
            instructorBg: clsx('instructorBg', (isCreateScreen && this.props.instructor) && 'control', (isWatchScreen && instructorData && instructorData.broadcast) && 'spotlight'),
            volumeLevel: clsx('volumeLevel'),
        }

        // Create a list of all seats that have not been taken
        // This will exclude the instructor seat by default since it will fill from numbers 0 -> 14 and the instructor is -1
        var free_seats = Array(numSeats).fill({}).map((v, idx) => idx).filter(idx => !this.props.students.find(s => s.order === idx))
        // For each student, sparsely insert into the student row and if it is already taken, pop the last available seat and use it instead
        var studentRow = []
        this.props.students.forEach(s => {
            if (s.instructor) {
                return
            }
            studentRow[studentRow[s.order] ? free_seats.pop() : s.order] = (this.props.screen_id === 0 || s.location === 'studio') ? s : null
        })

        // fill the rest of the sparse student row with placeholder data for display
        Array(numSeats).fill({}).forEach((v, idx) => {
            studentRow[idx] = studentRow[idx] || { order: idx }
        })


        //var studentRow = Array(12).fill({}).map((v, idx) => this.props.students.filter(s => !s.instructor && (this.props.screen_id === 0 || s.location === 'studio')).find(s => s.order === idx) || { order: idx })
        return (
            <div className={classes.main}  >
                {/* Left */}
                <div className={classes.left}>
                    <div className={classes.username}>
                        <div className={classes.volumeLevel}>
                            <AudioIndicator student={instructorData} />
                        </div>

                        {instructorData && instructorData.username}
                    </div>
                    <div className={classes.instructorIcon}
                        onClick={(event) => this.render_contextMenu(event, { instructor: instructorData })}>
                        {this.getCommsRoom()}
                        <div className={classes.cursor} />
                        <div className={classes.instructorBg}>
                            <img alt="" src={(instructorData ? instructorData.avatar : '')} />
                        </div>

                    </div>
                </div>

                {/* Center */}
                <div className="centre">
                    {/* Render the students in the row -- it's populated without the instructor */}
                    {studentRow.sort((a, b) => { return a.order - b.order }).map((student, idx) => {

                        // Don't show students in gridview unless they are being viewed
                        if (!viewScreen && this.props.screen_id === GRIDVIEW_SCREEN) {
                            return (<div key={'studioClassRow-' + student.identity + idx}></div>)
                        }
                        const me = student.identity === this.props.identity
                        var settings = {
                            name: idx,
                            data: { ...student },
                            me: me,
                            screen_id: this.props.screen_id,
                            selected: this.props.selected_student === student.identity,
                            viewing: this.state.viewing && this.props.screen_id === GRIDVIEW_SCREEN,
                            viewScreen: viewScreen,
                            action_callback: !this.props.instructor && student.identity === this.props.identity ? this.props.action_callback : null,
                            instructor: this.props.instructor,
                        }

                        return (
                            <div className={clsx('StudentIcon', numSeats > 12 && 'narrow', me && 'me')}
                                onClick={(event) => student.identity && this.props.instructor && this.render_contextMenu(event, { student: student, instructor: instructorData })}
                                key={'studioClassRow-' + student.identity + idx}>
                                <StudentIcon
                                    {...settings}
                                />
                            </div>
                        )
                    })}
                </div>
                {/* Right */}
                <div className="right" onClick={this.handleMouseMove}>
                    {(this.props.lab || !this.props.instructor) && <HelpAgentIcon needsHelp={this.state.needsHelp} helpContent={this.state.helpContent} hideBubble={this.state.hideBubble} /> }
                    {this.props.instructor ? <ClassMicsToggle classActionCallback={this.props.classActionCallback} /> : <div />}
                    {this.render_gridViewButton()}
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    authenticated: state.auth.authenticated,
    authentication: state.auth.authentication,
    identity: state.auth.identity,
    signIn: state.auth.signIn,
    selected_student: state.studioSelectedStudent.identity,
    screen_id: state.studioScreen.screen_id,
    subScreen_id: state.studioScreen.subScreen_id,
    lastScreen_id: state.studioScreen.lastScreen_id,
    students: state.studioStudents.students,
    volumeLevels: state.studioCommsVolume.volumeLevels,
    temp: state

});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setContextMenu, setStudioStudents, setStudioScreen, setStudioCommsVolume }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Classrow);
