/*
  Create Gamer
    login, password, avatar
    
  Create Parent
    username, password
    phone, email


*/
import React, { Component } from 'react';
import { connect } from 'react-redux';


//import { Authenticator, Greetings, VerifyContact, ConfirmSignUp, ConfirmSignIn } from 'aws-amplify-react';

import Input from 'components/Input'
import Button from 'components/Button'
import AvatarPicker from 'components/AvatarPicker'


import { send } from 'graphql/handler'
import { createUser } from 'graphql/studio';


import './styles.scss';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef()
    this.state = {
      authState: 'signIn',
      signUpMode: '',
      status: 'next',
      password_error: false,
      login_error: false,
      password_msg: '',
      login_msg: '',
      mode: props.mode,
      phone_required: false,
      email_required: false
    }
  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps, prevState) {
  }

  validate_code = (value) => {
    this.setState({
      code: value,
      code_error: !value || value.length !== 6,
      code_msg: 'Code should be 6 letters and numbers.'
    })
    return (!value || value.length !== 6)
  }
  validate_text(value) {
    if (!value) {
      return false;
    }
    var exp = new RegExp('^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$')
    return (value.length > 5 && exp.exec(value))
  }

  validate_login = (value) => {
    this.setState({
      login: value,
      login_error: !this.validate_text(value),
      login_msg: 'Login needs at least 6 letters and numbers.'
    })
    return this.validate_text(value)
  }
  validate_password = (value) => {
    this.setState({
      password: value,
      password_error: !this.validate_text(value),
      password_msg: 'Password needs at least 6 letters and numbers.'
    })
    return this.validate_text(value)

  }
  validate_phone = (value) => {
    if (!value) {
      value = ''
    }
    if (value) {
      value = value.match(/\d/g).join('')
      if (value && value[0] === '1') {
        value = value.slice(1, 12)
      }
      else {
        value = value.slice(0, 11)
      }
      value = '+1' + value
    }

    this.setState({
      phone: value,
      phone_error: !(value.length === 12),
      phone_msg: 'Phone number must be 7 digits.'
    })
    return (value.length === 12)
  }
  validate_email = (value) => {
    var exp = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/);
    var valid = exp.exec(value)
    this.setState({
      email: value,
      email_error: !valid,
      email_msg: 'Email address is not valid.'
    })
    console.log('handleSubmit')

    return valid
  }
  select_avatar = (value) => {
    this.setState({ avatar: value })
  }

  handleSubmit = (event) => {
    console.log('handleSubmit', event)
    event && event.preventDefault();

    // send the request, update the UI, send result to parent
    if (['sending'].indexOf(this.state.status) > -1) {
      console.log('\t not ready create', this.state.status)
      return
    }
    event.persist()
    let target = event.currentTarget
    console.log('\tlogin', this.state.login, (!this.validate_login(this.state.login)))
    console.log('\tvalidate_password', this.state.password, (!this.validate_password(this.state.password)))
    console.log('\tphone_required', !(this.state.phone_required && this.validate_phone(this.state.phone)))
    console.log('\temail_required', !(this.state.email_required && this.validate_email(this.state.email)))
    if (this.props.mode === 'parent') {
      var errors = {}
      if (!this.validate_login(this.state.login)) {
        errors.login_error = true;
      }
      if (!this.validate_password(this.state.password)) {
        errors.password_error = true;
      }
      if (!this.validate_phone(this.state.phone)) {
        errors.phone_error = true;
      }
      if (!this.validate_email(this.state.email)) {
        errors.email_error = true;
      }
      if (Object.keys(errors).length) {
        console.log('errors', errors, Object.keys(errors).length)
        return this.setState(errors)
      }
    }
    if (!this.validate_login(this.state.login) ||
      !this.validate_password(this.state.password)) {
      console.log('failed validation')
      return
    }
    if (target && window.PasswordCredential) {
      try {
        //var c = new window.PasswordCredential(target);
        //return navigator.credentials.store(c);
      } catch { }
    }
    this.setState({ status: 'sending' }, () => {
      const data = {
        login: this.state.login,
        password: this.state.password,
        phone: this.state.phone,
        email: this.state.email,
        avatar: this.state.avatar,
        parent_id: this.props.parent_id,
      }
      console.log('createUser', data)

      send(createUser, { input: data }, (response) => {
        console.log('createUser/Parent response', response)
        //var data = response['createUser']
        console.log(response.status)
        if (response.status === 'exists') {
          this.setState({ status: 'try again', login_error: true, login_msg: 'Login is taken, try another.' })
        }
        else if (response.status === 'login_fail') {
          this.setState({ status: 'try again', password_error: true, password_msg: response.message })
        }
        else if (response.status === 'success') {
          this.setState({ status: 'ready!' }, () => {
            this.props.callback(this.state.login, this.state.password, null)
          })
        }
      })
    });
  }

  render_avatarPicker() {
    if (this.state.mode === 'gamer') {
      return (<AvatarPicker onSelect={(value) => this.select_avatar(value)} />)
    }
    return (<div />)
  }

  render_parent() {
    if (this.state.mode !== 'parent') {
      return (<div />)
    }
    return (
      <div>
        <Input label="*Phone Number"
          message={this.state.phone_msg}
          placeholder="Required for SMS login code"
          type="tel"
          invalid={this.state.phone_error}
          autoComplete="tel"
          name="phone"
          id="phone"
          onChange={(value) => this.validate_phone(value)}
        />
        <Input label="*Email"
          placeholder="For scheduling & communication"
          message={this.state.email_msg}
          type="email"
          invalid={this.state.email_error}
          autoComplete="username email"
          name="email"
          id="email"
          onChange={(value) => this.validate_email(value)}
        />
      </div>
    )
  }

  render() {
    //console.log('CreateUser', this.state.mode, (this.state.mode === 'confirmSignUp'))
    if (!this.state.mode) {
      this.setState({ mode: 'gamer' })
      return (<div />)
      /*
      return (
        <div className="signup_wrapper">
          <div className="title">Are you a gamer or a parent?</div>
          <div className="buttons">
            <Button label="Gamer" callback={() => { this.setState({ mode: 'gamer' }) }} />
            <Button label="Parent" callback={() => { this.setState({ mode: 'parent' }) }} />
          </div>
        </div>
        
      );
      */
    }

    return (
      <div className="CreateUser__wrapper">
        {this.render_avatarPicker()}
        <form ref={this.form} id="UME_SignUp_Form" onSubmit={this.handleSubmit}>
          <div className="scrollable">
            <Input label={this.props.mode === 'parent' ? "Parent Login Name" : this.state.mode === 'addChild' ? "Child Login Name" : "Login Name (don't use real name!)"}
              message={this.state.login_msg}
              placeholder={this.state.mode === 'login' ? "Enter user's login name" : this.state.mode === 'addChild' ? "Do not use child's name" : "Create a login name"}
              invalid={this.state.login_error}
              autoComplete="username email"
              name="username"
              id="username"
              onChange={(value) => this.validate_login(value)}
            />
            <Input label="Password"
              message={this.state.password_msg}
              placeholder={this.state.mode === 'login' ? "Enter user's password" : "Create a password"}
              type={this.state.mode === 'parent' ? 'password' : 'text'}
              invalid={this.state.password_error}
              name="new-password"
              id="ap_password"
              autoComplete="new-password"
              onChange={(value) => this.validate_password(value)}
            />
            {this.render_parent()}
          </div>
          <div className="buttonRow">
            {this.props.onCancel ?
              <Button type="button" label="Back" callback={this.props.onCancel} /> : <div />
            }
            <Button
              label={this.state.status ? this.state.status : this.state.mode === 'login' ? 'Next' : this.props.label}
            //callback={this.handleSubmit}
            />
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authentication: state.auth.authentication,
  signIn: state.auth.signIn
});

const mapDispatchToProps = {
  //setProfile, setSignIn
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
