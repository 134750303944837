/**
 * React component that displays membership plan details. Filled in by a plan
 * model.
 */
import clsx from 'clsx';
import Badge from 'components/Badge';
import UMEButton from 'components/UMEButton';
import Background from '../assets/membership-bg.png';
import MembershipIcon from 'assets/images/icons/membership-icon-menu.png';

const PlanCard = ({ plan, canSelect, onChangeMembership, solo = false, owned = false }) => (
  <Badge
    className={clsx('badge-wrapper--subscription', owned && 'badge-wrapper--owned')}
    cardClassName="membership-card--plan"
    backgroundImage={Background}
    title={plan.name}
    footer={(
      <div className="membership-card__footer-details">
        <div className="membership-card__price">
          {plan.costString}
        </div>
        {plan.cost > 0 && <span className="per-month">/month</span>}
      </div>
    )}
    cta={(
      <>
        {!solo && canSelect && !owned && (
          <UMEButton
            className="badge-wrapper__button badge-wrapper__button--accent"
            to={plan.url}
          >
            <img
              src={MembershipIcon}
              alt=""
              className="cl-button__icon cl-button__icon--static"
              style={{ transform: 'scale(3)' }}
            />
            Get this one
          </UMEButton>
        )}

        {owned && (
          <div className="badge-wrapper__owned-info">
            <div className="badge-wrapper__info-text">
              - Current -
            </div>

            {!solo && !canSelect && (
              <button onClick={onChangeMembership} className="badge-wrapper__link">
                Change Membership
              </button>
            )}
          </div>
        )}
      </>
    )}
  >
    <ul className="membership-card__details">
      {plan.details.map((item, idx) => (
        <li key={idx} className="membership-card__detail-item">
          {item}
        </li>
      ))}
    </ul>
    {plan.promo && (
      <div className="flag membership-card__promo">
        {plan.promo}
      </div>
    )}
  </Badge>
);

export default PlanCard;
