import React, { useState } from 'react'
import { createPortal } from 'react-dom'

// Source: https://stackoverflow.com/a/34744946
export const IFrame = ({
    children,
    ...props
}) => {
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef && contentRef.contentWindow && contentRef.contentWindow.document && contentRef.contentWindow.document.body

    return (
        <iframe {...props} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}