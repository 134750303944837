import React, { Component } from 'react';
import clsx from 'clsx';
import './styles.scss';


export class ContextMenuItemButton extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
            active: props.active,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        /*
        if (this.props.active !== this.state.active) {
            //this.setState({ active: this.props.active })
        }
        */
    }
    clickCallback = (event) => {
        event.preventDefault()

        // Call the button click callback
        this.props.callback();

        // Toggle the active state
        this.setState({ active: !this.state.active })

    }
    render() {
        const classes = {
            main: clsx('ContextMenuItemButton__wrapper ', this.state.active && 'active'),
        }

        if (this.props.hide) return (<div></div>)

        return (
            <div className={classes.main} onClick={this.clickCallback}>
                {this.props.label}
            </div>
        )
    }
}

export class ContextMenuItemConfirmButton extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
            active: props.active,
            confirmed: "unconfirmed",
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Update the label from the props
        // if (this.props.label !== this.state.label) {
        //     this.setState({ label: this.props.label })
        // }
    }

    finalCallback = (event) => {
        event.preventDefault()

        // Call the button click callback
        this.props.callback();

        // Toggle the states
        this.setState({ active: !this.state.active })

        this.resetConfirmation(event)
    }

    confirmChoice = (event) => {
        event.preventDefault()

        // Are you sure
        this.setState({ confirmed: "confirming" })

    }

    resetConfirmation = (event) => {
        event.preventDefault()

        this.setState({ confirmed: "unconfirmed" })
    }

    render() {
        const classes = {
            main: clsx('ContextMenuItemConfirmButton__wrapper ', this.state.active && 'active'),
        }

        if (this.props.hide) return (<div></div>)


        // Label state choices with callbacks
        var normalLabel = <div onClick={this.finalCallback}>{this.props.label}</div>
        var initialLabel = <div onClick={this.confirmChoice}>{this.props.label}</div>
        var confirmLabel = <div className="confirmationLabel">Are you sure?
            <div className="confirmationButtons yes" onClick={this.finalCallback}>Yes</div>
            <div className="confirmationButtons no" onClick={this.resetConfirmation}>No</div>
        </div>

        // Depending on the state the button is in show the appropiate label, if its active (IE: been clicked) dont show the confirmation process
        if (this.state.active) {
            return (
                <div className={classes.main}>
                    {normalLabel}
                </div>
            )
        } else if (this.state.confirmed === "unconfirmed") {
            return (
                <div className={classes.main}>
                    {initialLabel}
                </div>
            )
        } else if (this.state.confirmed === "confirming") {
            return (
                <div className={classes.main}>
                    {confirmLabel}
                </div>
            )
        }

    }
}

export class ContextMenuItemSection extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
        }
    }

    render() {
        const classes = {
            main: clsx('ContextMenuItemSection__wrapper '),
        }
        return (
            <div className={classes.main}>
                {this.props.label}
            </div>
        )
    }
}
export class ContextMenuItemIcon extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
        }
    }

    render() {
        const classes = {
            main: clsx('ContextMenuItemIcon__wrapper '),
        }
        return (
            <div className={classes.main}>
                <div className="icon"><img src={this.props.icon} alt="" /></div>
                <div className="label">{this.props.label}</div>

            </div>
        )
    }
}


export class ContextMenuItemTitle extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
        }
    }

    render() {
        const classes = {
            main: clsx('ContextMenuItemTitle__wrapper '),
        }
        return (
            <div className={classes.main} onClick={() => { }}>
                {this.props.label}
            </div>
        )
    }
}

