import React from 'react';
import clsx from 'clsx';
import LoadingCard from './LoadingCard';
import './styles.scss';

const CardGrid = ({ children = null, className = '', maxLoadingCards = 6 }) => {
  const cards = children === null ? [<LoadingCard key="loading-0" />] : children;

  return (
    <ul className={clsx('card-grid', className)}>
      {cards.map(child => (
        <li className={`card-grid__item ${child.key?.includes('filler') || child.key?.includes('loading') ? 'card-grid__item--filler' : ''}`} key={child.key}>
          {child}
        </li>
      ))}
    </ul>
  );
};

export default CardGrid;
