import React, { useEffect } from 'react';
import clsx from 'clsx';
import SeedProjectCard from './SeedProjectCard';
import './styles.scss';

const ProjectFromSeedModal = ({ activities, show, onClose }) => {
  const styles = {
    container: clsx('start-new-modal__container', show && 'start-new-modal__container--show'),
    modal: clsx('start-new-modal', show && 'start-new-modal--show'),
    background: clsx('start-new-modal__background', show && 'start-new-modal__background--show')
  };

  useEffect(() => {
    if (show) {
      document.body.classList.add('noscroll');
    } else {
      document.body.classList.remove('noscroll');
    }

    return () => document.body.classList.remove('noscroll');
  }, [show]);

  return (
    <div className={styles.container} aria-hidden={!show}>
      <div className={styles.background} onClick={onClose} />
      <div className={styles.modal}>
        <button className="start-new-modal__close" onClick={onClose}>X</button>
        <ul className="start-new-modal__list">
          {activities.map(activity => (
            <li key={activity.id}>
              <SeedProjectCard activity={activity} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProjectFromSeedModal;
