import React, { Component } from 'react';
import Button from '../../../../components/Button'

import Logo from 'assets/images/logo_ume_header.png';
import './styles.scss'

class Wait extends Component {
  render() {
    return (
      <div className="Wait__wrapper">
        <div className="title-container">
          <div className="title-logo"><img className="logo" src={Logo} alt="UME" /></div>
        </div>
        <div className="message">
          Welcome {this.props.username}
        </div>
        <div className="avatar-layout">
          <img className="avatar" src={this.props.avatar} alt="" />
        </div>

        <div className="message">
          Waiting for Instructor to open the studio...
        </div>
        <div className="buttonRow">
          <Button label={this.props.waiting ? 'Studio Closed' : 'Join Studio'} />
        </div>
      </div >
    );
  }
}

export default Wait;
