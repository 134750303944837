import React from "react";
import JoinedTooEarlyStyles from "./JoinedTooEarlyStyles.module.scss"
import { TIME } from "helpers/datetime"

export default function JoinedTooEarly({ start, aggregate = {} }) {
    const startDate = new Date(parseInt(start || 0));
    const timeUserCanEnter = new Date(startDate - (TIME.TWENTY_MINUTES));

    return (
        <div className={JoinedTooEarlyStyles.mainContainer}>
            <h1 className={JoinedTooEarlyStyles.title}>
                Welcome to {aggregate.name}!
            </h1>
            <p className={JoinedTooEarlyStyles.explanation}>
                This lesson is scheduled to start at {startDate.toLocaleString()}.
            </p>
            <p className={JoinedTooEarlyStyles.explanation}>
                You have joined too early. Please come back at {timeUserCanEnter.toLocaleString()}.
            </p>
        </div>
    )
}