import React, { Component } from 'react';
import clsx from 'clsx';
import { useUserData } from 'contexts/userDataContext';
import './styles.scss';

export const ToolMenuItemPassword = () => {
    const userData = useUserData();

    return (
        <div className="ToolMenuItemPassword__wrapper">
            <span className="ToolMenuItemPassword__label">Password:</span> <span className="ToolMenuItemPassword__value">{userData?.password}</span>
        </div>
    );
};

export class ToolMenuItemButton extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
        }
    }

    render() {
        const classes = {
            main: clsx('ToolMenuItemButton__wrapper '),
        }
        return (
            <div className={classes.main} onClick={this.props.onClick}>
                {this.props.label}
            </div>
        )
    }
}

export class ToolMenuItemActionButton extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
            active: props.active
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.active !== this.state.active) {
            this.setState({ active: this.props.active })
        }
    }

    render() {
        const classes = {
            main: clsx('ToolMenuItemButton__wrapper', this.state.active && 'color: red'),
        }

        if (!this.props.showOption) return (<div></div>)

        return (
            <div className={classes.main} onClick={() => {
                this.props.classActionCallback(this.props.action)
                this.setState({ active: !this.state.active })
            }}>
                {this.props.label}
            </div>
        )
    }
}

export class ToolMenuItemSection extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
        }
    }

    render() {
        const classes = {
            main: clsx('ToolMenuItemSection__wrapper '),
        }
        return (
            <div className={classes.main} onClick={this.props.onClick}>
                {this.props.label}
            </div>
        )
    }
}


export class ToolMenuItemTitle extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
        }
    }

    render() {
        const classes = {
            main: clsx('ToolMenuItemTitle__wrapper '),
        }
        return (
            <div className={classes.main} onClick={this.props.onClick}>
                {this.props.label}
            </div>
        )
    }
}

