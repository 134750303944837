import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchSeeds } from 'api';

/**
 * React hook for retrieving a single lesson from Algolia
 *
 * TODO: Is this really just useSeeds all over again?
 *
 * @method
 * @param {string} context The context of the lesson to search for
 */
const useLesson = context => {
  const [loading, setLoading] = useState(false);
  const [lesson, setLesson] = useState();
  const { subscriptions } = useSelector(state => state.auth);

  useEffect(() => {
    if (!context) {
      return;
    }

    setLoading(true);

    (async () => {
      try {
        const seeds = await fetchSeeds({ context, subscriptions });

        if (seeds.length > 0) {
          setLesson(seeds[0]);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [context, subscriptions]);

  return { lesson, loading };
};

export default useLesson;
