import axios from 'axios';
import { GET_IP_API } from '../constants';

export const getIPAddress = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_IP_API)
      .then(({ data }) => {
        resolve(data.ip);
      })
      .catch(err => {
        console.log(err);
        reject(err);
      });
  });
};

export const checkProfanity = (value, callback) => {
  axios.post('https://commentanalyzer.googleapis.com/v1alpha1/comments:analyze?key=AIzaSyAdx61wa7TdBXqQsSVAxPMQBbuyxV63bQ8', {
    comment: { text: value },
    languages: ['en'],
    requestedAttributes: { 'TOXICITY': {} }
  })
    .then(function (response) {
      callback(response.data.attributeScores.TOXICITY.summaryScore.value)
    })
    .catch(function (error) {
      console.log(error);
    });

}

export const toggleFullScreen = () => {
  if (window.innerHeight >= 768 && window.innerWidth >= 1366) {
    return
  }
  var elem = document.getElementById("root");
  /* View in fullscreen */
  if (window.toggleFullScreen_mode && document.fullscreenElement) {
    window.toggleFullScreen_mode = false
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }

  }
  else {
    window.toggleFullScreen_mode = true
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
}

/**
 * Verifies that the returned attributes of the game object are correct
 * 
 * @param {object} game The game object as returned from Algolia
 * 
 * @returns {boolean}
 * 
 */
export const verifyGameAttributes = (game) => {
  return (
      // The fields used for associating a user to a game built from a lesson
      game.public &&
      game.public.identity &&
      game.public.username &&
      game.public.class_id &&

      // Default fields all games have
      game.title &&
      game.thumbnail &&
      game.video &&
      game.webgl &&

      // General fields
      game.id &&
      game.objectID &&

      // FLAG: Extra fields created by the front-end by the Alogia helper find_games method (get rid of these)
      game.game &&
      game.game.name &&
      game.game.id 
  )

}

/**
 * Verifies that the returned attributes of the GORP game object are correct
 * 
 * @param {object} game The game object as returned from Algolia
 * 
 * @returns {boolean}
 * 
 */
export const verifyGorpGameAttributes = (game) => {
  return (
      // Default fields all games have
      game.title &&
      game.thumbnail &&
      game.video &&
      game.webgl &&

      // General fields
      game.objectID &&

      // FLAG: Extra fields created by the front-end by the Alogia helper find_games method (get rid of these)
      game.game &&
      game.game.name
  )

}

/**
 * Checks if a given classId is formatted for an on-the-fly class.
 * 
 * @param {string} classId The classId. Sometimes known as the `sort_key`
 * 
 * @returns {boolean}
 * 
 */
export const isOnTheFlyClassId = (classId) => {
  if ((classId.match(new RegExp("-", "g")) || []).length === 1) {
      return true
  }

  return false
}

/**
 * Verifies that the returned attributes of the lesson object are correct. For now it does this by looking at the context_id
 * and making sure it conforms to a known pattern.
 * 
 * @param {string} lesson The game lesson as returned from Algolia
 * 
 * @returns {boolean}
 * 
 */
export const verifyLessonAttributes = (lesson) => {
  if (!lesson || !lesson.context_id) {
    return false
  }

  const lessonContextIdRegex = /^rec[0-9a-zA-Z]+$/;

  return lessonContextIdRegex.test(lesson.context_id)

}