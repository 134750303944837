import { SET_STUDIO_SCREEN, } from '../actions/types';



const initialState = {
    screen_id: 0,
    class_screen_id: 0,
    lastScreen_id: -1,
    subScreen_id: 0
};

function studioScreenReducer(state = initialState, action) {
    switch (action.type) {
        case SET_STUDIO_SCREEN:
            return {

                ...state,
                ...action.payload,
                lastScreen_id: state.screen_id
            };

        default:
            return state;
    }
}

export default studioScreenReducer;
