import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/Layout';
import StartLabModal from 'components/StartLabModal';
import { LaunchLabProvider } from 'contexts/launchLabContext';
import { ActivityProvider } from 'contexts/activityContext';

import Form from './components/Form';
import { useActivity } from 'contexts/activityContext';

const breadcrumbs = [
  {
    name: 'Make',
    uri: '/creator-lab'
  },
  {
    name: 'Edit Project',
    uri: '#'
  }
];

const LoadingContainer = ({ children }) => {
  const { activity, loading } = useActivity();

  if (loading && !activity) {
    return null;
  }

  return children;
};

const EditProject = () => {
  const { id: context } = useParams();
  const history = useHistory();

  const handleNotFound = () => {
    history.replace('/creator-lab');
  };

  return (
    <Layout tab="creator-lab" breadcrumbs={breadcrumbs}>
      <ActivityProvider context={context} onNotFound={handleNotFound}>
        <LaunchLabProvider>
          <LoadingContainer>
            <Form />
          </LoadingContainer>

          <StartLabModal />
        </LaunchLabProvider>
      </ActivityProvider>
    </Layout>
  );
};

export default EditProject;
