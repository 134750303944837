export const onStudioCommand = `subscription OnStudioCommand($id:String!){
  onStudioCommand(id:$id){
    id
    timestamp
    command
    arguments
  }
}
`;
export const onUserCommand = `subscription OnUserCommand($identity:String,$sort_key:String){
  onUserCommand(identity:$identity,sort_key:$sort_key){
    identity
    sort_key
    arguments
    timestamp
  }
}
`;

export const onUserCommand2 = `subscription OnUserCommand($identity:String){
  onUserCommand(identity:$identity){
    identity
    sort_key
    arguments
    timestamp
  }
}
`; 