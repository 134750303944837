import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UMEButton from 'components/UMEButton';
import { useGraphQLSubscription } from 'hooks';
import { onBroadcast } from 'graphql/arcade';
import cognitoService from 'services/cognito';
import { refreshToken } from 'actions/authAction';

/**
 * Step 3 in unlocking subscriptions: display a receipt for the membership that
 * was purchased and prompt the user to view their available lessons once the
 * subscription has been activated.
 */
const Step3 = ({ plan, order, email, checkForSubscriptionUpdates }) => {
  const [activated, setActivated] = useState(false);
  const [serverUpdated, setServerUpdated] = useState(false);
  const { subscriptions } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useGraphQLSubscription(onBroadcast, () => setServerUpdated(true));

  // Once we know that the subscription has been updated in the databse, we can
  // start polling it to see if the changes have propogated.
  useEffect(() => {
    if ((!checkForSubscriptionUpdates && !serverUpdated) || activated) {
      return;
    }

    const interval = setInterval(async () => {
      const response = await cognitoService.refreshToken();
      dispatch(refreshToken(response));
    }, 2000);

    return () => clearInterval(interval);
  }, [checkForSubscriptionUpdates, serverUpdated, activated, dispatch]);

  // Once the user's subscriptions have been updated in redux, we can let the
  // user know that their membership is activated.
  useEffect(() => {
    if (!plan || !subscriptions) {
      return;
    }

    if (subscriptions.has(plan.productID) || (plan.cost === 0 && subscriptions.size === 0)) {
      setActivated(true);
    }
  }, [subscriptions, plan]);

  return (
    <div className="unlock-form__content">
      <p className="unlock-form__subtitle">
        {plan.name} membership details
      </p>

      <p>
        Congratulations, you've unlocked:
      </p>

      <ul>
        {plan.details.map((detail, idx) => (
          <li key={idx}>
            {detail}
          </li>
        ))}
      </ul>

      {email && (
        <>
          <p>
            Email with account details &amp; receipt sent to:
          </p>

          <div className="receipt__email">
            {email}
          </div>
        </>
      )}

      <div className="unlock-form__button-container">
        {activated
          ? (
            <UMEButton to="/learn">
              Go to lessons
            </UMEButton>
          ) : (
            <div className="cl-button unlock-form__button" disabled>
              Activating
              <div className="unlock-form__loader" />
            </div>
          )}
      </div>
    </div>
  );
};

export default Step3;
