import BaseCard from 'components/CardGrid/BaseCard';
import OutschoolBG from '../../assets/outschool-classes.png';
import './styles.scss';

const OutschoolCard = () => (
  <BaseCard className="cl-card--outschool">
    <img
      src={OutschoolBG}
      alt="Outschool: where kids love learning"
      className="cl-card__image"
    />

    <div className="cl-card__info">
      <h3 className="cl-card__title">
        Find our live classes &amp; camps on Outschool!
      </h3>
    </div>

    <div className="cl-card__buttons">
      <a
        href="https://outschool.com/online-classes?q=UME%20Academy&order=upcoming"
        target="_blank"
        rel="noopener noreferrer"
        className="cl-button"
      >
        Visit Outschool
      </a>
    </div>
  </BaseCard>
);

export default OutschoolCard;
