export const getURI = `query GetURI($code: String!)
 {
  getURI(code: $code){
    uri
  }
}
`;

export const getUser = `query GetUser($identity: String){
  getUser(identity: $identity){
    identity
    username
    avatar
    sort_key  
    order    
  }
}
`;

export const getUserPrivateRecord = `query GetUserPrivateRecord {
  getUserPrivateRecord {
    identity
    sort_key
    password
    username
    facilitator_code
  }
}`;

export const listIdentityContextPublishes = `query ListIdentityContextPublishes(
  $context: String!,
  $limit: Int,
  $filter: String,
  $nextToken: String
) {
  listIdentityContextPublishes(
    context: $context,
    limit: $limit,
    filter: $filter,
    nextToken: $nextToken
  ) {
    items {
      artifact
      context
      id
      identity
      metadata
      status
      tenant
      timestamp
      type
      webview
      version
    }
    nextToken
  }
}
`;

export const listContextTypePublishes = `query ListContextTypePublishes(
  $context: String!,
  $type: String!,
  $limit: Int,
  $filter: String,
  $nextToken: String
) {
  listContextTypePublishes(
    context: $context,
    type: $type,
    limit: $limit,
    filter: $filter,
    nextToken: $nextToken
  ) {
    items {
      artifact
      context
      id
      identity
      metadata
      status
      tenant
      timestamp
      type
      webview
      version
    }
    nextToken
  }
}
`;

export const listIdentityTypePublishes = `query ListIdentityTypePublishes(
  $type: String!,
  $limit: Int,
  $filter: String,
  $nextToken: String
) {
  listIdentityTypePublishes(
    type: $type,
    limit: $limit,
    filter: $filter,
    nextToken: $nextToken
  ) {
    items {
      artifact
      context
      id
      identity
      metadata
      status
      tenant
      timestamp
      type
      webview
      version
    }
    nextToken
  }
}
`;

export const queryClassesByStatusIndex = `query QueryClassesByStatusIndex($status:String!){
  queryClassesByStatusIndex(status:$status){
    items{
      id
      status
      start
      end
      duration
      public
      students{
        username
        avatar        
        identity
        instructor
      }
      aggregate
    }
  }
}
`;

export const queryUser = `query QueryUser($username:String!,$nextToken:String){
  queryUser(username:$username,nextToken:$nextToken){
    items{
      identity
      username
      avatar
      sort_key
      order
    }
    nextToken
  }
}
`;

export const queryUser2 = `query QueryUser($username:String!){
  queryUser(username:$username){
    items{
      identity
      username
      avatar
    }
  }
}
`;

export const queryMyClasses = `query QueryMyClasses($nextToken:String){
  queryMyClasses(nextToken:$nextToken){
    items{
      identity
      aggregate
      username
      avatar
      sort_key
      id
      start
      end
      duration
      public
      order
      lab
      terminated_at
    }
    nextToken
  }
}
`;

export const getClass = `query GetClass($id: String!) {
  getClass(id: $id) {
      id
      status
      start
      end
      duration
      public
      students{
        username
        avatar        
        identity
        instructor
      }
      aggregate
  }
}
`;

export const getClassInfo = `query GetClass($id: String!) {
  getClass(id: $id) {
      id
      status
      start
      end
      duration
      public
      lab
      students{
        username
        avatar        
        identity
        instructor
      }
      aggregate
      seats
  }
}
`;

export const queryIdentity = `query QueryIdentity{
  queryIdentity(){
    items{
      identity
      username
      avatar
      parent_id

    }
  }
}
`;

export const queryGamePlays = `
    query QueryGamePlays($username:String,$aggregate_id:String){
        queryGamePlays(username:$username,aggregate_id:$aggregate_id){
            items{
                aggregate_id
                username      
            }
        }
    }
`
