export const tags = {
  SEED: 'seed',
  EXTENSION: 'extension',
  FEATURED: 'featured',
  MEMBERSHIP_REQUIRED: 'membership-required'
};

export const labStatuses = {
  STARTS_FUTURE: 'FUTURE',
  STARTS_TODAY: 'TODAY',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED: 'FINISHED'
};
