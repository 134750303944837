import DefaultState from './EDIT-OFF.png';
import HoverState from './EDIT-HOVER-ANIM.gif';
import ActiveState from './EDIT-PRESSED.png';

const anvil = {
  defaultState: DefaultState,
  hoverState: HoverState,
  activeState: ActiveState
};

export default anvil;
