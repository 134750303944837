import React, { Component } from 'react';
import { connect } from 'react-redux';

import './styles.scss';

import { ContextMenuItemButton, ContextMenuItemConfirmButton, ContextMenuItemTitle } from 'pages/Studio/components/ContextMenu/ContextMenuItems'
import { WATCH_SCREEN, GRIDVIEW_SCREEN } from 'pages/Studio/types';

class ClassroomTools extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: false,
            active: false,
            hold: false
        }
    }

    render_watch_tools() {
        return (
            <div>
                <ContextMenuItemButton
                    label={"Give Control"}
                    active={this.props.data.controlWatch}
                    hide={this.props.data.controlWatch}
                    callback={() => { this.props.callback('control-watch', this.props.data) }}
                />

                <ContextMenuItemButton
                    label={"Take Control Back"}
                    active={!this.props.data.controlWatch}
                    hide={!this.props.data.controlWatch}
                    callback={() => { this.props.callback('control-watch', this.props.instructorData) }}
                />

                <ContextMenuItemConfirmButton
                    label="Give Spotlight"
                    hide={this.props.data.broadcast}
                    callback={() => { this.props.callback('broadcast', this.props.data) }}
                />
            </div>
        )
    }
    render_create_tools() {

    }
    render_play_tools() {

    }
    render_viewscreen_tools() {
        return (
            <div>
                <ContextMenuItemConfirmButton
                    label="Give Spotlight"
                    hide={this.props.data.viewScreen && (this.props.data.broadcast ? true : false)}
                    callback={() => { this.props.callback('broadcast', this.props.data) }}
                />

                <ContextMenuItemButton
                    label={"Give Control of Create"}
                    hide={this.props.data.controlCreate}
                    callback={() => { this.props.callback('control-create', this.props.data) }}
                />
            </div>
        )
    }
    render_context_tools() {
        switch (this.props.screen_id) {
            case WATCH_SCREEN:
                return this.render_watch_tools();
            case GRIDVIEW_SCREEN:
                return this.render_viewscreen_tools();
            default:
                return (<div />)
        }
    }
    render() {
        if (!this.props.data) {
            return (<div />)
        }

        const hideMicToggle = (this.props.data.twilioNetQuality === undefined || this.props.data.twilioNetQuality === null || this.props.data.twilioNetQuality === 0)
        const hideViewScreen = this.props.data.viewScreen

        return (
            < div className="ClassroomTools__wrapper" >
                <ContextMenuItemTitle label={this.props.data.username} />
                <ContextMenuItemButton
                    label={this.props.data.micToggleable ? "Disable mic" : "Enable mic"}
                    active={this.props.data.micToggleable}
                    hide={hideMicToggle}
                    callback={() => { this.props.callback('mic-toggle', this.props.data) }}
                />
                <ContextMenuItemConfirmButton
                    label="Reload"
                    active={false}
                    callback={() => { this.props.callback('reload-student-browser', this.props.data) }}
                />
                <ContextMenuItemButton
                    label="Needs help"
                    active={false}
                    callback={() => { this.props.callback('flag-student-help', this.props.data) }}
                />
                <ContextMenuItemButton
                    label="View Screen"
                    active={false}
                    hide={hideViewScreen}
                    callback={() => { this.props.callback('viewScreen', this.props.data) }}
                />
                {this.render_context_tools()}


            </div >
        );

    }
}


const mapStateToProps = state => ({
    callback: state.contextMenu.callback,
});
export default connect(mapStateToProps, null)(ClassroomTools);
