import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * React hook for easier access to the current search params, formatted into an
 * object.
 */
const useSearchParams = () => {
  const [params, setParams] = useState({});
  const location = useLocation();

  useEffect(() => {
    const entries = new URLSearchParams(location.search).entries();
    setParams(Object.fromEntries(entries));
  }, [location]);

  return params;
};

export default useSearchParams;
