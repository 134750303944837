import React, { Component } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

import './styles.scss';


class HelpAgentIcon extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

    }

    render() {
        console.log(this.props)
        const classes = {
            main: clsx('HelpAgentIcon__wrapper'),
            helpAgentIcon: clsx('help-agent-icon', this.props.needsHelp ? 'idle-2' : 'closed'),
            helpSpeechBubble: clsx('speech-bubble', this.props.hideBubble ? 'closed' : 'open')
        };

        return (
            <div className={classes.main}>
                <div className={classes.helpSpeechBubble}> {this.props.helpContent} </div>
                <div className={classes.helpAgentIcon} />
            </div>

        )
    }
}

const mapStateToProps = state => ({
    identity: state.auth.identity,

});



export default connect(mapStateToProps, null)(HelpAgentIcon);
