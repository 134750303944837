import React, { Component } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

import { setStudioStudents } from 'actions/studio';

import './styles.scss';

import pollAudioLevel from '../../pages/Studio/components/Comms/pollaudiolevel'

const { createLocalAudioTrack } = require('twilio-video');

/**
 * The AudioCheck component allows a user to pick a different audio input device (microphone) and test their device by speaking
 * and hearing back the recording.
 * 
 * Import:
 * import AudioCheck from 'path/AudioCheck'
 * 
 * Usage:
 * This component is designed to be used in the PopUp component (but can be modified to work standalone or embedded)
 * ```jsx
 * <SomeButton
 *      label={"Audio Settings"}
 *      onClick={() => this.props.setPopUp({
 *          alert: false, show: true, contents: () => { return <AudioCheck initializationAllowed={true} /> }
 *      })}
 * />
 * ```
 * 
 * Props:
 * - initializationAllowed: This may be used to control whether the AudioCheck panel should initialize gathering data from the browser based
 * on other states in the application (such as third-party communication API's)
 * 
 */
class AudioCheck extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mediaDevices: null,
            selectedAudioInput: window.selectedAudioInput || null,
            selectedAudioOutput: window.selectedAudioOutput || null,
            localAudioLevel: null,
            audioInputChanged: false,
            audioOutputChanged: false,
            audioInputVolChanged: false,
            audioOutputVolChanged: false,
            testing: false
        }

        this.audioRef = React.createRef()
        this.audioInputDropdownRef = React.createRef()
        this.audioOutputDropdownRef = React.createRef()
        this.audioInputSliderRef = React.createRef()
        this.audioOutputSliderRef = React.createRef()

        this.recordingLength = 5000 // ms
        this.testTimer = null
        this.playbackRecording = false // Controls whether to render the test audio recording at the end

    }

    static defaultProps = {
        initializationAllowed: null
    }


    // MARK: Lifecycle Functions
    componentDidMount() {
        this.user = this.props.students.find(s => s.identity === this.props.identity)

        if (this.props.initializationAllowed === true) {
            // If theres already a selected audio input device that was configured in the constructor then use it to for the media stream
            if (this.state.selectedAudioInput) {
                this.refreshMediaStream()
            }

            this.populateMediaDevices()

        }
    }

    /**
     * On unmount the component will stop any timers, cancel the test audio recording and save the selected devices to window
     */
    componentWillUnmount() {
        // Stop testing if its going on (stop rendering audio) release
        this.testTimer && clearTimeout(this.testTimer)

        // Stop & cancel the testing playback and subsequent render of collected audio data
        if (this.recorder && this.recorder.state !== 'inactive') {
            this.playbackRecording = false
            this.recorder.stop();
        }

        if (this.localMediaStream) {
            this.localMediaStream.getTracks().forEach((track) => {
                if (track.readyState === 'live') {
                    track.stop();
                }
            })

            this.localMediaStream = null
        }

        // Write out the choices
        window.selectedAudioInput = this.state.selectedAudioInput
        window.selectedAudioOutput = this.state.selectedAudioOutput

    }


    // MARK: Helper functions
    /**
     * This will enumerate through all the media devices exposed by the browser and save them into a list of 
     * audio input devices and audio output devices while also picking out the default device for each category.
     */
    populateMediaDevices = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("-ac enumerateDevices() not supported. Please connect via https")
            return null
        }

        let devices = null;

        try {
            // Wait till we get a MediaStream or an error
            devices = await navigator.mediaDevices.enumerateDevices()
        } catch (err) {
            console.log("-ac enumerateDevices Error: " + err.name + ' --- ' + err.message)
        }

        this.setState({ mediaDevices: devices })

        devices.forEach((device) => {
            if (this.state.selectedAudioInput === null && device.kind === 'audioinput' && device.deviceId === 'default') {
                this.setState({ selectedAudioInput: device }, () => {
                    this.refreshMediaStream()
                })
            }

            if (this.state.selectedAudioOutput === null && device.kind === 'audiooutput' && device.deviceId === 'default') {
                this.setState({ selectedAudioOutput: device })
            }
        })

    }

    /**
     * This function forces an update in Participant.js to re-register a new MediaStream for audio level polling.
     */
    refreshMediaStream = async () => {
        let stream = null

        try {
            stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    deviceId: this.state.selectedAudioInput.deviceId
                },
                video: false
            })
        } catch (e) {
            console.log("-ac Could not get media stream", e)
            return
        }

        // If theres an existing media stream then stop it
        if (this.localMediaStream) {
            this.localMediaStream.getTracks().forEach((track) => {
                if (track.readyState === 'live') {
                    track.stop();
                }
            })
        }

        // Update to the new media stream
        this.localMediaStream = stream

        pollAudioLevel(null, (level) => {
            console.log("-ac Level: ", level)
            this.setState({ localAudioLevel: level })
        }, stream)


        // Good place to call this since we await on getting the stream above
        // Forces an update in Participant to re-register a new MediaStream for audio level polling
        const localizedStudents = this.props.students.map((student) => {
            if (student.identity === this.props.authentication.identityId) {
                return { ...student, refreshAudioTrack: Math.random() } // Ensures a copy of student not ref
            }

            return { ...student }
        })

        this.props.setStudioStudents({ students: localizedStudents })

        // return stream

    }

    /**
     * This function will use the MediaRecorder browser API to record from your microphone, render it and play it back.
     * Based from: https://www.twilio.com/blog/mediastream-recording-api
     * 
     * @param {string} deviceId The id of the media input device to use
     */
    testMicRecording = () => {
        // Based from: https://www.twilio.com/blog/mediastream-recording-api
        this.setState({ testing: true })

        if (!('MediaRecorder' in window)) {
            // console.log("-ac Can't access media recorder")
            this.setState({ testing: false })
            return
        }

        try {
            const mimeType = 'audio/webm'
            let chunks = []
            this.recorder = new MediaRecorder(this.localMediaStream, { type: mimeType })


            // Mute
            if (this.user && !this.user.mute) {
                this.props.actionCallback("mute", this.user)
                this.mutedByTest = true
            }


            // Listener for media recorder
            this.recorder.addEventListener('dataavailable', event => {
                if (typeof event.data === 'undefined') return;
                if (event.data.size === 0) return;
                chunks.push(event.data);
            })

            this.recorder.addEventListener('stop', () => {
                // The recorder can be stopped and either set to render and play the recording or just plain stop
                if (this.playbackRecording) {
                    const recording = new Blob(chunks, {
                        type: mimeType
                    });
                    this.renderRecording(recording);
                }

                chunks = [];

                // Unmute
                if (this.mutedByTest) {
                    this.props.actionCallback("mute", this.user)
                    this.mutedByTest = false
                }

                this.setState({ testing: false })
            })


            // Kicking off the media recording
            if (this.recorder.state === 'inactive') {
                // this.setState({ testing: true })
                this.recorder.start();
            }

            // After a predetermined amount of time stop the recording
            this.testTimer = setTimeout(() => {
                this.testTimer = null

                if (this.recorder.state !== 'inactive') {
                    this.playbackRecording = true
                    this.recorder.stop();
                }
            }, this.recordingLength)

        } catch (e) {
            console.log("-ac Error with recording: ", e)
            this.setState({ testing: false })
        }
    }

    /** 
     * This method will render out the audio stream from MediaRecorder and attach it to the `<audio ...>` component.
     * 
     * @param {Blob} blob The audio recording 
     */
    renderRecording = (blob) => {
        const blobUrl = URL.createObjectURL(blob);

        this.audioRef.current.src = blobUrl
        this.audioRef.current.load()
        this.audioRef.current.controls = false

        this.playbackRecording = false
    }


    // MARK: Audio Input Functions
    /**
     * This function will generate the dropdown menu in HTML for selecting an audio input device.
     * Based from: https://www.w3schools.com/howto/howto_js_dropdown.asp
     */
    createAudioInputDropdown = () => {
        return (
            <div className="dropdown">
                <div className="dropbtn" onClick={() => { this.audioInputDropdownRef.current.classList.toggle("show") }}>
                    <span className="selected-device-name">
                        {this.state.selectedAudioInput ? this.state.selectedAudioInput.label.substring(0, 32) + "..." : "Unable to get default..."}
                    </span>
                    <span className="dropbtn-symbol">&#11206;</span>
                </div>

                <div className="dropdown-content" ref={this.audioInputDropdownRef}>
                    {this.state.mediaDevices.filter((device) => { return device.kind === 'audioinput' }).map((audioInputDevice) => {
                        const selectedDeviceLabel = this.state.selectedAudioInput ? this.state.selectedAudioInput.label : ''
                        const highlightSelected = (selectedDeviceLabel === audioInputDevice.label) ? ' selected' : ''

                        return (<div className={"dropdown-content-item" + highlightSelected}
                            key={audioInputDevice.groupId + audioInputDevice.label}
                            onClick={() => { this.selectNewAudioInputDevice(audioInputDevice) }}>
                            {audioInputDevice.label}
                        </div>)
                    })}
                </div>
            </div >
        )
    }

    /**
     * This function will handle selecting a new audio input device.
     * 
     * @param newAudioInputDevice Device id for the new device
     */
    selectNewAudioInputDevice = (newAudioInputDevice) => {
        if (this.state.selectedAudioInput.label !== newAudioInputDevice.label) {
            this.setState({ selectedAudioInput: newAudioInputDevice, audioInputChanged: true }, () => {
                this.saveChoices()
                this.refreshMediaStream()
            })
        }

        this.audioInputDropdownRef.current.classList.toggle("show")

    }


    // MARK: Audio Output Functions
    /*
    // Below is code for the AudioOutput selection which we are not using yet
    createAudioOutputDropdown = () => {
        return (
            <div className="dropdown">
                <div className="dropbtn" onClick={() => { this.audioOutputDropdownRef.current.classList.toggle("show") }}>
                    <span className="selected-device-name">
                        {this.state.selectedAudioOutput ? this.state.selectedAudioOutput.label.substring(0, 32) + "..." : "Unable to get default..."}
                    </span>
                    <span className="dropbtn-symbol">&#11206;</span>
                </div>

                <div className="dropdown-content" ref={this.audioOutputDropdownRef}>
                    {this.state.mediaDevices.filter((device) => { return device.kind === 'audiooutput' }).map((audioOutputDevice) => {
                        const selectedDeviceLabel = this.state.selectedAudioOutput ? this.state.selectedAudioOutput.label : ''
                        const highlightSelected = (selectedDeviceLabel === audioOutputDevice.label) ? ' selected' : ''

                        return (<div className={"dropdown-content-item" + highlightSelected}
                            key={audioOutputDevice.groupId + audioOutputDevice.label}
                            onClick={() => { this.selectNewAudioOutputDevice(audioOutputDevice) }}>
                            {audioOutputDevice.label}
                        </div>)
                    })}
                </div>
            </div >
        )
    }

    selectNewAudioOutputDevice = (newAudioOutputDevice) => {
        if (this.state.selectedAudioOutput.label !== newAudioOutputDevice.label) {
            console.log("-ac newAudioOutputDevice:", newAudioOutputDevice)

            this.setState({ selectedAudioOutput: newAudioOutputDevice, audioOutputChanged: true })
        }

        this.audioOutputDropdownRef.current.classList.toggle("show")

    }
    */
    /**
     * This function attaches a new device id (sinkId) as the audio output source.
     */
    /*
    attachSinkId = (sinkId) => {
        // https://github.com/webrtc/samples/blob/f90d712c05d417d8ae8a93c424270b108bf9f020/src/content/devices/input-output/js/main.js#L76
        if (typeof this.audioRef.current.sinkId !== 'undefined') {
            this.audioRef.current.setSinkId(sinkId)
                .then(() => {
                    console.log(`Success, audio output device attached: ${sinkId}`);
                })
                .catch(error => {
                    console.error("-ac Error:", error);

                    // Jump back to first output device in the list as it's the default.
                    // audioOutputSelect.selectedIndex = 0;
                });
        } else {
            console.log('Browser does not support output device selection.');
        }
    }
    */


    // MARK: Save & Test Functions
    /**
     * This function handles what happens when you click the submit button which encapsulates the saving and testing action.
     */
    submit = () => {
        // Clicking the button while its testing
        if (this.state.testing) {
            return
        }

        this.testMicRecording()

    }

    /**
     * This function will save the newly selected audio input/output devices and trigger Twilio to restart the audio track in order for the changes
     * to take effect.
     */
    saveChoices = () => {
        // console.log("-ac Save: ", this.state.selectedAudioInput, this.state.selectedAudioOutput, this.audioInputSliderRef.current.value, this.audioOutputSliderRef.current.value)
        this.setState({ audioInputChanged: false, audioOutputChanged: false, audioInputVolChanged: false, audioOutputVolChanged: false })

        // Save audio input device
        if (window.twilio_room) {
            const localAudioTrack = Array.from(window.twilio_room.localParticipant.audioTracks.values())[0].track
            localAudioTrack && localAudioTrack.restart({ deviceId: this.state.selectedAudioInput.deviceId });
        }

        // Save audio output device
        // this.attachSinkId(this.state.selectedAudioOutput.deviceId)

    }



    // MARK: Render Function
    render() {
        const classes = {
            main: clsx('AudioCheck__wrapper'),
            volumeImg: clsx("volume-img", "level" + (this.state.localAudioLevel || 0)) // || (this.props.volumeLevels[this.props.identity] || 0)))
        };


        let audioInputDropdown = <div />
        let audioOutputDropdown = <div />
        if (this.state.mediaDevices) {
            audioInputDropdown = this.createAudioInputDropdown()
            // audioOutputDropdown = this.createAudioOutputDropdown()

        }

        // const submitBtnText = this.state.testing ? "TESTING..." : ((this.state.audioInputChanged || this.state.audioOutputChanged || this.state.audioInputVolChanged || this.state.audioOutputVolChanged) ? "SAVE & TEST" : "TEST")
        const submitBtnText = this.state.testing ? "TESTING..." : "TEST"


        return (
            <div className={classes.main}>
                <div className="panel-title">AUDIO SUPPORT &amp; SETTINGS</div>

                <audio ref={this.audioRef} autoPlay={true} muted={false} />

                <div className="audio-input-section">
                    <div className="audio-img" />

                    <div className="audio-text">Select Mic</div>

                    <div className="audio-input-dropdown">
                        {audioInputDropdown}
                    </div>
                    {/* <input className="audio-input-slider" ref={this.audioInputSliderRef} onChange={() => { this.setState({ audioInputVolChanged: true }) }} type="range" min="0" max="100" defaultValue="100" step="1" /> */}
                </div>

                {/* <div className="audio-output-section">
                    <div className="speaker-img" />

                    <div className="speaker-text">Headset Volume</div>

                    <div className="audio-output-dropdown">
                        {audioOutputDropdown}
                    </div>
                    <input className="audio-output-slider" ref={this.audioOutputSliderRef} onChange={() => { this.setState({ audioOutputVolChanged: true }) }} type="range" min="0" max="100" defaultValue="100" step="1" />
                </div> */}

                <div className="test-section">
                    <div className="test-text">Start a test and say something funny... then we'll play it back to you.</div>

                    <button className="submit-choices" onClick={this.submit}>{submitBtnText}</button>
                    <div className={classes.volumeImg} />

                </div>

                <div className="help-section">
                    <div className="help-img-left" />
                    <div className="help-title">Still not working? Try these:</div>
                    <div className="help-img-right" />
                    <div className="help-items">
                        1. Confirm your browser is allowing your Mic
                        <br />
                        <br />
                        2. Unplug & re-plug in your mic/headset
                        <br />
                        <br />
                        3. Try Control-Shift-R
                    </div>
                </div>



            </div>
        )
    }
}


const mapStateToProps = state => ({
    authentication: state.auth.authentication,
    identity: state.auth.identity,
    students: state.studioStudents.students,
    // volumeLevels: state.studioCommsVolume.volumeLevels,
});

const mapDispatchToProps = {
    setStudioStudents
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioCheck);
