import { gtagEvent } from 'services/gtags';

const TrackedButton = ({
  eventName = 'click',
  eventParams,
  onClick,
  ...props
}) => {
  const handleClick = event => {
    event.preventDefault();

    // Dispatch the event to Google
    gtagEvent(eventName, eventParams);

    // Call the passed onclick handler if it's available
    onClick?.(event);
  };

  return <button {...props} onClick={handleClick} />;
};

export default TrackedButton;
