import UMEAcademyLogo from './Academy_logo.png';
import SAILLogo from './logo-ume-surrey.png';
import BurnabyLogo from './partner-burnaby.png';
import CDMLogo from './partner-cdm.png';
import EcoleAndrePiolatLogo from './partner-ecole-andre-piolat.png';
import MulgraveLogo from './partner-mulgrave.png';
import WVSLogo from './partner-wvs.png';

// Map used here to maintain ordering
export default new Map([
  ['Burnaby', BurnabyLogo],
  ['EcoleAndrePiolat', EcoleAndrePiolatLogo],
  ['CDM', CDMLogo],
  ['Mulgrave', MulgraveLogo],
  ['WVS', WVSLogo],
  ['SAIL', SAILLogo],
  ['UMEAcademy', UMEAcademyLogo]
]);
