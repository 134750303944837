import React, { Component } from 'react';
import clsx from 'clsx';

import './styles.scss';

import GameModal from './GameModal';
// import ProjectModal from './ProjectModal';
// import JoinModal from './JoinModal';
// import LiveModal from './LiveModal';
// import CurriculumModal from './CurriculumModal';
// import ChildModal from './ChildModal';
// import ExpanderModal from './ExpanderModal';

import { scrollToY } from 'helpers/animation'

const Modals = {
  default: GameModal,
  game: GameModal
}
class Modal extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef()
    this.state = {
      modalFullScreen: false
    };
    this.resizeObserver = new ResizeObserver(this.resize_callback)

  }
  componentDidMount() {

  }
  componentDidUpdate(prevProps) {
    this.canvas.current && this.drawCanvas()
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      setTimeout(this.resize_callback, 100)
    }
  }
  resize_callback = () => {
    const rect = this.modal.getBoundingClientRect()

    const cardBottom = rect.bottom + window.scrollY
    const screenBottom = window.innerHeight + window.scrollY

    cardBottom > screenBottom && scrollToY(cardBottom - screenBottom + window.scrollY, 250)
  }

  drawCanvas = () => {
    const canvas = this.canvas.current;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const ctx = canvas.getContext("2d")
    var scaleRatio = canvas.width / 1366
    var lineWidth = scaleRatio * 2.0;
    var offset = 3.0 * lineWidth
    ctx.lineCap = "round";
    ctx.strokeStyle = '#0090ff';
    ctx.lineWidth = lineWidth;
    ctx.shadowBlur = offset;
    ctx.shadowColor = "rgba(15, 30, 255, 1)";

    try {
      //offset = 1 * lineWidth
      this.dblBox(offset,
        offset,
        canvas.width - 2 * offset,
        canvas.height - 2 * offset,
        8,
        offset,
        ctx)
      offset = 2 * offset

      this.dblBox(offset,
        offset,
        canvas.width - 2 * offset,
        canvas.height - 2 * offset,
        8,
        offset,
        ctx)
    }
    catch{ }
  }
  dblBox(x, y, w, h, r, offset, ctx) {

    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    ctx.beginPath();
    ctx.moveTo(w * .8, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath()
    ctx.stroke();
  }
  handleFullScreen = () => {
    console.log('handleFullScreen')
    this.setState({ modalFullScreen: !this.state.modalFullScreen })
  }
  handleClose = () => {
    //this.handleFullScreen();
    console.log('Modal handle Close')
    this.setState({ close: true }, () => {
      //this.props.onClose && this.props.onClose()
    })
  }
  closeCallback = (value) => {
    this.setState({ close: null }, () => {
      if (value) {
        this.props.onClose()
      }
    })
  }
  render() {
    if (!this.props.currentArtifact) {
      return (<div></div>)
    }

    const classes = {
      modal: clsx('Modal_pg', this.state.modalFullScreen && 'fullScreen'),
    };

    let ModalView = Modals[this.props.currentArtifact.type || 'default']
    /*
      type Project - ProjectView, Play, Edit
      type Game - Play, Edit, Rate (if not user)
      type Join - Lesson, Course, ProjectView (only play)
    */
    //console.log('Render Modal', this.props.currentArtifact)

    return (
      <div ref={element => (this.modal = element)}
        className={classes.modal}>
        <canvas ref={this.canvas} width={'100%'} height={'100%'} />

        <ModalView
          currentArtifact={this.props.currentArtifact}
          onFullScreen={this.handleFullScreen}

          close={this.state.close}
          closeCallback={this.closeCallback}
        />
        <div className="close_wrapper_pg" onClick={this.handleClose}>
          <span className="close" ></span>
        </div>
      </div >
    )
  }
}

export default Modal;

/*

        <ModalView
          currentArtifact={this.props.currentArtifact}
          closeClicked={this.state.closeClicked}
          onClose={this.handleClose} />
*/