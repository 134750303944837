import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { resolveURI } from 'api';

/**
 * This component responds to routes that were unmatched by the router. It
 * looks up the URI in the URL table and if it is matched, redirects the user to
 * the resolved URI. If it is unmatched or one such redirect has already occurred,
 * it sends the user to the root URI.
 *
 * TODO: The redirect should really route to a custom 404 page, not home.
 */
const UnmatchedRouteHandler = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const { pathname, state: locationState } = location;

      // You only get one redirect this way. Otherwise, you're going back home.
      if (locationState?.redirected) {
        history.replace('/');
      }

      const path = pathname.substr(1).toUpperCase();
      const resolvedPath = await resolveURI(path);

      if (resolvedPath) {
        history.replace(resolvedPath, { redirected: true });
      } else {
        history.replace('/');
      }
    })();
  }, [location, history]);

  return null;
};

export default UnmatchedRouteHandler;
