import React from 'react';
import MembershipWallButton from 'pages/Learn/components/MembershipWallButton';
import LaunchLabButton from 'components/LaunchLabButton';
import { tags } from 'models/constants';
import editIcon from 'assets/images/icons/anvil-md';
import './styles.scss';

const Banner = ({ lesson, duration }) => (
  <article className="lesson-banner">
    <img
      src={lesson && lesson.thumbnail}
      alt=""
      width="413px"
      height="240px"
      className="lesson-banner__thumbnail"
    />

    <div className="lesson-banner__content">
      <header className="lesson-banner__header">
        <h1 className="lesson-banner__title">
          {lesson && lesson.title}
        </h1>
      </header>

      <p className="lesson-banner__summary">
        {lesson && lesson.summary}
      </p>

      <footer className="lesson-banner__footer">
        <div className="lesson-banner__outcomes">
          {duration && (
            <p className="lesson-banner__outcome">
              <span role="img" aria-label="watch emoji">⌚</span>
              {Math.round(duration / 60)} minutes of instructional video
            </p>
          )}
          <p className="lesson-banner__outcome">
            <span role="img" aria-label="laptop emoji">💻</span>
            Custom Game Project
          </p>
        </div>

        <div className="membership-container">
          {lesson && (
            <MembershipWallButton
              membershipRequired={lesson.tags.has(tags.MEMBERSHIP_REQUIRED)}
              className="lesson-banner__button"
            >
              <LaunchLabButton
                className="membership-wall membership-wall--button membership-wall--account lesson-banner__button"
                activity={lesson}
              >
                <img
                  src={editIcon.defaultState}
                  alt=""
                  className="membership-wall__icon"
                  width="52px"
                  height="52px"
                />
                <img
                  src={editIcon.hoverState}
                  alt=""
                  className="membership-wall__icon membership-wall__icon--hover"
                  width="52px"
                  height="52px"
                />
                <span className="membership-wall__label">
                  {lesson?.isExtension() ? 'Continue' : 'Start Project'}
                </span>
              </LaunchLabButton>
            </MembershipWallButton>
          )}
        </div>
      </footer>
    </div>
  </article>
);

export default Banner;
