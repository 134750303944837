import { SET_STUDIO_COMMS_VOLUME } from '../actions/types';

const initialState = {
    volumeLevels: {}
};

function studioCommsVolumeReducer(state = initialState, action) {
    switch (action.type) {

        case SET_STUDIO_COMMS_VOLUME:
            return {
                ...state,
                volumeLevels: action.payload
            };

        default:
            return state;
    }
}

export default studioCommsVolumeReducer;
