import { useSelector } from 'react-redux';
import BaseBadge from 'components/Badge';
import { useSignedUrls, useUser } from 'hooks';
import NotSignedIn from 'assets/images/not-signed-in.png';
import './styles.scss';

const CallToAction = () => (
    <div className="share-signup">
        <p className="share-signup__tagline">
            Become a Game Designer
        </p>
        <a href="/subscribe" className="cl-button cl-button--accent">
            Start your journey
        </a>
    </div>
);

const Badge = ({ activity }) => {
    const username = activity?.user.username;
    const { authentication, authenticated, identity, subscriptions } = useSelector(store => store.auth);
    const user = useUser(activity?.user.identity);
    let [avatarUrl] = useSignedUrls(user ? `avatars/${user.avatar}` : null);

    // If this is the current user, we want their avatar to be able to update
    // immediately in response to the user changing their avatar.
    if (user && authentication && user.identity === identity) {
        avatarUrl = authentication.public.avatar;
    }

    return (
        <BaseBadge
            className="share-badge"
            cta={(authenticated && subscriptions.size > 0) ? null : <CallToAction />}
        >
            <div className="share-badge__header">
                <div className="share-badge__avatar-wrapper">
                    <img
                        width="112px"
                        height="112px"
                        className="share-badge__avatar"
                        src={avatarUrl || NotSignedIn}
                        alt=""
                    />
                </div>
                <p className="share-badge__title">
                    Game Designer
                </p>
                <h2 className="share-badge__username">
                    {username}
                </h2>
            </div>
            {/*TODO: include this once subscription info for other users is readily available */}
            {/* <p className="share-badge__body"> */}
                {/* Membership: CREATOR */}
            {/* </p> */}
        </BaseBadge>
    );
};

export default Badge;
