import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import PortfolioGrid from '../PortfolioGrid'

import { verifyGorpGameAttributes } from '../../../../../helpers/utils'

import './styles.scss';


class PlayParty extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentSlider: -1,
        }

        this.sliderCount = 0

    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    handleSliderSelect = sliderId => {
        this.setState({ currentSlider: sliderId });
    }

    render() {
        // const this_student = this.props.students.find(s => s.identity === this.props.identity)

        const classes = {
            main: clsx('PlayParty__wrapper ')

        }

        this.sliderCount = 0

        // Check if the gorp game object has the required attributes
        const filteredGames = this.props.gorpGames.filter((game) => verifyGorpGameAttributes(game))                 

        return (

            <div className={classes.main} >
                <PortfolioGrid
                    key={this.sliderCount}
                    featured={false}
                    sliderId={this.sliderCount++}
                    currentSlider={this.state.currentSlider}
                    artifacts={filteredGames}
                    onSelect={this.handleSliderSelect}
                    vertical={true}
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    identity: state.auth.identity,
    screen_id: state.studioScreen.screen_id,
    students: state.studioStudents.students,
    currentArtifact: state.artifact,
});

export default connect(mapStateToProps, null)(PlayParty);
