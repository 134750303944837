import { SET_CONTEXTMENU } from '../actions/types';

const initialState = {
    action: 'contextMenu',
    callback: null,
    show: false,
    contents: null,
    timer: null,
    x: null,
    y: null,
};

function contextMenuReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CONTEXTMENU:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default contextMenuReducer;
