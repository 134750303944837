import React, { Component } from 'react';
import clsx from 'clsx';
import LowWifiIcon from './images/LowWifi.png';
import './styles.scss';
class Connectivity extends Component {
    render() {
        const classes = {
            main: clsx('Connectivity__wrapper'),
            low_wifi: clsx(this.props.lowWifi ? 'low_wifi_enabled' : 'low_wifi_disabled'),

        }

        return (
            <div className={classes.main}>
                <img className={classes.low_wifi} src={LowWifiIcon} alt="Low Wifi" />
            </div>
        )
    }
}

export default Connectivity;
