export const AUTHENTICATED_STATE = 'signedIn';
export const DEFAULT_ANONYMOUS_PASSWORD = '123456';

export const AVATARS = [
    'avatar_cat_blue_B.png',
    'avatar_cat_blue_C.png',
    'avatar_cat_green_A.png',
    'avatar_cat_pink_B.png',
    'avatar_cat_pink_C.png',
    'avatar_cat_purple_A.png',
    'avatar_cat_yellow_A.png',
    'avatar_chipmunk_blue_B.png',
    'avatar_chipmunk_blue_C.png',
    'avatar_chipmunk_green_A.png',
    'avatar_chipmunk_green_C.png',
    'avatar_chipmunk_pink_B.png',
    'avatar_chipmunk_purple_A.png',
    'avatar_chipmunk_yellow_A.png',
    'avatar_dog_blue_B.png',
    'avatar_dog_blue_C.png',
    'avatar_dog_green_A.png',
    'avatar_dog_green_C.png',
    'avatar_dog_pink_B.png',
    'avatar_dog_purple_A.png',
    'avatar_dog_yellow_A.png',
    'avatar_fox_blue_B.png',
    'avatar_fox_green_A.png',
    'avatar_fox_pink_B.png',
    'avatar_fox_purple_A.png',
    'avatar_fox_yellow_A.png',
    'avatar_girl_01_D.png',
    'avatar_girl_01_E.png',
    'avatar_girl_01_F.png',
    'avatar_girl_02_D.png',
    'avatar_girl_02_E.png',
    'avatar_girl_02_F.png',
    'avatar_girl_03_D.png',
    'avatar_girl_03_E.png',
    'avatar_girl_03_F.png',
    'avatar_girl_04_D.png',
    'avatar_girl_04_E.png',
    'avatar_girl_04_F.png',
    'avatar_girl_05_E.png',
    'avatar_girl_05_F.png',
    'avatar_guy_01_D.png',
    'avatar_guy_01_E.png',
    'avatar_guy_01_F.png',
    'avatar_guy_02_D.png',
    'avatar_guy_02_E.png',
    'avatar_guy_02_F.png',
    'avatar_guy_03_D.png',
    'avatar_guy_03_E.png',
    'avatar_guy_03_F.png',
    'avatar_guy_04_D.png',
    'avatar_guy_04_E.png',
    'avatar_guy_04_F.png',
    'avatar_guy_05_D.png',
    'avatar_guy_05_E.png',
    'avatar_guy_05_F.png',
    'avatar_guy_06_E.png',
    'avatar_hedgehog_blue_B.png',
    'avatar_hedgehog_blue_C.png',
    'avatar_hedgehog_green_A.png',
    'avatar_hedgehog_green_C.png',
    'avatar_hedgehog_pink_B.png',
    'avatar_hedgehog_purple_A.png',
    'avatar_hedgehog_yellow_A.png',
    'avatar_helmet_blue_C.png',
    'avatar_helmet_green_C.png',
    'avatar_helmet_purple_C.png',
    'avatar_helmet_yellow_B.png',
    'avatar_raccoon_blue_B.png',
    'avatar_raccoon_blue_C.png',
    'avatar_racoon_green_A.png',
    'avatar_racoon_green_C.png',
    'avatar_racoon_purple_A.png',
    'avatar_racoon_yellow_A.png',
    'avatar_wolf_blue_C.png',
    'avatar_wolf_green_C.png',
    'avatar_wolf_pink_C.png',
]

