import { Link } from 'react-router-dom';
import VideoGameCharacters from 'assets/images/community.png';
import './styles.scss';

const Empty = () => (
  <div className="arcade-empty">
    <div className="arcade-empty__container">
      <h2 className="arcade-empty__title">
        Nothing here yet!
      </h2>
      <p className="arcade-empty__description">
        Why not <Link to="/learn">start a new project?</Link>
      </p>
      <img
        className="arcade-empty__image"
        src={VideoGameCharacters}
        alt="Video game characters"
      />
    </div>
  </div>
);

export default Empty;
