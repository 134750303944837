/**
 * Contains hooks to be used in React functional components.
 *
 * @namespace hooks
 * @module hooks
 */
import useMyResource from './useMyResource';
import { fetchMyClasses, fetchMyLabs } from 'api';

export { default as useLesson } from './useLesson';
export { default as useMyActivities } from './useMyActivities';
export { default as useSeeds } from './useSeeds';
export { default as useCourses } from './useCourses';
export { default as useYoutubeMetadata } from './useYoutubeMetadata';
export { default as useGraphQLSubscription } from './useGraphQLSubscription';
export { default as useSearchParams } from './useSearchParams';
export { default as useSignedUrls } from './useSignedUrls';
export { default as useUser } from './useUser';
export { default as useIntersection } from './useIntersection';
export { default as useIntercom } from './useIntercom';

/**
 * React hook for retrieving the current user's upcoming or active scheduled
 * classes.
 *
 * @method
 * @returns {ResourceState}
 */
export const useMyClasses = () => useMyResource(fetchMyClasses, {}, []);

/**
 * React hook for retrieving the current user's active labs.
 *
 * @method
 * @returns {ResourceState}
 */
export const useMyLabs = () => useMyResource(fetchMyLabs, {}, []);
