import { SET_STUDIO_COMMAND } from '../actions/types';


const initialState = {
    command: () => { }
};

function studioCommandReducer(state = initialState, action) {
    switch (action.type) {
        case SET_STUDIO_COMMAND:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default studioCommandReducer;
