import OnState from './WATCH-ON.png';
import OffState from './WATCH-OFF.png';
import HoverState from './WATCH-HOVER-ANIM.gif';

const watch = {
  onState: OnState,
  offState: OffState,
  hoverState: HoverState
};

export default watch;
