import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import clsx from 'clsx';
import './styles.scss';

import CountdownTimer from './CountdownTimer'
import MessageStatus from './MessageStatus'
import TimerStatus from './TimerStatus'

import { setStudioCommand, setStudioScreen, setStudioStudents } from 'actions/studio';
import { LOBBY_SCREEN } from '../../../types'; // WATCH_SCREEN, CREATE_SCREEN, PLAY_SCREEN, GRIDVIEW_SCREEN, PORTFOLIO_SCREEN


export const TIME = {
    ONE_MINUTE: 60000,
    FIVE_MINUTES: 300000,
    THIRTY_MINUTES: 1800000,
    SIXTY_MINUTES: 3600000
}


class Rundown extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
            messageText: '',
            timerText: '',
            messageStatus: MessageStatus.REGULAR, // regular, starting, warning
            timerStatus: TimerStatus.REGULAR // regular, starting, warning

        }

        this.sections = [] // [{ "title": "G.O.R.P. Analysis", "duration": 12000 }, { "title": "Instruction", "duration": 60000 }, { "title": "Challenge", "duration": 60000 }]
        this.currentSection = 0
        this.initialSectionsSetup = true
        this.initialTimerSetup = true

    }

    getCurrentTime = () => {
        return Date.now() + window.timeoffset

    }

    // Lifecycle components
    componentDidMount() {

    }

    componentWillUnmount() {
        this.preClassTimer && this.preClassTimer.stopTimer()
        this.intraClassTimer && this.intraClassTimer.stopTimer()
        this.sectionTimer && this.sectionTimer.stopTimer()

    }

    componentDidUpdate(prevProps, prevState) {
        // Kick-off code to setup the sections and timer under 2 conditions:
        //  1. The props being passed in are recieved async so dont make a timer until they are valid non-undefined props (and only once)
        //  2. If previously the props were null/undef and this time theyre not it means they are good to go
        if ((!prevProps.aggregate && this.props.aggregate) || (this.props.aggregate && this.initialSectionsSetup)) {
            this.initialSectionsSetup = false
            this.setupClassSections()
        }

        if ((!prevProps.classStartTime && this.props.classStartTime) || (this.props.classStartTime && this.initialTimerSetup)) {
            this.initialTimerSetup = false
            this.setupPreClassTimer()
        }

    }

    setupClassSections = () => {
        this.props.aggregate.steps && this.props.aggregate.steps.forEach((step) => {
            // Find if the sections been recorded already and if so append to its duration
            const foundIdx = this.sections.findIndex((recordedSection) => { return recordedSection.title === step.section })

            const durationMs = step.duration * 60000

            if (foundIdx > -1) {
                this.sections[foundIdx].duration += durationMs
            } else {
                this.sections.push({ "title": step.section, "duration": durationMs })
            }
        })

        // console.log("-rd sections finalized: ", this.sections)
    }

    setupPreClassTimer = () => {
        // Check if timer was already created
        if (this.preTimer) {
            return
        }

        this.preTimer = true


        // Setup the timer
        const durationUntilStart = this.props.classStartTime - this.getCurrentTime()
        const durationUntilEnd = this.getLessonEndTime() - this.getCurrentTime()


        this.preClassTimer = new CountdownTimer(durationUntilStart, (newTimerText) => {
            this.setState({ timerText: newTimerText })
        }, () => {
            this.setState({ timerStatus: TimerStatus.WARNING })
        }, () => {
            if (durationUntilStart > 0) {
                this.setState({ messageText: 'CLASS STARTS IN...', messageStatus: MessageStatus.STARTING, timerStatus: TimerStatus.STARTING })
            }
        }, () => {
            // When running through the timer only setup the auto-move to the agreements page if currently in the "Class starts in..." phase. Otherwise skip past it.
            if (durationUntilStart > 0) {
                // Move to agreements at end of this phase, otherwise ignore
                this.props.setStudioScreen({ screen_id: LOBBY_SCREEN, class_screen_id: LOBBY_SCREEN, subScreen_id: 2 })
            } else if (durationUntilStart <= 0 && durationUntilEnd > 0) {
                // Past the start time but havent hit the end yet
                this.props.setStudioScreen({ subScreen_id: 2 })
            }

            // Set up the next timer
            this.setupIntraClassTimer()
            this.setupSectionTimer()

        })

        this.preClassTimer.startTimer()

    }

    setupIntraClassTimer = () => {
        // Check if timer was already created
        if (this.intraTimer) {
            return
        }

        this.intraTimer = true


        // Set a countdown until X minutes till the end
        const durationUntilEnd = this.getLessonEndTime() - this.getCurrentTime()


        this.intraClassTimer = new CountdownTimer(durationUntilEnd, (newTimerText) => {
            this.setState({ timerText: newTimerText })
        }, () => {
            this.setState({ timerStatus: TimerStatus.WARNING })
        }, () => {
            this.setState({ messageText: '', messageStatus: MessageStatus.REGULAR, timerStatus: TimerStatus.REGULAR })
        }, () => {
            this.setState({ messageText: 'CLASS HAS ENDED...', messageStatus: MessageStatus.WARNING, timerText: '--m--s', timerStatus: TimerStatus.WARNING })
        })

        this.intraClassTimer.startTimer()

    }

    setupSectionTimer = () => {
        // If we've iterated through all the sections return
        if (this.currentSection >= this.sections.length) {
            // Wont override class ended text
            if (this.state.messageText !== 'CLASS HAS ENDED...') {
                this.setState({ messageText: 'END OF LESSON STEPS...' })
            }

            return
        } else if (this.currentSection === this.sections.length - 1) {
            // If we're at the last seciton move the studio over to the Class Over screen
            this.props.setStudioScreen({ screen_id: LOBBY_SCREEN, class_screen_id: LOBBY_SCREEN, subScreen_id: 3 })
        }


        // Get the end time for this section by totalling up the previous sections durations
        var sectionEndTime = this.props.classStartTime

        for (var i = 0; i <= this.currentSection; i++) {
            sectionEndTime += this.sections[i].duration
        }

        // If it is past the end of that section then go onto the next section
        if (this.getCurrentTime() > sectionEndTime) {
            this.currentSection += 1
            this.setupSectionTimer()
            return
        }


        // Otherwise set up the timer for the current section
        const sectionTimeRemaining = sectionEndTime - this.getCurrentTime()

        const title = this.sections[this.currentSection].title

        this.sectionTimer = new CountdownTimer(sectionTimeRemaining, (newTimerText) => {
            this.setState({ messageText: title + '\n' + newTimerText })
        },
            null,
            () => {
                this.setState({ messageStatus: MessageStatus.STARTING })
            }, () => {
                this.currentSection += 1
                this.setupSectionTimer()
            })

        this.sectionTimer.startTimer()
    }

    getLessonEndTime = () => {
        // Now return the true end time based at the end of the duration
        return (this.props.classStartTime + this.props.aggregate.duration)

        /*
        // Old way of ending lesson early
        var lessonEndTime = null

        // Depending on the lesson length (dev vs real) set the end time
        if (this.props.classDuration < TIME.THIRTY_MINUTES) {
            lessonEndTime = (this.props.classStartTime + this.props.classDuration) - TIME.ONE_MINUTE
        } else if (this.props.classDuration >= TIME.THIRTY_MINUTES) {
            lessonEndTime = (this.props.classStartTime + this.props.classDuration) - TIME.FIVE_MINUTES
        } else {
            // console.log("-rd Does not match: ", this.props.classDuration)
        }

        return lessonEndTime
        */
    }

    render() {
        const classes = {
            main: clsx('Rundown__wrapper', this.props.hideTimer && 'Rundown__wrapper--narrow'),
            timer: clsx('timer__wrapper', this.state.timerStatus),
            time: clsx('time', this.state.timerStatus),
            icon: clsx('icon', this.state.timerStatus),
            message: clsx('message__wrapper', this.state.messageStatus),
            text: clsx('text')
        }

        return (
            <div className={classes.main}>

                <div className={classes.message} >
                    <div className={classes.text} >
                        {/* Will break the text up onto two lines if it finds a \n in the string */}
                        {this.state.messageText.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br /></span>
                        })}
                    </div>
                </div>

                {!this.props.hideTimer && (<div className={classes.timer} >
                    <div className={classes.icon} alt="Clock"></div>

                    <div className={classes.time}>
                        {this.state.timerText}
                    </div>
                </div>)}

            </div>
        )
    }
}


const mapStateToProps = state => ({
    authenticated: state.auth.authenticated,
    authentication: state.auth.authentication,
    identity: state.auth.identity,
    signIn: state.auth.signIn,
    studio_cmd: state.studioCommand.command,
    selected_student: state.studioSelectedStudent.identity,
    screen_id: state.studioScreen.screen_id,
    lastScreen_id: state.studioScreen.lastScreen_id,
    subScreen_id: state.studioScreen.subScreen_id,
    students: state.studioStudents.students,

});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setStudioCommand, setStudioStudents, setStudioScreen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Rundown);

// export default Rundown;
