import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';

import IconUser from 'assets/images/ic_user_black.svg';
import './styles.scss';

// graphQL functions
import { send } from 'graphql/handler';
import { getUserPublicRecord } from 'graphql/studio';

class GameCard extends Component {
  state = {
    selected: this.props.selected,
    hasOpacity: this.props.hasOpacity,
    urls: {}
  };

  get_signed_url = (data) => {
    if (!this.props.authentication || !data) {
      return
    }

    if (this.state.urls[data]) {
      return this.state.urls[data]
    }

    var urls = this.state.urls
    urls[data] = this.props.authentication.s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: data, Expires: 3600 })

    this.setState({ urls: urls })
  }
  componentDidMount() {
    try {
      this.get_signed_url(this.props.content.public.identity + '/' + this.props.content.thumbnail)
      this.get_signed_url(this.props.content.public.identity + '/' + this.props.content.video)
      this.get_signed_url(this.props.content.public.identity + '/' + this.props.content.title)
      this.init_avatar()
    } catch(err) {
      // console.log("-pfg gc componentDidMount error: ", err)
    }
  }
  componentDidUpdate(prevProps) {

    if (JSON.stringify(prevProps.content.game) !== JSON.stringify(this.props.content.game)) {
      try {
        this.get_signed_url(this.props.content.public.identity + '/' + this.props.content.thumbnail)
        this.get_signed_url(this.props.content.public.identity + '/' + this.props.content.video)
        this.get_signed_url(this.props.content.public.identity + '/' + this.props.content.title)
        this.init_avatar()
      } catch(err) {
        // console.log("-pfg gc componentDidUpdate error: ", err)
      }
    }
    if (prevProps.selected !== this.props.selected ||
      prevProps.hasOpacity !== this.props.hasOpacity
    ) {
      this.setState({
        selected: this.props.selected,
        hasOpacity: this.props.hasOpacity
      });
    }
  }

  init_avatar = () => {
    // const avatar = 'avatars/' + avatars[Math.floor(random(this.props.currentArtifact.public.username.length) * avatars.length)] // Random avatar
    
    // If the identity is missing dont make the call
    if (!(this.props.content && this.props.content.public && this.props.content.public.identity)) {
      return
    }
    
    var avatar = ''

    window.configure_amplify('studio_endpoint')
    send(getUserPublicRecord, { identity: this.props.content.public.identity}, (response) => {
      if (!response || !response.avatar) {
        console.log("-pfg gc error getting required user public record data: ", response)
        return
      }

      avatar = 'avatars/' + response.avatar
      this.setState({userAvatar: response.avatar})

      this.get_signed_url(avatar)

    })
  
  }

  getAvatar = () => {
    const avatar = 'avatars/' + this.state.userAvatar
    const signedAvatar = this.state.urls[avatar]

    return signedAvatar

  }


  render() {
    const { selected, hasOpacity } = this.state;
    const { size, content, onSelect } = this.props;
    const classes = {
      wrapper: clsx('slide-GameCard_pg__wrapper'),
      container: clsx('container',hasOpacity && 'has-opacity'),
      neonBorder: clsx('neonBorder', selected && 'selected'),
      board: clsx('board', size),
      details: clsx('details'),
      feedback: clsx('feedback hide'),
      userDetails: clsx('user-details'),
      userAvatar: clsx('user-avatar')
    };
    //console.log('Render GameCard', content.statistics)
    return (
      <div key={content.objectID} className={classes.wrapper}>
        <div
          className={classes.container}
          onClick={onSelect}
        >
          <div className={classes.neonBorder} />
        
          <video key={content.id} autoPlay={true} loop={true} muted={true} playsInline={true} poster={this.state.urls[this.props.content.public.identity + '/' + content.game ? this.props.content.public.identity + '/' + content.thumbnail : '']} >
            {/* <source src={this.state.urls[this.props.content.public.identity + '/' + content.game ? this.props.content.public.identity + '/' + content.video : '']} type="video/webm" /> */}
          </video>

          <div className={classes.details}>
            {this.getAvatar() && <img className={classes.userAvatar} src={this.getAvatar()} alt="avatar" />}
            <div className={classes.userDetails}>{(content.public && content.public.username) || ""}</div>
            <div className={classes.feedback}>
              <div className="feedback-commented">
                <img src={IconUser} alt="commented" />
                <span>{content.commentedCount || '12k'}</span>
              </div>
            </div>
          </div>

          {/* game title overlay */}
          <img
            className="title-overlay"
            src={this.state.urls[this.props.content.public.identity + '/' + content.game ? this.props.content.public.identity + '/' + content.title : '']}
            alt={content.game ? content.game.name : ''}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.auth.authentication,
});



export default connect(mapStateToProps, null)(GameCard);
