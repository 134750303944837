import React from 'react';
import { Link } from 'react-router-dom';

import './styles.scss';

const LabEnd = ({ data: { class_id } }) => {
  return (
    <div className="LabEnd__wrapper">
      <p className="LabEnd__message">Your game is being published!</p>
      <Link to={`/creator-lab/${class_id}`} className="LabEnd__cta" replace>
        Go to my game
      </Link>
    </div>
  );
};

export default LabEnd;
