import OnState from './PLAY-ON.png';
import OffState from './PLAY-OFF.png';
import HoverState from './PLAY-HOVER-ANIM.gif';

const play = {
  onState: OnState,
  offState: OffState,
  hoverState: HoverState
};

export default play;
