import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { setPopUp } from 'actions/popupAction';


import './styles.scss';
class PopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: props.timer,
      onClose: props.onClose
    }
  }
  componentDidMount() {
    this.state.timer && this.init_timeout()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.timer !== this.props.timer) {
      this.clear_timeout()
      this.setState({ timer: this.props.timer }, () => {
        this.state.timer && this.init_timeout()
      })
    }
    // if we have an internal timer running and the new props have no timer, remove ours
    if (this.timer && !this.props.timer) {
      this.clear_timeout()
    }
    if (prevProps.show && !this.props.show) {
      this.dismissPopup()
    }
  }
  componentWillUnmount() {
    this.clear_timeout()
  }
  init_timeout = () => {
    this.timer = setTimeout(this.handleClose, this.state.timer)
  }
  clear_timeout = () => {
    this.timer && clearTimeout(this.timer)
    this.timer = null
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose()
    this.setState({ timer: null }, () => {
      this.props.setPopUp({ show: false, contents: null, timer: null, onClose: null })
    })

  }

  dismissPopup = () => {
    this.clear_timeout()
    this.handleClose()
  }

  render_close = () => {
    if (this.props.alert) {
      return (<div />)
    }
    return (<div className="actions" onClick={this.dismissPopup}>
      <span className="close" ></span>
    </div>
    )
  }
  render() {
    const classes = {
      popup: clsx('Popup__wrapper', this.props.show && 'show'),
    };
    return (
      <div className={classes.popup} style={this.props.styles ? { ...this.props.styles } : null}  >
        {this.render_close()}
        {this.props.contents && this.props.contents()}
      </div >
    )
  }
}

const mapStateToProps = state => ({
  ...state.popup,
});
const mapDispatchToProps = {
  setPopUp
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
