import { SET_STUDIO_STUDENTS } from '../actions/types';

const initialState = {
    students: []
};

function studioStudentsReducer(state = initialState, action) {
    switch (action.type) {

        case SET_STUDIO_STUDENTS:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
}

export default studioStudentsReducer;
