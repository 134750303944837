import React, { useState, useEffect, useRef } from 'react';
import { LocalAudioTrack } from 'twilio-video';

// const pollAudioLevel = require('./pollaudiolevel');
import pollAudioLevel from './pollaudiolevel'


const Participant = ({ participant, studentMuted, refreshAudioTrack, handleVolumeLevel }) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);

    const videoRef = useRef();
    const audioRef = useRef();

    useEffect(() => {
        setVideoTracks(Array.from(participant.videoTracks.values()));
        setAudioTracks(Array.from(participant.audioTracks.values()));

        const trackSubscribed = track => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => [...videoTracks, track]);
            } else {
                setAudioTracks(audioTracks => [...audioTracks, track]);
            }
        };

        const trackUnsubscribed = track => {
            if (track.kind === 'video') {
                setVideoTracks(videoTracks => videoTracks.filter(v => v !== track));
            } else {
                setAudioTracks(audioTracks => audioTracks.filter(a => a !== track));
            }
        };

        participant.on('trackSubscribed', trackSubscribed);
        participant.on('trackUnsubscribed', trackUnsubscribed);

        // Dont need individual level listening - done in Room now by instructor Participant on all other Participants
        // participant.on('networkQualityLevelChanged', (networkQualityLevel) => {
        //     console.log("Net changed: ", networkQualityLevel)
        // })

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {

        const videoTrackPublication = videoTracks[0];
        if (videoTrackPublication && videoTrackPublication.track) {
            const videoTrack = videoTrackPublication.track

            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };

        }
    }, [videoTracks]);

    useEffect(() => {
        // For AudioTrack details see: https://media.twiliocdn.com/sdk/js/video/releases/2.3.0/docs/LocalAudioTrack.html#enable__anchor

        const audioTrackPublication = audioTracks[0];

        // Check if there exists an audio track - it will exist if it set up properly behind the scenes and participant has a mic
        if (audioTrackPublication && audioTrackPublication.track) {
            const audioTrack = audioTrackPublication.track
            audioTrack.attach(audioRef.current); // Attaches the audio from the browser

            // Poll the volume levels for the given audio track and hand it back to be stored and referenced
            pollAudioLevel(audioTrack, level => {
                participant.identity && handleVolumeLevel(participant.identity, level)
            })


            // We can only mute (disable) LocalAudioTrack's and not any remote participant so check that here
            if (audioTrack instanceof LocalAudioTrack) {
                if (studentMuted) {
                    audioTrack.disable()
                } else {
                    audioTrack.enable()
                }
            } else {
                // console.log("AudioTrack not an instance of LocalAudioTrack - means this is a remote participant")
            }

            return () => {
                audioTrack.detach();
            };
        } else {
            // console.log("Participant does not have audio track")
        }
    }, [audioTracks, studentMuted, refreshAudioTrack, handleVolumeLevel]);

    return (
        <div className="participant">
            <video ref={videoRef} autoPlay={true} />
            <audio ref={audioRef} autoPlay={true} muted={false} />
        </div>
    );
};

export default Participant;
