import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAudio } from 'contexts/audioContext';
import Logo from '../../assets/ume-games.png';
import Characters from '../../assets/ume-characters.png';
import './styles.scss';

const Header = () => {
  const { authenticated, username } = useSelector(state => state.auth);
  const audio = useAudio();

  return (
    <header className="home-header">
      <div className="home-header__logo-container">
        <img className="home-header__logo" src={Logo} alt="UME Games" />
      </div>

      {!authenticated && (
        <div className="home-guest">
          <p className="home-guest__tagline">
            Start building the skills to <em>make</em> video games
          </p>

          <Link
            to="/login"
            className="home-guest__button home-guest__button--primary"
            onMouseDown={() => audio.play('click')}
          >
            Join now
          </Link>

          <Link to="/login" className="home-guest__button home-guest__button--link">
            Sign in
          </Link>
        </div>
      )}

      <div className="home-characters">
        {username && (
          <div className="home-characters__speech-bubble">
            Good to see you today, <strong>{username}</strong>!
          </div>
        )}

        <img
          src={Characters}
          alt="Two video game characters waving at you"
          className="home-characters__image"
        />
      </div>
    </header>
  );
};

export default Header;
