import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLaunchLab } from 'contexts/launchLabContext';
import { useAudio } from 'contexts/audioContext';
import TrackedButton from './TrackedButton';

const LaunchLabButton = ({ activity = {}, ...props }) => {
  const history = useHistory();
  const { identity } = useSelector(state => state.auth);
  const { launchLab } = useLaunchLab();
  const { play } = useAudio();

  const handleClick = () => {
    play('click');

    if (activity.isExtension()) {
      history.push('/creator-lab');
    } else {
      launchLab(activity);
    }
  };

  const eventParams = {
    from_identity: activity?.user?.identity,
    to_identity: identity,
    context: activity?.context, 
    objectID: activity?.id,
  };

  return (
    <TrackedButton
      type="button"
      onClick={handleClick}
      eventName="lab_launch"
      eventParams={eventParams}
      {...props}
      disabled={!activity || props.disabled}
    />
  );
};

export default LaunchLabButton;
