import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import clsx from 'clsx';

import Logo from './Logo'
import ClassTitle from './ClassTitle'

import Tab from './Tab'


import ToolMenu from './ToolMenu'
import Rundown from './Rundown'
import Connectivity from './Connectivity'
import CloseButton from '../CloseButton';

import { setStudioScreen } from 'actions/studio';


import './styles.scss';

import LobbyIcon from './images/tab_lobby_icon.png'
import CreateIcon from './images/tab_create_icon.png'
import WatchIcon from './images/tab_watch_icon.png'
import PlayIcon from './images/tab_play_icon.png'
import PortfolioIcon from './images/tab_work_icon.png'

import { LOBBY_SCREEN, WATCH_SCREEN, CREATE_SCREEN, PLAY_SCREEN, GRIDVIEW_SCREEN, PORTFOLIO_SCREEN } from '../../types';

class MenuBar extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
            lastScreen: -1
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.screen_id === GRIDVIEW_SCREEN && prevProps.screen_id !== GRIDVIEW_SCREEN) {
            this.setState({ lastScreen: prevProps.screen_id })
        }
    }

    resetGridviewZoom = () => {
        // If you are the instructor and on the gridview screen and zoomed in (viewing a students screen) then zoom out (basically reset viewScreen and the controlCreate of the student)
        //  as you click on a different tab
        if (!this.props.instructor) {
            return
        }
        
        var viewScreenStudent = this.props.students.find((s) => s.viewScreen)

        if (viewScreenStudent !== undefined && viewScreenStudent.viewScreen) {
            this.props.action_callback("viewScreen", viewScreenStudent)
            this.props.action_callback("reset-control-create", viewScreenStudent)
            return;
        }

    }

    resetWatchScreenBroadcaster = (selected_screen) => {
        // If the broadcaster is a student and moves off of the watch screen then reset the watch screen to be the instructors desktop
        if (selected_screen !== WATCH_SCREEN && !this.props.instructor) {
            const instructor = this.props.students.find((s) => s.instructor)
            const thisUserBroadcasting = this.props.students.find(s => s.broadcast && s.identity === this.props.identity)

            if (thisUserBroadcasting) {
                this.props.action_callback("reset-broadcast", instructor)
            }
        }

    }

    tab_callback = (event, action, payload) => {
        event.stopPropagation();

        const selected_screen = { lobby: LOBBY_SCREEN, watch: WATCH_SCREEN, create: CREATE_SCREEN, play: PLAY_SCREEN, portfolio: PORTFOLIO_SCREEN }[action]
        
        const eventFiredClassName = event.target.className
        // console.log("-mb eventFiredClassName: ", eventFiredClassName)

        if (eventFiredClassName.indexOf("pin on") > -1) {
            // Unpinning
            this.props.studio_cmd(action, { ...payload, newPinState: "off" })
        } else if (eventFiredClassName.indexOf("pin off") > -1) {
            // Pinning
            this.props.classActionCallback('unlock-' + action + '-screen') 
            this.props.studio_cmd(action, { ...payload, newPinState: "on" })
        } else if (eventFiredClassName.indexOf("lock off") > -1) {
            // Locking
            // Don't allow locking the pinned screen
            if (this.props.class_screen_id === selected_screen) {
                return
            }

            this.props.classActionCallback('lock-' + action + '-screen')
            return
        } else if (eventFiredClassName.indexOf("lock on") > -1) {
            // Unlocking
            this.props.classActionCallback('unlock-' + action + '-screen')
            return
        } else {
            // Navigating yourself to the tab
            this.props.setStudioScreen({ screen_id: selected_screen })
            this.resetWatchScreenBroadcaster(selected_screen)
        }

    }

    render() {
        const classes = {
            main: clsx('MenuBar__wrapper '),
        }

        const my_screen_id = this.props.screen_id === GRIDVIEW_SCREEN ? this.state.lastScreen : this.props.screen_id;

        const user = this.props.students.find(s => s.identity === this.props.identity)
        
        return (
            <div className={classes.main}>
                <div className="left">
                    <Logo />
                    <ClassTitle label={this.props.aggregate && this.props.aggregate.name} />
                </div>
                <div className="centre">
                    <Tab
                        active={(my_screen_id === LOBBY_SCREEN) || (this.props.class_screen_id === LOBBY_SCREEN) && (user && user.lobbyScreenNavigable)}
                        classActive={this.props.class_screen_id === LOBBY_SCREEN} // pinned or not
                        greyed={!(user && user.lobbyScreenNavigable) || (!this.props.instructor && (this.props.class_screen_id !== LOBBY_SCREEN) && (this.props.class_screen_id !== -1))} 
                        allowTabClick={this.props.instructor || (this.props.class_screen_id === -1) && (user && user.lobbyScreenNavigable)}
                        screenNavigable={(user && user.lobbyScreenNavigable)} // locked or not
                        showPinIcon={this.props.instructor || this.props.class_screen_id === LOBBY_SCREEN}
                        showLockIcon={this.props.instructor}
                        instructor={this.props.instructor}
                        tabIconStyle={{backgroundImage: 'url(' + LobbyIcon + ')' }}
                        style={{ backgroundColor: '#624177' }}
                        onClick={(event) => {
                            this.resetGridviewZoom()
                            this.tab_callback(event, 'lobby', {})
                        }
                        } >
                    </Tab>

                    <Tab
                        active={my_screen_id === WATCH_SCREEN || (this.props.class_screen_id === WATCH_SCREEN) && (user && user.watchScreenNavigable)}
                        classActive={this.props.class_screen_id === WATCH_SCREEN}                        
                        greyed={!(user && user.watchScreenNavigable) || (!this.props.instructor && this.props.class_screen_id !== WATCH_SCREEN) && (this.props.class_screen_id !== -1)}                      
                        allowTabClick={this.props.instructor || (this.props.class_screen_id === -1) && (user && user.watchScreenNavigable)}
                        screenNavigable={(user && user.watchScreenNavigable)}                        
                        showPinIcon={this.props.instructor || this.props.class_screen_id === WATCH_SCREEN}
                        showLockIcon={this.props.instructor}
                        instructor={this.props.instructor}
                        tabIconStyle={{backgroundImage: 'url(' + WatchIcon + ')' }}
                        style={{ backgroundColor: '#00AAC7' }}
                        onClick={(event) => {
                            this.resetGridviewZoom()
                            const broadcaster = this.props.students.find(s => s.broadcast)
                            this.tab_callback(event, 'watch', { watch_url: broadcaster ? broadcaster.create_url : null, broadcasterIdentity: broadcaster ? broadcaster.identity : null })
                        }
                        } >
                    </Tab>

                    <Tab
                        active={my_screen_id === CREATE_SCREEN || (this.props.class_screen_id === CREATE_SCREEN) && (user && user.createScreenNavigable)}
                        classActive={this.props.class_screen_id === CREATE_SCREEN}                                                
                        greyed={!(user && user.createScreenNavigable) || (!this.props.instructor && (this.props.class_screen_id !== CREATE_SCREEN) && (this.props.class_screen_id !== -1))}                        
                        allowTabClick={this.props.instructor || (this.props.class_screen_id === -1) && (user && user.createScreenNavigable)}
                        screenNavigable={(user && user.createScreenNavigable)}                        
                        showPinIcon={this.props.instructor || this.props.class_screen_id === CREATE_SCREEN}
                        showLockIcon={this.props.instructor}
                        instructor={this.props.instructor}
                        tabIconStyle={{backgroundImage: 'url(' + CreateIcon + ')' }}
                        style={{ backgroundColor: 'darkOrange' }}
                        onClick={(event) => {
                            this.resetGridviewZoom()
                            this.tab_callback(event, 'create', {})
                        }
                        } >
                    </Tab>

                    <Tab
                        active={my_screen_id === PLAY_SCREEN || (this.props.class_screen_id === PLAY_SCREEN) && (user && user.playScreenNavigable)}
                        classActive={this.props.class_screen_id === PLAY_SCREEN}                                                
                        greyed={!(user && user.playScreenNavigable) || (!this.props.instructor && (this.props.class_screen_id !== PLAY_SCREEN) && (this.props.class_screen_id !== -1))}                      
                        allowTabClick={this.props.instructor || (this.props.class_screen_id === -1) && (user && user.playScreenNavigable)}
                        screenNavigable={(user && user.playScreenNavigable)}                        
                        showPinIcon={this.props.instructor || this.props.class_screen_id === PLAY_SCREEN}
                        showLockIcon={this.props.instructor}
                        instructor={this.props.instructor}
                        tabIconStyle={{backgroundImage: 'url(' + PlayIcon + ')' }}
                        style={{ backgroundColor: '#76C16C' }}
                        onClick={(event) => {
                            this.resetGridviewZoom()
                            this.tab_callback(event, 'play', {})
                        }
                        } >
                    </Tab>

                    <Tab
                        active={my_screen_id === PORTFOLIO_SCREEN || (this.props.class_screen_id === PORTFOLIO_SCREEN) && (user && user.portfolioScreenNavigable)}
                        classActive={this.props.class_screen_id === PORTFOLIO_SCREEN}                                               
                        greyed={!(user && user.portfolioScreenNavigable) || (!this.props.instructor && (this.props.class_screen_id !== PORTFOLIO_SCREEN) && (this.props.class_screen_id !== -1))}                       
                        allowTabClick={this.props.instructor || (this.props.class_screen_id === -1) && (user && user.portfolioScreenNavigable)}
                        screenNavigable={(user && user.portfolioScreenNavigable)}                       
                        showPinIcon={this.props.instructor || this.props.class_screen_id === PORTFOLIO_SCREEN}
                        showLockIcon={this.props.instructor}
                        instructor={this.props.instructor}
                        tabIconStyle={{backgroundImage: 'url(' + PortfolioIcon + ')' }}
                        style={{ backgroundColor: '#97E18C' }}
                        onClick={(event) => {
                            this.resetGridviewZoom()
                            this.tab_callback(event, 'portfolio', {})
                        }
                        } >
                    </Tab>
                </div>
                <div className="right">
                    <ToolMenu
                        classActionCallback={this.props.classActionCallback}
                        actionCallback={this.props.action_callback}
                        instructor={this.props.instructor}
                    />
                    <Rundown aggregate={this.props.aggregate} instructor={this.props.instructor} classStartTime={Number(this.props.start)} hideTimer={this.props.lab} />
                    {this.props.lab && <CloseButton aggregate={this.props.aggregate} />}
                    <Connectivity lowWifi={this.props.lowWifi} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({

    selected_student: state.studioSelectedStudent.identity,
    screen_id: state.studioScreen.screen_id,
    class_screen_id: state.studioScreen.class_screen_id,
    students: state.studioStudents.students,
    identity: state.auth.identity,

});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setStudioScreen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuBar);
