import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setContextMenu } from 'actions/contextMenuAction';

import clsx from 'clsx';

import './styles.scss';


import GridViewTools from './GridViewTools'
import AudioIndicator from '../../Classrow/AudioIndicator';
import DCVHelper from '../../../helpers/DCVHelper'


class GridViewItem extends Component {
    ref = React.createRef()

    constructor(props) {
        super(props);
        this.screen_colors = ['#00AAC7', 'darkOrange', '#76C16C']
        this.state = {
            scale: 1.0,
            items: [],

        }

        this.dcvHelper = new DCVHelper()

    }

    componentDidMount() {
        const scale = (this.ref.current ? this.ref.current.offsetWidth : 100) / 1334
        this.setState({ scale: scale })
    }

    componentDidUpdate(prevProps) {
        const scale = (this.ref.current ? this.ref.current.offsetWidth : 100) / 1334
        if (scale !== this.state.scale) {
            this.setState({ scale: scale })
        }
    }

    render_viewport = () => {
        return (
            <div className="streamed_dcv" ref={this.ref}>
                <div className="scaleX" style={{ transform: 'scaleX(' + this.state.scale + ')' }}>
                    <div className="scaleY" style={{ transform: 'scaleY(' + this.state.scale + ')' }}>
                        <iframe id={"student_iframe-" + this.props.data.identity}
                            key={this.props.create_url + "-student"}
                            width={'1334px'}
                            height={'613px'}
                            title="Student"
                            className="student_iframe"
                            src={this.dcvHelper.transformUrl(this.props.create_url)} />
                    </div>
                </div >
            </div >
        )


    }

    render_contextMenu = (event) => {
        console.log('RENDER CONTEXT MENU')
        return this.props.setContextMenu(
            {
                show: true,
                clientX: event.clientX,
                clientY: event.clientY,
                contents: () => (<GridViewTools data={this.props.data} actionCallback={this.props.action_callback} />)
            })
    }

    render() {
        const { selected, viewScreen, gridAllowed } = this.props;
        const { helpNeeded, participate, twilioDominantSpeaker, username, dcvStreamWarning } = this.props.data;

        const classes = {
            wrapper: clsx('GridViewItem__wrapper', viewScreen && 'viewScreen'),
            contextMenu: clsx('contextMenu', viewScreen && 'viewScreen', gridAllowed ? 'show' : 'hide'),
            neonBorder: clsx('container neonBorder', selected && 'selected',
                helpNeeded && 'redBorder',
                participate && 'greenBorder'),
            label: clsx('label'),
            speaking: clsx(twilioDominantSpeaker && 'redBorder'),
            volumeLevel: clsx('volumeLevel'), //, this.parseVolumeLevel())
            arm: clsx(' arm', participate && 'show'),
            connection: clsx(' connection', dcvStreamWarning && 'poor'),

        };
        const ContainerStyle = {
            borderColor: this.screen_colors[this.props.screen_id],

        }

        var gridViewItem = (
            <div className={classes.wrapper}>

                <div className="topContent">
                    <div className={classes.label}>
                        {username} {selected}
                    </div>

                    <div className={classes.volumeLevel}>
                        <AudioIndicator student={this.props.data} />
                    </div>

                    <div className={classes.connection} />

                </div>
                <div className="container" style={ContainerStyle}>
                    <div className={classes.contextMenu} onClick={this.render_contextMenu} />
                    {this.render_viewport()}
                </div>
                <div className={classes.arm} onClick={this.participate} />


            </div>
        )

        try {
            if (!viewScreen) {
                document.getElementById('student_iframe-' + this.props.data.identity).contentWindow.dcvConnection.setVolume(0);
            } else {
                document.getElementById('student_iframe-' + this.props.data.identity).contentWindow.dcvConnection.setVolume(100);
            }
        } catch (error) {
            // console.log("-gvi DCV - could not adjust student volume in gridview - error: ", this.props.data.identity, error)
        }

        return gridViewItem
    }

}

const mapStateToProps = state => ({
    callback: state.contextMenu.callback,
    volumeLevels: state.studioCommsVolume.volumeLevels,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setContextMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridViewItem);
