// aws
import { API, Amplify } from 'aws-amplify';


import { studioCommand, userCommand } from 'graphql/studio';
import ddbStamper from '../pages/Studio/helpers/DDBStamper';

export const send = async (method, variables, callback) => {
    try {
        var { data } = await API.graphql({
            query: method,
            variables: { ...variables }
        });
        var name = Object.keys(data)[0]
        return callback ? callback(data[name]) : null
    }
    catch (err) {
        return callback ? callback(err) : null
    }
}

/**
 * Wraps the {@linkcode send} function in a promise to allow it to be awaited
 * instead of having to use a callback.
 *
 * @see {@link send}
 * @method
 * @param {string} operation The GraphQL operation to execute
 * @param {?Object} payload Params to pass into the operation. Defaults to an
 *                          empty object.
 * @returns {Promise} A promise that is rejected if the query comes back with
 *                    errors, and resolves otherwise. Either way, the promise
 *                    returns the response from the call.
 */
export const asyncSend = (operation, payload = {}) => new Promise((resolve, reject) => {
  send(operation, payload, response => {
    if (response.errors && response.errors.length > 0) {
      return reject(response.errors[0]);
    }

    return resolve(response);
  });
});

/**
 * This function will parse all the data passed back from a GraphQL Mutation call.
 *
 * @param {object} graphqlOperation
 * @param {string} graphqlOperation.query - The GraphQL query string/method
 * @param {object} graphqlOperation.variables - The custom variables to be passed
 * @returns {Promise<any>}
 */
export const post = async (graphqlOperation) => {
    try {
        const { data } = await API.graphql(graphqlOperation);
        const operationName = Object.keys(data)[0];
        const parsedData = data[operationName];

        return parsedData;
    } catch (err) {
        return err;
    }
};


export const user_cmd = (identity, sort_key, args, callback) => {
    window.configure_amplify("studio_endpoint");

    send(
        userCommand,
        {
            input: {
                identity: identity,
                sort_key: sort_key,
                arguments:
                    typeof args === "object" ? JSON.stringify(args) : args,
            },
        },
        (response) => {
            if (response && response.errors) {
                ddbStamper.stampFrontendError(sort_key, {
                    user_command_error: response || "no err response available",
                    type: "userCommand",
                    req: { uc: { args: args || "" } },
                });
            }

            callback && callback(response);
        }
    );
    return true;
};

export const studio_cmd = (id, command, args, callback) => {
    window.configure_amplify('studio_endpoint')

    send(studioCommand,
        {
            input: {
                id: id,
                command: command,
                arguments: typeof args === 'object' ? JSON.stringify(args) : args,
            }
        }, (response) => {
            /**
             * INFO: Code below is legacy. We used to have a stats array in DDB that was just
             * an appended list of everything we can slap into DDB.
             * Commenting this out as we're not sure what to do with this one. It doesn't fit one of the three
             * categories: `user_information`, `stats`, and `frontend_errors`
            if (response && !response.errors) {
                ddbStamper.addStatsEntry(id, { "sc": { "cmd": command, "args": args || "" } })
            }
            */
            if (response && response.errors) {
                ddbStamper.stampFrontendError(id, { "studio_cmd_error": response || "no err response available", "type": "studioCommand", "req": { "sc": { "cmd": command, "args": args || "" } } })
            }

            callback && callback(response);
        })
    return true
}

export const query = async (method, variables, callback) => {
    // Get the amplify config that is currently in use when this function is called
    const cached_config = { ...Amplify._config }
    let results = [];
    let nextToken = null;
    try {
        do {
            try {
                // init the amplify again if it is different
                Amplify.configure(cached_config)
                var { data } = await API.graphql({
                    query: method,
                    variables: { ...variables, nextToken: nextToken }
                });
                var name = Object.keys(data)[0]

                nextToken = data[name].nextToken;
                results = [...results, ...(data[name].items || [])];
            }
            catch (err) {
                console.log(method, err)
                nextToken = null;
            }
        }
        while (nextToken);
        callback && callback(results)
    }
    catch (err) {
        callback && callback(results)
    }
}
