// This class contains helper functions for working with NICE DCV


class DCVHelper {
    constructor() {

    }


    // MARK: Public methods
    /**
     * Returns the modified url to point at using the local modified DCV client.
     * @param {string} url The DCV url
     * @returns {string} modifiedUrl
     */
    transformUrl = (url) => {
        if (url === null || url === undefined) return

        const instanceIdRegex = /i-[0-9a-f]+#/i;

        return url.search(instanceIdRegex) !== -1 ? this._transformNewLoadBalancerUrl(url) : this._transformOldUrl(url)

    }


    // MARK: Private methods
    /**
     * Modify the create and watch url's to the old format using instances that are not behind a load balancer.
     * 
     * @param {string} url The raw DCV url: https://studio-dev001.ume.games:8443#login-6b62f7
     * @returns {string} The modified url: https://www.ume.games/dcv/index.html?type=old&host=studio-dev001.ume.games#login-6b62f7 || https://localhost:3000/dcv/index.html?host=studio-dev001.ume.games#login-6b62f7
     */
    _transformOldUrl = (url) => {
        // Check if using http (indicates localhost if https is not set up) then prepend and use the dcv client found on https:/ume.games otherwise stick with what you have
        var baseWebsite = document.location.protocol === 'https:' ? document.location.origin : 'https://ume.games'

        const modifiedUrl = baseWebsite + '/dcv/index.html' + '?type=old' + '&host=' + url.replace('https://', '').replace(':8443', '')
        return modifiedUrl

    }

    /**
     * Modify the create and watch url's to the new format using instances that are behind a load balancer.
     * 
     * @param {string} url The raw DCV url: https://studio-pac1.ume.games/i-abc123#login-6b62f7
     * @returns {string} The modified url: https://www.ume.games:8081/dcv/index.html?type=new&dcvLb=studio-pac2.ume.games&dcvServer=i-0dac5c39ebbb2e254&dcvLogin=#login-be083e
     */
    _transformNewLoadBalancerUrl = (url) => {
        var baseWebsite = document.location.protocol === 'https:' ? document.location.origin : 'https://ume.games'

        let strippedUrl = url.replace('https://', '')

        let dcvLoginStartIdx = strippedUrl.lastIndexOf("#")
        let dcvLogin = strippedUrl.substring(dcvLoginStartIdx, strippedUrl.length)

        let dcvServerStartIdx = strippedUrl.lastIndexOf("/")
        let dcvServer = strippedUrl.substring(dcvServerStartIdx + 1, dcvLoginStartIdx)

        let dcvLb = strippedUrl.substring(0, dcvServerStartIdx)

        let dcvLocalClient = '/dcv/index.html'

        const modifiedUrl = baseWebsite + dcvLocalClient + '?type=new' + '&dcvLb=' + dcvLb + '&dcvServer=' + dcvServer + '&dcvLogin=' + dcvLogin
        return modifiedUrl

    }

}

export default DCVHelper;
