

export const getComms = `query GetComms($id:String!){
  getComms(id:$id){
    comms_status
    comms_id
    comms_token
  }
}

`;

export const getUserPublicRecord = `query GetUserPublicRecord($identity:String!){
  getUserPublicRecord(identity:$identity){
    username
    avatar
  }
}
`;

export const getSeat = `query GetSeat($id:String!,$config:String!,$role:String){
  getSeat(id:$id,config:$config,role:$role){
    identity
    login
    create_url
    create_status 
  }
}

`;

export const querySeats = `query QuerySeats($id:String!, $nextToken: String){
  querySeats(id:$id, nextToken: $nextToken ){
    items{
      aggregate
      identity
      username
      sort_key
      identity
      login
      order
      create_url
      create_status 
    }
    nextToken
  }
}
`;

export const getClass = `query GetClass($id:String!){
  getClass(id:$id){    
    id
    appsync_id
    aggregate
    duration
    comms_status
    enrollment_period
    source
    start
    end
    status
    type
    order
    lab
    lab_data
  }
}

`;