import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Scrolls to the top of the page whenever the route changes.
 *
 * @see https://stackoverflow.com/a/54343182
 */
const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return unlisten;
  }, [history]);

  return null;
};

export default ScrollToTop;
