import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

import { send } from 'graphql/handler'
import { getUser } from 'graphql/arcade';

const NON_SUBSCRIPTION_GROUPS = ['Student', 'Developer'];

class cognitoService {
  constructor() {
    this._callbacks = []

    this.DEFAULT_AVATAR = "avatar_default.png"

  }

  get_signed_url = (data) => {
    if (!this.props.authentication || !data) {
      return
    }
    if (this.state.urls[data]) return this.state.urls[data]
    var urls = this.state.urls
    urls[data] = this.props.authentication.s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: data, Expires: 3600 })
    this.setState({ urls: urls })
  }

  get_user = async (callback) => {
    callback && callback({ authenticating: true, message: 'Authorizing...' })

    // TODO: clear all subscriptions - need to put the subscription caching globally

    let credentials = await Auth.currentCredentials();

    let user = {}
    try {
      user = await Auth.currentAuthenticatedUser();
    }
    catch (err) {
      // Remove any existing users cached in local storage
      Object.keys(localStorage).forEach((key) => {
        key.indexOf('CognitoIdentity') === 0 && localStorage.removeItem(key)
      })

      // try getting anonymous credentials
      credentials = await Auth.currentCredentials();
      try {
        user = await Auth.currentAuthenticatedUser();
      }
      catch (err) {
        //console.log('err', err)
      }

    }
    // Get credential tokens 
    const essentials = await Auth.essentialCredentials(credentials)

    // S3 handler for making signed requests
    // This fails during testing - ergo the try/catch.
    let s3 = {};
    try {
      s3 = new AWS.S3({
        apiVersion: '2006-03-01',
        region: 'us-west-2',
        credentials: essentials
      });
    } catch {}
    const defaultPublicData = {}

    // Template for a user authentication dictionary 
    const response_data = {
      username: user.username ? user.username : 'anonymous' + (credentials.identityId && credentials.identityId.substr) ? credentials.identityId.substr(credentials.identityId.length - 5) : '123',
      authenticated: credentials.authenticated, // contains data 
      authenticating: false, // contains data 
      identityId: credentials.identityId, // contains data 
      s3: s3, // s3 handler for making signed requests
      groups: user.signInUserSession ? user.signInUserSession.accessToken.payload['cognito:groups'] : [],
      credentials: essentials, //store for easy access to send to endpoints
      public: defaultPublicData
    }
    if (!response_data.authenticated) {
      return callback && callback(response_data)
    }

    // Query will recursively make the call using nextToken to gather more results - until then show "Synchronizing"
    callback && callback({ authenticating: true, message: 'Synchronizing...' })

    // Check if the claims on the token have changed since the last sign in
    const tokenUpdate = await this.refreshToken();

    window.configure_amplify('arcade_endpoint')
    send(getUser, {}, (response) => {
      if (!response || response.errors) {
        return callback(response_data)
      }

      var result = {
        ...response_data,
        ...tokenUpdate
      };
      var item = response
      result[item.sort_key] = item
      result[item.sort_key].instructor = response_data.groups.indexOf('Instructor') > -1 ? true : false
      result[item.sort_key].arm = (result[item.sort_key].avatar && !result[item.sort_key].avatar.includes(this.DEFAULT_AVATAR)) ? s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: 'avatars/arm_' + result[item.sort_key].avatar.split('_').pop(), Expires: 3600 }) : s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: 'avatars/arm_A.png', Expires: 3600 })
      result[item.sort_key].avatar = result[item.sort_key].avatar ? s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: 'avatars/' + result[item.sort_key].avatar, Expires: 3600 }) : null

      callback && callback({ authenticating: true, message: 'Ready!' })
      return callback(result)
    })
  };

  refreshToken = async () => {
    const [user, session] = await Promise.all([
      Auth.currentAuthenticatedUser(),
      Auth.currentSession()
    ]);

    user.refreshSession(session.refreshToken, () => {
      console.log('Refreshing token');
    });

    const groups = user.signInUserSession
      ? user.signInUserSession.accessToken.payload['cognito:groups']
      : [];

    const subscriptions = new Set(groups.filter(g => {
      return !NON_SUBSCRIPTION_GROUPS.includes(g);
    }));

    return { subscriptions };
  }

  signIn = async (username, password, callback) => {
    try {
      var response = await Auth.signIn(username, password, callback);
      //console.log('Cognito', response)
      if (response.challengeName === 'SMS_MFA') {
        return callback(response)
      }
      this.get_user(callback)
    }
    catch (err) {
      //console.log('Cognito err', err)
      callback(err)
    }
  }

  signOut = async (callback) => {
    Auth.signOut({ global: true })
      // .then(data => { callback(data) }) // window.location.reload(false);      
      .then(data => {
        window.location.reload(false)
      }) // window.location.reload(false);      
      .catch(err => callback(err));
  }

  confirmSignIn = async (user, code, callback) => {
    Auth.confirmSignIn(
      user,   // Return object from Auth.signIn()
      code,   // Confirmation code  
      'SMS_MFA' // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then(data => {
      this.get_user(callback)
    }) // window.location.reload(false);
      .catch(err => callback(err));
  }
}

const instance = new cognitoService();

export default instance;

