import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import YouTube from 'react-youtube';
import clsx from 'clsx';
import LockIcon from 'assets/images/icons/lock-normal.png';
import './styles.scss';

/**
 * Wrapper for the YouTube player that exposes the player API through its ref
 * and shows an overlay if the video is locked. (The video is not loaded at all
 * when it is locked.)
 */
const Video = ({ src, locked = false, className = '' }, ref) => {
  const [showOverlay, setShowOverlay] = useState(locked);
  const [controls, setControls] = useState();

  const handleReady = e => {
    setControls(e.target);
  };

  const play = useCallback(() => {
    if (!locked && controls) {
      controls.playVideo();
      setShowOverlay(false);
    }
  }, [controls, locked]);

  useEffect(() => {
    setShowOverlay(locked || !controls);
  }, [controls, locked]);

  // Data to expose through this component's ref
  useImperativeHandle(ref, () => ({
    play,
    seek: seconds => {
      controls.seekTo(seconds, true);
      play();
    }
  }), [controls, play]);

  return (
    <div
      className={clsx('video__container', showOverlay && 'video__container--overlay', !locked && 'video__container--clickable', className)}
      onClick={play}
      style={{
        '--bg-image': src ? `url(https://img.youtube.com/vi/${src}/hqdefault.jpg)` : ''
      }}
    >
      <YouTube
        videoId={(!locked && src) ? src : ''}
        onReady={handleReady}
        className="youtube"
        containerClassName="youtube-container"
      />

      {locked && (
        <img
          src={LockIcon}
          alt="Video is locked"
          width="354px"
          height="354px"
          className="video__icon"
        />
      )}
    </div>
  );
};

export default forwardRef(Video);
