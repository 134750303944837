export const onBroadcast = `subscription OnBroadcast($identity: String!) {
  onBroadcast(identity: $identity) {
    identity
    context
    timestamp
    payload
  }
}
`;

export const onUpdateClass = `subscription OnUpdateClass($id:String!){
  onUpdateClass(id:$id){
    id
    aggregate
    status
    start
    end
    duration
    public
    students{
      username
      identity
      avatar  
      instructor
    }
  }
}
`;

export const onCreateClass = `subscription OnCreateClass{
  onCreateClass{
    id    
    aggregate
    status
    start
    end
    duration
    public
    students{      
      identity
      username      
      avatar 
      instructor           
    }
  }
}
`;
