import { useSearchParams } from 'hooks';
import { useSelector } from 'react-redux';
import { updateUserInfo } from 'api';
import { useEffect } from 'react';

const FacilitatorCodeWatcher = () => {
    const { facilitatorCode } = useSearchParams();
    const { authenticated } = useSelector(state => state.auth);

    useEffect(() => {
        if (authenticated && facilitatorCode) {
            updateUserInfo({ facilitator_code: facilitatorCode });
        }
    }, [authenticated, facilitatorCode]);

    return null;
};

export default FacilitatorCodeWatcher;
