import { SET_ARTIFACTS, SET_CURRENT_ARTIFACT } from '../actions/types';

const initialState = {
  artifacts: [],
  currentArtifact: {}
};

function artifactReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ARTIFACTS:
      return {
        ...state,
        artifacts: action.payload
      };
    case SET_CURRENT_ARTIFACT:
      return {
        ...state,
        currentArtifact: action.payload
      };
    default:
      return state;
  }
}

export default artifactReducer;
