import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { fetchMyLatestPublishByContext } from 'api';

const ActivityContext = createContext(null);

export const ActivityProvider = ({ context, onNotFound, children }) => {
  const [activity, setActivity] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const loadActivity = useCallback(() => {
    setLoading(true);
    setError(undefined);

    (async () => {
      try {
        const foundActivity = await fetchMyLatestPublishByContext(context);

        if (!foundActivity) {
          onNotFound();
        }

        setActivity(foundActivity);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [context]);

  useEffect(() => {
    if (context) {
      loadActivity();
    }
  }, [loadActivity, context]);

  const state = {
    activity,
    loading,
    error,
    forceUpdate: loadActivity
  };

  return (
    <ActivityContext.Provider value={state}>
      {children}
    </ActivityContext.Provider>
  );
};

export const useActivity = () => useContext(ActivityContext);
