import React, { Component } from 'react';
import clsx from 'clsx';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import GameCard from './GameCard';
// import LiveCard from './LiveCard';
// import ProjectCard from './ProjectCard';
// import ChildCard from './ChildCard';
// import ChildAddCard from './ChildAddCard';
// import JoinCard from './JoinCard';
// import CurriculumCard from './CurriculumCard';
// import ExpanderCard from './ExpanderCard';

import './styles.scss';

class Carousel extends Component {
  state = {
    isSwipe: false,
    selectedCard: this.props.selectedCard,
    currentSlider: this.props.currentSlider
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedCard !== this.props.selectedCard) {
      this.setState({ selectedCard: this.props.selectedCard });
    }
    if (prevProps.currentSlider !== this.props.currentSlider) {
      this.setState({ currentSlider: this.props.currentSlider });
    }
    if (prevProps.cardItems !== this.props.cardItems) {
      this.setState({ isSwipe: false });
    }
  }

  handleCardSelect = (id) => {
    if (this.state.isSwipe) return;
    this.setState({ selectedCard: id }, () => this.props.onSelect(id));
    ;
  };

  handleSliderBeforeChange = () => this.setState({ isSwipe: true });

  handleSliderAfterChange = () => this.setState({ isSwipe: false });

  render() {
    const { currentSlider } = this.state;
    const { selectedCard, size, cardItems, id, featured } = this.props;
    const settings = {
      accessibility: false,
      dots: false,
      //speed: 500,
      //centerMode: false,
      initialSlide: 0,
      variableWidth: true,
      beforeChange: this.handleSliderBeforeChange,
      afterChange: this.handleSliderAfterChange,
      //onSelect: this.handleCardSelect,
      infinite: false,
      ...this.props

    };
    var Cards = {
      default: GameCard,
      game: GameCard
    }
    const classes = {
      slider: clsx('Carousel__wrapper', featured && 'featured'),
    }
    return (
      <div id={`slider-${id}`} className={classes.slider}>
        < Slider
          {...settings}
        >
          {cardItems &&
            cardItems.map((card, idx) => {
              let Card = Cards[card.type ? card.type : 'default']
              return (
                < Card
                  featured={featured}
                  key={idx}
                  size={size}
                  content={card}
                  selected={selectedCard === idx}
                  hasOpacity={currentSlider >= 0}
                  onSelect={event => this.handleCardSelect(idx)}
                /> 
              )
            })
          }
        </Slider>
      </div >
    );
  }
}



export default Carousel;
