
import React, { Component } from 'react';
import './styles.scss';

class Skills extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    get_signed_url = (data) => {
        if (!this.props.authentication) {
            return
        }
        if (this.state.urls[data]) return this.state.urls[data]
        var urls = this.state.urls
        urls[data] = this.props.authentication.s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: data, Expires: 3600 })
        this.setState({ urls: urls })
    }
    render() {
        return (
            <div className="skills__wrapper">
                <span className="title textGlow_yellowBold">RATING</span>
                <div className="details">
                    {
                        Object.keys(this.props.skills).map((key, idx) => {
                            return (
                                <div key={idx} className="skill-item" >
                                    <img src={this.props.skills[key]} alt="hexagon" />
                                    <span>{key}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div >
        )

    }
}

export default Skills;