import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import cognitoService from 'services/cognito';

import { setProfile, setSignIn } from 'actions/authAction';
import { setPopUp } from 'actions/popupAction';

import AudioCheck from '../../../../../components/AudioCheck'

import { ToolMenuItemButton, ToolMenuItemSection, ToolMenuItemTitle, ToolMenuItemPassword } from './ToolMenuItem'

import { withIntercomHOC } from 'hooks/useIntercom';

import './styles.scss';

class ToolMenu extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {
            signedIn: props.authentication && props.authentication.authenticated,
            helpTitle: this.props.intercom.isIntercomActive ? "Close help" : "Get help"
        }

    }

    componentDidUpdate(prevProps) {
        if ((this.props.authentication && !prevProps.authentication) ||
            JSON.stringify(this.props.authentication) !== JSON.stringify(prevProps.authentication)) {
            this.setState({ signedIn: this.props.authentication && this.props.authentication.authenticated })
        }

    }

    handleLogin = () => {
        if (this.props.authentication && this.props.authentication.authenticated) {
            cognitoService.signOut((msg) => {
                return window.location.reload();//force reload of page, start fresh
            })
        }
        else {
            this.props.setSignIn(true)
        }
    };

    onClose = () => this.setState({ opened: false });

    onOpen = () => {
        // Update the help button text depending on if there's an active Intercom session
        const helpTitle = this.props.intercom.isIntercomActive ? "Close help" : "Get help"
        helpTitle !== this.state.helpTitle && this.setState({ helpTitle })

    }

    handleIntercomHelp = () => {
        const { intercom } = this.props;

        if (intercom.isIntercomActive) {
            intercom.closeSession()
            this.setState({ helpTitle: "Get help" })
        } else {
            intercom.launchSession()
            intercom.showPrepopulatedHelpSessionMessage(this.props.username)

            this.setState({ helpTitle: "Close help" })

        }

    }

    render() {
        const classes = {
            main: clsx('ToolMenu__wrapper '),
        }

        return (
            <div className={classes.main}>
                <div className="hamburger" onMouseEnter={() => { this.onOpen() }}>&#9776;</div>
                <div className="ToolMenu__layout">
                    <ToolMenuItemTitle label={this.props.username} />

                    <ToolMenuItemPassword />

                    < ToolMenuItemButton
                        label={this.state.signedIn ? 'Sign Out' : 'Sign in to UME'}
                        onClick={event => this.handleLogin(event)}
                    />
                    < ToolMenuItemButton
                        label={"Audio Settings"}
                        onClick={() => this.props.setPopUp({
                            alert: false, show: true, contents: () => { return <AudioCheck initializationAllowed={true} actionCallback={this.props.actionCallback} /> }
                        })}
                    />
                    < ToolMenuItemButton
                        label={this.state.helpTitle}
                        onClick={() => { this.handleIntercomHelp() }}
                    />
                    < ToolMenuItemButton
                        label={"Privacy Policy"}
                        onClick={() => window.open('https://ume.academy/privacy', '_blank')}
                    />
                    <ToolMenuItemSection label={window.branch_version} />
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    authentication: state.auth.authentication,
    identity: state.auth.identity,
    username: state.auth.username,
    signIn: state.auth.signIn,
});

const mapDispatchToProps = {
    setPopUp,
    setProfile,
    setSignIn
}


// const mapDispatchToProps = dispatch =>
//     bindActionCreators({ setProfile, setSignIn }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withIntercomHOC(ToolMenu));
