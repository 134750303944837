import React, { Component } from 'react';
import clsx from 'clsx';

import Agreement01Normal from './images/agreement_01_normal.png';
import Agreement02Normal from './images/agreement_02_normal.png';
import Agreement03Normal from './images/agreement_03_normal.png';
import Agreement04Normal from './images/agreement_04_normal.png';
import Agreement05Normal from './images/agreement_05_normal.png';
import Agreement05Hover from './images/agreement_05_hover.png';
import './styles.scss';

const images = [
    { normal: Agreement01Normal },
    { normal: Agreement02Normal },
    { normal: Agreement03Normal },
    { normal: Agreement04Normal },
    { normal: Agreement05Normal, hover: Agreement05Hover },
];

class AgreementItem extends Component {
    render() {
        const classes = {
            agreement: clsx('Agreement__wrapper'),
            flipCard: clsx(this.props.allowHover ? "flip-card" : "no-flip-card")
        }

        function random(seed) {
            var x = Math.sin(seed) * 10000;
            return x - Math.floor(x);
        }

        const rot = (2.0 * random(this.props.index) - 1.0) * 15;
        const marginLeft = random(this.props.index) * 8 + 2;
        const marginBottom = random(this.props.index) * 5 + 1;

        return (
            <div className={classes.agreement} style={{ marginLeft: marginLeft + "%", marginBottom: marginBottom + "%" }}>
                <div className={classes.flipCard} style={{ transform: 'rotateZ(' + rot + 'deg)' }}>
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            <img src={images[this.props.index - 1].normal} alt="Avatar" />
                        </div>
                        <div className="flip-card-back">
                            <img alt="" src={images[this.props.index - 1].hover} />
                        </div>
                    </div>
                </div>
            </div >
        )

    }
}

class Summary extends Component {
    render() {
        const { viewScreen } = this.props;

        const classes = {
            wrapper: clsx('Summary__wrapper', viewScreen && 'viewScreen'),
        };

        return (
            <div className={classes.wrapper}>
                <div className="left-panel">
                </div>
                <div className="right-panel">
                    <div className="title">Agreements</div>
                    {
                        Array(5).fill({}).map((v, idx) => {
                            return (<AgreementItem key={'Agreement' + idx} index={idx + 1} allowHover={idx === 4 ? true : false} />)
                        })
                    }
                </div>
            </div>
        )
    }

}

export default Summary;
