import BaseCard from './BaseCard';
import LoginLink from '../LoginLink';

const SignInCard = () => (
  <BaseCard className="cl-card--sign-in">
    <div className="cl-card__info">
      <h3 className="cl-card__title">
        Please sign in or sign up to view your classes.
      </h3>
    </div>

    <div className="cl-card__buttons">
      <LoginLink className="cl-button">
        Sign in/Sign up
      </LoginLink>
    </div>
  </BaseCard>
);

export default SignInCard;
