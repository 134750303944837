import React, { Component } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { setContextMenu } from 'actions/contextMenuAction';


import './styles.scss';
class ContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 100,
      height: 100,

    }
    this.resizeObserver = new ResizeObserver(this.resize_callback)
  }
  componentDidMount() {
  }
  componentWillUnmount() {
    this.resizeObserver.unobserve(this.element)
    this.removeClickListener()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contextMenu.show !== this.props.contextMenu.show) {

      if (this.props.contextMenu.show) {
        this.resizeObserver.observe(this.element)
        this.hideOnClickOutside()
        this.setState({ closed: false })
        return
      }
      this.resizeObserver.unobserve(this.element)
      this.removeClickListener()

    }

    if (prevProps.scale !== this.props.scale) {
      this.resize_callback()
    }

  }

  resize_callback = () => {
    const rect = this.element.getBoundingClientRect()
    var el = document.getElementsByClassName('Studio__wrapper')[0]
    var elRect = el.getBoundingClientRect()
    this.setState({
      menu_width: rect.width,
      menu_height: rect.height,
      studio_width: elRect.width,
      studio_height: elRect.height,
      studio_offset_left: (el.offsetWidth - 2 * el.offsetLeft) * .5,
      studio_offset_top: (el.offsetHeight - 2 * el.offsetTop) * .5
    })
  }

  outsideClickListener = event => {
    event.preventDefault()
    // if the window is already open and we are not clicking on it, hide
    if (
      this.props.contextMenu.show && // the menu is showing
      !this.element.contains(event.target)) { // the item being clicked on is NOT a child
      this.props.setContextMenu({ show: false, contents: null })
      this.setState({ closed: true })
      this.removeClickListener()
    }
  }
  removeClickListener = () => {
    document.removeEventListener('click', this.outsideClickListener)
  }
  hideOnClickOutside = () => {
    if (this.props.contextMenu.show) {
      // Adding the listener in the same frame can cause it to trigger
      // immediately. Using setTimeout to defer after the render.
      setTimeout(() => document.addEventListener('click', this.outsideClickListener));
    }
  }

  dismissPopup = () => {
    this.props.setContextMenu({ show: false, contents: null })
  }

  render_close = () => {

    return (<div className="actions" onClick={this.dismissPopup}>
      <span className="close" ></span>
    </div>
    )
  }


  render() {
    const classes = {
      contextMenu: clsx('ContextMenu__wrapper', this.props.contextMenu.show && 'show'),
    };
    const scale = this.props.scale ? this.props.scale : 1
    const { clientX, clientY } = this.props.contextMenu

    var posX = clientX / window.innerWidth * this.state.studio_width / scale
    posX = (posX + this.state.menu_width) > (window.innerWidth / scale) ? posX - this.state.menu_width / scale : posX;

    var posY = clientY / window.innerHeight * this.state.studio_height / scale;
    posY = (posY + this.state.menu_height) > (window.innerHeight / scale) ? posY - this.state.menu_height / scale : posY

    const style = { top: posY ? posY : 1, left: posX ? posX : 1, ...(this.props.contextMenu.styles || {}) }
    return (
      <div className={classes.contextMenu}
        style={style}
        ref={element => (this.element = element)
        }
      >
        {this.render_close()}
        {this.props.contextMenu.contents && this.props.contextMenu.contents()}
      </div >
    )
  }
}

const mapStateToProps = state => ({
  contextMenu: state.contextMenu,
});
const mapDispatchToProps = {
  setContextMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
