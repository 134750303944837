/***
 * Enter username
 *  if it does not exist
 *    create anonymous account and register for this class, parent email is anonymous@ume.academy
 *    login with username and auto created password
 *  if it exists
 *    enter password
 *    if success, login with username and password
 */
import React, { Component } from 'react';
import { AWSPingAvg } from 'helpers/awsPing';
import axios from 'axios';
import Input from 'components/Input'
import UMEButton from 'components/UMEButton'
import Button from 'components/Button'
import { getApiEndpoint } from 'helpers';

import './styles.scss'

const avatars = [
  'avatar_cat_blue_B.png',
  'avatar_cat_blue_C.png',
  'avatar_cat_green_A.png',
  'avatar_cat_pink_B.png',
  'avatar_cat_pink_C.png',
  'avatar_cat_purple_A.png',
  'avatar_cat_yellow_A.png',
  'avatar_chipmunk_blue_B.png',
  'avatar_chipmunk_blue_C.png',
  'avatar_chipmunk_green_A.png',
  'avatar_chipmunk_green_C.png',
  'avatar_chipmunk_pink_B.png',
  'avatar_chipmunk_purple_A.png',
  'avatar_chipmunk_yellow_A.png',
  'avatar_dog_blue_B.png',
  'avatar_dog_blue_C.png',
  'avatar_dog_green_A.png',
  'avatar_dog_green_C.png',
  'avatar_dog_pink_B.png',
  'avatar_dog_purple_A.png',
  'avatar_dog_yellow_A.png',
  'avatar_fox_blue_B.png',
  'avatar_fox_green_A.png',
  'avatar_fox_pink_B.png',
  'avatar_fox_purple_A.png',
  'avatar_fox_yellow_A.png',
  'avatar_hedgehog_blue_B.png',
  'avatar_hedgehog_blue_C.png',
  'avatar_hedgehog_green_A.png',
  'avatar_hedgehog_green_C.png',
  'avatar_hedgehog_pink_B.png',
  'avatar_hedgehog_purple_A.png',
  'avatar_hedgehog_yellow_A.png',
  'avatar_helmet_blue_C.png',
  'avatar_helmet_green_C.png',
  'avatar_helmet_purple_C.png',
  'avatar_helmet_yellow_B.png',
  'avatar_raccoon_blue_B.png',
  'avatar_raccoon_blue_C.png',
  'avatar_racoon_green_A.png',
  'avatar_racoon_green_C.png',
  'avatar_racoon_purple_A.png',
  'avatar_racoon_yellow_A.png',
  'avatar_wolf_blue_C.png',
  'avatar_wolf_green_C.png',
  'avatar_wolf_pink_C.png',
]

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()
    const urlParams = new URLSearchParams(window.location.search);
    this.state = {
      login: urlParams.get('username'),
      password: urlParams.get('password'),
      email: null,
      label: props.label || 'Continue',
      status: props.status,
      message: props.message,
      subscriptionCode: urlParams.get('subscriptionCode')
    }
  }
  componentDidUpdate(prevProps, prevState) {
    var updates = {}
    if (this.state.label === 'Sending' && this.props.status === 'error') {
      updates.label = 'Try again'
      updates.status = 'invalid'
    }
    if (prevProps.message !== this.props.message && this.state.message !== this.props.message) {
      updates.message = this.props.message
      updates.label = this.props.status !== 'error' ? this.props.message : updates.label
    }
    if (prevState.login !== this.state.login) {
      updates.password_required = false;
    }
    if (Object.keys(updates).length) {
      this.setState(updates)
    }

  }

  onClick = () => {
    if (this.state.status === 'send') {
      return
    }
    this.setState({ status: 'send', label: 'Sending' }, () => {
      this.props.callback(this.state.login, this.state.password, null)
    })
  }
  validate_login = (value) => {
    this.setState({ email_optional: false, password_required: false, login: value, login_status: null, login_error: '', status: null })
    return true
  }
  validate_password = (value) => {
    this.setState({ password: value, status: null })
    return true
  }
  validate_subscribe = (event) => {
    this.setState({ subscribe: event.target.checked, status: null })
    return true
  }
  validate_email = (value) => {
    this.setState({ email: value, status: null })
    return true
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.state.login) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Enter a valid login name.' })
    }
    if (!this.state.password) {
      return this.setState({ status: 'error', password_status: 'invalid', password_error: 'Enter a valid password.' })
    }
    if (this.state.login.replace(/^\s+|\s+$/g, "") !== this.state.login) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Login has a space at the start or end, please fix.' })
    }
    if (this.state.password.replace(/^\s+|\s+$/g, "") !== this.state.password) {
      return this.setState({ status: 'error', password_status: 'invalid', message: 'Password has a space at the start or end, please fix.' })
    }
    event.persist()
    let target = event.currentTarget
    this.onClick()
    if (target && window.PasswordCredential) {
      try {
        //var c = new window.PasswordCredential(target);
        //return navigator.credentials.store(c);
      } catch { }
    }
  }

  registration = (region) => {
    let class_id = undefined;

    if (window.location.href.includes('/studio/')) {
      class_id = window.location.href.split('/studio/').pop().replace('class-', '').split('?')[0];
    }

    const email = 'anonymous@ume.academy'

    var self = this;
    axios.post(getApiEndpoint('/registration'), {
      username: this.state.login,
      password: this.state.password,
      email: email,
      class_id: class_id,
      region: region,
      avatar: avatars.random(),
      subscribe: this.state.subscribe,
      subscriptionCode: this.state.subscriptionCode
    })
      .then(function (response) {
        if (response.data.status === 'success' || (class_id === undefined && response.data.password)) {
          self.setState({ password: response.data.password, status: 'success' }, self.onClick);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  handleSimpleSubmit = (event) => {
    /**
        Following should FAIL:
            "bk 0216 1" // space
            "bk	0216" // tab
            "bk\n0216" // \ symbol
            "rock3t/ship" // / symbol
            "bk@abc" // @ symbol
            "_______" // no letter
            "-_-_-_-" // no letter
            "ab3" // too short
            "a234567890123" // too long
            "abcde" // no number
            "abcd123 " // space at end
            " abcd123" // space at front

        Following should PASS:
            "bk0216-"
            "b1_-_-_-"
            "b1______"
            "----b1----"
     */
    event.preventDefault();

    if (this.state.status === 'send') {
      return;
    }
    if (!this.state.login) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Enter a valid login name.' })
    }
    if (this.state.login.replace(/^\s+|\s+$/g, "") !== this.state.login) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Login has a space at the start or end, please fix.' })
    }
    const value = this.state.login;
    const expWhiteSpace = new RegExp('\\s');
    const expAlpha = new RegExp('^([A-Za-z0-9-_]){5,12}$');
    // const expAlpha = new RegExp('^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$'); // NOTE: This forces alphanumeric at front and end of string.
    const expLetters = new RegExp('[A-Za-z]');
    const expNumbers = new RegExp('[0-9]');
    const expEmail = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
    if (value.length < 5) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Too short; you need at least 5 letters/numbers!' })
    }
    if (value.length > 12) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Too long; you can have at most 12 letters/numbers!' })
    }
    if (expWhiteSpace.exec(value)) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: "Your username can't have any spaces!" })
    }
    if (!expLetters.exec(value)) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Your username must have at least 1 letter!' })
    }
    if (!expNumbers.exec(value)) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Your username must have at least 1 number!' })
    }
    if (!expAlpha.exec(value)) {
      return this.setState({ status: 'error', login_status: 'invalid', login_error: 'Usernames can only have letters, numbers, _, and -' })
    }
    if (this.state.email && !expEmail.exec(this.state.email)) {
      return this.setState({ email: this.state.email, email_error: true, email_msg: 'Email address is not valid.' })
    }

    event.persist() // this is for browser to secretly store the password
    let target = event.currentTarget

    this.setState({ status: 'send', label: 'Checking...' })
    var self = this;
    axios.post(getApiEndpoint('/checkusername'), {
      username: this.state.login,
      password: this.state.password
    })
      .then(function (response) {
        if (!response.data.exists || response.data.owner) {
          // if (!response.data.exists && !self.state.email && !self.state.email_optional) {
          //   return self.setState({ email_optional: true, status: null, label: 'Continue' })
          // }

          return self.setState({ password_required: response.data.owner, status: 'send', password_status: 'valid', label: 'Joining...' }, () => {
            // store the password in the browser
            if (target && window.PasswordCredential) {
              try {
                //var c = new window.PasswordCredential(target);
                //navigator.credentials.store(c);
              } catch { }
            }

            // ping closest region and store with registration
            AWSPingAvg((pingResults) => self.registration(pingResults[0].name))
          })
          // the account exists and has been verified with the password, login          

        }
        if (response.data.exists && !response.data.owner) {
          return self.setState({ password_required: true, label: 'Continue', status: 'error', password_status: 'invalid', password_error: 'Login exists, enter your password or pick a new username!' })
        }
      })
      .catch(function (error) {
        console.log(error);
      });


  }

  createAccount = () => {
    this.props.onStateChange('signUp')
  }
  forgotPassword = () => {
    this.props.onStateChange('requireNewPassword')
  }
  handleConfirmationCode = (event) => {
    event.preventDefault();
    var code = this.state.code
    this.props.confirmSignIn(this.state.login, code, (response) => {
    })
  }
  render_confirmSignUp() {
    return (
      <div className="CreateUser__wrapper">
        <form ref={this.form} id="UME_ConfirmSignIn_Form" onSubmit={this.handleConfirmationCode}>

          <Input label="Code Received"
            placeholder={"Enter code that was sent to " + this.state.phone}
            message={this.state.code_msg}
            type="txt"
            invalid={this.state.code_error}
            onChange={(value) => this.validate_code(value)}
          />
          <div className="action_btn">
            <Button
              label="Next"
              callback={this.handleConfirmationCode}
            />
          </div>
        </form>
      </div>
    )
  }
  render_signIn() {
    return (
      <div className="UME_SignIn_Form">
        <form ref={this.form} id="UME_SignIn_Form" onSubmit={this.handleSubmit}>
          <Input label="Login Name"
            placeholder="Enter your login name"
            autoComplete="username email"
            name="username"
            id="username"
            value={this.state.login}
            message={this.state.login_error}
            invalid={(this.state.login_status === 'invalid')}
            onChange={(value) => this.validate_login(value)}
          />
          <Input label="Password"
            message={this.state.password_error}
            placeholder="Enter your password"
            invalid={(this.state.password_status === 'invalid')}
            password={true}
            value={this.state.password}
            name="current-password"
            id="ap_password"
            type="password"
            autoComplete="current-password"
            onChange={(value) => this.validate_password(value)}
          />

          <div className="buttonRow">
            <Button label={this.props.status === 'success' ? this.props.message : this.state.label ? this.state.label : 'Login'} />
          </div>
        </form>
      </div>
    )
  }
  render_simpleSignIn() {

    const password = (<Input label="Password"
      autofocus={true}
      message={this.state.password_error}
      invalid={(this.state.password_status === 'invalid')}
      password={true}
      value={this.state.password}
      name="current-password"
      id="ap_password"
      type="password"
      autoComplete="current-password"
      onChange={(value) => this.validate_password(value)}
    />)

    const email = (
      <div>
        <div className="email__wrapper">
          <div>By entering your email you can:</div>
          <ul className="checklist">
            <li>access your child's work portfolio</li>
            <li>receive updates on your child's learning progress after each class</li>
            <li>choose your own avatar</li>
            <li>receive exclusive offers for UME programs</li>
          </ul>
        </div>
        <Input label="Email (Optional)"
          autofocus={true}
          placeholder=""
          message={this.state.email_msg}
          type="email"
          invalid={this.state.email_error}
          autoComplete="username email"
          name="email"
          id="email"
          onChange={(value) => this.validate_email(value)}
        />
        <div className="checkboxLayout">
          <input className="checkbox" type="checkbox" id="subscribe" name="subscribe" onClick={this.validate_subscribe} />
          <label htmlFor="subscribe">Yes, it's okay to send me emails</label>
        </div>
        <div className="privacy">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://ume.academy/privacy.html" target="_blank" >Privacy policy</a>
        </div>
      </div>)

    const button = (
      <UMEButton>
        {this.props.status === 'success' ? this.props.message : this.state.label ? this.state.label : 'Login'}
      </UMEButton>
    )
    return (
      <div className="UME_SignIn_Form">
        <form ref={this.form} id="UME_SignIn_Form" onSubmit={this.handleSimpleSubmit}>
          <Input label="Enter a NEW or Existing user name"
            placeholder=""
            autoComplete="username email"
            name="username"
            id="username"
            value={this.state.login}
            message={this.state.login_error}
            invalid={(this.state.login_status === 'invalid')}
            onChange={(value) => this.validate_login(value)}
          />
          {this.state.password_required ? password : ''}

          <div className="buttonRow">
            {this.state.label ? button : ''}

          </div>
        </form>
      </div>
    )
  }
  render() {
    return ['studio', 'simple-sign-in'].includes(this.props.activity.action) ? this.render_simpleSignIn() : this.render_signIn()
  }
}

export default SignIn;
