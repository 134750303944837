import React from 'react';

class SubscriptionPlan {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.details = data.details;
    this.cost = data.cost;
    this.productID = data.productID;
    this.promo = data.promo;
  }

  get costString() {
    if (this.cost === 0) {
      return 'FREE';
    }

    if (this.cost < 0) {
      return 'COMING SOON';
    }

    return `$${this.cost / 100} USD`
  }

  get url() {
    return `/subscribe/${this.id}`;
  }

  isOwned(subscriptions) {
    if (!subscriptions) {
      return false;
    }

    if (this.productID === null) {
      return subscriptions.size === 0;
    }

    return subscriptions.has(this.productID);
  }

  toAnalyticsItem() {
    return {
      item_id: this.productID,
      item_name: this.name,
      item_variant: this.id,
      price: this.cost / 100,
      currency: 'USD'
    };
  }
}

export const ALL_PLANS = [
  new SubscriptionPlan({
    id: 'entry',
    productID: null,
    name: 'Free',
    details: [
      <span><strong>INTRO Video Lessons &amp; Projects</strong></span>,
      <span>MAKE Access: 1hr/week</span>,
      <span>Basic Avatar pack</span>
    ],
    cost: 0
  }),
  new SubscriptionPlan({
    // TODO: Load this plan dynamically
    id: 'P-8SG93626PC735344MMHAJA5A',
    productID: 'ume-creator-8675',
    name: 'Creator',
    details: [
      <span><strong>ALL Video Game Design courses</strong></span>,
      <span>MAKE Access: UNLIMITED</span>,
      <span>Creator Avatar pack (coming soon)</span>
    ],
    cost: 999,
    promo: '2 Weeks Free Trial'
  })
];

export default SubscriptionPlan;
