import clsx from 'clsx';
import './styles.scss';

const BaseCard = ({
  className,
  cardClassName,
  backgroundImage,
  title,
  children,
  footer,
  cta
}) => (
  <div className={clsx('badge-wrapper', className)}>
    <div className="membership-card__pill">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="72" viewBox="0 0 2 3">
        <rect width="2" height="3" fill="currentColor" ry="1" />
      </svg>
    </div>

    <article className={clsx('membership-card', cardClassName, !footer && 'membership-card--no-footer')}>
      {backgroundImage && <img src={backgroundImage} alt="" className="membership-card__background" />}

      <h3 className="membership-card__title">
        {title}
      </h3>

      {children}

      {footer && (
        <footer className="membership-card__footer">
          {footer}
        </footer>
      )}
    </article>

    <div className="badge-wrapper__button-row">
      {cta}
    </div>
  </div>
);

export default BaseCard;
