import { useEffect, useMemo, useState } from 'react';

import LaunchLabButton from 'components/LaunchLabButton';
import editIcon from 'assets/images/icons/anvil';
import { useActivity } from 'contexts/activityContext';
import { updatePublish } from 'api';

import Tooltip from '../Tooltip';
import TextField from '../TextField';

import './styles.scss';

const Form = () => {
  const [recentlySent, setRecentlySent] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    description: ''
  });
  const { activity, forceUpdate } = useActivity();

  const dirty = useMemo(() => {
    if (!activity || recentlySent) {
      return false;
    }

    return Object.entries(formData).some(([ key, value ]) => {
      if (value === '') {
        return !!activity.metadata[key];
      }

      return activity.metadata[key] !== value;
    });
  }, [formData, activity, recentlySent]);

  // Initialize form data whenever we get a new activity
  useEffect(() => {
    if (!activity) {
      return;
    }

    setRecentlySent(false);
    setFormData({
      name: activity.title,
      description: activity.description
    });
  }, [activity]);

  const handleChange = event => {
    setFormData(data => ({
      ...data,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    setRecentlySent(true);
    await updatePublish(activity, formData, {
      reindex: activity.isBuilt()
    });

    forceUpdate();
  };

  return (
    <form autoComplete="off" className="activity-edit-form" onSubmit={handleSubmit}>
      <input type="hidden" autoComplete="false" />
      <div className="activity-edit-form__header">
        <LaunchLabButton activity={activity} className="cl-button">
          <img src={editIcon.hoverState} alt="" className="cl-button__icon cl-button__icon--hover" />
          <img src={editIcon.defaultState} alt="" className="cl-button__icon" />
          Edit Game
        </LaunchLabButton>

        <button type="submit" disabled={!dirty} className="cl-button">
          Save Changes
        </button>
      </div>

      <div>
        <div className="activity-edit-form__label-container">
          <p className="activity-edit-form__label">
            Title Image
          </p>
          <Tooltip>
            <p>Customize the Title Screen thumbnail of your game (<a href="https://youtu.be/Qw_vLzGqTFw" target="_blank" rel="noopener noreferrer">see this tutorial video</a>).</p>
            <p><strong>This will only work for projects started after February 23<sup>rd</sup>, 2022.</strong></p>
          </Tooltip>
        </div>
        <img
          src={activity?.thumbnail}
          alt="Project Thumbnail"
          className="activity-edit-form__thumbnail"
        />
      </div>

      <div className="game-play-link">
        <p className="game-play-link__label">Game play link</p>
        <p className="game-play-link__link">https://ume.games/play/{activity?.id}</p>
      </div>

      <TextField
        label="Game Title"
        name="name"
        value={formData.name}
        onChange={handleChange}
        limit={30}
        tooltip="What should we call your game? Pick something awesome!"
      />

      <TextField
        textarea
        label="Description"
        name="description"
        value={formData.description}
        onChange={handleChange}
        limit={500}
        tooltip="Tell us about your game! How did you make it? What are the rules? Is there anything you're especially proud of?"
      />

      <div className="activity-edit-form__footer">
        <button type="submit" disabled={!dirty} className="cl-button">
          Save Changes
        </button>
      </div>
    </form>
  );
};

export default Form;
