
import React, { Component } from 'react';
import clsx from 'clsx';

import './styles.scss';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.autofocus) {
            this.inputRef.current.focus()
        }
    }

    handleChange = (event) => {
        var value = event.target.value
        this.setState({ value: value }, () => {
            this.props.onChange && this.props.onChange(this.state.value)
        })
    }

    render() {
        var classes = {
            input: clsx('', this.props.lock && 'locked', this.state.value && 'valid', this.props.invalid && 'invalid'),
            label: clsx('label', this.props.label && 'show'),
            message: clsx('message', this.props.invalid && 'show'),
            lockLabel: clsx('message', this.props.lock && 'show'),
        }
        return (
            <div className="input__wrapper">
                <div className="placement">
                    <div className="sizing">
                        <div className={classes.label}>
                            <div className="text">{this.props.label}</div>
                        </div>
                        <input ref={this.inputRef}
                            className={classes.input}
                            type={this.props.type}
                            placeholder={this.props.value ? this.props.value : this.props.placeholder}
                            value={this.state.value}
                            autoComplete={this.props.autocomplete}
                            name={this.props.name}
                            id={this.props.id}
                            pattern={this.props.pattern}
                            onChange={this.handleChange}
                            disabled={this.props.lock ? true : false}

                        />
                        <div className={classes.message}>
                            <div className="text">{this.props.message}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Input;

