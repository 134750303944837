import { API } from "aws-amplify";

import { post } from "graphql/handler";

import { v4 as uuidv4 } from "uuid";

import ddbStamper from 'pages/Studio/helpers/DDBStamper'
import { TIME } from "helpers/datetime";
import {
    MAX_SUBSCRIPTION_RECONNECTION_ATTEMPTS,
    MAX_SUBSCRIPTION_HEALTH_CHECK_FAILURES,
    MAX_SUBSCRIPTION_INITIAL_CONNECTION_ATTEMPTS,
} from "helpers/defaults";


class Subscription {
    constructor(event_id, graphqlSubscriptionOperation, graphqlMutationOperation, type = "") {
        console.log("-sub Initializing Subscription: ", type, event_id)

        this.event_id = event_id
        this.subscriptionSessionId = type ? `${type}:${event_id}:${uuidv4()}` : `${event_id}:${uuidv4()}`;
        this.type = type

        this.graphqlSubscriptionOperation = graphqlSubscriptionOperation

        this.graphqlPingOperation = JSON.parse(JSON.stringify(graphqlMutationOperation))
        this.graphqlPingOperation.variables.input.arguments = JSON.stringify({ping: this.subscriptionSessionId,})

        this.graphqlHealthCheckOperation = JSON.parse(JSON.stringify(graphqlMutationOperation))
        this.graphqlHealthCheckOperation.variables.input.arguments = JSON.stringify({health: this.subscriptionSessionId,})


        this.subscription = null;
        this.isSubscriptionActive = false;

        this.healthCounter = 0;
        this.totalReconnections = 0;
        this.numPings = 0;

        this.healthCounterTimeout = null;
        this.subscriptionSetupPingInterval = null;

        this.useNewAWSRealTimeProvider = false;
        this.dataCallback = null;
    }

    // Public class methods
    start = async (dataCallback, postSubscriptionSetupCallback = null) => {
        // Cache the callback to use it upon a reinitialization
        this.dataCallback = dataCallback;
        this.postSubscriptionSetupCallback = postSubscriptionSetupCallback;

        window.configure_amplify("studio_endpoint", this.useNewAWSRealTimeProvider);

        // Setup the subscription
        this.subscription && this.stop();

        this.subscription = await API.graphql(this.graphqlSubscriptionOperation).subscribe({
            error: (err) => {
                console.error("-sub SUBSCRIPTION ERROR: ", err);
                // FLAG: Write out to the database or log somewhere that there was an error returned on the subscription
            },
            next: (event) => {
                // console.log("-sub Raw Event (any): ", event)
                // Parse the payload
                if (!event || !event.value || !event.value.data) return

                const subscriptionName = Object.keys(event.value.data)[0];

                if (!event.value.data[subscriptionName] || !event.value.data[subscriptionName].arguments) return

                const args = { ...JSON.parse(event.value.data[subscriptionName].arguments) };
                if (!args) return;

                // Ping check (checks when the subscription has fully initialized and calls any post subscription setup initialization code)
                if (args.ping) {
                    const incomingSubscriptionSessionId = args.ping;
                    
                    // Check it must not be a straggling ping or someone elses ping
                    if (!this.isSubscriptionActive && incomingSubscriptionSessionId === this.subscriptionSessionId) {
                        this.isSubscriptionActive = true;
                        clearInterval(this.subscriptionSetupPingInterval);

                        // Once we hear a setup ping back call any post subscription setup functions and begin the health check for the subscription
                        postSubscriptionSetupCallback && postSubscriptionSetupCallback();
                        this._conductHealthCheck();
                    }

                    return;
                }

                // Health check (happens every X seconds to see that the subscription is still active and handles reconnecting if it goes down)
                if (args.health) {
                    const incomingSubscriptionSessionId = args.health;
                    
                    if (incomingSubscriptionSessionId === this.subscriptionSessionId) {
                        this.healthCounter -= 1;
                    }

                    return;
                }


                // Finally, pass the data to the callback
                dataCallback(event);
            },
        });

        if (this.type) window[this.type] = this.subscription // FLAG: Remove before merging

        // Use a ping to probe when the subscription has been initialized
        this.subscriptionSetupPingInterval = setInterval(() => {
            if (this.numPings > MAX_SUBSCRIPTION_INITIAL_CONNECTION_ATTEMPTS) {
                this.stop();
                console.error("-sub Max subscription initial connection attempts reached, pinged out");
            } else {
                this.numPings += 1;
                window.configure_amplify("studio_endpoint") 
                post(this.graphqlPingOperation);
            }
        }, TIME.ONE_SECOND);
    };

    stop = () => {
        // Reset the subscription connection helper state variables
        this.healthCounterTimeout && clearTimeout(this.healthCounterTimeout);
        this.healthCounterTimeout = null;
        this.healthCounter = 0;

        this.numPings = 0;
        this.subscriptionSetupPingInterval && clearInterval(this.subscriptionSetupPingInterval);
        this.subscriptionSetupPingInterval = null;

        // Unsubscribe from the subscription
        this.useNewAWSRealTimeProvider = false;
        this.subscription.unsubscribe();
        this.subscription = null;
        this.isSubscriptionActive = false;
    };

    // Private class methods
    _conductHealthCheck = () => {
        this.healthCounter += 1;

        if (this.healthCounter >= MAX_SUBSCRIPTION_HEALTH_CHECK_FAILURES) {
            console.error("-sub Unable to reach the subscription, attempting to reconnect...");
            this.totalReconnections += 1;

            // Write out to the database that a subscription health check failed
            ddbStamper.stampFrontendError(this.event_id, { "subscription error": this.subscription, "info": "Unable to reach the subscription, attempting to reconnect..." })

            // Check if the user has maxed the allowed reconnection tries to prevent against an infinite recursion
            if (this.totalReconnections >= MAX_SUBSCRIPTION_RECONNECTION_ATTEMPTS) {
                console.error("-sub Exceeded total subscription session reconnections");
                
                // Write out to the database that max reconnection attempts were reached
                ddbStamper.stampFrontendError(this.event_id, { "subscription error": this.subscription, "info": "Exceeded total subscription session reconnections" })
                
                this.stop();
                return;
            }

            // Finally, reconnect
            this.useNewAWSRealTimeProvider = true;
            this.start(this.dataCallback, this.postSubscriptionSetupCallback);
        } else {
            // Use the mutation endpoint provided to send a health check on
            window.configure_amplify("studio_endpoint") 
            post(this.graphqlHealthCheckOperation).then((_response) => {
                // After we send a healthcheck queue up another
                this.healthCounterTimeout = setTimeout(() => {
                    this._conductHealthCheck();
                }, TIME.TEN_SECONDS);
            });
        }
    };
}

export default Subscription;
