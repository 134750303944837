import React from 'react';
import clsx from 'clsx';
import './styles.scss';

const Loader = ({ light = false }) => {
  const styles = clsx({
    'loader': true,
    'loader--bounce': true,
    'loader--light': light
  });

  return (
    <div
      role="progressbar"
      aria-valuetext="Working..."
      aria-busy="true"
      className={styles}
    >
      <span />
      <span />
      <span />
    </div>
  );
};

export default Loader;
