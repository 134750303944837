import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import PortfolioGrid from '../PortfolioGrid'

import { find_games } from 'helpers/algolia';

import './styles.scss';


class Portfolio extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentSlider: -1,
            classGames: []
        }

        this.sliderCount = 0
        this.GAME_RETRIEVAL_COUNT = 25
    }

    componentDidMount() {
        const id = this.getPreviousClassId()
        this.getClassGames(id)
    }

    componentDidUpdate(prevProps, prevState) {

    }

    getPreviousClassId = () => {
        // console.log("-pf class_id", this.props.class_id)
        // console.log("-pf aggregate", this.props.aggregate)

        // Check if a parent id exists
        if (this.props.aggregate && !this.props.aggregate.parent_activity_id) {
            // console.log("-pf no parent activity")
            return null
        }

        // Check if its an on-the-fly class
        if ((this.props.class_id.match(new RegExp("-", "g")) || []).length === 1) {
            // console.log("-pf its an on the fly class")
            return null
        }

        // Form the previous class id
        const classSession = this.props.class_id.substr(0, this.props.class_id.lastIndexOf('-')) 
        const previousClassId = classSession + "-" + this.props.aggregate.parent_activity_id

        // console.log("-pf pci: ", previousClassId)
        return previousClassId

    }

    getClassGames = (classId) => {
        if (classId === null) {
            // console.log("-pf null pci")
            return
        }

        find_games(classId, this.GAME_RETRIEVAL_COUNT, results => {
            // console.log("-pf Class games: ", results)
            
            // Check if the game object has the required attributes
            const filteredResults = results.filter((result) => this.verifyGameAttributes(result))
            // console.log("-pf Class games filtered: ", filteredResults)
            
            this.setState({ classGames: filteredResults })
        })
    }

    verifyGameAttributes = (gameSearchResult) => {
        return (
            // The fields used for associating a user to a game built from a lesson
            gameSearchResult.public &&
            gameSearchResult.public.identity &&
            gameSearchResult.public.username &&
            gameSearchResult.public.class_id &&

            // Default fields all games have
            gameSearchResult.title &&
            gameSearchResult.thumbnail &&
            gameSearchResult.video &&
            gameSearchResult.webgl &&

            // General fields
            gameSearchResult.id &&
            gameSearchResult.objectID &&

            // FLAG: Extra fields created by the front-end by the Alogia helper find_games method (get rid of these)
            gameSearchResult.game &&
            gameSearchResult.game.name &&
            gameSearchResult.game.id 
        )

    }

    handleSliderSelect = sliderId => {
        this.setState({ currentSlider: sliderId });
    }

    render() {
        // const this_student = this.props.students.find(s => s.identity === this.props.identity)

        const classes = {
            main: clsx('Portfolio__wrapper ')

        }

        this.sliderCount = 0

        // TODO: Make the search here for games

        return (

            <div className={classes.main} >
                <PortfolioGrid
                    key={this.sliderCount}
                    featured={false}
                    sliderId={this.sliderCount++}
                    currentSlider={this.state.currentSlider}
                    artifacts={this.state.classGames}
                    onSelect={this.handleSliderSelect}
                    vertical={true}
                />
            </div>
        );
    }
}


const mapStateToProps = state => ({
    identity: state.auth.identity,
    screen_id: state.studioScreen.screen_id,
    students: state.studioStudents.students,
    currentArtifact: state.artifact,
});

export default connect(mapStateToProps, null)(Portfolio);
