import { Link } from 'react-router-dom';
import clsx from 'clsx';

const Breadcrumbs = ({ breadcrumbs, className }) => {
  const links = breadcrumbs.map(({ name, uri }, i) => {
    const isCurrent = i === breadcrumbs.length - 1;
    const styles = clsx(
      'breadcrumb__item',
      isCurrent && 'breadcrumb__item--current'
    );

    return (
      <Link
        to={uri}
        className={styles}
        key={i}
        aria-current={isCurrent ? 'page' : false}
      >
        {name}
      </Link>
    );
  });

  return (
    <div
      className={clsx('breadcrumb', className)}
      role="navigation"
      aria-label="breadcrumbs"
    >
      {links}
    </div>
  );
};

export default Breadcrumbs;
