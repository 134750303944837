import OnState from './MAKE-ON.png';
import OffState from './MAKE-OFF.png';
import HoverState from './MAKE-HOVER-ANIM.gif';

const make = {
  onState: OnState,
  offState: OffState,
  hoverState: HoverState
};

export default make;
