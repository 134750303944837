import { useSelector } from 'react-redux';
import Layout from 'components/Layout';
import Header from './components/Header';
import Card from './components/Card';

import UMEPlayLogo from './assets/ume-play.png';
import UMEInstructorsLogo from './assets/ume-Instructors.png';
import UMEJourneyLogo from './assets/ume-journey.png';
import KidSafeLogo from './assets/kidsafe.png';
import './styles.scss';

const Home = () => {
  const { authenticated } = useSelector(state => state.auth);

  return (
    <div>
      {!authenticated && (
        <div className="home-top-bar">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          Welcome to UME Games. <a href="https://ume.academy" target="_blank">Are you an educator?</a>
        </div>
      )}
      <Layout className="home" showHeader={authenticated} showWatermark={false}>
        <Header />

        <section className="home-card-section">
          <h2 className="home-card-section__title">
            Join a community of gamers &amp; creators!
          </h2>

          <div className="home-card-section__container">
            <Card title="The Games" image={UMEPlayLogo}>
              <p>
                Learn to make games in Unity, the world's most popular game engine, used to create games like Among Us and Pokémon Go!
              </p>
            </Card>

            <Card title="The Instructors" image={UMEInstructorsLogo}>
              <p>
                Learn from and with our amazing team of UME Academy instructors and mentors.
              </p>
              <p>
                Build your skills with video lessons or join a <strong>live</strong> class and <strong>learn</strong> with and from our instructors and other creators in our virtual UME Studio.
              </p>
            </Card>

            <Card title="The Journey" image={UMEJourneyLogo}>
              <p>
                Journey as a game creator alongside kids ages 7 - 14 in our inclusive and safe community.
              </p>

              <p>
                Get started right away with our skill building courses, lessons, and projects!
              </p>
            </Card>
          </div>
        </section>

        <section className="home-kid-safe-section">
          <img src={KidSafeLogo} alt="" className="home-kid-safe-section__image" />
          <h2 className="home-kid-safe-section__title">
            Our Kid Safe Pledge
          </h2>
          <p className="home-kid-safe-section__body">
            Children's safety is our priority and that's why we've designed our products and services in alignment with all COPPA requirements. We do not store or use any personal data.
          </p>
        </section>
      </Layout>
    </div>
  );
};

export default Home;
