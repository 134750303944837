import OnState from './LEARN-ON.png';
import OffState from './LEARN-OFF.png';
import HoverState from './LEARN-HOVER-ANIM.gif';

const learn = {
  onState: OnState,
  offState: OffState,
  hoverState: HoverState
};

export default learn;
