import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { LOBBY_SCREEN, WATCH_SCREEN, CREATE_SCREEN, GRIDVIEW_SCREEN } from '../../types';

import LobbyView from '../LobbyView';
import LoadingScreen from '../LoadingScreen';
import GridView from '../GridView';
import GameModal from './GameModal'
import PlayParty from './PlayParty';
import Portfolio from './Portfolio';

import './styles.scss';
import DCVHelper from '../../helpers/DCVHelper'
import { IFrame } from './IFrame'
import vmBroken from 'assets/images/vmBroken.png'




/*
    classmate icon can do the following
    1. Show notification if they have something to share
    2. Indicate they are speaking
    3. Indicate if they raised their hand to answer
    4. Indicate if they are controlling your iframe
    you can view something that they shared

    if You are an instructor the classmate icon can also
    1. you can assign control of your screen for demo purposes
    2. you can mute their mic
    3. you can have a 1:1 conversation
*/
class Screen extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }

        this.dcvHelper = new DCVHelper()
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.screen_id !== this.props.screen_id) {
            this.slider.slickGoTo(this.props.screen_id)
            this.muteScreenAudio()
        }
    }

    muteScreenAudio = () => {
        // If on watch then mute create OR if on create then mute watch OR if on lobby or game mute both

        try {
            document.getElementById('play_iframe').blur()
        } catch (error) {
            // Do nothings
        }

        // Catch errors related to non-initialized iframes and CORS when running localhost and catch different errors related to DCV
        try {
            if (this.props.screen_id === WATCH_SCREEN) {
                document.getElementById('create_iframe').contentWindow.dcvConnection.setVolume(0);
                document.getElementById('watch_iframe').contentWindow.dcvConnection.setVolume(100);
            } else if (this.props.screen_id === CREATE_SCREEN) {
                document.getElementById('create_iframe').contentWindow.dcvConnection.setVolume(100);
                document.getElementById('watch_iframe').contentWindow.dcvConnection.setVolume(0);
            } else {
                document.getElementById('create_iframe').contentWindow.dcvConnection.setVolume(0);
                document.getElementById('watch_iframe').contentWindow.dcvConnection.setVolume(0);
            }

        } catch (error) {
            // console.log("-mb DCV - could not adjust volume - error: ", error)
            return 1
        }

    }

    renderPlayTab = () => {
        if (!this.props.games || !this.props.games.length) {
            return (<div />)
        } else if (this.props.games.length === 1) {
            return (
                <GameModal scale={this.props.scale} currentArtifact={this.props.games[0]} />
            )
        } else {
            return (
                <PlayParty gorpGames={this.props.games} />
            )
        }

    }

    render() {
        // Slider settings
        const settings = {
            accessibility: false,
            fade: true,
            dots: false,
            speed: 150,
            centerMode: false,
            initialSlide: 0,
            slidesToShow: 1,
            variableWidth: false,
            infinite: false,
            arrows: false,
            slidesToScroll: 0,
            swipe: false,
            touchMove: false,
            swipeToSlide: false,
        };


        // Data for various students and instructor
        const this_student = this.props.students.find(s => s.identity === this.props.identity)
        const viewingStudent = this.props.students.find(s => s.viewScreen === true)
        const instructorData = this.props.students.find((s) => s.instructor)


        // Setting styling based on different modes and variables the students and the Studio are in
        const viewScreen = this_student && this_student.viewScreen
        const controlWatch = this_student && this_student.controlWatch
        const controlCreate = this_student && this_student.controlCreate
        const controlViewScreen = viewingStudent && !viewingStudent.controlCreate // When zoomed in only allow control to instructor when taken from student
        const controlGrid = viewingStudent === undefined // When zoomed out and viewing nobody then allow control to grid
        const create_status = this_student && this_student.create_status
        const create_url = this_student && this_student.create_url

        const classes = {
            watchView: clsx('screen_frame watch', controlWatch && 'control'),
            createView: clsx('screen_frame create', controlCreate && 'control'),
            gridView: clsx('screen_frame gridview', viewScreen && 'watching', (controlGrid || controlViewScreen) && 'control'),
            lobbyView: clsx('screen_frame lobbyview'),
            playView: clsx('screen_frame playView'),
            portfolioView: clsx('screen_frame portfolioView'),
            main: clsx('Screen__wrapper ',
                [LOBBY_SCREEN, WATCH_SCREEN, CREATE_SCREEN, GRIDVIEW_SCREEN].indexOf(this.props.screen_id) > -1 && 'border',
                (!controlWatch && WATCH_SCREEN === this.props.screen_id) && 'disableControl',
                (!controlCreate && CREATE_SCREEN === this.props.screen_id) && 'disableControl',

            ),

        }


        // When not in control remove the focus from the watch iframe so other person cannot continue typing
        if (!controlWatch) {
            if (document.getElementById("watch_iframe")) {
                try {
                    document.getElementById("watch_iframe").blur()
                }
                catch (error) {
                    return
                }
            }
        }


        // Setting the watch and create iFrame src urls
        // If the watch_url prop is passed in (set Studio wide by instructor) then use it, otherwise use the instructor's create_url in the watch tab and if thats not availible then use the students create_url
        const watchIFrameUrl = this.props.watch_url ? this.dcvHelper.transformUrl(this.props.watch_url) : (instructorData && instructorData.create_url) ? this.dcvHelper.transformUrl(instructorData.create_url) : this.dcvHelper.transformUrl(create_url)
        const createIFrameUrl = create_url ? this.dcvHelper.transformUrl(create_url) : ''

        return (

            <div className={classes.main} >
                < Slider
                    ref={slider => (this.slider = slider)}
                    {...settings}
                >
                    <div className={classes.lobbyView} >
                        <LobbyView data={this.props} />
                    </div>

                    <div className={classes.watchView} >
                        <iframe id="watch_iframe"
                            width={'100%'}
                            height={'100%'}
                            key={watchIFrameUrl + "-public"}
                            title="Watch"
                            className="watch_iframe enableIframe"
                            src={watchIFrameUrl}
                        />
                    </div>

                    <div className={classes.createView}>
                        {["waiting", "request", "error", "error:no_machines_available"].includes(create_status) ?
                            <div
                                id="create_iframe"
                                key={createIFrameUrl + "-private"}
                                style={{ height: '100%', width: '100%' }}
                                title="Create"
                                className="create_iframe"
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    {
                                        create_status === "waiting" || create_status === "request" ?
                                            <LoadingScreen /> :
                                            create_status === "error:no_machines_available" ? <h1 style={{ maxWidth: "60%", textAlign: "center" }}>We're sorry, there are no cloud computers available at this time. Please inform an instructor or try again later!</h1> :
                                                <img src={vmBroken} alt="Broken computer" />
                                    }
                                </div>
                            </div>
                            :
                            <IFrame
                                id="create_iframe"
                                key={create_url + "-private"}
                                width={'100%'}
                                height={'100%'}
                                title="Create"
                                className="create_iframe"
                                src={createIFrameUrl}
                            />
                        }
                    </div>

                    <div className={classes.playView}>
                        {this.renderPlayTab()}
                    </div>

                    <div className={classes.gridView}>
                        {this.props.instructor && <GridView class_id={this.props.class_id} action_callback={this.props.action_callback} />}
                    </div>

                    <div className={classes.portfolioView}>
                        {/* FLAG: Change to checking this.props.aggregate since this.props.games is a subset */}
                        {this.props.games && this.props.games.length &&
                            <Portfolio class_id={this.props.class_id} aggregate={this.props.aggregate} />
                        }
                    </div>
                </Slider>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    identity: state.auth.identity,
    screen_id: state.studioScreen.screen_id,
    students: state.studioStudents.students,
});

export default connect(mapStateToProps, null)(Screen);
