import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useLesson, useYoutubeMetadata } from 'hooks';
import { LaunchLabProvider } from 'contexts/launchLabContext';
import { tags } from 'models/constants';
import Layout from 'components/Layout';
import LaunchLabButton from 'components/LaunchLabButton';
import LoginLink from 'components/LoginLink';
import StartLabModal from 'components/StartLabModal';
import Video from 'components/Video';
import Banner from './components/Banner';
import editIcon from 'assets/images/icons/anvil';
import './styles.scss';

/**
 * Main react component for the Lesson page. Displays the information about a
 * lesson, including its video, description, and bookmarks.
 */
const Lesson = () => {
  const { authenticated, subscriptions } = useSelector(state => state.auth);
  const { id: lessonID } = useParams();
  const ref = useRef();

  const { lesson } = useLesson(lessonID);
  const { bookmarks, duration } = useYoutubeMetadata(lesson && lesson.videoID);

  const breadcrumbs = [
    {
      name: 'Learn',
      uri: '/learn'
    },
    {
      name: lesson?.title,
      uri: '#'
    }
  ];

  const canStartProject = lesson && (!lesson.tags.has(tags.MEMBERSHIP_REQUIRED) || (subscriptions && subscriptions.size > 0));
  const launchText = lesson?.isExtension() ? 'Continue' : 'Start Project';

  return (
    <Layout tab="learn" breadcrumbs={breadcrumbs} fitContent>
      <LaunchLabProvider>
        <section className="lesson-page">
          <Banner lesson={lesson} duration={duration} />

          <h2 className="lesson-page__title" id="video-landmark">
            Video lesson &amp; project
          </h2>

          <Video
            locked={!canStartProject}
            src={lesson && lesson.videoID}
            ref={ref}
            className="lesson-page__video"
          />

          <div className="lesson-page__left-column">
            {lesson && lesson.description && (
              <details className="lesson-section lesson-section--expandable" open>
                <summary className="lesson-section__title">
                  Learning Objectives
                </summary>

                <p>
                  {lesson.description}
                </p>
              </details>
            )}

            <details className="lesson-section lesson-section--expandable">
              <summary className="lesson-section__title">
                How to start
              </summary>

              <p>
                <span className="emoji" role="img" aria-label="Computer">💻</span> Use a computer that has a mouse or track pad
              </p>

              <p>
                <span className="emoji" role="img" aria-label="Timer">⌚</span> Click on the <strong>Open Project</strong> button and wait a few minutes until your virtual computer is ready with this project opened in Unity.
              </p>

              <p>
                Press <strong>PLAY</strong> on the video and follow along with the steps.
              </p>

              <p>
                Press <strong>PAUSE</strong> on the video when you want to try it out yourself in the UME Studio.
              </p>

              <p>
                <span className="emoji" role="img" aria-label="Video game controller">🎮</span> You can play, edit, or share your game project from the <Link to="/creator-lab">Make</Link> tab.
              </p>
            </details>

            {bookmarks.length > 0 && (
              <details className="lesson-section lesson-section--expandable">
                <summary className="lesson-section__title">
                  Video Bookmarks
                </summary>

                <ol className="bookmarks">
                  {bookmarks.map(bookmark => (
                    <li key={bookmark.label} className="bookmark__item">
                      <a
                        href="#video-landmark"
                        className="bookmark"
                        onClick={() => ref.current.seek(bookmark.seconds)}
                      >
                        {bookmark.timestamp} - {bookmark.label}
                      </a>
                    </li>
                  ))}
                </ol>
              </details>
            )}
          </div>

          <div className="lesson-page__right-column">
            <div className="lesson-section">
              <h3 className="lesson-section__title">
                Lesson Resources
              </h3>

              {(authenticated || !canStartProject)
                ? (
                  <LaunchLabButton
                    activity={lesson}
                    disabled={!canStartProject}
                    className="cl-button"
                  >
                    <img src={editIcon.hoverState} alt="" className="cl-button__icon cl-button__icon--hover" />
                    <img src={editIcon.defaultState} alt="" className="cl-button__icon" />
                    {launchText}
                  </LaunchLabButton>
                ) : (
                  <LoginLink className="cl-button">
                    <img src={editIcon.hoverState} alt="" className="cl-button__icon cl-button__icon--hover" />
                    <img src={editIcon.defaultState} alt="" className="cl-button__icon" />
                    {launchText}
                  </LoginLink>
                )}
            </div>

            {/* TODO: Add this back in when we have prereqs */}
            {/*<div className="lesson-section">
              <h3 className="lesson-section__title">
                Prerequisites
              </h3>

              <Link to="#">
                Lesson 1 - Game Making
              </Link>
            </div>*/}
          </div>
        </section>

        <StartLabModal />
      </LaunchLabProvider>
    </Layout>
  );
};

export default Lesson;
