import React, { Component } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import { WATCH_SCREEN, CREATE_SCREEN, GRIDVIEW_SCREEN } from 'pages/Studio/types';
import AudioIndicator from '../AudioIndicator';
import MicIndicator from '../MicIndicator';
import LobbyType from 'pages/Studio/enums/LobbyType'


import './styles.scss';

/*
	classmate icon can do the following
	1. Show notification if they have something to share
	2. Indicate they are speaking
	3. Indicate if they raised their hand to answer
	4. Indicate if they are controlling your iframe
	you can view something that they shared

	if You are an instructor the classmate icon can also
	1. you can assign control of your screen for demo purposes
	2. you can mute their mic
	3. you can have a 1:1 conversation
*/
class StudentIcon extends Component {
    constructor(props) {
        super(props)

        this.buttonPressTimer = false
        this.participateTimer = null


        this.state = {
            selected: false,
            active: false,
            hold: false
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.subScreen_id !== this.props.subScreen_id) {
            switch (this.props.subScreen_id) {
                case LobbyType.WELCOME:
                    break;
                case LobbyType.GAME:
                    break;
                case LobbyType.SUMMARY:
                    this.props.action_callback && this.props.action_callback('participate-off', this.props.data)
                    break;
                case LobbyType.END:
                    break;

                default:
                    break
            }
        }
    }
    participate = () => {
        if (this.participateTimer) {
            // If a timer is set nullify it
            clearTimeout(this.participateTimer)
            this.participateTimer = null
        } else if (this.props.screen_id !== 0 || this.props.subScreen_id !== 1) {
            // Set timer to toggle off after X seconds
            this.participateTimer = setTimeout(() => {
                this.props.action_callback && this.props.action_callback('participate-off', this.props.data)
                this.participateTimer = null
            }, 10000)
        }

        this.props.action_callback && this.props.action_callback('participate', this.props.data)
    }

    mute = () => {
        if (this.props.data.twilioNetQuality === undefined || this.props.data.twilioNetQuality === null || this.props.data.twilioNetQuality === 0) {
            return
        }

        this.props.action_callback && this.props.action_callback('mute', this.props.data)

    }

    render_student = () => {
        const isWatchScreen = this.props.screen_id === WATCH_SCREEN
        const isGridViewScreen = this.props.screen_id === GRIDVIEW_SCREEN
        const isSpotlight = (isWatchScreen && this.props.data.broadcast) || (isGridViewScreen && this.props.data.viewScreen)
        const classes = {
            head: clsx('avatar head'),
            arm: clsx('avatar arm', this.props.data.participate && 'show'),
            cursor: clsx((this.props.data.controlWatch && !isSpotlight && isWatchScreen) && 'avatar cursor'),
            mic: clsx('avatar mic'),
            audio: clsx('avatar audio'),
            username: clsx('username'),
            spotlight: clsx('spotlight'),
            projector: clsx(((isWatchScreen || isGridViewScreen) && this.props.data.broadcast) && 'avatar projector'),
            connection: clsx('avatar connection', this.props.data.dcvStreamWarning && 'poor'),
        };

        return (
            <div>
                <div className={classes.projector} />
                <div className={classes.head} style={{ backgroundImage: 'url(' + this.props.data.avatar + ')' }} />
                <div className={classes.arm} style={this.props.data.arm ? { backgroundImage: 'url(' + this.props.data.arm + ')' } : {}} onClick={this.participate} />
                <div className={classes.cursor} />
                <div className={classes.spotlight} />
                <div className={classes.connection} />
                <div className={classes.mic} onClick={this.mute}><MicIndicator student={this.props.data} /></div>
                <div className={classes.audio}><AudioIndicator student={this.props.data} /></div>
                <div className={classes.username} >{this.props.data.username}</div>
            </div>
        )
    }
    render() {
        const isWatchScreen = this.props.screen_id === WATCH_SCREEN
        const isCreateScreen = this.props.screen_id === CREATE_SCREEN
        const isGridViewScreen = this.props.screen_id === GRIDVIEW_SCREEN
        const isSpotlight = (isWatchScreen && this.props.data.broadcast) || (isGridViewScreen && this.props.data.viewScreen)
        const classes = {
            main: clsx('StudentIcon__wrapper',
                (isSpotlight || (isCreateScreen && this.props.me)) && 'spotlight',
                (this.props.data.control && !isSpotlight && isWatchScreen) && 'control',
                (!isSpotlight && isGridViewScreen) && 'dimmed'),
            chair: clsx('avatar chair'),
        };

        return (
            <div className={classes.main} id={this.props.data.identity + '-StudentIcon'}>
                <div className={classes.chair} />
                {this.props.data.username && this.render_student()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    volumeLevels: state.studioCommsVolume.volumeLevels,
    screen_id: state.studioScreen.screen_id,
    subScreen_id: state.studioScreen.subScreen_id,
});

export default connect(mapStateToProps, null)(StudentIcon);
