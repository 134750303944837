import './styles.scss';

const Tooltip = ({ children }) => (
  <div className="tooltip">
    <span className="tooltip__target">?</span>
    <div className="tooltip__tip">
      {children}
    </div>
  </div>
);

export default Tooltip;
