import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setContextMenu } from 'actions/contextMenuAction';

import clsx from 'clsx';

import './styles.scss';

class Welcome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            left: undefined,
        }

        this.resizeObserver = new ResizeObserver(this.init_raisearm)
    }
    // Lifecycle components
    componentDidMount() {
        this.resizeObserver.observe(this.element)

    }
    componentDidUpdate(prevProps) {
        this.props.identity && this.props.identity !== prevProps.identity && this.init_raisearm()
        this.props.scale !== prevProps.scale && this.init_raisearm()
        const self = this.props.students ? this.props.students.filter(s => s.identity === this.props.identity) : null
        this.state.left === undefined && self.length && (self.reduce(s => s.order).order !== undefined && !self.reduce(s => s.order).instructor) && this.init_raisearm() // Have an order and not be an instructor

    }

    componentWillUnmount() {
        this.resizeObserver.unobserve(this.element)
        this.countdownTimer && clearInterval(this.countdownTimer)
    }

    init_raisearm = () => {
        // these elements already exist, so it is safe to query for them
        const StudentIcon = document.getElementById(this.props.identity + '-StudentIcon')
        const StudioScreens = document.getElementById('StudioScreens')

        if (StudentIcon && StudioScreens) {
            const offset = StudentIcon.getBoundingClientRect().left - StudioScreens.getBoundingClientRect().left
            this.setState({ left: offset / (this.props.scale ? this.props.scale : 1) }) // factor screen scaling for offse
        }
    }

    render() {
        const instructor = this.props.students.find(s => s.identity === this.props.identity && s.instructor)
        const classes = {
            wrapper: clsx('Welcome__wrapper', this.props.show && 'show'),
            raisearm: clsx('raisearm_tip', instructor && 'hide', this.state.left === undefined && 'hide')
        };

        return (
            <div className={classes.wrapper} ref={element => (this.element = element)} >
                <div className="title">Welcome to UME!</div>
                <div className={classes.raisearm} style={{ left: this.state.left }} />
            </div>
        )
    }

}
const mapStateToProps = state => ({
    callback: state.contextMenu.callback,
    identity: state.auth.identity,
    students: state.studioStudents.students,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setContextMenu }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
