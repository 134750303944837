import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { updateMemberships } from './utils';

/**
 * Step 2 in unlocking subscriptions: display the plan cost and prompt the
 * purchaser to complete payment through PayPal. Return when the membership
 * request has been sent.
 */
const Step2 = ({ plan, email, onSubmit }) => {
  const { identity, subscriptions } = useSelector(state => state.auth);

  const firstBillTimestamp = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000);
  const firstBillString = firstBillTimestamp.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });

  useEffect(() => {
    // If the plan is free, we don't need any payment; complete this step
    // immediately.
    if (plan && plan.cost === 0) {
      // If the user is already subscribed to something, unsubscribe them.
      if (subscriptions && subscriptions.size > 0) {
        updateMemberships({
          identity, unsubscribeFrom: [...subscriptions]
        });
      }

      onSubmit(null, null);
    }
  }, [plan, onSubmit, identity]);

  const onApprove = async (data, actions) => {
    const order = await actions.subscription.get();
    onSubmit(order, firstBillString);
  };

  const handleSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: plan.id,
      custom_id: identity,
      subscriber: {
        email_address: email
      },
      application_context: {
        brand_name: 'UME Academy',
        shipping_preference: 'NO_SHIPPING',
        user_action: 'SUBSCRIBE_NOW',
        payment_method: {
          payer_selected: 'PAYPAL',
          payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED'
        },
        return_url: 'https://www.ume.games',
        cancel_url: 'https://www.ume.games'
      }
    });
  };

  return (
    <form className="unlock-form__content">
      <p className="unlock-form__subtitle">
        Payment Details
      </p>

      <div className="unlock-form__fields unlock-form__fields--centered">
        <div className="unlock-form__price-group">
          <p>
            First 2 weeks:
          </p>
          <p className="unlock-form__free">
            FREE
          </p>
        </div>

        <div className="unlock-form__price-group">
          <p>
            First bill on: {firstBillString}
          </p>

          <p className="unlock-form__subtitle">
            {plan.costString}
          </p>
        </div>
      </div>

      <PayPalButtons
        createSubscription={handleSubscribe}
        onApprove={onApprove}
        style={{ tagline: false }}
      />
    </form>
  );
};

export default Step2;
