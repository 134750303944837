
import React, { Component } from 'react';
import './styles.scss';

class Avatar extends Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="avatar__wrapper">
                <div className="profile" onClick={this.handleOpenDeveloperPage}>
                    <img src={this.props.avatar} alt="profile" />
                </div>
            </div>
        )

    }
}

export default Avatar;
