import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { track } from 'react-tracking';
import onClickOutside from 'react-onclickoutside';
import clsx from 'clsx';
import { setCurrentArtifact } from 'actions/artifactAction';
import { setActivity } from 'actions/activityAction';

import Carousel from './Carousel';
import Modal from './Modal';

import { toggleFullScreen } from 'helpers/utils'


import './styles.scss';

class PortfolioGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCard: -1,
      currentArtifact: null,
      toggleFullScreen_mode: false,
    };
  }

  handleUIClose = () => {
    this.props.setActivity({ action: 'arcade' })
    const { onSelect } = this.props;
    this.setState({ selectedCard: -1 });
    onSelect && onSelect(-1);
    if (window.screen.height < 680) {
      toggleFullScreen()
    }
  };

  handleCarouselChange = selectedCard => {
    const { sliderId, onSelect, artifacts } = this.props;
    this.setState({ selectedCard, ...{ currentArtifact: artifacts[selectedCard] } });
    this.props.setCurrentArtifact(artifacts[selectedCard])
    if (window.screen.height < 680) {
      toggleFullScreen()
    }

    onSelect && onSelect(sliderId, artifacts[selectedCard]);
  };

  /*
  handleClickOutside = (e) => {
    if (!e || !e.path || this.props.sliderId !== this.props.currentSlider) {
      return
    }
    var isAuthenticator = e.path.filter(item => {
      return (
        String(item.className).indexOf('Authenticator') > -1 ||
        String(item.className).indexOf('navbar__wrapper') > -1 ||
        String(item.className).indexOf('Popup__wrapper') > -1
      )
    })
    var isModal = e.path.filter(item => {
      return (
        String(item.className).indexOf('Modal') > -1
      )
    })
    if (e.path.length === 3 || isAuthenticator.length || isModal.length) {
      return
    }

    // If using the handleClickOutside method in the Studio comment the if statement below out
    // Otherwise any click/state update will keep closing the game
    if (this.state.selectedCard >= 0) {
      this.handleUIClose();
    }

  };
  */

  handleClickOutside = (e) => {
    // Do nothing
  }

  render() {
    const { selectedCard } = this.state;
    
    const {
      sliderId,
      artifacts,
      currentSlider,
      arcadeCardStyle,
      onAction,
      featured
    } = this.props;
    
    const classes = {
      wrapper: clsx('PortfolioGrid__wrapper', selectedCard >= 0 && 'expanded'),
      arcadeCard: clsx('arcade-card', selectedCard >= 0 && 'expanded')
    };
    
    const carousel_id = selectedCard > -1 && artifacts[selectedCard] ? artifacts[selectedCard].activity_id : sliderId
    
    return (
      <div key={sliderId} className={classes.wrapper} id={`arcade-slider-${sliderId}`}>
        <div
          id={`card-modal-${sliderId}`}
          className={classes.arcadeCard}
          style={arcadeCardStyle}
        >
          <div >
            <Modal
              onClose={this.handleUIClose}
              currentArtifact={this.state.currentArtifact}
            />
          </div>
        </div>
        
        <Carousel
          id={`carousel-${carousel_id}`}
          featured={featured}
          cardItems={artifacts}
          selectedCard={selectedCard}
          currentSlider={currentSlider}
          onSelect={this.handleCarouselChange}
          onAction={onAction}
          arrows={false}
          //slidesToShow={1}
          //slidesToScroll={1}
          swipeToSlide={true}
          infinite={false}
          vertical={this.props.vertical}
        />
        
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setCurrentArtifact, setActivity }, dispatch);

const TrackedPortfolioGrid = track({
  targetToSlider: { type: 'widget', name: 'PortfolioGrid' }
})(onClickOutside(PortfolioGrid));

export default connect(null, mapDispatchToProps)(TrackedPortfolioGrid);
