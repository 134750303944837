import React from 'react';
import UMEButton from 'components/UMEButton';
import clsx from 'clsx';

import BaseCard from './BaseCard';
import DateTime from './DateTime';
import PlaytestIconOff from 'assets/images/icons/PLAYTEST-OFF.png';
import editIcon from 'assets/images/icons/anvil';
import LoadingIcon from 'assets/images/icons/hammer-and-wrench.png';

const PlayButton = ({ activity, iconOnly }) => (
  <UMEButton
    to={activity.shareURI}
    aria-label="Play"
    icon={PlaytestIconOff}
  >
    {iconOnly ? '' : 'Play'}
  </UMEButton>
);

const PrivateFooter = ({ activity }) => (
  <div className="cl-card__buttons">
    {activity.isBuilt() && <PlayButton activity={activity} iconOnly />}
    <UMEButton
      to={activity.editURI}
      icon={editIcon.defaultState}
      hoverIcon={editIcon.hoverState}
    >
      Edit
    </UMEButton>
  </div>
);

const PublicFooter = ({ activity }) => (
  <div className="">
    <div className="cl-card__author">
      <div className="portrait cl-card__avatar">
        <img width="48px" className="portrait__image" src={`https://ume-studio-user.s3.us-west-2.amazonaws.com/avatars/${activity.user.avatar}`} alt="" />
      </div>

      <div className="cl-card__username">
        @{activity.user.username}
      </div>

      <DateTime timestamp={activity.timestamp} includeTime={false} className="cl-card__date" />
    </div>
    <div className="cl-card__buttons">
      <PlayButton activity={activity} />
    </div>
  </div>
);

const ActivityCard = ({ activity, className, personal }) => (
  <BaseCard id={activity.id} className={className}>
    <img
      src={activity.isBuilt() ? activity.thumbnail : LoadingIcon}
      alt=""
      className={clsx('cl-card__image', !activity.isBuilt() && 'cl-card__image--contain')}
    />
    <div className="cl-card__info">
      <h3 className="cl-card__title">
        {activity.title}
      </h3>
      {personal && <DateTime timestamp={activity.timestamp} className="cl-card__date" />}
    </div>

    {personal ? <PrivateFooter activity={activity} /> : <PublicFooter activity={activity} />}
  </BaseCard>
);

export default ActivityCard;
