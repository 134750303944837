import React, { Component } from 'react';

import Input from 'components/Input'
import Button from 'components/Button'

import './styles.scss'

class ConfirmSignIn extends Component {
  constructor(props) {
    super(props);
    this.form = React.createRef()

    this.state = {
      code: null,
      label: props.label,
      status: props.status,
      message: props.message,
      phone: props.phone
    }
  }
  componentDidUpdate(prevProps, prevState) {
    var updates = {}
    if (prevProps.label !== this.props.label) {
      updates.label = this.props.label;
    }
    if (prevProps.status !== this.props.status) {
      updates.status = this.props.status
      if (this.props.status !== 'success') {
        updates.label = 'Try again'
        updates.status = 'invalid'
      }
    }
    if (prevProps.message !== this.props.message) {
      updates.message = this.props.message
    }
    if (Object.keys(updates).length) {
      this.setState(updates)
    }
  }
  validate_code = (value) => {
    this.setState({
      code: value,
      code_error: !value || value.length !== 6,
      code_msg: 'Code should be 6 letters and numbers.'
    })
    return (!value || value.length !== 6)
  }
  handleConfirmationCode = (event) => {
    event.preventDefault();
    if (this.state.status === 'send') {
      return
    }
    this.setState({ status: 'send', label: 'Confirming' }, () => {
      this.props.callback(this.state.code, (response) => {
        console.log('confirmSignIn', response)
      })
    })
  }

  render() {
    return (
      <div className="CreateUser__wrapper">
        <form ref={this.form} id="UME_ConfirmSignIn_Form" onSubmit={this.handleConfirmationCode}>

          <Input label="Code Received"
            placeholder={"Enter code that was sent to " + this.state.phone}
            message={this.state.code_msg}
            type="txt"
            invalid={this.state.code_error}
            onChange={(value) => this.validate_code(value)}
          />
          <div className="buttonRow">
            <Button label="Next" />
          </div>
        </form>
      </div>
    )
  }
}

export default ConfirmSignIn;
