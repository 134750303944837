import React, { Component } from 'react';
import clsx from 'clsx';
import './styles.scss';

class Tab extends Component {

    render() {
        const classes = {
            main: clsx('Tab__wrapper', this.props.active && 'active', this.props.greyed && (this.props.instructor ? 'greyed' : 'greyed-student')),
            tabIcon: clsx('tab-icon', (this.props.instructor || !this.props.greyed) && !this.props.active && 'tab-icon-hover', this.props.active && 'tab-icon-active'),
            lock: clsx('lock', this.props.screenNavigable ? 'off' : 'on', this.props.showLockIcon ? 'show' : 'hide'),
            pin: clsx('pin', this.props.classActive ? (this.props.instructor ? 'on' : 'on-student') : 'off', this.props.showPinIcon ? 'show' : 'hide'),
        }

        return (
            
            <div className={classes.main} style={this.props.style} onClick={this.props.allowTabClick ? this.props.onClick : null}>
                <div className={classes.tabIcon} style={this.props.tabIconStyle}/> 
                <div className={classes.lock} />
                <div className={classes.pin} />
            </div>
        )
    }
}

export default Tab;
