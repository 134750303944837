/**
 * React component that shows memberships. Can exist in two broad states,
 * depending on the route:
 *
 * 1) When visited from /subscriptions, all available memberships are displayed
 *    horizontally (or vertically on mobile).
 * 2) When visited from /subscriptions/id, the desired subscription is displayed
 *    on the left (or on top, on mobile) and the form to unlock that
 *    subscription is displayed to the right (or underneath).
 */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PlanCard from '../PlanCard';
import OrgCard from '../OrgCard';
import UnlockForm from '../UnlockForm';
import { ALL_PLANS } from 'models';
import './styles.scss';

const Memberships = ({ planID }) => {
  const [plans, setPlans] = useState(ALL_PLANS);
  const { authenticated, subscriptions } = useSelector(state => state.auth);
  const [selectMode, setSelectMode] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (planID) {
      setPlans(plans => plans.filter(plan => plan.id === planID));
    } else {
      setPlans(ALL_PLANS);
    }
  }, [planID]);

  useEffect(() => {
    if (plans.length === 0) {
      history.replace('/subscribe');
    }
  }, [plans, history]);

  useEffect(() => {
    setSelectMode(!subscriptions || subscriptions.size === 0);
  }, [subscriptions]);

  return (
    <section className="membership-section">
      <h2 className="membership-section__title">
        Memberships
      </h2>

      <p className="flag membership-section__subtitle">
        Welcome to the Community!
      </p>

      <p className="membership-section__text">
        Upgrade to a CREATOR Membership to gain access to UNLIMITED lessons and Projects!
      </p>

      <div className={clsx('membership-section__container', planID && 'membership-section__container--filled')}>
        <div className="membership-section__plans">
          {plans.map(plan => (
            <PlanCard
              plan={plan}
              key={plan.name}
              canSelect={selectMode && plan.cost >= 0}
              owned={authenticated && plan.isOwned(subscriptions)}
              solo={plans.length === 1}
              onChangeMembership={() => setSelectMode(true)}
            />
          ))}
        </div>

        {planID && plans.length === 1 && <UnlockForm plan={plans[0]} />}
      </div>

      {!planID && (
        <>
          <p className="membership-section__text">
            Looking to bring UME Games to your school or organization?
          </p>
          <OrgCard />
        </>
      )}
    </section>
  );
};

export default Memberships;
