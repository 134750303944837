import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useAudio } from 'contexts/audioContext';

const NavLink = ({
  to,
  icon,
  label,
  className = '',
  selected = false,
  target = '',
  rel = ''
}) => {
  const { play } = useAudio();

  let route = to;

  if (to.match(/^http/)) {
    route = { pathname: to };
  }

  return (
    <Link
      to={route}
      className={clsx('nav-link', className, selected && 'nav-link--selected')}
      target={target}
      rel={rel}
      onClick={() => play('click')}
    >
      <img
        src={selected ? icon.onState : icon.offState}
        alt=""
        className="nav-link__icon"
      />
      <img
        src={icon.hoverState}
        alt=""
        className="nav-link__icon nav-link__icon--hover"
      />
      <span className="nav-link__text" data-text={label}>
        {label}
      </span>
    </Link>
  );
};

export default NavLink;
