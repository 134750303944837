import React from 'react';
import { useSelector } from 'react-redux';
import UMEButton from 'components/UMEButton';
import { tags } from 'models/constants';
import Video from 'components/Video';

const SeedProjectCard = ({ activity }) => {
  const { authenticated, subscriptions } = useSelector(state => state.auth);

  const canStartProject = authenticated && subscriptions && (!activity.tags.has(tags.MEMBERSHIP_REQUIRED) || subscriptions.size > 0);

  return (
    <div className="start-new-modal__list-item seed-project-card">
      {activity.videoID ? (
        <Video locked={!canStartProject} src={activity.videoID} className="seed-project-card__thumbnail" />
      ) : (
        <img className="seed-project-card__thumbnail" src={activity.thumbnail} alt="" />
      )}
      <div className="seed-project-card__info">
        <h3 className="seed-project-card__title">{activity.title}</h3>
        <p className="seed-project-card__description">
          {activity.description}
        </p>
      </div>
      <div className="seed-project-card__buttons">
        <UMEButton to={`/lessons/${activity.context}`}>
          View Project
        </UMEButton>
      </div>
    </div>
  );
};

export default SeedProjectCard;
