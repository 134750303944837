import React, { Component } from 'react';
import clsx from 'clsx';
import './styles.scss';

class ClassTitle extends Component {

    constructor(props) {
        super(props); // props are passed from the parent component to the cild, they should be treated as const values

        this.state = {

        }
    }

    render() {
        const classes = {
            main: clsx('ClassTitle__wrapper'),
        }
        return (
            <div className={classes.main}>
                <div className="title_text">
                    {this.props.label}
                </div>
            </div>
        )
    }
}

export default ClassTitle;
