import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSearchParams } from 'hooks';
import { signOut } from 'helpers/auth';

/**
 * If a subscription code is detected in the query string, the user is force to
 * sign out and is redirected to the login page, keeping the subscription code
 * in the query string. When the user signs in, they will be redirected back to
 * where they were, but without the subscriptionCode in the query string.
 */
const SubscriptionCodeHandler = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (
      searchParams.subscriptionCode
      // Not double checking this causes an early update in an invalid state
      && location.search.includes('subscriptionCode')
      && location.pathname !== '/login'
    ) {
      const searchString = Object.entries(searchParams)
        .filter(([key, _]) => key !== 'subscriptionCode')
        .map(entry => entry.join('='))
        .join('&');

      signOut();
      history.push({
        pathname: '/login',
        search: `?subscriptionCode=${searchParams.subscriptionCode}`,
        state: {
          from: {
            pathname: location.pathname,
            search: `?${searchString}`
          }
        }
      });
    }
  }, [searchParams, history, location]);

  return null;
};

export default SubscriptionCodeHandler;
