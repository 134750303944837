import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import Tooltip from '../Tooltip';
import './styles.scss';

const TextField = ({
  onChange,
  value,
  limit,
  label,
  name,
  tooltip,
  textarea = false
}) => {
  const ref = useRef();

  const handleChange = event => {
    const { value: newValue } = event.target;

    // Changes are processed if the new value doesn't exceed the limit or if
    // characters have been removed.
    if (newValue.length <= Math.max(limit, value.length)) {
      onChange(event);
    }
  };

  // Resize textareas when the value changes
  useEffect(() => {
    if (!textarea) {
      return;
    }

    ref.current.style.height = '5px';
    ref.current.style.height = (ref.current.scrollHeight) + 'px';
  }, [value, textarea]);

  const inputParams = {
    ref,
    value,
    name,
    onChange: handleChange,
    className: 'edit-text-field__input'
  };

  const input = textarea
    ? <textarea {...inputParams}></textarea>
    : <input type="text" {...inputParams} />;

  return (
    <div className="edit-text-field">
      <div className="edit-text-field__header">
        <label className="edit-text-field__label activity-edit-form__label">
          {label}
        </label>
        <Tooltip>
          {tooltip}
        </Tooltip>
      </div>
      {input}
      <div className={clsx('edit-text-field__counter', value.length >= limit && 'edit-text-field__counter--full')}>
        {value.length}/{limit}
      </div>
    </div>
  );
};

export default TextField;
