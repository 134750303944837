import React, { Component } from 'react';
import clsx from 'clsx';

import './styles.scss';

import { connect } from 'react-redux';

import { ContextMenuItemButton, ContextMenuItemIcon, ContextMenuItemConfirmButton, ContextMenuItemTitle } from 'pages/Studio/components/ContextMenu/ContextMenuItems'

class GridViewTools extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }
    callback
    render() {
        if (!this.props.data) {
            return (<div />)
        }
        const classes = {
            main: clsx('GridViewTools__wrapper')
        };

        const hideMicToggle = (this.props.data.twilioNetQuality === undefined || this.props.data.twilioNetQuality === null || this.props.data.twilioNetQuality === 0)

        return (
            < div className={classes.main} >
                <ContextMenuItemTitle label={this.props.data.username} />
                <ContextMenuItemIcon label="fps" />
                <ContextMenuItemIcon label="audio" />

                <ContextMenuItemButton
                    label={this.props.data.micToggleable ? "Disable mic" : "Enable mic"}
                    hide={hideMicToggle}
                    callback={() => { this.props.callback('mic-toggle', this.props.data) }}
                />

                <ContextMenuItemButton
                    label="View Screen"
                    callback={() => { this.props.callback('viewScreen', this.props.data) }}
                />

                <ContextMenuItemConfirmButton
                    label="Give Spotlight"
                    hide={this.props.data.broadcast}
                    callback={() => { this.props.callback('broadcast', this.props.data) }}
                />

                <ContextMenuItemConfirmButton
                    label="Reload"
                    active={false}
                    callback={() => { this.props.callback('reload-student-browser', this.props.data) }}
                />

                <ContextMenuItemButton
                    label="Needs help"
                    active={false}
                    callback={() => { this.props.callback('flag-student-help', this.props.data) }}
                />
            </div >
        );

    }
}
const mapStateToProps = state => ({
    callback: state.contextMenu.callback,
});
export default connect(mapStateToProps, null)(GridViewTools);
