export const updatePrivateUser = `mutation UpdatePrivateUser($input: PrivateUserInput!) {
  updatePrivateUser(input: $input) {
    username
    email
    facilitator_code
  }
}
`;

export const updatePublishMetadata = `mutation UpdatePublishMetadata($input: PublishInput!) {
  updatePublishMetadata(input: $input) {
    artifact
    context
    id
    identity
    metadata
    status
    tenant
    timestamp
    type
    webview
    version
  }
}
`;

export const mailCommand = `mutation MailCommand($input: MailCommandInput!) {
  mailCommand(input: $input) {
    identity
    command
    args
  }
}
`;

export const createLab = `mutation CreateLab($input: LabInput!) {
  createLab(input: $input) {
    id
    error
  }
}
`;

export const createClass = `mutation CreateClass($input: ClassInput!) {
  createClass(input: $input) {
    id
    aggregate
    appsync_id
    comms_status
    duration
    enrollment_period
    public
    source
    start
    end
    status
    students{
      identity
      instructor
      avatar
      username
    }
  }
}
`;

export const joinClass = `mutation JoinClass($id:String!,$input: UserInput!) {
  joinClass(id:$id,input: $input) {
    id
    aggregate  
    comms_status
    duration
    enrollment_period
    public
    source
    start
    end
    status
  }
}
`;


export const createUser = `mutation CreateUser($input: UserInput!) {
  createUser(input: $input) {
    login
    phone
    email
    avatar
    parent_id
    status
    message
    aws_pings {
      name
      title
      ping
    }
  }
}
`;

export const updateUser = `mutation UpdateUser($input: UserInput!) {
  updateUser(input: $input) {
    login
    phone
    email
    avatar
    parent_id
    status
    message
    aws_pings {
      name
      title
      ping
    }
  }
}
`;
