// This class contains helper functions for working with Intercom help, support and sales-leads chat system
// Core methods taken from: https://github.com/intercom/example-single-page-app-install/tree/logged-in-react-router


class Intercom {
    constructor() {
        // Setting up the singleton
        if (Intercom._instance instanceof Intercom) {
            return Intercom._instance
        }

        Intercom._instance = this

        this._load()
        this._isLaunched = false

    }


    // MARK: Public methods
    /**
     * Launches an anonymous Intercom chat session
     * 
     */
    launchAnonymousSession = () => {
        this._isLaunched = true

        let minimalIntercomOptions = {}
        minimalIntercomOptions.productBuild = window.branch_version

        this._boot(minimalIntercomOptions)

    }

    /**
     * Launches a signed in Intercom chat session
     * 
     * @param {string} cognitoIdentity The users cognito identity in the app
     * @param {string} name Typically this is the username but can also be any other name we'd like to associate to the user
     * @param {string} productBuild The version of the app
     * 
     */
    launchSignedInSession = (cognitoIdentity, name) => {
        this._isLaunched = true

        let intercomOptions = {}
        intercomOptions.cognitoIdentity = cognitoIdentity
        intercomOptions.name = name
        intercomOptions.productBuild = window.branch_version

        this._boot(intercomOptions)

    }

    /**
     * Closes the Intercom chat session
     * 
     */
    closeSession = () => {
        this._isLaunched = false
        this._shutdown()

    }

    /**
     * Returns whether an Intercom session is active
     * 
     * @returns {boolean}
     * 
     */
    isIntercomActive = () => {
        return this._isLaunched

    }

    /**
     * After a session is launched this opens up the Intercom chat with a prepopulated message filled out for the user
     * 
     * @param {string} name Typically this is the username but can also be any other name we'd like to associate to the user
     * @param {string} extraInfo Any additional information to include in the prepopulated message
     * 
     */
    showPrepopulatedHelpSessionMessage = (name, extraInfo = "") => {
        const message = "It looks like you need help! Hit enter or click the send button to the right and someone will get right to you.\n\nMy username is: " + name + "\nI'm on build: " + window.branch_version + "\nMy class URL is: " + window.location.href + (extraInfo !== "" ? "\n" + extraInfo : '')

        window && window.Intercom && this.isIntercomActive() && window.Intercom('showNewMessage', message)

    }


    // MARK: Core methods
    /**
     * Loads Intercom with the snippet
     * This must be run before boot, it initializes window.Intercom
     * 
     */
    // prettier-ignore
    _load = () => {
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic("reattach_activator");
                ic("update", w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments);
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args);
                };
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement("script");
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = "https://widget.intercom.io/widget/" + "pw3dsc92";
                    var x = d.getElementsByTagName("script")[0];
                    x?.parentNode.insertBefore(s, x);
                };
                if (document.readyState === "complete") {
                    l();
                } else if (w.attachEvent) {
                    w.attachEvent("onload", l);
                } else {
                    w.addEventListener("load", l, false);
                }
            }
        })();
    }

    /**
     * Initializes Intercom
     * 
     */
    _boot = (options = {}) => {
        window && window.Intercom && window.Intercom("boot", { app_id: 'pw3dsc92', ...options })

    }

    /**
     * This method just calls Intercom('update'), which should be run on every page
     * change. This does two things:
     * 1. Send an update to Intercom to create an impression on the current URL
     * 2. Fetch any messages that should be delivered based on the URL and user
     * 
     */
    _update = () => {
        window && window.Intercom && window.Intercom("update")

    }

    /**
     * Clears user session and unloads messages
     * 
     */
    _shutdown = () => {
        window && window.Intercom && window.Intercom("shutdown")

    }

}


const intercom = new Intercom();
Object.seal(intercom);

export default intercom;
