import { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Layout from 'components/Layout';
import { fetchFeaturedGames, fetchGames } from 'api';
import { useUserData } from 'contexts/userDataContext';
import { useIntersection } from 'hooks';
import CategorySelect from './components/CategorySelect';
import Empty from './components/Empty';
import './styles.scss';

const Arcade = () => {
  const { authenticated, identity } = useSelector(state => state.auth);
  const { facilitatorCode } = useUserData();
  const [games, setGames] = useState();
  const [gameType, setGameType] = useState('featured');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(-1);
  const [desiredPage, setDesiredPage] = useState(0);
  const [moreToLoad, setMoreToLoad] = useState(true);
  const ref = useRef();
  const maxY = useIntersection(ref, gameType);

  const groupTypes = [
    {
      id: 'featured',
      label: 'Featured Games'
    },
    {
      id: 'mine',
      label: 'My Games'
    },
  ];

  if (facilitatorCode) {
    groupTypes.push({
      id: 'guild',
      label: 'My Guild\'s Games'
    });
  }

  const handleChangeGameType = (e) => {
    setGameType(e.target.value);
    setCurrentPage(-1);
    setDesiredPage(0);
    setGames([]);
    setMoreToLoad(true);
  };

  const loadMoreGames = useCallback(async () => {
    setLoading(true);

    const fetchFunc = gameType === 'featured' ? fetchFeaturedGames : fetchGames;
    const options = {
      page: desiredPage
    };

    if (gameType === 'mine') {
      options.query = identity;
    }

    if (gameType === 'guild') {
      options.query = facilitatorCode;
    }

    const { hits, isLastPage } = await fetchFunc(options);
    setGames(games => [
      ...(games || []),
      ...hits
    ]);
    setCurrentPage(desiredPage);

    if (isLastPage) {
      setMoreToLoad(false);
    }

    setLoading(false);
  }, [desiredPage, facilitatorCode, gameType, identity]);

  useEffect(() => {
    if (desiredPage === currentPage) {
      setDesiredPage(page => page + 1);
    }
  }, [maxY, desiredPage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading && moreToLoad && currentPage < desiredPage) {
      loadMoreGames();
    }
  }, [loading, currentPage, desiredPage, moreToLoad, loadMoreGames]);

  return (
    <>
      <div className="arcade__panel">
        {authenticated
          ? (
            <CategorySelect
              value={gameType}
              options={groupTypes}
              onChange={handleChangeGameType}
            />
          )
          : (
            <h2 className="arcade__title">
              Featured Games
            </h2>
          )
        }
      </div>

      {games?.length === 0 && !loading && !moreToLoad && <Empty />}

      <div className="arcade__games-container">
        {games && games.map(game => game.toCard())}
      </div>
      <div className="loading-sentinel" ref={ref} />
    </>
  );
};

// Arcade depends on the UserData context, which is defined in the layout.
// Should refactor this.
const MetaArcade = () => (
  <Layout tab="arcade">
    <Arcade />
  </Layout>
);

export default MetaArcade;
