import React, { Component } from 'react';
import { connect } from 'react-redux';

import './styles.scss';

import { ContextMenuItemButton, ContextMenuItemTitle } from 'pages/Studio/components/ContextMenu/ContextMenuItems'
import { WATCH_SCREEN, GRIDVIEW_SCREEN } from 'pages/Studio/types';

class InstructorTools extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    componentDidUpdate(prevProps) {
    }

    render_watch_tools() {
        return (
            <div>
                <ContextMenuItemButton
                    label={"Take Spotlight"}
                    active={this.props.data.broadcast}
                    hide={this.props.data.broadcast}
                    callback={() => { this.props.callback('broadcast', this.props.data) }}
                />
                <ContextMenuItemButton
                    label={"Take Control"}
                    active={this.props.data.controlWatch}
                    hide={this.props.data.controlWatch}
                    callback={() => { this.props.callback('control-watch', this.props.data) }}
                />
            </div>
        )
    }
    render_create_tools() {

    }
    render_play_tools() {

    }
    render_viewscreen_tools() {
        var viewScreenStudent = this.props.students.find((student) => student.viewScreen === true)

        if (viewScreenStudent === undefined || !viewScreenStudent) {
            return (<div></div>)
        } else {
            return (
                <div>
                    {<ContextMenuItemButton
                        label={"Take Control of " + viewScreenStudent.username}
                        //active={this.props.data.controlCreate}
                        hide={!viewScreenStudent.controlCreate}
                        callback={() => { this.props.callback('control-create', viewScreenStudent) }}
                    />}
                </div>
            )
        }
    }
    render_context_tools() {
        switch (this.props.screen_id) {
            case WATCH_SCREEN:
                return this.render_watch_tools();
            case GRIDVIEW_SCREEN:
                return this.render_viewscreen_tools();
            default:
                return (<div />)
        }
    }
    render() {
        if (!this.props.data) {
            return (<div />)
        }

        const hideMute = (this.props.data.twilioNetQuality === undefined || this.props.data.twilioNetQuality === null || this.props.data.twilioNetQuality === 0)

        return (
            < div className="InstructorTools__wrapper" >
                <ContextMenuItemTitle label={this.props.data.username} />
                <ContextMenuItemButton
                    label={this.props.data.mute ? "Unmute" : "Mute"}
                    active={this.props.data.mute}
                    hide={hideMute}
                    callback={() => { this.props.callback('mute', this.props.data) }}
                />

                {this.render_context_tools()}

            </div >
        );

    }
}


const mapStateToProps = state => ({
    callback: state.contextMenu.callback,
});
export default connect(mapStateToProps, null)(InstructorTools);
