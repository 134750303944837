import { useEffect, useState } from 'react';

const BASE_YT_URL = 'https://youtube.googleapis.com/youtube/v3/videos';

/**
 * Fetches metadata about a video from YouTube
 */
const fetchYoutubeMetadata = async (src, part = ['snippet', 'contentDetails']) => {
  const params = {
    part: part.join(','),
    id: src,
    key: window.ume_config.youtube_api_key
  };
  const search = Object.entries(params).map(entry => entry.join('=')).join('&');

  const url = `${BASE_YT_URL}?${search}`;
  const response = await fetch(url);
  const { items } = await response.json();
  return items?.[0];
};

const extractBookmarks = metadata => {
  const description = metadata.snippet.description;
  const bookmarkStrings = description.match(/\d{2}:\d{2} - [^\n]+/g);

  if (!bookmarkStrings) {
    return [];
  }

  const bookmarks = bookmarkStrings.map(str => {
    const [timestamp, label] = str.split(' - ');
    const [minutes, seconds] = timestamp.split(':');

    return {
      timestamp,
      seconds: parseInt(minutes) * 60 + parseInt(seconds),
      label
    };
  });

  return bookmarks;
};

const extractDuration = metadata => {
  const durationString = metadata.contentDetails.duration;
  const match = durationString.match(/PT(\d+)M(\d+)S/);
  const minutes = parseInt(match[1]);
  const seconds = parseInt(match[2]);

  return minutes * 60 + seconds;
};

const useYoutubeMetadata = src => {
  const [bookmarks, setBookmarks] = useState([]);
  const [duration, setDuration] = useState();

  useEffect(() => {
    if (!src) {
      return;
    }

    (async () => {
      const metadata = await fetchYoutubeMetadata(src);

      if (metadata) {
        setBookmarks(extractBookmarks(metadata));
        setDuration(extractDuration(metadata));
      }
    })();
  }, [src]);

  return { bookmarks, duration };
};

export default useYoutubeMetadata;
