import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

/**
 * @typedef {Object} ResourceState
 * @property {boolean} loaded Has the resource been loaded yet?
 * @property {any} data The result of the resource call
 * @property {?Error} error The error that was thrown in the most recent run,
 *                          if any
 */

/**
 * Hook wrapper for an authenticated resource.
 *
 * @method
 * @param {Function} resource - Retrieves the data from an API.
 * @param {Object} options - Non-reactive options to pass to the resource.
 * @param {any} defaultValue - The value to use for the data when none is
 *                             otherwise available.
 * @returns {ResourceState}
 */
const useMyResource = (resource, options, defaultValue) => {
  const defaultRef = useRef(defaultValue);
  const optionsRef = useRef(options);

  const [data, setData] = useState(defaultRef.current);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState();
  const { identity, authentication } = useSelector(store => store.auth);
  const authed = authentication !== null;

  useEffect(() => {
    setError(undefined);

    if (!identity) {
      setLoaded(authed);
      setData(defaultRef.current);
      return;
    }

    (async () => {
      try {
        const fetchedData = await resource(optionsRef.current);
        setData(fetchedData);
      } catch (e) {
        setData(defaultRef.current);
        setError(e);
      } finally {
        setLoaded(true);
      }
    })();
  }, [identity, resource, authed]);

  return { data, loaded, error };
};

export default useMyResource;
