import React, { createContext, useCallback, useContext, useState } from 'react';
import { useSearchParams } from 'hooks';
import { createLab, updateUserInfo } from 'api';
import { getRegion } from 'helpers';
import { useUserData } from 'contexts/userDataContext';

const LaunchLabContext = createContext(null);

/**
 * @typedef {Object} LabLaunchContextType
 * @property {boolean} loading
 * @property {?Object} error
 * @property {?String} url
 * @property {function} launchLab
 */

/**
 * Provider for lab launches.
 *
 * @method
 */
export const LaunchLabProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [url, setUrl] = useState();
  // TODO: facilitator code linking should really be done on the back end.
  const { facilitatorCode: newFacilitatorCode } = useSearchParams();
  const { facilitatorCode: existingFacilitatorCode } = useUserData();

  /**
   * Launches a lab for the current user based on a given identity in a given
   * region and environment.
   *
   * @param {Object} activity - The activity to launch from
   * @param {?string} env - The environment to launch the lab in. Defaults to
   *                        the global ume_config's id, falling back to prod.
   * @param {?string} region - The region to launch the lab in. If not provided,
   *                           the region will be determined based on a ping.
   */
  const launchLab = useCallback(async (activity, env = null, region = null) => {
    if (loading) {
      return;
    }

    setLoading(true);
    setError(undefined);

    if (newFacilitatorCode) {
      updateUserInfo({ facilitator_code: newFacilitatorCode });
    }

    try {
      const { error, uri } = await createLab({
        sessionId: activity.id,
        region: region || await getRegion(),
        envType: env || window.ume_config.id || 'prod',
        facilitatorCode: newFacilitatorCode || existingFacilitatorCode
      });
      setUrl(uri);
      setError(error);
    } catch (e) {
      console.error(e.message);
      console.error(activity);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [loading, newFacilitatorCode, existingFacilitatorCode]);

  const reset = () => {
    setLoading(false);
    setError(undefined);
    setUrl(undefined);
  };

  const context = {
    loading,
    error,
    url,
    launchLab,
    reset
  };

  return (
    <LaunchLabContext.Provider value={context} >
      {children}
    </LaunchLabContext.Provider>
  );
};

/**
 * React hook that acts as an interface into the launching of a lab.
 *
 * @method
 * @returns {LabLaunchContextType} context
 */
export const useLaunchLab = () => useContext(LaunchLabContext);
