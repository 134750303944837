import { useSelector } from 'react-redux';

import { useSeeds } from 'hooks';
import { LaunchLabProvider } from 'contexts/launchLabContext';
import Layout from 'components/Layout';
import SignInPrompt from 'components/SignInPrompt';
import StartLabModal from 'components/StartLabModal';
import MyProjects from './components/MyProjects';
import VideoGameCharacters from 'assets/images/community.png';
import './styles.scss';

const CreatorLab = () => {
  const { authentication, subscriptions } = useSelector(state => state.auth);

  const search = new URLSearchParams(window.location.search);
  const focus = search.get('seed');
  const seeds = useSeeds({ focus, subscriptions });

  return (
    <Layout tab="creator-lab" className="creator-lab">
      <header className="creator-lab__header">
        <p className="creator-lab__title">
          Make, edit, playtest &amp; share your games
        </p>
        <p className="creator-lab__tagline">
          This is your game project library where you can continue to <strong>make</strong> your own games to playtest &amp; share!
        </p>
        <img src={VideoGameCharacters} alt="" width="320px" className="creator-lab__image" />
      </header>

      <main className="section section--creator-lab">
        <div className="section__banner">
          <h2 className="section__title">
            My Projects
          </h2>
        </div>
        <div className="section__body">
          <LaunchLabProvider>
            {authentication && !authentication.authenticated && (
              <SignInPrompt prompt="Please sign in or sign up to access your projects." />
            )}
            {authentication && authentication.authenticated && <MyProjects seeds={seeds.data} />}

            <StartLabModal />
          </LaunchLabProvider>
        </div>
      </main>
    </Layout>
  );
};

export default CreatorLab;
