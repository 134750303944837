import React from 'react';
import LoginLink from '../LoginLink';
import './styles.scss';

const SignInPrompt = ({ prompt }) => (
  <div className="sign-in-prompt">
    <p className="sign-in-prompt__description">
      {prompt}
    </p>

    <div>
      <LoginLink
        prompt={prompt}
        className="cl-button cl-button--wide"
      >
        Sign in/Sign up
      </LoginLink>
    </div>
  </div>
);

export default SignInPrompt;
