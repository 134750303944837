import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import PubSub from '@aws-amplify/pubsub';
import { DateUtils } from "@aws-amplify/core"
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import branch_version_file from './branch_version.json';
import { getVersion, getApiEndpoint } from 'helpers';

window.configure_amplify = function (api, useNewRealTimeProvider = false) {
    // console.log("Amplify configure", api, useNewRealTimeProvider);

    let ume_config = window.ume_config;
    if (!useNewRealTimeProvider && Amplify._config.aws_appsync_graphqlEndpoint === ume_config[api]) {
        return;
    }

    // cache the current realtime provider before switching
    ume_config.cache = ume_config.cache ? ume_config.cache : {};

    if (!ume_config.cache[Amplify._config.aws_appsync_graphqlEndpoint]) {
        ume_config.cache[Amplify._config.aws_appsync_graphqlEndpoint] = PubSub._awsAppSyncRealTimeProvider;
    }

    // use a cached realtimeprovider or set to to null and have it create one
    PubSub._awsAppSyncRealTimeProvider = ume_config.cache[ume_config[api]] || null;

    if (useNewRealTimeProvider) {
        PubSub._awsAppSyncRealTimeProvider = null;
    }

    Amplify.configure({...ume_config, aws_appsync_graphqlEndpoint: ume_config[api],});
};


const getConfig = async () => {
    // Store time offset if user's clock is off from global internet time
    window.timeoffset = 0;
    const version = getVersion();
    const configUrl = getApiEndpoint(`/config?id=${version}`);
    await fetch(configUrl).then(function (response) {
        response.json().then(function (data) {
            window.ume_config = data;
            window.timeoffset = window.ume_config.timestamp - Date.now()
            DateUtils.setClockOffset(window.timeoffset) // Fixes clock skew so AWS requests can be signed correctly
            window.configure_amplify('arcade_endpoint')
            ReactDOM.render(<App config={data} />, document.getElementById('root'));
        });

    }).catch(function (err) {
        return
    })

}

getConfig();

// add random function for returning an element from an array
Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}

// Disable right click context menu
if (window.location.hostname !== 'localhost') {
    document.oncontextmenu = () => false;
}


// Get the branch version
window.branch_version = "Unknown version"

if (branch_version_file && branch_version_file.branch_version) {
    window.branch_version = branch_version_file.branch_version
}

serviceWorkerRegistration.register();
