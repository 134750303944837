import React, { useEffect, useState } from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { useUserData } from 'contexts/userDataContext';
import { gtagEvent } from 'services/gtags';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { updateUserEmail, subscribeToMailingList, sendReceipt } from './utils';
import './styles.scss';

const UnlockForm = ({ plan }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [order, setOrder] = useState();

  const { username, password } = useUserData();

  // Step 0: The rendering of this component constitutes the beginning of
  // checkout.
  useEffect(() => {
    if (!plan) {
      return;
    }

    gtagEvent('begin_checkout', { items: [ plan.toAnalyticsItem() ] });
  }, [plan]);

  // Step 1 gets the user's email and their mailing list decision. When it
  // returns, wee need to record the email in the database and subscribe them
  // to the mailing list before moving on to the payment step. These tasks can
  // be handled asynchronously, so there's no need to delay continuation to
  // step 2.
  const handleStep1 = ({ email, confirmedMailingList }) => {
    if (email) {
      setEmail(email);

      updateUserEmail(email, confirmedMailingList).then(() => {
        if (confirmedMailingList) {
          // TODO: Uncomment this when there is a mailing list to subscribe to.
          // subscribeToMailingList();
        }
      });
    }

    setStep(2);
  };

  // Step 2 handles payment for the subscription. When it returns, we need to
  // send the user a receipt via email and carry on to step 3, which shows a
  // mini receipt and lets the user know when their subscription has been
  // activated.
  const handleStep2 = (order, firstBill) => {
    setOrder(order);

    if (order) {
      // TODO: Send analytics event when there is an order (i.e. free
      // membership selected). Note that that would either be a new sign up or
      // a downgrade, the event should reflect that.
      gtagEvent('purchase', {
        transaction_id: order.id,
        items: [ plan.toAnalyticsItem() ]
      });
    }

    sendReceipt(plan, {
      username,
      password,
      first_bill: firstBill
    });

    setStep(3);
  };

  const paypalOptions = {
    'client-id': window.ume_config.paypal_client_id,
    currency: 'USD',
    intent: 'subscription',
    vault: true
  };

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <section className="unlock-form-section">
        <div className="unlock-form">
          <h2 className="unlock-form__title">
            {step < 3 ? `CHECK OUT Step ${step} of 2` : 'MEMBERSHIP CONFIRMATION'}
          </h2>

          {step === 1 && <Step1 plan={plan} onSubmit={handleStep1} />}
          {step === 2 && <Step2 plan={plan} email={email} onSubmit={handleStep2} />}
          {step === 3 && (
            <Step3
              plan={plan}
              email={email}
              order={order}
              checkForSubscriptionUpdates={plan.cost === 0}
            />
          )}
        </div>
      </section>
    </PayPalScriptProvider>
  );
};

export default UnlockForm;
