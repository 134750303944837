import React, { useRef, useState, useEffect } from 'react';
import './styles.scss';

const useResizeObserver = box => {
    const [contentRect, setContentRect] = useState({});
    const resizeObserver = useRef(null);

    useEffect(() => {
        const observe = cls => {
            resizeObserver.current = new cls(entries => {
                const { width, height } = entries[0].contentRect;
                setContentRect({ width, height });
            });

            resizeObserver.current.observe(box.current);

            return disconnect;
        };

        observe(window.ResizeObserver);
    }, [box]);

    const disconnect = () => {
        if (resizeObserver.current) {
            resizeObserver.current.disconnect();
        }
    }

    return contentRect;
};

const GameFrame = ({ activity }) => {
    const [showWebgl, setShowWebgl] = useState(false);
    const iframeWidth = 1360;
    const iframeHeight = 822;

    const containerRef = useRef();
    const {
        width: containerWidth,
        height: containerHeight
    } = useResizeObserver(containerRef);

    const widthRatio = containerWidth / iframeWidth;
    const heightRatio = containerHeight / iframeHeight;
    const scale = Math.min(widthRatio, heightRatio);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowWebgl(true);
        }, 3000);

        return () => clearTimeout(timeout);
    }, []);

    const iframeStyle = {
        width: `${iframeWidth}px`,
        height: `${iframeHeight}px`,
        transform: `scale(${scale})`,
    };

    const backgroundStyle = {
        width: `${iframeWidth}px`,
        transform: `scale(calc(${scale} * 0.92)) translateY(-3%)`,
    }

    return (
        <div className="game-frame" ref={containerRef}>
            <img
                src={activity.media.thumbnail}
                alt=""
                className="game-cover"
                style={backgroundStyle} />
            <iframe
                title={`${activity.user.username}'s game`}
                src={`${activity.media.webgl}/index.html`}
                allowFullScreen
                className={`unity-iframe ${showWebgl ? '' : 'unity-iframe--hidden'}`}
                style={iframeStyle}
            ></iframe>
        </div>
    );
};

export default GameFrame;
