import React, { Component } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

import './styles.scss';


class MicIndicator extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }

    }

    static defaultProps = {
        student: null
    }

    parseMicStatus = () => {
        if (!this.props.student) {
            return 'broken'
        }

        if (this.props.student.twilioNetQuality === undefined || this.props.student.twilioNetQuality === null || this.props.student.twilioNetQuality === 0) {
            return 'broken'
        }

        if (this.props.student.mute && this.props.student.micToggleable) {
            return 'muted-enabled'
        } else if (this.props.student.mute && !this.props.student.micToggleable) {
            return 'muted-disabled'
        } else if (!this.props.student.mute && this.props.student.micToggleable) {
            return 'unmuted-enabled'
        } else if (!this.props.student.mute && !this.props.student.micToggleable) {
            return 'unmuted-disabled'
        } else {
            // You're in some super saiyan state, but we only have enough assets to show you its broken
            console.error("Incorrect state of mute and micToggleable: ", this.props.student.mute, this.props.student.micToggleable)
            return 'broken'
        }

    }

    render() {
        const micStatus = this.parseMicStatus()

        const classes = {
            main: clsx('MicIndicator__wrapper'),
            mic: clsx('mic', micStatus),
        };

        return (
            <div className={classes.main}>
                <div className={classes.mic} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    identity: state.auth.identity,
});

export default connect(mapStateToProps, null)(MicIndicator);
