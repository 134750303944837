import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setContextMenu } from 'actions/contextMenuAction';
import { setPopUp } from 'actions/popupAction';

import clsx from 'clsx';

import './styles.scss';
class ClassEnd extends Component {

    constructor(props) {
        super(props);

        this.state = {
            timerText: ''
        }

        this.popupShown = false
    }

    componentDidMount() {
        //this.goBack()
    }

    componentWillUnmount() {
        clearTimeout(this.popupTimer)
        this.popupTimer = null

        clearInterval(this.countdownTimer)
        this.countdownTimer = null

        this.props.setPopUp({ show: false })

    }

    componentDidUpdate(prevProps, prevState) {
        // When this screen is shown then start the timeout (and only once)
        if (this.props.show && !this.popupShown) {
            this.popupShown = true

            // After this page is shown, in 5 secs start a timer counting down from 2 minutes
            this.popupTimer = setTimeout(() => { this.showTimeRemaining(120000, null, this.goBack) }, 5000)
        }
        /*
                // When the timertext updates update the popup - it will only change on an interval started above
                if (this.state.timerText !== prevState.timerText) {
                    
                    // Have to update the popup entirely because the contents dont map as states internally so repass them as props
                    this.props.setPopUp({
                        alert: true, show: true, contents: () => {
                            return (
                                <div className='classEndPopup'>
                                    <div className='title'>
                                        Exiting to the Arcade...
                                    <br />
                                        {this.state.timerText}
                                    </div>
        
                                    <div className="buttons">
                                        <Button label="Exit" callback={() => { this.goBack() }} />
                                    </div>
                                </div>
                            )
                        }, timer: null
                    })
                    
                }
        */
    }

    showTimeRemaining = (durationUntil, durationStartFn, durationEndFn) => {
        this.timeRemaining = durationUntil

        // Clear existing
        clearInterval(this.countdownTimer)
        this.countdownTimer = null

        // Do the starting function
        if (durationStartFn !== null) {
            durationStartFn()
        }

        this.countdownTimer = setInterval(() => {
            // If class is ended clear the timer and run the end fn
            if (this.timeRemaining <= 0) {
                clearInterval(this.countdownTimer)
                this.countdownTimer = null

                if (durationEndFn !== null) {
                    durationEndFn()
                }

                return
            }

            if (this.timeRemaining <= 30000) {
                this.setState({ timerStatus: 'warning' })
            }

            // Parse out the components given a duration in ms
            //var days = Math.floor(this.timeRemaining / (1000 * 60 * 60 * 24));
            //var hours = Math.floor((this.timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((this.timeRemaining % (1000 * 60)) / 1000);

            // Show/hide the minutes indication
            var minutesText = ''

            if (minutes > 0) {
                minutesText = minutes + 'm'
            }

            // Compile the text
            const text = minutesText + seconds + 's'
            this.setState({ timerText: text })

            // Update the timer
            this.timeRemaining = this.timeRemaining - 1000

        }, 1000)

    }

    goBack = () => {
        // Temporarily disable kicking the instructor and students out of the studio
        // window.location.replace(document.location.origin);

    }

    render() {
        const classes = {
            main: clsx('ClassEnd__wrapper'),
        };


        return (
            <div className={classes.main}>
                <div className="title">Class over!</div>
            </div>
        )
    }

}
const mapStateToProps = state => ({
    callback: state.contextMenu.callback,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ setPopUp, setContextMenu }, dispatch);

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ClassEnd));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClassEnd));
