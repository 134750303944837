import { useEffect, useState } from 'react';
import { fetchActivity } from 'api';

import Layout from 'components/Layout';
import GameFrame from './components/GameFrame';
import Badge from './components/Badge';
import './styles.scss';

const Share = ({ match }) => {
    const [activity, setActivity] = useState();

    // Finding the game
    useEffect(() => {
        (async () => {
            const fetchedActivity = await fetchActivity(match.params.id);
            setActivity(fetchedActivity);
        })();
    }, [match.params.id]);

    const breadcrumbs = [
        {
            name: 'PLAY',
            uri: '/play'
        },
        {
            name: activity?.user.username,
            uri: '#'
        },
        {
            name: activity?.title,
            uri: '#'
        }
    ];

    return (
        <Layout tab='arcade' breadcrumbs={breadcrumbs}>
            {activity && <GameFrame activity={activity} />}
            <div className="share-body">
                <div className="share-body__text">
                    <h2 className="share-body__title">
                        {activity?.title}
                    </h2>
                    <p className="share-body__description">
                        {activity?.description}
                    </p>
                </div>
                <div>
                    <Badge activity={activity} />
                </div>
            </div>
        </Layout>
    );
};

export default Share;
