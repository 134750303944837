import React, { useState } from 'react';
import { useAudio } from 'contexts/audioContext';
import BaseCard from 'components/CardGrid/BaseCard';
import TrackedButton from 'components/TrackedButton';
import ProjectFromSeedModal from './ProjectFromSeedModal';

const StartNewProjectCard = ({ activities }) => {
  const [showModal, setShowModal] = useState(false);
  const { play } = useAudio();

  const handleClick = () => {
    play('click');
    setShowModal(true);
  };

  return (
    <>
    <BaseCard className="cl-card--button">
      <TrackedButton onClick={handleClick} eventName="new_project_modal_opened">
        <p className="new-project-card__text">
          Start New Project
        </p>
        <span className="new-project-card__icon">
          +
        </span>
      </TrackedButton>
    </BaseCard>

      <ProjectFromSeedModal
        activities={activities}
        show={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

export default StartNewProjectCard;
