import { SET_ACTIVITY } from './types';
import { SET_NAVBAR } from './types';

// set artifacts
export const setActivity = activity => {
    return {
        type: SET_ACTIVITY,
        payload: activity
    };
};


// set artifacts
export const setNavbar = state => {
    return {
        type: SET_NAVBAR,
        payload: state
    };
};

