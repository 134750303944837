import { REFRESH_TOKEN, SET_PROFILE, SET_SIGNIN } from '../actions/types';

const initialState = {
  authentication: null,
  authenticated: false,
  identity: null,
  username: null,
  groups: [],
  signIn: false,
  subscriptions: null
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case REFRESH_TOKEN:
      return {
        ...state,
        subscriptions: action.payload.subscriptions
      };
    case SET_PROFILE:
      return {
        ...state,
        //...action.payload,
        authentication: action.payload,
        authenticated: action.payload.authenticated,
        identity: action.payload.authenticated ? action.payload.identityId : null,
        username: action.payload.authenticated ? action.payload.username : null,
        subscriptions: action.payload.subscriptions
      };
    case SET_SIGNIN:
      return {
        ...state,
        //...action.payload,
        signIn: action.payload
      };
    default:
      return state;
  }
}

export default authReducer;
