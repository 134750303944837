import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

const QueryStringValidator = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const version = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('version');
  }, [location]);

  useEffect(() => {
    if (!version) {
      return;
    }

    const unlisten = history.listen((newLocation) => {
      const searchParams = new URLSearchParams(newLocation.search);

      // If the route we're going to doesn't have a version, replace it with a
      // route that does.
      if (!searchParams.has('version')) {
        searchParams.set('version', version);
        const newUrl = `${newLocation.pathname}?${searchParams.toString()}`

        history.replace(newUrl, newLocation.state);
      }
    });

    return unlisten;
  }, [history, version]);

  return <>{children}</>;
};

export default QueryStringValidator;
