import { combineReducers } from 'redux';
import artifactReducer from './artifactReducer';
import authReducer from './authReducer';
import activityReducer from './activityReducer';
import popupReducer from './popupReducer';
import contextMenuReducer from './contextMenuReducer';
import studioCommandReducer from './studioCommandReducer';
import studioScreenReducer from './studioScreenReducer';
import studioSelectedStudentReducer from './studioSelectedStudentReducer';
import studioStudentsReducer from './studioStudentsReducer';
import studioCommsVolumeReducer from './studioCommsVolumeReducer';

export default combineReducers({
  artifact: artifactReducer,
  auth: authReducer,
  activity: activityReducer,
  popup: popupReducer,
  contextMenu: contextMenuReducer,
  studioCommand: studioCommandReducer,
  studioScreen: studioScreenReducer,
  studioSelectedStudent: studioSelectedStudentReducer,
  studioStudents: studioStudentsReducer,
  studioCommsVolume: studioCommsVolumeReducer,
});
