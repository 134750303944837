/**
 * Utilities used by the unlock form for updating the user's account and
 * communicating with them via email.
 */
import { asyncSend } from 'graphql/handler';
import { mailCommand, updateUser } from 'graphql/arcade';

/**
 * Updates the user's email in the databsae and records whether or not they
 * wish to be subscribed to the mailing list.
 *
 * @param {string} email The user's email.
 * @param {boolean} subscribeToMailingList True if the user wants to subscribe
 *   to our mailing list, and false otherwise.
 */
export const updateUserEmail = async (email, subscribeToMailingList = false) => {
  window.configure_amplify('arcade_endpoint');

  const payload = {
    input: {
      email,
      subscribed_to_mailing_list: subscribeToMailingList
    }
  };

  try {
    await asyncSend(updateUser, payload);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Subscribes the user to our mailing list
 *
 * @param {string} mailingList The mailing list to subscribe to
 */
export const subscribeToMailingList = async mailingList => {
  window.configure_amplify('arcade_endpoint');
  
  const payload = {
    input: {
      command: 'subscribe_to_mailing_list',
      args: JSON.stringify({
        mailing_list: mailingList
      })
    }
  };

  try {
    await asyncSend(mailCommand, payload);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Sends the user a receipt for the plan they just subscribed to
 *
 * @param {string} plan The plan the user has subscribed to
 * @param {object} args Arguments to fill in the email
 */
export const sendReceipt = async (plan, args) => {
  window.configure_amplify('arcade_endpoint');

  const template = plan.name === 'Free'
    ? 'updated-free-membership'
    : 'new-creator-membership';
  
  const payload = {
    input: {
      command: 'send_email',
      args: JSON.stringify({
        template,
        variables: args
      })
    }
  };

  try {
    await asyncSend(mailCommand, payload);
  } catch (err) {
    console.error(err);
  }
};

/**
 * Updates the user's current memberships by PATCHing to /paypal.
 *
 * @param {object} args
 * @param {string} args.identity The identity to update
 * @param {Array?} args.subscribeTo A list of memberships to add
 * @param {Array?} args.unsubscribeFrom A list of memberships to remove
 */
export const updateMemberships = async ({
  identity,
  subscribeTo,
  unsubscribeFrom
}) => {
  const url = 'https://api.ume.games/dev/paypal';
  const payload = { identity, subscribeTo, unsubscribeFrom };

  const response = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  });

  const body = await response.text();

  return body;
};
