import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { endMyStudioSession } from 'api';
import TrackedButton from 'components/TrackedButton';
import { LOBBY_SCREEN  } from '../../types';
import './styles.scss';

const CloseButton = ({ aggregate }) => {
  const history = useHistory();
  const { screen_id } = useSelector(state => state.studioScreen);
  const { identity } = useSelector(state => state.auth);

  const buttonStates = {
    [LOBBY_SCREEN]: {
      message: 'Close',
      className: '',
      action() {
        history.replace(`/creator-lab/class-${aggregate.context}`);
      },
    },
    default: {
      message: 'Save & Close',
      className: 'close-button--danger',
      action() {
        // TODO: replace window.confirm with something that looks nicer.
        if (window.confirm('Are you sure?')) {
          endMyStudioSession(`class-${aggregate.context}`).then(response => {
            console.log({ updateSeat: response });
          });
        }
      }
    }
  };

  const buttonState = buttonStates[buttonStates.hasOwnProperty(screen_id) ? screen_id : 'default'];

  const styles = {
    button: clsx('close-button', buttonState.className)
  };

  return (
    <TrackedButton
      type="button"
      onClick={buttonState.action}
      className={styles.button}
      eventName="lab_terminate"
      eventParams={{
        identity,
        context: `class-${aggregate.context}`
      }}
    >
      {buttonState.message}
    </TrackedButton>
  );
};

export default CloseButton;
