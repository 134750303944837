
import React, { Component } from 'react';
import './styles.scss';
import clsx from 'clsx';


class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }
  onClick = (e) => {
    this.props.callback && this.props.callback(this.props.label);
  }
  render() {
    const classes = {
      wrapper: clsx('Button__wrapper', this.props.align && this.props.align),
      enabled: clsx('button', this.props.basic && 'simple',
        'fancy' && 'neonBorder glow_purple',
        this.props.selected && 'selected textGlow_purpleBold',
      ),
      disabled: clsx('button neonBorder glow_purple disabled ', this.props.basic && 'simple'),
    };
    return (
      <div className={classes.wrapper}>
        <button
          className={this.props.disabled ? classes.disabled : classes.enabled}
          onClick={this.onClick}
          type={this.props.type}
        >
          {this.props.label}
        </button>
      </div>
    )
  }
}

export default Button;
