import React, { Component } from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';

import { setProfile, setSignIn } from 'actions/authAction';
import cognitoService from 'services/cognito';
import { Authenticator, Greetings, VerifyContact, ConfirmSignUp, } from 'aws-amplify-react';

import CreateUser from './CreateUser'
import SignIn from './SignIn'
import ConfirmSignIn from './ConfirmSignIn'

import './styles.scss';




class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth_id: 0,
      urls: {},
      authenticating: true,
      authState: 'signIn',
      signUpMode: '',
      status: 'create',
      password_error: false,
      login_error: false,
      password_msg: '',
      login_msg: ''
    }
  }

  componentDidMount() {
    !this.props.authenticated && this.auth_change('signIn');
    cognitoService.get_user(this.cognito_callback)
  }

  componentDidUpdate(prevProps, prevState) {
    this.state.authState === 'signedIn' && !this.props.authenticated && this.auth_change('signIn')
  }

  cognito_callback = (response => {
    if (response.authenticating) {
      this.setState({ authenticating: true, authenticationMessage: response.message })
      return
    }
    this.setState({ authenticating: false })
    this.props.setProfile(response)
  })
  auth_change = (authState) => {
    if (authState === 'confirmSignup') {
      this.setState({ auth_id: this.state.auth_id + 1, authState: authState, authenticating: true },
        () => { cognitoService.get_user(this.cognito_callback) })
    }
    else if (authState === 'signIn') {
      this.setState({ authState: authState, signUpMode: '', authenticating: true },
        () => { cognitoService.get_user(this.cognito_callback) })
    }
    else {
      this.setState({ authState: authState, authenticating: true },
        () => { cognitoService.get_user(this.cognito_callback) })
    }

  }

  get_signed_url = (data) => {
    if (!this.props.authentication) {
      return
    }
    if (this.state.urls[data]) return this.state.urls[data]
    return { data: this.props.authentication.s3.getSignedUrl('getObject', { Bucket: 'ume-studio-user', Key: data, Expires: 3600 }) }

  }
  handleClose = () => {
    this.props.setSignIn(false)
    this.setState({ authState: 'signIn' })
  }
  signUp_parent = () => {
    this.setState({ authState: 'signUp', signUpMode: 'parent' })
  }


  validate_text(value) {
    if (!value) {
      return false;
    }
    if (value.length < 6) {
      return true;
    }
    var exp = new RegExp('^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$')
    return !exp.exec(value)

  }
  validate_login = (value) => {
    const invalid = this.validate_text(value)
    this.setState({ login: value, login_error: invalid, login_msg: 'Login needs at least 6 letters and numbers.' })
    return true
  }
  validate_password = (value) => {
    const invalid = this.validate_text(value)
    this.setState({ password: value, password_error: invalid, password_msg: 'Password needs at least 6 letters and numbers.' })
    return true
  }
  validate_avatar = (value) => {
    this.setState({ avatar: value })
    return true
  }
  requireNewPassword = () => {
    return (<Authenticator
      key={this.state.auth_id}
      hide={[Greetings, VerifyContact, ConfirmSignUp, ConfirmSignIn]}
      includeGreetings={false}
      onStateChange={this.auth_change}
      authState={'signUp'}
    >
    </Authenticator>
    )
  }
  confirmSignUp = () => {
    return (
      <Authenticator
        key={this.state.auth_id}
        hide={[Greetings,]}
        includeGreetings={false}
        onStateChange={this.auth_change}
        authState={'confirmSignUp'}
      >
      </Authenticator>
    )
  }
  signedUp = () => {
    return (this.signIn())
  }


  login = (login, password, callback) => {
    cognitoService.signIn(login, password, (response) => {
      if (response.challengeName === 'SMS_MFA') {
        return this.setState({
          user: response,
          status: 'SMS_MFA',
          message: response.challengeParam.CODE_DELIVERY_DESTINATION
        }, () => {
          this.auth_change('confirmSignIn')
        })
      }
      else if (response.username) {
        return this.setState({ status: 'success' }, () => {
          this.auth_change('signedIn')
        })
      }
      if (response.authenticating) {
        return this.setState({ status: response.message, message: response.message })

      }
      if (response.code === 'NotAuthorizedException') {
        return this.setState({ status: 'error', message: response.message })
      }
      else {
        return this.setState({ status: 'error', message: 'Incorrect username or password.' })
      }
    })
  }
  login_confirmSignIn = (code, callback) => {
    cognitoService.confirmSignIn(this.state.user, code, (response) => {
      if (response.code === 'CodeMismatchException' || response.status === 'invalid') {
        return this.setState({ status: 'error', message: response.message })
      }
      else {
        return this.setState({ status: 'success' }, () => {
          this.auth_change('signedIn')
        })
      }

    })
  }
  signUp = () => {
    return (
      <CreateUser
        status={this.state.status}
        message={this.state.message}
        callback={this.login}
      />
    )
  }
  signIn = () => {
    if (!this.props.authenticated && this.props.signIn) {
      return (<SignIn
        activity={this.props.activity} // pass studio/arcade/play to sign-in
        status={this.state.status === 'success' ? 'create' : this.state.status}
        message={this.state.message}
        callback={this.login}
        onStateChange={this.auth_change}
      />)
    }
    return (<div />);
  }

  confirmSignIn = () => {
    return (
      <ConfirmSignIn
        status={this.state.status}
        message={this.state.message}
        callback={this.login_confirmSignIn}
      />
    )
  }

  signedIn = () => {
    return (<div />)
  }
  verifyContact = () => {
    return (<div />)
  }

  render() {
    if (this.props.authenticated || this.state.authenticating) { //signed in
      return (<div></div>)
    }

    var hideAuth = !this.props.signIn

    const classes = {
      authenticator: clsx('Authenticator', hideAuth && 'hide')
    };
    const title = !this.props.authenticated ? 'Log in' + this.state.signUpMode : this.state.authenticationMessage
    return (
      < div className={classes.authenticator} >

        <div className="Auth__wrapper">
          <div className="title-container">
            <div className="title">{title}</div>
            {this.props.prompt && <p className="prompt">{this.props.prompt}</p>}
          </div>
          {this[this.state.authState]()}
        </div>
      </div >
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.auth.authentication,
  authenticated: state.auth.authenticated,
  signIn: state.auth.signIn,
  activity: state.activity,

});

const mapDispatchToProps = {
  setProfile, setSignIn
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
