import { useEffect, useState } from 'react';
import vmBuilding from 'assets/images/vmBuilding.gif';
import './styles.scss';

const LoadingScreen = () => {
    const [progress, setProgress] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            if (Math.random() < 0.25) {
                setProgress(p => p + 1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="create-loading">
            <img
                className="create-loading__image"
                src={vmBuilding}
                alt="Cats typing on computer"
            />

            {/* Hidden HTML progress element for a11y */}
            <progress
                className="create-loading__progress"
                value={progress}
                max="100"
            >
                {progress}%
            </progress>

            <div className="create-loading__progress-bar">
                <div
                    className="create-loading__progress-value"
                    style={{ width: `${progress}%` }}
                />
            </div>
        </div>
    );
};

export default LoadingScreen;
