import { useCallback, useMemo, useEffect, useState } from 'react';
import { fetchCourses } from 'api';
import { Course  } from 'models';

/**
 * React hook for retrieving the available seed projects.
 *
 * @method
 * @param {Array} lessons
 * @returns {ResourceState}
 */
const useCourses = (availableLessons) => {
  const [loaded, setLoaded] = useState(false);
  const [courseData, setCourseData] = useState([]);

  const loadCourses = useCallback(async () => {
    const data = await fetchCourses();

    setCourseData(data);
    setLoaded(true);
  }, []);

  useEffect(loadCourses, [loadCourses]);

  const populatedCourses = useMemo(() => {
    return courseData.map(course => {
      const lessons = course.lessonContexts.map(lessonContext => {
        return availableLessons?.find(lesson => lesson.context === lessonContext);
      }).filter(lesson => !!lesson);

      return new Course({
        ...course,
        lessons
      });
    });
  }, [courseData, availableLessons]);

  return { loaded, data: populatedCourses };
};

export default useCourses;
