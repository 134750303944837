import React from 'react';
import { Link } from 'react-router-dom';
import partnerLogos from 'assets/images/partners';

const Footer = () => (
  <footer className="footer">
    <div className="footer__primary">
      <ul className="partner-list">
        {Array.from(partnerLogos).map(([name, logo]) => (
          <li key={name} className="partner-list__item">
            <img src={logo} alt={name} className="footer__logo" />
          </li>
        ))}
      </ul>

      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a href="https://ume.academy/contact" target="_blank" className="footer__link footer__link--primary">
        Contact us for Partnerships
      </a>
    </div>
    <div className="footer__secondary">
      <div className="footer__link-container">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://ume.academy/privacy" target="_blank" className="footer__link footer__link--secondary">
          Privacy and Cookies
        </a>
        <Link to="/terms" className="footer__link footer__link--secondary">
          Terms of Use
        </Link>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://ume.academy" target="_blank" className="footer__link footer__link--secondary">
          About UME Academy
        </a>
        <Link to="/faq" target="_blank" className="footer__link footer__link--secondary">
          FAQ
        </Link>
      </div>
    </div>
  </footer>
);

export default Footer;
