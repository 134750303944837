import React, { createContext, useEffect, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchMyPrivateRecord } from 'api';

const UserDataContext = createContext(null);

export const UserDataProvider = ({ children }) => {
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [facilitatorCode, setFacilitatorCode] = useState();
  const { identity } = useSelector(store => store.auth);

  useEffect(() => {
    if (!identity) {
      setUsername(null);
      setPassword(null);
      setFacilitatorCode(undefined);
      return;
    }

    (async () => {
      const { username, password, facilitator_code } = await fetchMyPrivateRecord();
      setUsername(username);
      setPassword(password);
      setFacilitatorCode(facilitator_code);
    })();
  }, [identity]);

  return (
    <UserDataContext.Provider value={{ username, password, facilitatorCode }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
