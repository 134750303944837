import { useCallback, useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setProfile } from 'actions/authAction';
import cognito from 'services/cognito';
import { useAudio } from 'contexts/audioContext';
import { useSignedUrls } from 'hooks';
import { updatePublicUserInfo } from 'api';
import { AVATARS } from 'constants/strings';

import './styles.scss';

const avatarPaths = AVATARS.map(a => `avatars/${a}`);

const chooseRandom = (array, n) => {
    const needed = Math.min(n, array.length);
    const indices = [];

    while (indices.length < needed) {
        const index = Math.floor(Math.random() * array.length);

        if (!indices.includes(index)) {
            indices.push(index);
        }
    }

    return indices.map(index => array[index]);
};

const AvatarPicker = ({ className, children, onClick }) => {
    const dispatch = useDispatch();
    const { play } = useAudio();
    const urls = useSignedUrls(avatarPaths);
    const ref = useRef();
    const [allAvatars, setAllAvatars] = useState([]);
    const [currentOptions, setCurrentOptions] = useState([]);

    const handleSelect = avatar => {
        play('click');

        updatePublicUserInfo({ avatar })
            .then(() => cognito.get_user(data => {
                dispatch(setProfile(data));
            }));

        ref.current.close();
    };

    const handleOpenClick = event => {
        onClick?.(event);
        ref.current.showModal();
    };

    const handleDialogClick = e => {
        const dialog = ref.current;
        const rect = dialog.getBoundingClientRect();

        const isInDialog = (rect.top <= e.clientY && e.clientY <= rect.top + rect.height && rect.left <= e.clientX && e.clientX <= rect.left + rect.width);
        if (!isInDialog) {
            dialog.close();
        }
    };

    const shuffle = items => setCurrentOptions(chooseRandom(items, 5));

    const triggerShuffle = items => {
        play('click');
        shuffle(items);
    };

    useEffect(() => {
        const items = (urls || []).map((url, i) => ({ name: AVATARS[i], url }));
        setAllAvatars(items);
        shuffle(items);
    }, [urls]);

    return (
        <>
            <button className={className} onClick={handleOpenClick}>
                {children}
            </button>
            <dialog ref={ref} className="avatar-picker" onClick={handleDialogClick}>
                <header className="avatar-picker__header">
                    <h2 className="avatar-picker__title">
                        Choose an Avatar
                    </h2>
                    <form method="dialog">
                        <button type="submit" className="avatar-picker__close-button" onClick={() => play('click')}>
                            X
                        </button>
                    </form>
                </header>

                <div className="avatar-picker__buttons">
                    {currentOptions.map((item, i) => (
                        <button
                            className="avatar-picker__button avatar-picker__button--avatar"
                            key={i}
                            onClick={() => handleSelect(item.name)}
                        >
                            <img
                                className="avatar-picker__avatar"
                                width="200px"
                                height="200px"
                                src={item.url} alt="Avatar"
                            />
                        </button>
                    ))}
                    <button
                        className="avatar-picker__button avatar-picker__button--text"
                        onClick={() => triggerShuffle(allAvatars)}
                    >
                        <span className="avatar-picker__button-text">
                            More
                        </span>
                    </button>
                </div>
            </dialog>
        </>
    );
};

export default AvatarPicker;
