import DefaultState from './anvil-md.png';
import HoverState from './anvil-md-hover.gif';

const anvil = {
  defaultState: DefaultState,
  hoverState: HoverState,
  activeState: DefaultState
};

export default anvil;
