/**
 * Turns a time into a formatted time string. Time always includes seconds and
 * minutes; hours are added if there is more that 1 hour in the time. Units are
 * separated with colons.
 *
 * @method
 * @param {number} time The time to format, in milliseconds
 * @returns {String} A formatted version of the time
 */
export const formatTime = time => {
  const totalSeconds = Math.max(Math.floor(time / 1000), 0);
  const seconds = totalSeconds % 60;
  const totalMinutes = Math.floor(totalSeconds / 60);
  const minutes = totalMinutes % 60;
  const hours = Math.floor(totalMinutes / 60);

  const segments = [minutes, seconds];
  if (hours > 0) {
    segments.unshift(hours);
  }

  return segments.map((segment, i) => {
    // The first segment looks weird if it has leading zeros.
    if (i === 0) {
      return segment;
    }
    return segment.toString().padStart(2, '0');
  }).join(':');
};
