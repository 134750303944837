class Course {
  constructor(data) {
    this.id = data.id ?? data.objectID;
    this.context = data.context ?? data.context_id;
    this.title = data.title;
    this.level = data.level;
    this.description = data.description;
    this.curriculumAreas = data.curriculumAreas;
    this.media = data.media;
    this.lessonContexts = data.lessonContexts ?? data.lesson_contexts ?? [];
    this.lessons = data.lessons ?? [];
  }

  isComingSoon() {
    return this.lessons.length === 0;
  }
}

export default Course;
