import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Layout from 'components/Layout';
import { useMyClasses, useSeeds, useCourses } from 'hooks';
import Grid from 'components/CardGrid';
import SignInCard from 'components/CardGrid/SignInCard';
import LessonCard from './components/LessonCard';
import CourseCard from './components/CourseCard';
import OutschoolCard from './components/OutschoolCard';
import VideoGameCharacters from 'assets/images/community.png';
import './styles.scss';

const Learn = () => {
  const { authenticated } = useSelector(state => state.auth);
  const classData = useMyClasses();
  const { data: allLessons } = useSeeds();
  const { data: courses } = useCourses(allLessons);

  const lessons = useMemo(() => {
    return allLessons.filter(lesson => {
      return !courses.some(course => course.lessons.includes(lesson));
    });
  }, [allLessons, courses]);

  const cards = useMemo(() => {
    const cards = classData.loaded ? classData.data.map(data => data.toCard()) : [];

    if (!authenticated) {
      cards.push(<SignInCard key="sign-in" />);
    }
    cards.push(<OutschoolCard key="outschool" />);

    return cards;
  }, [classData, authenticated]);

  return (
    <Layout className="learn" tab="learn">
      <header className="learn__header">
        <p className="learn__title learn__title--header">
          Start your game design journey!
        </p>
        <p className="learn__tagline">
          Looking to build your skills as a game designer? Just getting started? We've got quick access to skill building projects that will get you creating games in no time. Check them out below!
        </p>
        <img src={VideoGameCharacters} alt="" width="320px" className="learn__image" />
      </header>

      <section className="learn__section">
        <div className="learn__banner">
          <h2 className="learn__title">
            Skill Building Projects
          </h2>

          <a href="#live-classes" className="learn__link">
            view LIVE Classes
          </a>
        </div>

        <div className="learn__body learn__body--projects">
          <ul className="learn__lesson-list">
            {lessons.map(lesson => (
              <li key={lesson.id}>
                <LessonCard activity={lesson} />
              </li>
            ))}
          </ul>

          <ul className="learn__course-list">
            {courses.map(course => (
              <li key={course.id}>
                <CourseCard course={course} />
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="learn__section">
        <div className="learn__banner">
          <h2 className="learn__title">
            Live Classes
          </h2>
          <div className="scroll-target" id="live-classes" />
        </div>

        <div className="learn__body learn__body--bottom">
          <Grid className="learn__class-grid">
            {cards}
          </Grid>
        </div>
      </section>
    </Layout>
  );
};

export default Learn;
