import algoliasearch from 'algoliasearch';

const algolia = algoliasearch('7IZ81A4WRZ', '878e2a4818ea9d0dae5455b4063fe503');

/**
 * Queries a given Algolia index, optionally including additional query
 * parameters.
 *
 * @method
 * @async
 * @param {string} query The string to search the index for
 * @param {string} indexName The name of the index to query
 * @param {object} params Additional parameters to pass to the search
 * @returns {Array} An array of objects returned from the search
 */
export const searchAlgolia = async (query, indexName = 'game', params = {}) => {
  const index = algolia.initIndex(indexName);

  const response = await index.search(query, params);

  return response;
};

/**
 * Retrieves an Algolia object by ID.
 *
 * @method
 * @async
 * @param {string} objectID The ID of the object to retrieve
 * @param {string} indexName The name of the index to query
 * @returns {object} The object returned from the search
 */
export const getAlgoliaObject = async (objectID, indexName = 'game') => {
  const index = algolia.initIndex(indexName);

  const object = await index.getObject(objectID);

  return object;
};

export const find_games = (query = '', hits = 50, callback, facetFilters = []) => {
    const client = algolia.initIndex('games');
    client.search(query, { facetFilters: (query === '' ? ['category:featured', ...facetFilters] : facetFilters), hitsPerPage: hits }).then((data) => {
        const hits = data.hits
        let results = []
        let featured = {}
        hits.forEach(item => {
            if (item.category === 'Featured') {
                if (!featured[item.name] || featured[item.name].date < item.date) {
                    featured[item.name] = item
                }
                else {
                    return
                }
            }
            try {
                let game = item.game ? item.game : null
                let tags = item.tags ? item.tags : null
                if (!game) { // remap the new index format to the old
                    game = {}
                    //console.log('NEW STYLE') //ume-admin-1593457161438-1596668233696
                    game.id = item.id
                    game.created_at = ''
                    game.name = item.name
                    game.permission = item.permission
                    game.channels = {}
                    game.channels.thumbnail = item.thumbnail ? item.public.identity + '/' + item.thumbnail : ''
                    game.channels.webgl = item.webgl ? item.public.identity + '/' + item.webgl : ''
                    game.channels.title = item.title ? item.public.identity + '/' + item.title : ''
                    game.channels.video = item.video ? item.public.identity + '/' + item.video : ''
                    tags = {}
                    tags.theme = item.theme

                }
                item.game = game
                item.tags = tags
                results.push(item)
            }
            catch (error) {
                return
            }
        })
        callback && callback(results);
    })
}


export const find_lessons = (query = '', hits = 50, callback) => {

    const client = algolia.initIndex('lessons');
    client.search(query, { hitsPerPage: hits }).then((data) => {
        callback && callback(data.hits);
    })
}
