
import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';

import { query } from 'graphql/handler'
import { queryGamePlays } from 'graphql/arcade';
class Leaderboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leaderboard: [],
      aggregate_id: props.aggregate_id,
    };
  }
  componentDidMount() {
    this.getData()
    const canvas = this.refs.canvas
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const ctx = canvas.getContext("2d")

    var lineWidth = 3;
    var offset = 3 * lineWidth
    ctx.lineCap = "round";
    ctx.strokeStyle = '#ffffff';
    ctx.lineWidth = lineWidth;
    ctx.shadowBlur = 3 * lineWidth;
    ctx.shadowColor = "#ffd90088";
    this.titleBox(offset,
      offset,
      canvas.width - 2 * offset,
      canvas.height - 2 * offset,
      lineWidth * 3,
      offset,
      ctx)
  }
  titleBox(x, y, w, h, r, offset, ctx) {
    r = Math.max(0, r)
    h = Math.max(0, h)
    w = Math.max(0, w)

    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    ctx.beginPath();
    ctx.moveTo(w * .9, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.lineTo(w * .1 + 2 * offset, y);
    ctx.stroke();
  }
  componentDidUpdate(prevProps) {

    if (prevProps.aggregate_id !== this.props.aggregate_id) {
      this.setState({ aggregate_id: this.props.aggregate_id }, () => {
        this.getData()
      })
    }
  }
  getData = () => {
    if (!this.state.aggregate_id) {
      return this.setState({ leaderboard: [] })
    }
    query(queryGamePlays, { aggregate_id: this.state.aggregate_id }, (results) => {
      this.setState({ leaderboard: results })
    })
  }

  scrollListTo = () => {
    console.log('Leaderboard scrollListTo')
    /*
    this.scrollRef.refs.container.scrollTo({
      top: 200,
      left: 0,
      behavior: 'smooth'
    });
    */
  };

  render() {
    const settings = {
      accessibility: false,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      swipeToSlide: true,
      arrows: false,
      infinite: false,

    };
    return (
      <div className="Leaderboard__wrapper">
        <canvas className="canvas" ref="canvas" width={'100%'} height={'100%'} />

        <span className="list-title textGlow_yellowBold">LEADER BOARDS</span>
        < Slider className="list-content"
          ref={slider => (this.slider = slider)}
          {...settings}
        >
          {
            this.state.leaderboard.map((item, idx) => {
              return (
                <div key={idx} className="list-content__item bordered">
                  <div className="details">
                    <span>{idx + 1}</span>
                    <span className="name">{item.username ? item.username : 'Anonymous'}</span>
                    <span className="value">999999</span>
                  </div>
                </div>
              )
            })
          }
        </Slider>
      </div>
    )

  }
}

/*
<div key={idx} className="list-content__item neonBorder">
                                    <div className="details">
                                        <span>{idx + 1}</span>
                                        <span className="name">{item.username ? item.username : 'Anonymous'}</span>
                                        <span className="value">999999</span>
                                    </div>
                                </div>
                                */

export default Leaderboard;
