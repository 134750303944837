/**
 * @namespace models.activity
 */
import React from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { tags } from './constants';
import ActivityCard from 'components/CardGrid/ActivityCard';

const BUILDING_STATUSES = [
  'ready',
  'no-build',
  'build',
  'pending',
  'in-progress'
];

/**
 * Model for an activity-type publish in the Dynamo Publish table.
 */
class Activity {
  constructor(data) {
    this.timestamp = data.timestamp ? parseInt(data.timestamp) : 0;
    this.artifact = data.artifact || {};
    this.id = data.id;
    this.title = data.title || 'Project';
    this.status = data.status;
    this.tags = new Set(data.tags || []);
    this.media = data.media || {};
    this.description = data.description || '';
    this.metadata = {};
    this.summary = data.summary || '';
    this.user = {
      username: 'Creator',
      avatar: 'avatar_ghost_01.png',
      ...data.user
    };

    if (data.context) {
      const parsedContext = (data.context || '').split(':');
      this.context = parsedContext[parsedContext.length - 1];
    } else {
      this.context = null;
    }
  }

  /**
   * Initializes an activity with data received from a webview.
   */
  static fromWebview(webview) {
    return new Activity({
      ...webview,
      id: webview.objectID,
      context: webview.context_id,
      media: webview.media,
      timestamp: webview.date,
      status: 'complete',
      title: webview.publish_title
    });
  }

  /**
   * Initializes an activity with data received from the DDB Publish table.
   *
   * @param {Object}
   */
  static fromPublish(publish) {
    // Initialize the activity using the webview, then backfill with the extra
    // data that's available from the publish table.
    const activity = Activity.fromWebview(JSON.parse(publish.webview || '{}'));
    const metadata = JSON.parse(publish.metadata || '{}');

    if (publish.context) {
      activity.context = publish.context;
    }

    // Metadata will have the most up-to-date information, so use it since it's
    // available to DDB publishes
    activity.title = metadata.name || activity.title;
    activity.description = metadata.description || activity.description;

    activity.metadata = metadata;
    activity.artifact = Object.fromEntries(JSON.parse(publish.artifact || '[]')
                          .map(publish => publish.split(':')));
    activity.status = publish.status;

    return activity;
  }

  static fromGamesSchema(data) {
    const bucket = 'https://ume-studio-user.s3.us-west-2.amazonaws.com';
    const identity = data.public ? data.public.identity : data.identity;
    const webgl = data.channels ? data.channels.webgl : `${identity}/${data.webgl}`;
    const thumbnail = data.channels ? data.channels.thumbnail : `${identity}/${data.thumbnail}`;

    const activityData = {
      id: data.objectID,
      status: 'complete',
      user: {
        username: data.public ? data.public.username : data.username,
        identity
      },
      media: {
        webgl: `${bucket}/${webgl}`,
        thumbnail: `${bucket}/${thumbnail}`,
      }
    };

    if (data.public && data.public.avatar) {
      activityData.user.avatar = data.public.avatar;
    }

    return new Activity(activityData);
  }

  /**
   * Is this activity a seed project?
   *
   * @returns {boolean}
   */
  isSeed() {
    return this.tags.has(tags.SEED);
  }

  /**
   * Is this activity featured?
   *
   * @returns {boolean}
   */
  isFeatured() {
    return this.tags.has(tags.FEATURED);
  }

  /**
   * Is this activity an extension of another?
   *
   * @returns {boolean}
   */
  isExtension() {
    return this.tags.has(tags.EXTENSION) && this.user?.identity === 'studio';
  }

  /**
   * Is the activity presently building?
   *
   * @returns {boolean}
   */
  isBuilding() {
    return BUILDING_STATUSES.some(status => this.status.includes(status));
  }

  /**
   * Has the activity has been successfully built?
   *
   * @returns {boolean}
   */
  isBuilt() {
    return !this.isBuilding();
  }

  /**
   * The URL for the activity's thumbnail, or null if it has no thumbnail.
   *
   * @type {?String}
   */
  get thumbnail() {
    if (!this.media.thumbnail) {
      console.warn(`Activity ${this.id} has no thumbnail.`);
    }

    return this.media.thumbnail || null;
  }

  /**
   * The URL for the activity's video, or null if it has no video.
   *
   * @type {?String} 
   */
  get video() {
    if (!this.media.video) {
      return null;
    }

    const url = this.media.video
      .replace('youtu.be', 'youtube.com/embed')
      .replace('watch?v=', 'embed/');

    return url;
  }

  get videoID() {
    if (!this.media.video) {
      return null;
    }

    const url = this.video;

    const tokens = url.split('/');
    return tokens[tokens.length - 1];
  }

  get projectChainId() {
    if (!this.context) {
      return null;
    }

    const tokens = this.context.replace('class-', '').split('-');

    return tokens.slice(0, 2).join('-');
  }

  /**
   * The URI for the activity's playable game, or null if the activity has no playable game.
   *
   * @type {?String}
   */
  get shareURI() {
    if (!this.isBuilt()) {
      return null;
    }

    if (!this.id) {
      console.warn(`Activity has no objectID.`);
      return null;
    }

    return `/play/${this.id}`;
  }

  get editURI() {
    return `/creator-lab/${this.context}`;
  }

  /**
   * Converts the activity to a presentable card.
   *
   * @param {Object} params - Parameters to pass to the React element.
   * @param {number} params.delay - How long to wait before displaying the card.
   * @returns {ReactElement} A card representing the activity.
   */
  toCard(options = {}) {
    const styles = clsx({
      [`fade-in-${options.delay}`]: options.hasOwnProperty('delay')
    });

    return <ActivityCard
      key={this.id}
      activity={this}
      className={styles}
      personal={options.personal}
    />;
  }
}

export const factory = (data = {}) => new Activity({
  id: `factory-activity-${uuid()}`,
  status: 'complete',
  context: 'factory-context',
  title: 'Factory Activity',
  ...data,

  media: {
    thumbnail: 'https://placekitten.com/456',
    video: 'https://youtube.com/watch?v=lJIrF4YjHfQ',
    ...(data.media || {})
  }
});

export default Activity;
