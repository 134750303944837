export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const SET_ARTIFACTS = 'SET_ARTIFACTS';
export const SET_CURRENT_ARTIFACT = 'SET_CURRENT_ARTIFACT';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_ACTIVITY = 'SET_ACTIVITY';
export const SET_SIGNIN = 'SET_SIGNIN';
export const SET_NAVBAR = 'SET_NAVBAR';
export const SET_POPUP = 'SET_POPUP';
export const SET_CONTEXTMENU = 'SET_CONTEXTMENU';
export const SET_STUDIO_COMMAND = 'SET_STUDIO_COMMAND';
export const SET_STUDIO_SCREEN = 'SET_STUDIO_SCREEN';
export const SET_STUDIO_STUDENTS = 'SET_STUDIO_STUDENTS';
export const SET_STUDIO_SELECTED_STUDENT = 'SET_STUDIO_SELECTED_STUDENT';
export const SET_STUDIO_COMMS_VOLUME = 'SET_STUDIO_COMMS_VOLUME';
