import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchSeeds } from 'api';

/**
 * React hook for retrieving the available seed projects.
 *
 * @method
 * @returns {ResourceState}
 */
const useSeeds = (params = {}) => {
  const { focus } = params;
  const { subscriptions } = useSelector(state => state.auth);
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await fetchSeeds({ subscriptions });
      const focusIndex = data.findIndex(activity => activity.context === focus);

      if (focusIndex >= 0) {
        setData([
          data[focusIndex],
          ...data.slice(0, focusIndex),
          ...data.slice(focusIndex + 1)
        ]);
      } else {
        setData(data);
      }

      setLoaded(true);
    })();
  }, [focus, subscriptions]);

  return { loaded, data };
};

export default useSeeds;
