
import React, { Component } from 'react';
import Rating from 'react-rating'
import Action from 'components/Action'

import './styles.scss';

class Rate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ratings: {}
        };
    }

    onClick = (item, value) => {
        console.log('Rated', item, value)
        var ratings = this.state.ratings;
        ratings[item] = value
        this.setState({ ratings: ratings })
    }

    render() {
        var actions = [{ label: 'done', fn: () => { this.props.onDone(this.state.ratings) } }]

        return (
            <div className="Rate__wrapper">
                <div className="centered">
                    <div className="title">Rating</div>
                    {Object.keys(this.props.criteria).map((key, idx) => {
                        return (
                            <div key={idx} className="rating__wrapper">
                                <div className="label">{this.props.criteria[key]}</div>
                                <div className="rating">
                                    <Rating start={0} stop={this.props.maxRating} initialRating={this.state.ratings[key] ? this.state.ratings[key] : 1} onClick={(value) => { this.onClick(key, value) }} />
                                </div>
                            </div>
                        )
                    })}
                    <div className="action_btn">
                        <Action actions={actions} />
                    </div>
                </div>
            </div>
        )

    }
}

export default Rate;
