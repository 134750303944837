import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setStudioSelectedStudent, setStudioStudents, setStudioScreen } from 'actions/studio';
import { LOBBY_SCREEN } from 'pages/Studio/types';


import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import './styles.scss';

import Welcome from './Welcome'
import Game from './Game'
import Summary from './Summary'
import ClassEnd from './ClassEnd'
import LabEnd from './LabEnd';


class LobbyView extends Component {
  constructor(props) {

    super(props);
    this.state = {
    }
  }
  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("-lv componentDidUpdate: ", this.props, this.state, this.prevState)

    // If we are on welcome and the student raises their hand, move to game screen
    if (this.props.screen_id === LOBBY_SCREEN && this.props.subScreen_id === 0 && this.props.students.find(s => s.participate && s.identity === this.props.identity)) {
      this.props.setStudioScreen({ screen_id: LOBBY_SCREEN, class_screen_id: LOBBY_SCREEN, subScreen_id: 1 })
    }

    // Switch slider when subScreen_id changes
    if (prevProps.subScreen_id !== this.props.subScreen_id) {
      this.slider.slickGoTo(this.props.subScreen_id)
    }

  }

  renderSlides() {
    if (this.props.data.lab) {
      return <>
        <Welcome scale={this.props.data.scale} show={this.props.screen_id === LOBBY_SCREEN && this.props.subScreen_id === 0} />
        <LabEnd {...this.props} />
      </>;
    }

    return [
      <Welcome scale={this.props.data.scale} show={this.props.screen_id === LOBBY_SCREEN && this.props.subScreen_id === 0} />,
      <Game show={ this.props.screen_id === LOBBY_SCREEN && this.props.subScreen_id === 1} />,
      <Summary />,
      <ClassEnd show={this.props.screen_id === LOBBY_SCREEN && this.props.subScreen_id === 3} />
    ];
  }

  render() {
    const classes = {
      main: clsx('LobbyView__wrapper'),
    }
    var settings = {
      accessibility: false,
      dots: false,
      speed: 150,
      centerMode: false,
      onSelect: null,
      infinite: false,
      swipe: false,
      touchMove: false,
      swipeToSlide: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
    };

    return (
      <div className={classes.main}>

        < Slider className={classes.slider} ref={slider => (this.slider = slider)} {...settings}>
          {this.renderSlides()}
        </Slider>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  //_student: state.studioSelectedStudent.identity,
  identity: state.auth.identity,
  screen_id: state.studioScreen.screen_id,
  lastScreen_id: state.studioScreen.lastScreen_id,
  subScreen_id: state.studioScreen.subScreen_id,
  students: state.studioStudents.students,
  studio_cmd: state.studioCommand.command,

});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ setStudioSelectedStudent, setStudioStudents, setStudioScreen }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LobbyView);
