import { useRef, useState, useEffect } from 'react';

const useIntersection = (observedRef, context) => {
  const [maxY, setMaxY] = useState(0);
  const observerRef = useRef();

  observerRef.current = new IntersectionObserver(([ entry ]) => {
    const { y } = entry.boundingClientRect;

    if (y > maxY) {
      setMaxY(y);
    }
  });
  
  useEffect(() => {
    setMaxY(0);
  }, [context]);

  useEffect(() => {
    if (observedRef.current) {
      observerRef.current.observe(observedRef.current);
    }
    return () => observerRef.current.disconnect();
  }, [observedRef]);

  return maxY;
};

export default useIntersection;
