import { useEffect, useState } from 'react';
import { lookupUser } from 'api';

const useUser = identity => {
    const [user, setUser] = useState();

    useEffect(() => {
        if (!identity) {
            return;
        }

        lookupUser(identity).then(setUser);
    }, [identity]);

    return user;
};

export default useUser;
