import React from 'react';
import BaseCard from './BaseCard';

const LoadingCard = ({ delay = 0 }) => (
  <BaseCard className={`cl-card--loading delay-${delay}`}>
    Loading&hellip;
  </BaseCard>
);

export default LoadingCard;
