import React from 'react';
import { Link } from 'react-router-dom';
import BaseCard from './BaseCard';
import LabTime from './LabTime';
import LoadingIcon from 'assets/images/icons/hammer-and-wrench.png';
import editIcon from 'assets/images/icons/anvil';
import clsx from 'clsx';

/**
 * Card presentation for a lab. Note that "labs" can be true labs (i.e. ad-hoc
 * classes initiated by a student) or scheduled classes.
 *
 * The card will try to include display information from an associated publish
 * if one is available; otherwise, it will fall back to some generic defaults.
 * These defaults should only appear for true labs.
 *
 * @see {@link LabTime} for how time is presented on the card, which can vary
 *   depending on the difference between the current time and the card's
 *   start, end, and terminated times.
 */
const LabCard = ({ lab, className }) => (
  <BaseCard id={lab.id} className={className}>
    <img
      src={lab.publish ? lab.publish.thumbnail : LoadingIcon}
      alt=""
      className={clsx('cl-card__image', !lab.publish && 'cl-card__image--contain')}
    />

    <div className="cl-card__info">
      <h3 className="cl-card__title">
        {lab.publish ? lab.publish.title : (lab.isActive() ? 'Lab in progress' : 'Finalizing your lab...')}
      </h3>

      <LabTime lab={lab} />
    </div>

    <div className="cl-card__buttons">
      {!lab.hasEnded() && (
        <Link
          to={lab.studioURI}
          className="cl-button"
          disabled={!lab.isJoinable()}
        >
          <img src={editIcon.hoverState} alt="" className="cl-button__icon cl-button__icon--hover" />
          <img src={editIcon.defaultState} alt="" className="cl-button__icon" />
          {lab.isLab ? 'Continue' : 'Join'}
        </Link>
      )}
    </div>
  </BaseCard>
);

export default LabCard;
