// This class ...

import { studio_cmd } from 'graphql/handler';
import ActionableType from '../enums/ActionableType'
import ActionableState from '../enums/ActionableState'


class ActionHandler {
    constructor(classId) {
        this._classId = classId

    }

    send = (action, student, students) => {
        const payloads = this._getActionPayloads(action, student, students)

        // Send the payloads associated to that action
        payloads.forEach((payload) => {
            studio_cmd(this._classId, payload.action, payload.data)
        })

    }

    _getActionPayloads = (action, student, students) => {
        var payloads = [] // [ {"action": "some-action", "data": [{"identity": "abc123", "username": "fe3s-user1", "someField": True}, {"identity": "def456", "username": "fe3s-user2", "someField": False}]} ]

        if (action === 'broadcast') {
            payloads.push({ 'action': 'viewScreen', 'data': this._createPayload(student, students, ['viewScreen'], ActionableType.TURN_ALL_OFF) })
            payloads.push({ 'action': 'control-create', 'data': this._createPayload(student, students, ['controlCreate'], ActionableType.TURN_ALL_ON) })
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['broadcast', 'controlWatch'], ActionableType.TOGGLE_SELECTED_REST_OFF) })
            
            const watchUrl = student.create_url 
            const broadcasterIdentity = student.identity
            payloads.push({ 'action': 'watch', 'data': { watch_url: watchUrl, broadcasterIdentity: broadcasterIdentity } })

        } else if (action === 'reset-broadcast') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['broadcast', 'controlWatch'], ActionableType.TOGGLE_SELECTED_REST_OFF) })
            
            // Resets the watch url of everyone back to the instructor (reset-broadcaster only ever called with instructor as the student parameter)
            const watchUrl = student.create_url
            const broadcasterIdentity = student.identity
            payloads.push({ 'action': 'watch', 'data': { watch_url: watchUrl, broadcasterIdentity: broadcasterIdentity } })
        } else if (action === 'mute') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['mute'], ActionableType.TOGGLE_SELECTED) })
        } else if (action === 'mic-toggle') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['micToggleable'], ActionableType.TOGGLE_SELECTED) })
        } else if (action === 'mic-disable') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['micToggleable'], ActionableType.TURN_SELECTED_OFF) })
        } else if (action === 'mic-enable') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['micToggleable'], ActionableType.TURN_SELECTED_ON) })
        } else if (action === 'mic-disable-all') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['micToggleable'], ActionableType.TURN_ALL_OFF) })
        } else if (action === 'mic-enable-all') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['micToggleable'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'control-watch') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['controlWatch'], ActionableType.TOGGLE_SELECTED_REST_OFF) })
        } else if (action === 'control-create') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['controlCreate'], ActionableType.TOGGLE_SELECTED) })
        } else if (action === 'reset-control-create') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['controlCreate'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'participate') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['participate'], ActionableType.TOGGLE_SELECTED) })
        } else if (action === 'participate-off') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['participate'], ActionableType.TURN_SELECTED_OFF) })
        } else if (action === 'mouse-on-instructor-video') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['dimInstructorVideo'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'mouse-off-instructor-video') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['dimInstructorVideo'], ActionableType.TURN_ALL_OFF) })
        } else if (action === 'viewScreen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['viewScreen'], ActionableType.TOGGLE_SELECTED_REST_OFF) })
        } else if (action === 'dcv-stream-warning') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['dcvStreamWarning'], ActionableType.TOGGLE_SELECTED) })
        } else if (action === 'reload-student-browser') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['reload-student-browser'], ActionableType.TOGGLE_SELECTED) })
        } else if (action === 'flag-student-help') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['flag-student-help'], ActionableType.TOGGLE_SELECTED) })
        } else if (action === 'lock-create-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['createScreenNavigable'], ActionableType.TURN_ALL_OFF) })
        } else if (action === 'lock-watch-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['watchScreenNavigable'], ActionableType.TURN_ALL_OFF) })
        } else if (action === 'lock-lobby-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['lobbyScreenNavigable'], ActionableType.TURN_ALL_OFF) })
        } else if (action === 'lock-play-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['playScreenNavigable'], ActionableType.TURN_ALL_OFF) })
        } else if (action === 'lock-portfolio-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['portfolioScreenNavigable'], ActionableType.TURN_ALL_OFF) })
        } else if (action === 'unlock-create-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['createScreenNavigable'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'unlock-watch-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['watchScreenNavigable'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'unlock-lobby-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['lobbyScreenNavigable'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'unlock-play-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['playScreenNavigable'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'unlock-portfolio-screen') {
            payloads.push({ 'action': action, 'data': this._createPayload(student, students, ['portfolioScreenNavigable'], ActionableType.TURN_ALL_ON) })
        } else if (action === 'update-create-url') {
            const watchUrl = student.create_url
            payloads.push({ 'action': action, 'data': [{ identity: student.identity, username: student.username, create_url: watchUrl }] })
        } else {
            // Unknown
        }

        return payloads

    }

    _createPayload = (selectedStudent, students, fields, actionableType) => {
        var actionState = this._getFieldStates(actionableType)

        // Create the payload    
        var payload = []


        students.forEach((student) => {
            // Strip out any extra fields from the student (and remove null values by setting them to false so the code can work with them below)
            var minifiedStudent = {}
            fields.forEach((field) => {
                if (student[field] === null) {
                    minifiedStudent[field] = false
                } else {
                    minifiedStudent[field] = student[field]
                }
            })

            minifiedStudent['identity'] = student['identity']
            minifiedStudent['username'] = student['username']

            student = minifiedStudent

            // Set the students fields
            if (selectedStudent.identity === student.identity) {
                // For the selected student, toggle the state if nothing was set for it
                if (actionState.selected !== ActionableState.SKIP) {
                    if (actionState.selected === ActionableState.TOGGLE) {
                        // If multiple fields (like in broadcast, control) where control may have been toggled seperately base the toggle state on first field
                        var stateOverride = null

                        fields.forEach((field) => {
                            if (stateOverride === null) stateOverride = !student[field]
                            student[field] = stateOverride
                        })
                    } else {
                        fields.forEach((field) => {
                            student[field] = actionState.selected === 'true' ? true : false
                        })
                    }

                    payload.push({
                        ...student
                    })
                }
            } else {
                // For other students (only send if theres a state to set) (theres no toggle case for others)
                if (actionState.other !== ActionableState.SKIP) {
                    fields.forEach((field) => {
                        student[field] = actionState.other === 'true' ? true : false
                    })

                    payload.push({
                        ...student
                    })
                }
            }
        })

        return payload

    }

    _getFieldStates = (actionableType) => {
        // IMPORTANT: Define a new enum in ActionableStates.js when creating a new state

        // Based on the action type parse and set the value of the fields states
        // Field can have 4 states: skip, toggle, true, false

        var selected = ActionableState.SKIP
        var other = ActionableState.SKIP

        switch (actionableType) {
            case ActionableType.TOGGLE_SELECTED:
                selected = ActionableState.TOGGLE
                other = ActionableState.SKIP
                break;
            case ActionableType.TURN_SELECTED_ON:
                selected = ActionableState.TRUE
                other = ActionableState.SKIP
                break;
            case ActionableType.TURN_SELECTED_OFF:
                selected = ActionableState.FALSE
                other = ActionableState.SKIP
                break;
            case ActionableType.TURN_SELECTED_ON_REST_OFF:
                selected = ActionableState.TRUE
                other = ActionableState.FALSE
                break;
            case ActionableType.TURN_SELECTED_OFF_REST_ON:
                selected = ActionableState.FALSE
                other = ActionableState.TRUE
                break;
            case ActionableType.TOGGLE_SELECTED_REST_ON:
                selected = ActionableState.TOGGLE
                other = ActionableState.TRUE
                break;
            case ActionableType.TOGGLE_SELECTED_REST_OFF:
                selected = ActionableState.TOGGLE
                other = ActionableState.FALSE
                break;
            case ActionableType.TURN_REST_ON:
                selected = ActionableState.SKIP
                other = ActionableState.TRUE
                break;
            case ActionableType.TURN_REST_OFF:
                selected = ActionableState.SKIP
                other = ActionableState.FALSE
                break;
            case ActionableType.TURN_ALL_ON:
                selected = ActionableState.TRUE
                other = ActionableState.TRUE
                break;
            case ActionableType.TURN_ALL_OFF:
                selected = ActionableState.FALSE
                other = ActionableState.FALSE
                break;
            default:
                console.log("Error: Unknown ActionableType: ", actionableType)
                // Return
                break;
        }

        return {
            selected,
            other
        }
    }

}


export default ActionHandler;
