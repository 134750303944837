export const sec2time = timeInSeconds => {
  const formater = (num, size) => ('000' + num).slice(size * -1);

  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;

  return `${formater(minutes, 2)}:${formater(seconds, 2)}`;
};

export const getCurrentTime = () => Date.now() + window.timeoffset;

export const TIME = {
  ONE_SECOND: 1000,
  TEN_SECONDS: 10000,
  ONE_MINUTE: 60000,
  FIVE_MINUTES: 300000,
  TWENTY_MINUTES: 1200000,
  THIRTY_MINUTES: 1800000,
  SIXTY_MINUTES: 3600000,
  TWENTY_FOUR_HOURS: (60 * 1000) * 60 * 24, // (60 secs * 1000 ms in sec) * 60 minutes * 24 hours in a day
  ONE_WEEK: ((60 * 1000) * 60 * 24) * 7,
  TWO_WEEKS: ((60 * 1000) * 60 * 24) * 14
}

export const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
