import React from 'react';
import VideoGameCharacters from 'assets/images/community.png';
import MembershipIcon from '../../assets/membership-icon-large.png';
import './styles.scss';

const Community = () => (
  <section className="community-info">
    <h2 className="community-info__title">
      Join a community of creators
    </h2>
    <div className="community-info__content">
      <img
        src={VideoGameCharacters}
        alt="Video game characters"
        className="community-info__image"
      />
      <p className="community-info__description">
        UME Games is a COPPA safe learning platform designed for young creators who are passionate about making video games. Our courses are designed to develop problem solving, engineering, math, art, and storytelling and to learn industry relevant technologies like Unity and GIMP.
      </p>
      <div className="community-info__cta">
        <img src={MembershipIcon} alt="" />
        with CREATOR MEMBERSHIP
      </div>
    </div>
  </section>
);

export default Community;
