import './styles.scss';

const CategorySelect = ({ value, options, onChange }) => (
  <div className="arcade-category-select">
    <select
      value={value}
      onChange={onChange}
    >
      {options.map(({ id, label }) => (
        <option
          key={id}
          value={id}
        >
          {label}
        </option>
      ))}
    </select>
  </div>
);

export default CategorySelect;
