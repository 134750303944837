import React, { useMemo } from 'react';

import CardGrid from 'components/CardGrid';
import StartNewProjectCard from '../StartNewProjectCard';

import { useMyActivities, useMyLabs } from 'hooks';
import './styles.scss';

const MyProjects = ({ seeds = [] }) => {
  const activities = useMyActivities();
  const labs = useMyLabs();

  const cards = useMemo(() => {
    const items = [
      // Show labs if they're active or if they don't have an activity record yet.
      ...labs.data.filter(lab => {
        return lab.isActive() || !activities.data.some(activity => activity.context === lab.context);
      }),

      // Show activities if they're built or if there are no corresponding active labs.
      ...activities.data.filter(activity => {
        return activity.isBuilding() || !labs.data.some(lab => lab.isActive() && lab.context === activity.context);
      })
    ];

    return [
      <StartNewProjectCard activities={seeds} key="start-new" />,
      ...items.map((item, i) => item.toCard({ delay: i, personal: true }))
    ];
  }, [labs, activities, seeds]);

  return (
    <section className="my-projects">
      <CardGrid>
        {activities.loaded && labs.loaded ? cards : null}
      </CardGrid>
    </section>
  );
};

export default MyProjects;
