import './styles.scss';

const Card = ({ children, image, title }) => (
  <article className="home-card">
    <img
      className="home-card__image"
      src={image}
      alt=""
      width="264px"
      height="264px"
    />
    <h3 className="home-card__title">
      {title}
    </h3>
    <div className="home-card__body">
      {children}
    </div>
  </article>
);

export default Card;
