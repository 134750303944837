import { Link } from 'react-router-dom';
import clsx from 'clsx'
import { useAudio } from 'contexts/audioContext';

const chooseTag = props => {
  if (props.hasOwnProperty('to')) {
    return Link;
  }

  if (props.hasOwnProperty('href')) {
    return 'a';
  }

  return 'button';
};

const UMEButton = ({
  icon,
  hoverIcon,
  children,
  onClick,
  className,
  clip = 'click',
  alt = '',
  ...props
}) => {
  const { play } = useAudio();

  const handleClick = event => {
    console.log(clip);
    if (clip) {
      play(clip);
    }

    onClick?.(event);
  };

  const Tag = chooseTag(props);

  return (
    <Tag
      {...props}
      onClick={handleClick}
      className={clsx('cl-button', className)}
    >
      {hoverIcon && <img src={icon} alt={alt} className={clsx('cl-button__icon', 'cl-button__icon--hover', !children && 'cl-button__icon--solo')} />}
      {icon && <img src={icon} alt={alt} className={clsx('cl-button__icon', !hoverIcon && 'cl-button__icon--static', !children && 'cl-button__icon--solo')} />}
      {children}
    </Tag>
  );
};

export default UMEButton;
