import { SET_ACTIVITY, SET_NAVBAR } from '../actions/types';

const initialState = {
    action: 'arcade',
    navbar: true
};

function activityReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVITY:
            return {
                ...state,
                ...action.payload
            };
        case SET_NAVBAR:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}

export default activityReducer;
